<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header :current='current' :plate="plate"></Header>
        <div class="Rulers_box">
            <!-- 管理人园地 -->
            <div class="xuan_tit" v-if="plate==3||plate==9">
                <div class="xuan_tab">
                    <div class="flexar" @click="administrator('0')" :class="tabnum==='0'?'xuan_tab_active':''">管协动态</div>
                    <img src="../../image/dashed.png" alt="">
                    <div class="flexar" @click="administrator('1')" :class="tabnum==='1'?'xuan_tab_active':''">管理人选任</div>
                    <!-- <img src="../../image/dashed.png" alt="">
                    <div class="flexar">同业交流</div> -->
                </div>
            </div>
            <!-- 法律法规 -->
            <div class="xuan_tit" v-if="plate==1">
                <div class="xuan_tab">
                    <div class="flexar" @click="law('0')" :class="tabnum==='0'?'xuan_tab_active':''">法律法规</div>
                    <img src="../../image/dashed.png" alt="">
                    <div class="flexar" @click="law('1')" :class="tabnum==='1'?'xuan_tab_active':''">司法解释</div>
                    <img src="../../image/dashed.png" alt="">
                    <div class="flexar" @click="law('2')" :class="tabnum==='2'?'xuan_tab_active':''">规范性文件</div>
                </div>
            </div>

            <!-- 右侧 -->
            <div class="Rules_box" :style="{width:(plate=='1'||plate=='3')?'90%':'100%'}">
                <div class="Rules_input" v-if="plate==3||plate==4||plate==5||plate==8">
                    <!-- <div class="selectcity" >
                        <select name="public-choice" v-model="provinceSelected" @change="getprovinceSelected">                                        
                            <option :value="province.id" v-for="province in provinceList" >{{province.name}}</option>                                    
                        </select>
                        <select name="public-choice" v-model="citySelected" @change="getcitySelected">                                        
                            <option :value="city.id" v-for="city in cityList" >{{city.name}}</option>                                    
                        </select>
                    </div> -->
                    <el-cascader
                        clearable
                        v-model="areavalue"
                        :options="selectarea"
                        :props="{ expandTrigger: 'hover' }"
                        @change="handleChange">
                    </el-cascader>
                    <div class="searchbtn" @click="searchlist">搜索</div>
                </div>
                <div class="Rules_con">
                    <div class="Rules_list" @click="Godetail(item.id,item.pdf_input[0].path)" v-for="(item,index) in systemMsg" :key="index" v-if="systemMsg.length!==0">
                        <img :src="item.first_image.length==0?logoimage:baseURL+item.first_image[0].path" alt="">
                        <div class="Rules_mess">
                            <div class="Rules_tit">{{item.title}}</div>
                            <div class="Rules_other"><span>{{item.author}}</span><span>{{item.create_time}}</span></div>
                        </div>
                    </div>
                   
                    <Nocontent v-if="systemMsg.length==0" ></Nocontent>
                    
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage4"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="5"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
       
    </div>
</template>
<!-- 管理人选任，投资人招募，资产拍卖，管理人园地（名册），破产公告       （需要选择省市） -->
<script>
    import Header from '../../components/Header.vue';
    import Nocontent from '../../components/Nocontent.vue';
    import {InformationList,GetArea } from '@/request/api';
    import Common from '../../request/common.vue'
    import { getcitydata } from "../../../public/city-picker.data";

    export default {
        name: 'Rulers',
        components: {
            Header,
            Nocontent
        },
        data(){
            return{
                baseURL:'https://pc.pochanjie.com',
                
                tabshow:false,
                systemMsg:[],
                total:0,
                limit:'5',
                page:'1',
                current:Common.current,
                plate:'1',
                currentPage4: 1,
                tabnum:'0',
                provinceList:[
                    {
                        id: 'A',
                        name: '北京'
                    },
                    {
                        id: '1',
                        name: '山西'
                    },
                    {
                        id: '2',
                        name: '天津'
                    }
                ],
                provinceSelected: '',
                cityList:[
                    {
                        id: 'A',
                        name: '太原'
                    },
                    {
                        id: '1',
                        name: '大同'
                    },
                    {
                        id: '2',
                        name: '运城'
                    }
                ],
                citySelected: '',
                areavalue: [],
                selectarea: [{
                    value: '山西',
                    label: '山西',
                    children: [{
                        value: '太原',
                        label: '太原',
                    }, {
                        value: '大同',
                        label: '大同',
                            
                    }]
                }],
                pro:'',
                city:'',

                logoimage:require('../../image/logo.png'),
            }
        },
        created(){
            this.selectarea=getcitydata('3')

            this.current=this.$route.query.current;
　　　　　　　//如果没有这句代码，select中初始化会是空白的，默认选中就无法实现
            this.provinceSelected = this.provinceList[0].id;
            this.citySelected = this.cityList[0].id;
            // 破产公告
            this.plate=this.$route.query.plate;    //第一次进入rules页面，没有plate
            // GetArea({    //获取省市区
            //     level:1,   //两层
            // }).then(res=>{
            //     this.selectarea=res.data;
            // })
            this.pro="";
            this.city="";
            this.areavalue=[]
            this.List()
            // this.Listlength()
        },
        watch:{   //同一页面，监听plate
            "$route":function(to,from){
                if(to.query.plate!==from.query.plate){
                    this.plate=to.query.plate;
                    this.current=to.query.current;
                    this.page=1;
                    this.currentPage4=1;
                    this.tabnum='0';
                    this.pro="";
                    this.city="";
                    this.areavalue=[];

                    this.List()
                    // this.Listlength()
                }
            //from 对象中包含当前地址
            //to 对象中包含目标地址
            //其实还有一个next参数的，这个参数是控制路由是否跳转的，如果没写，可以不用写next()来代表允许路由跳转，如果写了就必须写next(),否则路由是不会生效的。
            }
        },
        mounted () {
            
        },
        destroyed () {
            
        },
        methods:{
            handleChange(value) {   //选择地址
                this.pro=value[0];
                this.city=value[1];
                this.areavalue=value;
            },
            List:function(){
                InformationList({
                    plate:this.plate,   //公告id
                    page:this.page,
                    limit:this.limit,
                    pro:this.pro,
                    city:this.city,
                    plate_class:this.tabnum,
                    from:'pc'
                }).then(res => { 
                    this.systemMsg=res.data.rows;
                    var aa=[];
                    aa=this.systemMsg;
                    this.systemMsg=[];
                    this.systemMsg=aa;
                    this.total=res.data.total;
                })
            },
            // Listlength:function(){
            //     InformationList({
            //         plate:this.plate,   //公告id
            //         page:this.page,
            //         limit:this.limit,
            //         pro:this.pro,
            //         city:this.city,
            //         plate_class:this.tabnum,
            //         from:'pc'
            //     }).then(res => { 
            //         this.total=res.data.length;
            //     })
            // },
　　　　　　getprovinceSelected(){
            },
        　  getcitySelected(){
            },
            handleSizeChange:function(val){
                this.limit=val;
                this.page=1;
                this.currentPage4=1;
                this.List()
                // this.Listlength()

            },
            handleCurrentChange:function(val) {
                this.page=val;
                this.currentPage4=val;
                this.List()
                // this.Listlength()

            },
            searchlist:function(){
                // this.pro="";
                // this.city=""
                this.List();
                // this.Listlength()

            },
            Godetail:function(id,path){
                window.open(this.baseURL+path)
            },
            law:function(index){
                this.tabnum=index;
                this.page=1;
                this.List()
                // this.Listlength()
                this.handleCurrentChange(1)
                this.currentPage4=1;
            },
            administrator:function(index){
                this.tabnum=index;
                if(index==0) this.plate=9
                else this.plate=3;
                this.page=1;
                this.List()
                // this.Listlength()
                
            }
        }
    }
</script>
<style scoped>
    .Rulers_box{
        background: #eef3fa;
        padding-bottom: 2rem;
        min-height: 100%;
        display: flex;
        width: 86.4%;
        margin: 0 auto;
        margin-top: 27px;
        justify-content: space-between;
    }
.Download_tit{
   font-family: 'siyuanb';
    font-size: 16px;
    line-height: 24px;
    
    position: absolute;
    left: 43px;
    top: 138px;
}

/* 选项卡 */
.xuan_tit{
    position: relative;
    margin-top: 20px;
}
.xuan_tab{
    /* position: absolute;
    top: 47px;
    left: 13.4rem; */
}
.xuan_tab div{
    width: 142px;
    height:56px;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    color: #706E8C;
    font-family:'siyuanb';
    font-size: 16px;
    cursor: pointer;
}
.xuan_tab .xuan_tab_active{
    background: #43A0F5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    color: #fff;
}
.xuan_tab img{
    width: 4px;
    display: block;
    margin: 5px auto;
}
/* 右侧 */
.Rules_box{
    width: 90%;
    /* margin: 0 auto; */
    position: relative;
    height: auto;
}
.Rules_input{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}
.Rules_input select{
    width: 15rem;
    height: 4rem;
    background: #FFFFFF;
    border: 0.1rem solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 0.4rem;
    padding-left: 1.3rem;
    outline: none;
    line-height: 4rem;
    cursor: pointer;
    margin-right: 10px;
}
.Rules_input .searchbtn{
    width: 80px;
    height: 40px;
    background: #43A0F5;
    border-radius: 5px;
    margin-left: 10px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    font-size: 14px;

}
.Rules_con{
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    padding: 20px 20px 50px 20px;
    background: #e7eef5;
}
.Rules_list{
    height: 92px;
    /* left: 312px; */
    /* top: 292px; */
    background: #F5FAFE;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
    padding: 13px 16px;
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
}
.Rules_list img{
    width: 158px;
    height: 94px;
    background: #CED8E0;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
    margin-right: 7px;
}
.Rules_mess{
    position: relative;
    width: 86%;
}
.Rules_tit{
    font-family:'siyuanb';
    font-size: 18px;
    line-height: 27px;
    
    
}
.Rules_other{
    font-size: 14px;
    line-height: 21px;
    
    
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    bottom: 0;
}
/* 分页 */
.el-pagination{
    position: absolute;
    bottom: -30px;
    right: 20px;
}
</style>