<template>
    <div class="Bottom_box">
        <!-- <div class="grids_title flexbe">
           <div class="grids_title_name">管理人合作</div> -->
           <!-- <div class="more">更多></div> -->
        <!-- </div> -->
        <div style="position: relative;">
            <div style="color: #43A0F5; position: absolute;top: 15px;text-align: center;width: 100%;font-size: 16px;" >管理人合作</div>
            <dv-decoration-5 style="width: 90%;height: 25px;margin: 0 auto;position: absolute;top: 30px;left:5%" :color="['#43A0F5','#43A0F5']" dur="6"/>
        </div>
        <div class="Guan_mess">
            <!-- <iframe src="gaode_rota.html" frameborder="0" ></iframe> -->
            <!-- <img :src="contentmess.logo" alt="">
            <div class="mess_list_box">
                <div class="mess_list">
                    <span class="iconfont icon-Frame-26"></span>
                    {{contentmess.phone}}
                </div>
                <div class="mess_list">
                    <span class="iconfont icon-Frame-22"></span>
                    {{contentmess.address}}
                </div>
                <div class="mess_list">
                    <span class="iconfont icon-Frame-25"></span>
                    {{contentmess.email}}
                </div>
                <div class="mess_list">
                    <span class="iconfont icon-Frame-24"></span>
                    {{contentmess.wechat}}
                </div>
            </div> -->
        </div>
        <div style="height: 260px;margin-top: 40px;">
            <!-- <el-amap vid="amap" :plugin="plugin" class="amap-demo" :center="center" :mapStyle="mapStyle">
            </el-amap> -->
            <iframe src="aa.html" frameborder="0" style="width: 100%;height: 100%"></iframe>

        </div>
        <!-- <div class="grids_con">
            <img src="../assets/logo.jpg" alt="">
            <div>谋道破产管理智慧平台</div>
            <div><span class="iconfont icon-shouji"></span>19872005565</div>
            <div><span class="iconfont icon-dizhi"></span>湖北省武汉市东西湖区</div>
            <div><span class="iconfont icon-tubiao209"></span>3058064310@qq.com</div>
            <div><span class="iconfont icon-weixin"></span>pochanjie</div>
        </div> -->
    </div>
</template>
 
<script>
// import {InformationBanner} from '@/request/api';

    
    export default {
        name: 'Guanli',
        data(){
            return{
                contentmess:{},
                mapStyle: 'amap://styles/normal',
                center: [121.59996, 31.197646],
                lng: 0,
                lat: 0,
                loaded: false,
                plugin: [{
                    enableHighAccuracy: true,//是否使用高精度定位，默认:true
                    timeout: 100,          //超过10秒后停止定位，默认：无穷大
                    maximumAge: 0,           //定位结果缓存0毫秒，默认：0
                    convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
                    showButton: true,        //显示定位按钮，默认：true
                    buttonPosition: 'RB',    //定位按钮停靠位置，默认：'LB'，左下角
                    showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
                    showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
                    panToLocation: true,     //定位成功后将定位到的位置作为地图中心点，默认：true
                    zoomToAccuracy:true,//定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
                    extensions:'all',
                    pName: 'Geolocation',
                    events: {
                        init(o) {
                        // o 是高德地图定位插件实例
                            o.getCurrentPosition((status, result) => {
                                if (result && result.position) {
                                    self.lng = result.position.lng;
                                    self.lat = result.position.lat;
                                    self.center = [self.lng, self.lat];
                                    self.loaded = true;
                                // self.$nextTick();
                                }
                            });
                        }
                    }
                }],
                drawer: false,
                direction: 'ltr',
                arrowbtn:'right',
                withHeader: false,
                appendToBody: true,
            }
        },
        created() {
            // 合作
            // InformationBanner({

            // }).then(res=>{
            //     this.contentmess=res.data;
            // })
        },
        methods:{
           
        }
    }
</script>
 
<style scoped>
.Guan_mess{
    display: flex;
    margin-top: 28px;
}
.Guan_mess img{
    width: 115px;
    height: 112px;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.3), -9px -9px 16px rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    margin-right: 60px;
}
.mess_list_box{

}
.mess_list{
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    font-size: 16px;
}
.mess_list span{
    margin-right: 24px;
    font-size: 18px;
}
</style>