<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header></Header>
        <div class="Network_box">
            <div class="Network_tit flexar" @click="titclick">协<br>同<br>办<br>公</div>
            <div class="Network_list" v-if="companyList.length!==0&&casedetail==false">
                <div class="list_tit">案件列表</div>
                <div class="list_con" v-for="(item,index) in companyList" :key="index">
                    <div class="list_mess flexbe">
                        <div class="list_mess_listbox">
                            <div class="list_mess_list">债务人：{{item.company_name}}</div>
                            <div class="list_mess_list">债权截止日期：{{item.claims_expire}}</div>
                            <div class="list_mess_list">受理法院：{{item.court}}</div>
                            <div class="list_mess_list">管理人机构：{{item.link_man}}</div>
                        </div>
                        <div class="list_mess_network" @click="Gonet(item.id)">
                            <img src="../../image/p_wang.png" alt="">
                            <div>协同办公</div>
                        </div>
                    </div>
                    <img src="../../image/case.png" alt="" class="list_case_img" @click="caseprogress(item,index)">
                    <div class="case_type" v-if="item.type=='open'">案件进展状态</div>
                    <div class="case_type_box" v-if="item.type=='open'">
                        <div style="color: #FD2001;font-size: 16px;text-align: center;line-height: 30px;">{{item.progress}}</div>
                        <div style="color: #FD2001;font-size: 14px;text-align: center;line-height: 20px;">{{item.progress_detail}}</div>
                        <div style="font-size: 14px;">{{item.progress_state}}</div>
                        <!-- <div>债权申报进行中</div>
                        <div>《债权表》实时更新</div> -->
                    </div>
                </div>
            </div>
            <div v-if="companyList.length==0&&casedetail==false" style="font-size: 16px;margin: 0 auto;line-height: 200px;">暂无内容</div>
            <!-- <div v-if="othertype==true&&casedetail==false" @click="loginother">登录或添加其他账号</div> -->
            <div class="Network_detail" v-if="casedetail==true">
                <div class="detail_left">
                    <div :class="indextype==current?'detail_left_list_active':'detail_left_list'"  v-for="(item,indextype) in memberlist" :key="indextype" @click="changenet(item,indextype)">
                        <img :src="item.headimgurl==''?require('../../image/logo.png'):item.headimgurl" alt="">
                        <span>{{item.real_name}}({{item.role_name}})</span>
                    </div>
                </div>

                <div class="detail_right">
                    <div class="detail_right_tit">{{casetype}}</div>
                    <div class="detail_right_con" >
                        <div id="aaaa">
                            <div v-for="(item,indextype) in rowslist" :key="indextype">
                                <div class="detail_chat">
                                    <div class="detail_chat_time">{{item.create_time}}</div>
                                    <div style="display: flex;justify-content: flex-start;align-items: center;" v-if="item.side=='left'">
                                        <!-- <div class="detail_chat_con_left" > -->
                                            <img style="margin-right: 19px;width: 50px;" :src="(item.headimgurl==''||item.headimgurl==null)?require('../../image/logo.png'):item.headimgurl" alt="">
                                            <div style="margin-right: 10px;max-width: 70%;">
                                                <div style="color: #999;font-size: 16px;">{{item.title}}</div>
                                                <div style="font-size: 13px;">{{item.content}}</div>
                                            </div>
                                        <!-- </div> -->
                                        <!-- <div class="detail_chat_con_right" > -->
                                            <div style="font-size: 30px;color: #43A0F5;margin-left: 10px;" v-for="(items,index) in item.file" :key="index" class="iconfont" :class="items.extension=='png'?'icon-png2':items.extension=='jpeg'?'icon-jpeg':items.extension=='jpg'?'icon-JPG':items.extension=='pdf'?'icon-pdf':items.extension=='doc'?'icon-DOC':items.extension=='docx'?'icon-DOCX':items.extension=='xlsx'?'icon-excel1':items.extension=='xls'?'icon-excel1':''" @click="read(items)"></div>
                                        <!-- </div> -->
                                    </div>
                                    <div style="display: flex;justify-content: flex-end;align-items: center;" v-if="item.side=='right'">
                                        <!-- <div class="detail_chat_con_left" > -->
                                        <div style="font-size: 30px;color: #43A0F5;margin-right: 10px;" v-for="(items,index) in item.file" :key="index" class="iconfont" :class="items.extension=='png'?'icon-png2':items.extension=='jpeg'?'icon-jpeg':items.extension=='jpg'?'icon-JPG':items.extension=='pdf'?'icon-pdf':items.extension=='doc'?'icon-DOC':items.extension=='docx'?'icon-DOCX':items.extension=='xlsx'?'icon-excel1':items.extension=='xls'?'icon-excel1':''" @click="read(items)"></div>
                                        <!-- </div> -->
                                        <!-- <div class="detail_chat_con_right"> -->
                                        <div style="margin-right: 10px;max-width: 70%;text-align: right;">
                                            <div style="color: #999;font-size: 14px;" >{{item.title}}</div>
                                            <div style="font-size: 13px;">{{item.content}}</div>
                                            
                                        </div>
                                        <img style="margin-right: 19px;width: 50px;" :src="(item.headimgurl==''||item.headimgurl==null)?require('../../image/logo.png'):item.headimgurl" alt="">
                                            
                                        <!-- </div> -->
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="detail_con">
                        <!-- <div class="detail_con_qian"><div style="text-align: right;margin-right: 10px;"><div>适时工作处理签</div><div>标题</div></div><input type="text" v-model="worktitle"></div>
                        <div class="detail_con_qian"><div style="text-align: right;margin-right: 10px;"><div>适时工作处理签</div><div>内容</div></div><textarea name="" id="" cols="30" rows="10" v-model="workcontent"></textarea></div> -->
                        <div>
                            <div>适时工作处理签</div>
                            <div>
                                <input type="text" v-model="worktitle" placeholder="请输入标题" style="height: 30px;line-height: 30px;margin: 10px 0;padding: 0 10px;">
                            </div>
                            <div>
                                <textarea name="" id="" cols="30" rows="10" v-model="workcontent" placeholder="请输入内容" style="padding: 10px;"></textarea>
                            </div>
                        </div>
                        <div class="detail_con_qian">
                            <span>上传附件：</span>
                            <!-- https://pc.pochanjie.com/api/claims/upload -->
                            <el-upload
                                class="upload-demo"
                                action=""
                                :headers="myHeaders"
                                :http-request="uploadSectionFile"
                                :auto-upload="true"
                                :on-preview="handlePreview"
                                :on-remove="handleRemove"
                                :on-error="uploadFileError"
                                :on-success="uploadFileSuccess"
                                :before-remove="beforeRemove"
                                multiple
                                :limit="3"
                                :on-exceed="handleExceed"
                                :file-list="fileList">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <span style="color: #999;font-size: 12px;margin-left: 10px;">1.支持扩展名：.jpg .png .jpeg .doc .docx .excel .pdf</span>
                                <!-- <span style="color: #999;font-size: 12px;margin-left: 10px;">2.请同一次上传同一种文件</span> -->
                                
                            </el-upload>
                            <!-- <input type="file" multiple  @change='upload' style="opacity: 0;z-index: 999;"> -->
                            <!-- <img src="../../image/upfile.png" alt="" style="cursor: pointer;position: absolute;left: 12%;"> -->
                        </div>
                        
                        <!-- 上传内容展示 -->
                        <!-- <div style="padding: 10px 0;" v-if="file_type!=='image'">
                            <div v-for="(item,index) in pathname" :key="index" v-if="pathname.length!==0" style="display: flex;justify-content: space-between;line-height: 30px;color: #999999;">
                                <span :class="item.type=='doc'?'icon-doc':item.type=='xlsx'?'icon-xls':'icon-pdf' " class="iconfont"></span>
                                <span style="width: 80%;overflow: hidden;display: block;">{{item.name}}</span>
                                <span @click="cancelpath(index)" >取消</span>
                            </div>
                        </div> -->
                        
                        <!-- <div class="detail_con_qian detail_notice"><span></span> 支持扩展名：.rar .zip .doc .docx .pdf .jpg...</div> -->
                        <div class="sendbtn" @click="sendcontent">发送</div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="Network_notice">协同办公，是法官、管理人、政府联动机构、书记员、会议主席、债委会、审计评估拍卖机构、银行、保险公司等处理破产事务的专用区域。请联系相关案件的管理人获取权限。</div>
         <!-- 提示框 -->
         <Toast :toastText="content" v-show="toastShow"></Toast>

         <!-- 图片放大 -->
        <div style='position:fixed;top:0;width:100%;height:100%;background-color:rgb(0,0,0,0.65);z-index: 999;' v-if="imgbigtype==true" @click="closeimgbig">
            <div class='modal' >
                <img :src="imgbig"/>
            </div>
        </div>
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'
    import Toast from '../../components/Toast.vue'

    import {caseSelfList,PcCaseMember,PcCaseUserDialogue,CaseSetContent} from '@/request/api';

    export default {
        name: 'Network',
        components: {
            Header,
            Toast
        },
        data(){
            return{
                // caseactive:0,
                othertype:false,
                casedetail:false,
                companyList:[],
                account:'',
                mima:'',
                 
                //案件进展状态
                progresstype:0,
                progressindex:-1,
                //协同办公
                case_id:'',
                memberlist:[],
                rowslist:[],
                my:{},
                user:{},
                id:'',
                current:0,
                casetype:'',
                worktitle:'',
                workcontent:'',
                pathname:[],
                roles:'',
                imgbigtype:false,
                imgbig:'',
                //上传
                fileList: [],
                myHeaders:{'content-type': 'application/json','Authori-zation':'Bearer ' + localStorage.getItem('token')},
                srcarr1:[],
                imagestring1:'',
                content:'',
                toastShow:false
            }
        },
        created() {
            this.getlist()
        },
        methods:{
            titclick:function(){
                if(this.casedetail){
                    this.casedetail=false
                }
            },
            loginother:function(){

            },
            getlist:function(){
                caseSelfList({
                    token:localStorage.getItem('token')
                }).then(res=>{
                    if(res.code==1){
                        this.companyList=res.data;
                        for(var i=0;i<this.companyList.length;i++){
                            this.companyList[i].type='close'
                        }
                        if(res.data.show_button==1){
                            this.othertype=true;
                        }else{
                            this.othertype=false;
                        }
                    }
                })
            },
            caseprogress:function(item,index){//案件进展状态
                this.case_id=item.id;
                
                if(item.type=='close'){
                    this.companyList[index].type="open";
                    var aa=[];
                    aa=this.companyList;
                    this.companyList=[];
                    this.companyList=aa;
                    // PersonCaseStatus({   
                    //     case_id:item.id
                    // }).then(res=>{
                    //     if(res.data.allow==1){
                    //         if((item.info_status_1==''||item.info_status_1==null)&&(item.info_status_2==''||item.info_status_2==null)&&(item.info_status_text==''||item.info_status_text==null)){
                    //             this.content='暂无案件进展状态'
                    //             this.toastShow=true
                    //             var that=this;
                    //             setTimeout(function(){
                    //                 that.toastShow = false
                    //             }, 1500)
                    //         }else{
                    //             this.companyList[index].type="open";

                    //             // this.progresstype=1
                    //             // this.currentid=item.id;
                    //         }
                    //     }
                    //     else{
                    //         this.content="暂无权限查看"
                    //         this.toastShow=true;
                    //         var that=this;
                    //         setTimeout(function(){
                    //             that.toastShow = false
                    //         }, 3000)
                    //     }
                    // })
                }else{
                    this.companyList[index].type="close";
                    var bb=[];
                    bb=this.companyList;
                    this.companyList=[];
                    this.companyList=bb;
                }


                // if(this.progressindex==-1){
                //     this.progressindex=index;
                    
                // }else{
                //     this.progressindex=-1;

                // }
               
            },
            Gonet:function(id){  //协同办公权限显示 
                this.case_id=id;
                // CaseGetUserStatus({
                //     case_id:id
                // }).then(res=>{
                //     if(res.data.allow==0){   
                //         this.content='协同办公，是法官、管理人、政府联动机构、书记员、会议主席、债委会、审计评估拍卖机构、银行、保险公司等处理破产事务的专用区域。请联系相关案件的管理人获取权限。'
                //         this.toastShow=true;
                //         var that=this;
                //         setTimeout(function(){
                //             that.toastShow = false
                //         }, 10000)
				// 	}else{    //直接进入协同办公
                        // this.caseactive=2;
                        this.getcasecontent();
                        this.casedetail=true;
					// }
                // })
            },
            changenet:function(item,type){
                this.current=type;
                this.id=item.id;
                PcCaseUserDialogue({
                    token:localStorage.getItem('token'),
                    case_id:this.case_id,
                    user_id:item.id,
                    exp_time:Date.parse(new Date()),
                    page:1,
                    limit:100
                }).then(res=>{
                    this.rowslist=res.data.rows;
                    this.my=res.data.my;
                    this.casetype=item.real_name;

                })
            },
            getcasecontent:function(){
                PcCaseMember({
                    token:localStorage.getItem('token'),
                    case_id:this.case_id
                }).then(res=>{
                    this.memberlist=res.data;
                    this.casetype=this.memberlist[0].real_name;
                    this.id=this.memberlist[0].id;
                    PcCaseUserDialogue({
                        token:localStorage.getItem('token'),
                        case_id:this.case_id,
                        user_id: this.memberlist[0].id,
                        exp_time:Date.parse(new Date()),
                        page:1,
                        limit:100
                    }).then(res=>{
                        this.rowslist=res.data.rows;
                        this.my=res.data.my;
                        this.user=res.data.user;
                    })
                })
            },
            read:function(item){
                if(item.extension=='png'||item.extension=='jpg'||item.extension=='jpeg'){
                    this.imgbigtype=true;
                    this.imgbig=item.path;
                }
                if(item.extension=='doc'||item.extension=='docx'||item.extension=='xlsx'||item.extension=='xls'){
                    window.open('https://view.officeapps.live.com/op/view.aspx?src=' + item.path)  // 微软提供的方法
                }
                if(item.extension=='pdf'){
                    window.open(item.path)
                }
                
            },
            closeimgbig:function(){
                this.imgbigtype=false
            },
            //上传
            uploadSectionFile(param){
                var fileObj = param.file;
                var form = new FormData();
                // 文件对象
                form.append("file", fileObj);
                this.$axios.post('/base/upload',form).then(res => {
                    param.onSuccess(res)
                   
                }).catch(({err}) => {
                    param.onError(err)
                })  
            },
            //上传失败
            uploadFileError(err, file, fileList){
            // this.$message.error("上传失败！")
            },
            //上传成功
            uploadFileSuccess(response, file, fileList){
                // if(response.data.error==0){
                    file.response=response.data.data;
                    this.fileList.push(file)
                    this.srcarr1.push(response.data.data.src)
                // }else{
               
                //     // this.$message.error(response.data.message);//文件上传错误提示
                // }

            },
            handleRemove(file, fileList) {
                for(var i=0;i<fileList.length;i++){
                    this.srcarr1.push(fileList[i].response.src)
                }
            },
            handlePreview(file) {
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            //上传完
            sendcontent:function(){
                // &&this.srcarr1.length!==0
                if(this.workcontent!==''&&this.worktitle!==''){
                    var that=this;
                    that.imagestring1=that.srcarr1.join(',');
                    CaseSetContent({
                        token:localStorage.getItem('token'),
                        case_id:that.case_id,
                        to_user:that.id,
                        title:that.worktitle,
                        content:that.workcontent,
                        file:that.imagestring1,
                    }).then(res=>{
                        if(res.code==1){
                            this.content="提交成功";
                            // this.getcasecontent();
                            PcCaseUserDialogue({
                                token:localStorage.getItem('token'),
                                case_id:this.case_id,
                                user_id:this.id,
                                exp_time:Date.parse(new Date()),
                                page:1,
                                limit:100
                            }).then(res=>{
                                this.rowslist=res.data.rows;
                                this.my=res.data.my;
                                this.user=res.data.user;

                            })
                            this.worktitle='';
                            this.workcontent='';
                            this.srcarr1=[];
                            this.fileList=[];
                            this.imagestring1='';

                        }else{
                            this.content="提交失败"
                        }
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false;
                            document.getElementById("aaaa").scrollIntoView(false);
                            this.casedetail=false
                        }, 1500);
                       
                    })
                }else{
                    if(this.worktitle==''){
                        this.content='请输入标题',
                        this.toastShow=true;
                    }
                    if(this.workcontent==''){
                        this.content='请输入内容'
                        this.toastShow=true;
                    }
                    // if(this.srcarr1.length==0){
                    //     this.content='请上传附件'
                    //     this.toastShow=true;
                    // }
                    setTimeout(() => {
                        this.toastShow=false;
                    }, 1500);
                }
                
            },
            
        }
    }
</script>
<style scoped>
::-webkit-scrollbar{
    display: none;
}
.Network_box{
    position: relative;
    background: #eef3fa;
    padding-bottom: 2rem;
    min-height: 100%;
    display: flex;
    width: 86.4%;
    margin: 0 auto;
    /* margin-top: 45px; */
    justify-content: space-between;
}
.Network_tit{
    width: 52px;
    height: 138px;
    margin-top: 127px;
    background: #43A0F5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    font-size: 16px;
    /* font-size: 16px; */
    color: #fff;
    text-align: center;
    cursor: pointer;
    /* vertical-align: middle; */
}
.Network_login{
    width: 30.35%;
    height: 431px;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    /* margin: 148px auto 0; */
    /* position: absolute;
    top: 148px;
    left: 36.72%; */
    margin: 0 auto;
    margin-top: 100px;
}
.Network_notice{
    font-size: 14px;
    /* font-size: 1.4rem; */
    line-height: 21px;
    /* position: fixed; */
    margin: 25px 0;
    width: 100%;
    text-align: center;
}
/* 登录框 */
.login_tit{
    font-size: 20px;
    /* font-size: 2rem; */
    line-height: 30px;
    /* font-family:'siyuanb'; */
    font-family: "siyuanb";
    margin-top: 71px;
    text-align: center;
    
}
.login_desc{
    text-align: center;
    
    /* font-size: 13px; */
    font-size: 1.3rem;
    
}
.login_input{
    height: 45px;
    width: 58.26%;
    border-bottom: 1px solid rgb(112 110 140 / 60%);
    margin: 0 auto;
    line-height: 45px;
    font-size: 14px;
    /* font-size: 1.4rem; */
    outline: none;
    margin-top: 20px;
}
.login_input input{
    border: none;
    background: transparent;
    margin-left: 48px;
    width: 15rem;
    outline: none;
}
.login_btn{
    width: 58.26%;
    height: 40px;
    background: #43A0F5;
    border-radius: 5px;
    font-size: 14px;
    /* font-size: 1.4rem; */
    text-align: center;
    line-height: 40px;
    color: #fff;
    margin: 61px auto 0;
    cursor: pointer;
}

/* 案件列表 */
.Network_list{
    width: 90%;
}
.list_tit{
    text-align: center;
    
    
    padding-top: 63px;
    /* font-family:'siyuanb'; */
    font-family: "siyuanb";
font-size: 20px;
    /* font-size: 2rem; */
    line-height: 30px;
    }
.list_con{
    /* width: 64.42%; */
    /* height: 410px; */
    margin: 34px auto 0;
    padding: 1.82% 2.61% 2.71%;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.6);
    border-radius: 10px;

}
.list_mess_list{
    font-size: 14px;
    /* font-size: 1.4rem; */
    line-height: 21px;
    
    
    margin-bottom: 10px;
}
.list_mess_network{
    width: 166px;
    height: 138px;
    /* left: 1410px; */
    /* top: 347px; */
    background: #43A0F5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    color: #fff;
    /* font-family:'siyuanb'; */
    font-family: "siyuanb";
    font-size: 14px;
    /* font-size: 1.4rem; */
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}
.list_mess_network img{
    margin: 33px auto 15px;
}
.list_case_img{
    margin: 33px auto 15px;
    display: block;
    cursor: pointer;
}
.case_type{
    font-size: 14px;
    /* font-size: 1.4rem; */
    line-height: 21px;
    /* identical to box height */
    
    color: #706E8C
}
.case_type_box{
    /* width: 100%; */
    height: 110px;
    background: #F5FAFE;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
    margin-top: 24px;
    font-size: 14px;
    /* font-size: 1.4rem; */
    text-align: center;
    color: #FF5C5C;
}
.case_type_box div:nth-child(1){
    margin: 11px auto 10px;
}

/* 区域 */
.Network_detail{
    width: 90%;
    /* height: 56.1rem; */
    /* margin: 5rem auto 0; */
    margin-top: 50px;
    background: #E7EEF5;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    padding: 1.04% 1.2%;
    display: flex;
    justify-content: space-between;

}
.detail_left{
    width: 17.38%;
}
.detail_left_list{
    height: 53px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 3px;
}
.detail_left .detail_left_list_active{
    height: 53px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 3px;
    background: #43A0F5;
    color: #fff;
}
.detail_left_list img,.detail_left_list_active img{
    width: 40px;
    height: 40px;
    background: #BBD9F4;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 18px;
}
.detail_right{
    width: 77%;
    /* height: 56.2rem;  */
    background: #F5FAFE;
    border: 1px solid #E2E2E8;
    border-radius: 10px;
    padding: 0 0.5rem;
}
.detail_right_tit{
    height: 46px;
    
    
    line-height: 46px;
    border-bottom: 1px solid #E7EEF5;
    /* border-radius: 5px; */
    font-size: 14px;
    font-family: 'siyuanb';
}
.detail_right_con{
    max-height: 500px;
    overflow: scroll;
    border-bottom: 3px solid #eee;
    padding-bottom: 20px;
}
.detail_chat{
    border-bottom: 1px solid #E7EEF5;
    padding-bottom: 20px;
    margin: 24px 0;
    max-height: 500px;
    overflow-y: scroll;
    /* width: 70%; */
}
.detail_chat_time{
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    
    
}
.detail_chat_con{
    height: 5rem;
    /* margin-bottom: 3rem; */
    border-bottom: 1px dashed #e8e8e8;
    padding: 10px 0;
}
.detail_chat_con:last-child{
    margin-bottom: 0;
}
.detail_chat_con_left{
    font-size: 14px;
    display: flex;
    align-items: center;
    
    
}
.detail_chat_con_left img,.detail_chat_con_right img{
    width: 49px;
    height: 49px;
    background: #BBD9F4;
    border-radius: 5px;
    /* margin-right: 19px; */
}
.detail_chat_con_right{
    display: flex;
    align-items: center;
    margin-left: 20px;
}


.detail_con{
    font-family: 'siyuanb';
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    padding-top: 20px;
    /* display: flex; */
    /* align-items: center; */
    
    
}
.detail_con_qian{
    /* height: 40px; */
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /* position: relative; */
}
.detail_con_qian span{
    /* width: 11.5%; */
    text-align: right;
}
.detail_con_qian input{
    width: 444px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
}
.detail_con_qian textarea{
    width: 444px;
    height: 120px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;

}
.upload-demo{
    /* position: absolute;
    top: 0;
    left: 12%; */
    margin-top: 20px;
}
.detail_notice{
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    
    
    font-family: 'siyuanr';
}
.sendbtn{
    width: 100px;
    height: 30px;
    background: #43A0F5;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    line-height: 30px;
    float: right;
    cursor: pointer;
    margin-bottom: 10px;
}
/* 图片放大 */

.modal {
    margin:auto;
    width: 50%;
    z-index: 1;
}
 
.modal img {
    display: block;
    padding: 10px;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 2px 6px rgb(0, 0, 0, 0.2), 0 10px 20px rgb(0, 0, 0, 0.2);
    border-radius: 12px;
    /* border: 1px solid white; */
}
@keyframes zoom {
    from {transform: scale(0.1)}
    to {transform: scale(1)}
}
 
.modal img {
    animation-name: zoom;
    animation-duration: 0.6s;
}
</style>