<template>
    <div class="backcolor"  style="min-width: 1200px;">
        <Header ></Header>
        <div class="Network">
            <div class="Day_box">
                <div class="Day_title flexbe" style="display: none;">
                    <div style="font-size: 18px;font-family: 'siyuanb';">日常通知</div>
                    <div class="Day_title_input" style="display: flex;">
                        <input type="text" placeholder="选择区域搜索">
                        <div>搜索</div>
                    </div>
                </div>
                <div class="Day_con">
                    <div class="Day_con_list" v-for="(item,index) in daynoticelist" :key="index">
                        <div class="Day_con_list_tit">{{item.title}}</div>
                        <div class="Day_con_list_desc flexbe">
                            <div>{{item.company_name}}</div>
                            <div>{{item.time}}</div>
                        </div>
                        <div class="Day_con_list_con">{{item.content}}</div>
                    </div>
                    <!-- 分页 -->
                    <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'

    import {personSms  } from '@/request/api';


    export default {
        name: 'Daynotice',
        components: {
            Header,
        },
        data(){
            return{
                current:6,
                currentPage4: 4,
                daynoticelist:[],
                total:''
            }
        },
        created(){
            personSms({
                token:localStorage.getItem('token')
            }).then(res => { 
                this.daynoticelist=res.data;
                this.total=this.daynoticelist.length
            })
        },
        methods:{
            handleSizeChange:function(val){
            },
            handleCurrentChange:function(val) {
            },
        }   
    }
</script>
<style scoped>
.Network{
    position: absolute;
    top: 245px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #EEF3FA;
}
.Day_box{
    width: 86%;
    margin: 0 auto;
    position: relative;
    height: auto;
}
.Day_title{
    height: 40px;
    margin-top: 27px;
    margin-bottom: 20px;
}
.Day_title_input input{
    width: 260px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 13px;
    font-size: 14px;
    margin-right: 9px;
    outline: none;
}
.Day_title_input div{
    width: 80px;
    height: 40px;
    left: 1550px;
    top: 212px;
    background: #43A0F5;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    cursor: pointer;
}
.Day_con{
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 60px;
}
.Day_con_list{
    background: #F5FAFE;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
    margin-bottom: 10px;
    padding-bottom: 20px;
}
.Day_con_list_tit{
    font-size: 18px;
    font-family: 'siyuanb';
    text-align: center;
    line-height: 50px;
}
.Day_con_list_desc{
    margin-bottom: 20px;
    padding: 0 30px;
}
.Day_con_list_desc div:nth-child(1){
    font-size: 16px;
    font-family: 'siyuanb';
}
.Day_con_list_desc div:nth-child(2){
    font-size: 14px;
}
.Day_con_list_con{
    font-size: 14px;
    padding: 0 30px;
    line-height: 21px;
    /* height: 150px;
    overflow-y: scroll; */
}
/* 分页 */
.el-pagination{
    position: absolute;
    bottom: -30px;
    right: 20px;
}
</style>