<template>
    <div class="center-page">
     
    </div>
</template>
<script>
 
export default {
  data () {
    return {
      
    }
  },
  created(){
    // if(this.$router.query!=undefined){
      this.$router.push({path:'/Liveroom',query:{id:this.$route.query.id}})
    // }

  }
  
}
</script>
<style  scoped>
 
  .local-stream {
    width: 500px;
    height: 500px;
  }
 
  .distant-stream {
    width: 200px;
    height: 200px;
  }
  </style>