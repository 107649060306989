import Vue from 'vue'
import { Container, Header, Main,Select,Input,Option,Collapse,CollapseItem,Breadcrumb, BreadcrumbItem,Drawer} from 'element-ui'

Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Select)
Vue.use(Input)
Vue.use(Option)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Drawer)
