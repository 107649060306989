<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header></Header>
        <div class="Network">
            <div class="Day_box">
                <div class="Day_title flexbe">
                    <div style="font-size: 18px;font-family: 'siyuanb';">管理人名册</div>
                    <div style="display: flex;justify-content: space-between;width: 55%;">
                        <div class="Day_title_input" style="display: flex;align-items: center;margin-right: 10px;">
                            <input type="text" placeholder="请输入关键词进行搜索" v-model="searchname">
                            <div style="background: orange;color: #fff;width: 1.2rem;text-align: center;height: 0.6rem;line-height: 0.6rem;border-radius: 5px;cursor: pointer;font-size: 16px;" @click="sousuo">搜索</div>
                        </div>
                        <div class="Day_title_input" style="display: flex;align-items: center;">
                            <el-select v-model="value" placeholder="请选择机构类型" @change="jobExperienceChange">
                                <el-option
                                  v-for="(item,index) in conlist_c"
                                  :key="index"
                                  :value="item.name">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="Day_title_input" style="display: flex;">
                            <el-cascader
                                clearable
                                v-model="areavalue"
                                :options="selectarea"
                                :props="{ expandTrigger: 'hover' }"
                                @change="handleChange">
                            </el-cascader>
                        </div>
                    </div>
                    
                </div>
                <div class="Day_con" style="display: flex;flex-wrap: wrap;">
                    <div class="Day_con_list" v-for="(item,index) in guanlirenlist" :key="index" v-if="guanlirenlist.length!==0">
                        <div class="Admin_list_card">
                            <div class="Admin_list_card_name">{{item.link_man}} <span>机构联系人</span></div>
                            <div class="Admin_list_card_mess"><span class="iconfont icon-Frame-21"></span>{{item.name}}</div>
                            <div class="Admin_list_card_mess"><span class="iconfont icon-Frame-23"></span>{{item.phone}}</div>
                            <div class="Admin_list_card_mess"><span class="iconfont icon-Frame-22"></span>{{item.address}}</div>
                            <img src="../../image/logo.png" alt="">
                        </div>
                        <div class="Admin_list_message flexbe">
                            <div class="Admin_list_message_con_tit">
                                <!-- <span class="iconfont icon-Frame3" style="font-size: 45px;"></span> -->
                                联系方式
                            </div>

                            <div class="Admin_list_message_con">
                                <div>
                                    <span class="iconfont icon-Frame-26"></span> 咨询电话
                                </div>
                                <div>{{item.phone}}</div>
                            </div>
                            <div class="Admin_list_message_con">
                                <div>
                                    <span class="iconfont icon-Frame-24"></span> 加我微信
                                </div>
                                <div>{{item.weixin==''?'暂无微信':item.weixin}}</div>
                            </div>
                        </div>
                    </div>
                    
                    <Nocontent v-if="guanlirenlist.length==0"></Nocontent>


                    <!-- 分页 -->
                    <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size="5"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
                </div>

                <div style="line-height: 60px;text-align: center;font-size: 14px;">尚未被录入的管理人机构，请与网站工作人员联系，并将相关信息完整提交给工作人员；如果无需展示，亦请及时联系工作人员。联系方式：微信号pochanjie <span style="margin-right: 10px;"></span> 联系电话：19872005565</div>
            </div>
        </div>
        
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'
    import Nocontent from '../../components/Nocontent.vue'
    import {PersonInstitutionList} from '@/request/api';
    import { getcitydata } from "../../../public/city-picker.data";

    export default {
        name: 'Administrators',
        components: {
            Header,
            Nocontent
        },
        data(){
            return{
                current:0,
                currentPage4: 1,
                pro:'',
                city:'',
                page:'1',
                limit:5,
                guanlirenlist:[],
                total:0,
                // 地址
                areavalue: [],
                selectarea: [],
                pro:'',
                city:'',
                area:'',
                //机构类型
                value:'',
                name:'',
                type:-1,
                searchname:'',

                conlist_c:[{name:"全部"},{name:"律师事务所"},{name:"会计师事务所"},{name:"清算公司"}],

            }
        },
        created() {
            this.list()
            this.selectarea=getcitydata('3')
            
            // GetArea({    //获取省市区
            //     level:1,   //两层
            // }).then(res=>{
            //     this.selectarea=res.data;
            // })
        },
        methods:{

            sousuo:function(){
                this.list()
            },
            handleChange(value) {   //选择地址
                this.pro=value[0];
                this.city=value[1];
                this.area=value[2];
                this.areavalue=value;
                this.list()
            },
            jobExperienceChange:function(e){
                if(e=='全部') this.type=-1
                if(e=='律师事务所') this.type=0
                if(e=='会计师事务所') this.type=1
                if(e=='清算公司') this.type=2
                this.list()
            },
            list:function(){
                PersonInstitutionList({
                    token:localStorage.getItem('token'),
                    pro:this.pro,
					city:this.city,
					page:this.page,
					limit:this.limit,
                    type:this.type,
                    name:this.searchname
                }).then(res=>{
                    this.guanlirenlist=res.data.rows;
                    this.total=res.data.total;

                    for(var i=0;i<this.guanlirenlist.length;i++){
                        var aa=this.guanlirenlist[i].phone.indexOf("/");
                        var bb=this.guanlirenlist[i].phone.substring(0,aa);
                        if(bb!==""){
                            this.guanlirenlist[i].phone=bb
                        }
                    }
                })
            },
            handleSizeChange:function(val){
                this.limit=val;
                this.currentPage4=1;
                this.page=1;
                this.list()
            },
            handleCurrentChange:function(val) {
                this.page=val;
                this.list()
            },
        }   
    }
</script>
<style scoped>
.Network{
    position: relative;
    background: #eef3fa;
    padding-bottom: 0.5rem;
    min-height: 100%;
    display: flex;
    width: 86.4%;
    margin: 0 auto;
    /* margin-top: 45px; */
    justify-content: space-between;
}
.Day_box{
    margin: 0 auto;
    position: relative;
    height: auto;
    width: 100%;
}
.Day_title{
    height: 40px;
    margin-top: 27px;
    margin-bottom: 20px;
}
.Day_title_input input{
    width: 260px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 13px;
    font-size: 14px;
    margin-right: 9px;
    outline: none;
}

.Day_con{
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 60px;
}
.Day_con_list{
    width: 32%;
    /* height: 172px; */
    margin-right: 20px;
    position: relative;
    margin-bottom: 24px;
}
.Day_con_list:nth-child(3n+3) {
    margin-right: 0;
}
.Admin_list_card{
    height: 130px;
    background: radial-gradient(55.69% 86.43% at 82.36% 8.6%, #59597C 0%, #333E63 100%);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 40%);
    backdrop-filter: blur(20px);
    border-radius: 8px;
    padding: 25px 20px;
    color: #fff;
    margin-bottom: 10px;
}
.Admin_list_card_name{
    font-size: 18px;
    font-family: 'siyuanb';
}
.Admin_list_card_name span{
    font-size: 14px;
    font-family: 'siyuanr';
    margin-left: 29px;
}
.Admin_list_card_mess{
    font-size: 14px;
    margin-top: 13px;
}
.Admin_list_card_mess span{
    margin-right: 6px;
}
.Day_con_list img{
    width: 107px;
    height: 107px;
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 50%;
}
/* 卡片下 */
.Admin_list_message_con_tit{
    height: 64px;
    width: 27.62%;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    line-height: 64px;
    background: linear-gradient(
210.55deg
, #FE9B4B 19.14%, #FFA861 154.68%);
    box-shadow: 0px 10px 20px rgb(18 61 101 / 15%), inset -8px -6px 30px rgb(255 255 255 / 18%);
    border-radius: 8px;
}
.Admin_list_message_con{
    background: linear-gradient(
210.55deg
, #EBF2F9 19.14%, #C6D7EB 154.68%);
    box-shadow: 0px 10px 20px rgb(18 61 101 / 15%), inset -8px -6px 30px rgb(255 255 255 / 18%);
    border-radius: 8px;
    width: 33.8%;
    height: 64px;
    text-align: center;
    font-size: 14px;
}
.Admin_list_message_con div:nth-child(1){
    margin: 8px auto 7px;
}
.Admin_list_message_con div:nth-child(1) span{
    color: #FEA55E;
    font-size: 18px;
}
/* 分页 */
.el-pagination{
    position: absolute;
    bottom: 15px;
    right: 20px;
}
</style>