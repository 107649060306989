<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header></Header>
        <!-- 画面 -->
        <div class="Meet_box" v-if="backurl==''">
            <div class="Meet_box_inside">
                <!-- 左侧画面 -->
                <div class="Meet_box_video">
                    <div class="Meet_box_detail">
                        <!-- 主播 -->
                        <div style="background: #000;height: 100%;">
                            <div id='local_stream' class="local-stream" style="height: 100%;" :style="{display:localtype?'block':'none'}">
                            </div>
                        </div>
                         <!-- 未进入 -->
                         <div class="noticeclass">{{noticetext}}</div>
                         <!-- 主播开始直播 -->
                         <div class="startroomclass" @click="startlive" v-if="zhibo_status==0&&roles=='play'">开启直播</div>
                         <div class="startroomclass" @click="closelive" v-if="zhibo_status==1&&roles=='play'&&showbtn">结束直播</div>
                         <!-- 主播退房 -->
                         <div class="iconfont icon-Frame-31 leaveroomclass" @click="changeleaveRoom" v-if="showbtn"></div>
                         <!-- 观众4个 -->
                         <div class="audiencebox"  v-if="showbtn">
                           
                            <div style="width: 40%;height: 100px;overflow: scroll;position: relative;" id="bbbb">
                                <div ref="my_scroller" class="scrollclass" id="aaaa"> 
                                    <div class="infinite-list-item" v-for="(item,index) in huilist" :key="index"
                                        style="line-height: 20px;font-size: 12px;word-break: break-all;color: #fff;height:auto"
                                        >
                                        {{item.left}}:{{item.right}}
                                    </div>
                                </div>
                            </div>
                            <div class="detail_input">
                                <input type="text" placeholder="请输入发送内容" v-model="huicon" @keyup.enter="sendcon">
                                <div class="sendbtn" @click="sendcon">发送</div>
                            </div>
                        </div>
                        <!-- 播放按钮 -->
                        <div class="iconfont icon-bofang playbtnclass" v-if="playbtn" @click="GoSign">
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>

        <div v-if="backurl!==''">
            <video :src="backurl" controls controlslist="nodownload" style="width: 60%;margin: 0 auto;margin-top: 20px;display: block;"></video>
        </div>
        <!-- 外部播放地址 -->
        <el-dialog width="30%" title="播放地址" :visible.sync="pusherVisible">
            <div style="color: red;text-align: center;line-height: 50px;font-size: 17px;">{{pusheraddress}}</div>

            <div class="flexar devicebox">
                <div @click="pusherVisible==false">取消</div>
                <button class="btns" :data-clipboard-text="pusheraddress" @click="copy">复制</button>
            </div>
        </el-dialog>
        <el-dialog width="30%" title="提示" :visible.sync="pushertextVisible">
            <div style="color: red;text-align: center;line-height: 50px;font-size: 17px;">如已确保外接设备开始推流，请点击“确定并直播”。</div>
            <div class="flexar devicebox">
                <div class="btns" @click="pushertextbtn">确定并直播</div>
            </div>
        </el-dialog>
        <!-- 确定退房 -->
        <el-dialog title="确定退房" :visible.sync="leaveVisible">
            <div class="qianbtn">
                <div @click="leaveVisible=false" class="themebgcolor">取消</div>
                <div @click="leaveRoom" class="themebgcolor">确定</div>
            </div>
        </el-dialog>
        <!-- 关闭直播 -->
        <el-dialog title="确定关闭直播" :visible.sync="closeVisible">
            <div class="qianbtn">
                <div @click="closeVisible=false" class="themebgcolor">取消</div>
                <div @click="closeRoom" class="themebgcolor">确定</div>
            </div>
        </el-dialog>
        
        <!-- 提示框 -->
        <Toast :toastText="content" v-show="toastShow"></Toast>
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'
    import Toast from '../../components/Toast.vue'

    import {StudyUrl,PullStream,StudyGetSig,StudyGetRoomInfo,StudyEditStatus,StopPushStudy,TrtcGetRoomId} from '@/request/api';

    import LibGenerateTestUserSig from '../../../public/lib-generate-test-usersig.min.js'

    //导入sdk
    import TRTC from "trtc-js-sdk";
    import TIM from "tim-js-sdk";
    export default {
        name: 'Liveroom',
        components: {
            Header,
            Toast,
            // VideoPlayer
        },
        data() {
            return {
                localtype:false,
                noticetext:'',
                host_status:0,
                zhibo_status:-1,
                showbtn:false,   //各种按钮控制
                playbtn:false,
                pusherVisible:false,
                pusheraddress:'',
                leaveVisible:false,
                closeVisible:false,
                toastShow:false,
                content:'',
                leavetype:true,
                backurl:'',

                id:'',
                play_schema:0,
                roles:'',
                roomId:0,

                timChatGroupId:'',
                host_id:'',
                real_name:'',
                scene:'',
                sig:'',
                user_id:'',

                pushertextVisible:false,
                closeVisible:false,
                leaveVisible:false,
                managerole:'',

                huilist:[],
                huicon:'',
                isHave:false,

                timInst:{},
                client:{},
                timRoomType: TIM.TYPES.GRP_CHATROOM, // 最新版的API中没有该类型，但为了兼容小程序端，才使用这个和小程序保持一致

                
            }
        },
        created() {
            this.id=this.$route.query.id;
            // this.roomId=parseInt(this.id)+100000000;
            this.play_schema=this.$route.query.play_schema;
            this.roles=this.$route.query.roles;
            this.backurl=this.$route.query.backurl;
            if(this.roles=='manage'||this.roles=='play'||this.roles=='aid'){
                this.managerole=true;
            }
            TrtcGetRoomId({
                token:localStorage.getItem('token'),
                type:'study',
                id:this.id
            }).then(res=>{
                this.timChatGroupId = res.data.room_id+'';
            })
            // this.timChatGroupId = this.roomId+'';
            this.leavetype=true;
            var that=this;

            StudyGetSig({
                token:localStorage.getItem('token')
            }).then(res=>{
                that.sig=res.data.sig;
            })
            if(this.backurl==''){
                StudyGetRoomInfo({
                    token:localStorage.getItem('token'),
                    study_id:that.id,
                    roles:that.roles,
                }).then(res=>{
                    that.host_id=res.data.host_id;
                    that.real_name=res.data.real_name;
                    that.scene=res.data.scene;
                    that.user_id=res.data.user_id+'';
                    that.host_status=res.data.host_status;    //主播状态 0掉线  1上线
                    that.zhibo_status=res.data.zhibo_status;    //直播状态：0 还没开始 1正在直播 2结束
                    // that.start_time=res.data.start_time;
                    that.setnoticetext()
                    if(that.host_status==1&&that.roles=='play'){
                        that.refreshplayuser()
                    }
                    that.initIM()
                    
                })
            }
           
        },
        destroyed() {
            this.leaveRoom('destroyed');
            this.logoutIm();
        },
        methods:{
            copy:function(){    //复制
            　　var clipboard = new this.Clipboard(".btns"); //在main.js中引用
                clipboard.on("success", e => {
                    clipboard.destroy();
                    this.pusherVisible=false;
                    this.pushertextVisible=true;
                });
                clipboard.on("error", e => {
                    // 不支持复制
                    Message({
                    message: "该浏览器不支持自动复制",
                    type: "warning"
                    });
                    // 释放内存
                    clipboard.destroy();
                });
            },
            pushertextbtn:function(){
                var that=this;
                PullStream({
                    room_id:this.timChatGroupId,
                    play_url:this.playLinkAddr,
                    token:localStorage.getItem('token')
                }).then(res=>{   
                    this.requestId=res.data.requestId;
                })
                this.createClient();
                this.checksystem('audience');
                this.showbtn=true;
                this.pushertextVisible=false;
            },
            startlive:function(){  //主播开启直播
                var that = this;
                StudyEditStatus({
                    token:localStorage.getItem('token'),
                    study_id:that.id,
                    zhibo_status:1,

                }).then(res=>{
                    that.zhibo_status=1;
                    that.setnoticetext()
                    that.playbtn=true;
                    that.startlivemess()
                })

            },
            startlivemess:function(){
                var that=this;
                let message = this.timInst.createCustomMessage({
                    to: that.timChatGroupId,
                    conversationType: TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: '开启直播', // 用于标识该消息是骰子类型消息
                        description: '1', // 获取骰子点数
                        extension: '1'
                    },
                });
                this.timInst.sendMessage(message).then(function (res) {
                }).catch(function (err) {
                    console.warn('强制下麦失败', err);
                });
            },
            closelive:function(){
                this.closeVisible=true;
            },
            changeleaveRoom:function(){
                this.leaveVisible=true;
            },
            GoSign:function(){
                this.noticetext="";
                if(this.roles=='play'){   //主播，选择直播方式
                    if(this.play_schema==0){  //内部
                        if(this.camerastype==false||this.microphonestype==false){
                            this.devicescontent="麦克风或摄像头设备没有连接,连接后请重新进入直播"
                            return
                        }else{
                            this.playbtn = false;
                            this.checktrtc();
                            this.checksystem('anchor','on');
                            this.showbtn=true;
                        }
                    }else{   //外部
                        this.playbtn = false;
                        StudyUrl({
                            token:localStorage.getItem('token'),
                            study_id:this.id,
                            // room_id:this.roomId
                        }).then(res => {
                            this.pusherVisible=true;
                            this.pusheraddress=res.data.push;
                            this.playLinkAddr=res.data.play;
                        })
                    }
                    this.host_status=1;
                    var that = this;
                    StudyEditStatus({
                        token:localStorage.getItem('token'),
                        study_id:that.id,
                        host_status:1
                    }).then(res=>{
                        let message = this.timInst.createCustomMessage({
                            to: that.timChatGroupId,
                            conversationType: TIM.TYPES.CONV_GROUP,
                            payload: {
                                data: '开始直播', // 用于标识该消息是骰子类型消息
                                description: that.host_status+"", // 获取骰子点数
                                extension: that.host_status+""
                            },
                        });
                        this.timInst.sendMessage(message).then(function (res) {
                        }).catch(function (err) {
                            console.warn('开始直播失败', err);
                        });
                    })
                }else{   //非主播播放
                    this.playbtn=false;
                    if(this.camerastype==false||this.microphonestype==false){
                        this.devicescontent="麦克风或摄像头设备没有连接,连接后请重新进入直播";
                    }
                    this.checktrtc();
                    this.checksystem('audience');
                    this.showbtn=true;

                }
            },
            leaveRoom:function(from='undestroyed'){   //退房
                if (this.client) {
                    this.leavetype=false;
                    if(this.roles=='play'){   //主播退房提示
                        if(this.play_schema==1){
                            StopPushStudy({
                                token:localStorage.getItem('token'),
                                // requestId:this.requestId
                                study_id:this.id
                            }).then(res=>{

                            })
                        }
                        var that = this;
                        that.host_status=0;
                        StudyEditStatus({
                            token:localStorage.getItem('token'),
                            study_id:that.id,
                            host_status:0
                        }).then(res=>{
                            
                        })
                        let message1 = that.timInst.createCustomMessage({
                            to: that.timChatGroupId,
                            conversationType: TIM.TYPES.CONV_GROUP,
                            payload: {
                                data: '开始直播', // 用于标识该消息是骰子类型消息
                                description: that.host_status+"", // 获取骰子点数
                                extension: that.host_status+""
                            },
                        });
                        that.timInst.sendMessage(message1).then(function (res) {
                        }).catch(function (err) {
                            console.warn('开始直播失败', err);
                        });
                        let message = that.timInst.createCustomMessage({
                            to: that.timChatGroupId,
                            conversationType: TIM.TYPES.CONV_GROUP,
                            payload: {
                                data: '主播退房', // 用于标识该消息是骰子类型消息
                                description: '主播', // 获取骰子点数
                                extension: '主播退房'
                            },
                        });
                        that.timInst.sendMessage(message).then(function (res) {
                        }).catch(function (err) {
                            console.warn('主播退房失败', err);
                        });
                    }
                   

                    this.client.leave().then(() => {
                       
                        this.client="";
                        if(from!='destroyed'){
                            this.$router.push({path:'/'})
                        }
                        // 退房成功，可再次调用client.join重新进房开启新的通话。
                    }).catch(error => {
                    });
                }
            },
            closeRoom:function(){
                var that=this;
                StudyEditStatus({
                    token:localStorage.getItem('token'),
                    study_id:that.id,
                    zhibo_status:2
                }).then(res=>{
                    let message = this.timInst.createCustomMessage({
                        to: that.timChatGroupId,
                        conversationType: TIM.TYPES.CONV_GROUP,
                        payload: {
                            data: '开启直播', // 用于标识该消息是骰子类型消息
                            description: '2', // 获取骰子点数
                            extension: '2'
                        },
                    });
                    this.timInst.sendMessage(message).then(function (res) {
                    }).catch(function (err) {
                        console.warn('强制下麦失败', err);
                    });
                    this.leaveRoom()
                })
            },
            setnoticetext:function(){
                var that=this;
                if(that.zhibo_status==0){
                    that.noticetext = "请等待直播";
                }
                if(that.zhibo_status==1){
                    if(that.host_status==0){
                        that.noticetext = "直播尚未开始，请等待或与举办方联系";
                        if(that.roles=='play'){
                            that.playbtn=true;
                        }
                    }else{
                        if(that.roles!='play'){
                            that.playbtn=true;
                        }
                        that.noticetext="直播已开始，请点击进入会场"
                    }
                    
                }
                if(that.zhibo_status==2){
                    that.noticetext = "直播已结束";
                }
            },
            refreshplayuser:function(){
                var that = this;
                that.host_status=0;
                StudyEditStatus({
                    token:localStorage.getItem('token'),
                    study_id:that.id,
                    host_status:0
                }).then(res=>{

                })
                let message1 = that.timInst.createCustomMessage({
                    to: that.timChatGroupId,
                    conversationType: TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: '开始直播', // 用于标识该消息是骰子类型消息
                        description: that.host_status+"", // 获取骰子点数
                        extension: that.host_status+""
                    },
                });
                that.timInst.sendMessage(message1).then(function (res) {
                }).catch(function (err) {
                    console.warn('开始直播失败', err);
                });
                that.setnoticetext()

            },
            // 聊天回复
            sendcon: function () {
                if (this.huicon == '') {
                    this.content = "发送内容为空";
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
                } else {
                    var roles = '';
                    if (this.roles == 'member') {
                        roles = "债权人"
                    } else if (this.roles == 'manage') {
                        roles = "主持"
                    } else if (this.roles == 'play') {
                        roles = "主播"
                    } else if (this.roles == 'work') {
                        roles = "法院工作人员"
                    } else {
                        roles = "助手"
                    }

                    
                    var that = this;
                    let message = this.timInst.createCustomMessage({
                        to: that.timChatGroupId,
                        conversationType: TIM.TYPES.CONV_GROUP,
                        payload: {
                            data: '聊天',
                            description: that.huicon,
                            extension: localStorage.getItem('nickname') + '(' + roles + ')'
                        },
                    });
                    this.timInst.sendMessage(message).then(function (res) {
                        that.huilist.push({ left: localStorage.getItem('nickname') + '(' + roles + ')', right: that.huicon })
                        that.huicon = '';
                        setTimeout(function() {
                            // document.getElementById("aaaa").scrollIntoView(false);
                            that.$nextTick(() => {
                                var container = that.$el.querySelector("#bbbb");
                                container.scrollTop = container.scrollHeight;

                            })
                        },10)
                    }).catch(function (err) {
                        console.warn('聊天失败:', err);
                    });
                        
                    
                }
            },
            onmessage:function(){
                var that = this;
                let onMessageReceived = function (event) { 
                    for (var i = 0; i < event.data.length; i++) {

                        if(event.data[i].conversationID=='GROUP'+that.timChatGroupId&&that.leavetype){
                            if (event.data[i].payload.data == '聊天') {
                                that.huilist.push({ left: event.data[i].payload.extension, right: event.data[i].payload.description })
                            }
                           
                            if (event.data[i].payload.data == '主播退房') {
                                that.$router.push({path:'/Asset',query:{id:that.id,play_schema:that.play_schema,roles:that.roles}})
                                // that.playbtn=true;
                                // that.localtype=false;
                                // that.huilist.push({ left: event.data[i].payload.description, right: event.data[i].payload.extension })
                                // that.client.unsubscribe(this.remoteStreamobj);

                            }

                            if (event.data[i].payload.data == '开启直播') {
                                that.zhibo_status = event.data[i].payload.description;
                                that.setnoticetext()
                            }

                            if (event.data[i].payload.data == '开始直播') {
                                that.host_status = event.data[i].payload.description;
                                that.setnoticetext()
                            }
                        }
                        setTimeout(function() {
                            // document.getElementById("aaaa").scrollIntoView(false);
                            that.$nextTick(() => {
                                var container = that.$el.querySelector("#bbbb");
                                container.scrollTop = container.scrollHeight;

                            })
                        },10)
                    }
                };
                that.timInst.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
            },

            createClient:function(){
                const sdkAppId = 1400395034;
                const userSig = this.sig;   //获取签名
                const userId=this.user_id.toString();
                this.client = TRTC.createClient({
                    mode: 'live',
                    sdkAppId,
                    userId,
                    userSig
                });
                
                //注册远程监听，要放在加入房间前--这里用了发布订阅模式
                this.subscribeStream()
                this.playStream()
            },
            subscribeStream() {
                const client=this.client;
                client.on('stream-added', event => {
                    const remoteStream = event.stream;
                    client.subscribe(remoteStream);

                    if(this.roles=='play'&&this.play_schema==1&&remoteStream.getUserId()=='LINUX'+this.host_id){
                        remoteStream.muteAudio()   //移出音频
                    }
                });
                
            },
            playStream() {
                const client=this.client;
                client.on('stream-subscribed', event => {
                    const remoteStream = event.stream;
                    const id = remoteStream.getId()
                    if(remoteStream.hasVideo()){
                        if((this.play_schema==1&&remoteStream.getUserId()=="LINUX"+this.host_id)||(this.play_schema==0&&remoteStream.getUserId()==this.host_id)){
                            //大屏
                            this.localtype=true;
                            this.remoteStreamobj=remoteStream;
                            remoteStream.play('local_stream');
                            this.huilist.push({ left: '主播', right: '主播已发起直播'})
                        }
                    }
                });
            },
            createStream() {
                const userId=this.user_id;
                const localStream = TRTC.createStream({ userId, audio: true, video: true });
                this.localStream = localStream;
                var id=localStream.id_;
                var that=this;
                localStream.initialize().catch(error => {
                }).then(() => {
                    if(that.roles=='play'){    //主播
                        this.localtype=true;
                        localStream.play('local_stream');// 创建好后才能播放 本地流播放 local_stream 是div的id
                    }
                    this.publishStream() //创建好后才能发布
                });
            },
            publishStream() {//发布本地音视频流
                var that=this;
                this.client.publish(this.localStream).catch(error => {
                }).then(() => {
                    that.isHave=true;
                });
            },
            initIM: function () {
                let options = {
                    SDKAppID: 1400395034
                };
                this.timInst = TIM.create(options); //创建 IM SDK 实例
                this.timInst.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
                var that=this;
                this.timInst.on(TIM.EVENT.SDK_READY, function (event) {
                    that.onSdkReady();
                    that.onmessage();
                });
                this.timInst.on(TIM.EVENT.SDK_NOT_READY,function(){
                    // that.initIM()
                });
                this.loginIm()

            },
            loginIm: function () {
                var that=this;
                if (this.timInst) {
                    let options = { userID: this.user_id, userSig: this.sig };
                    let promise = this.timInst.login(options);
                    promise.then((res) => {
                        

                        if (res.data.repeatLogin === true) {
                        }
                    }).catch(function (err) {
                    });
                }
            },
            logoutIm: function () {
                if (this.timInst) {
                    let promise = this.timInst.logout();
                    promise.then((imResponse) => {
                    }).catch(function (imError) {
                        console.warn('logout error:', imError);
                    });
                }
            },
            checktrtc:function(){
                const micList = TRTC.getMicrophones();
                const cameraList = TRTC.getCameras();
                const speakerList = TRTC.getSpeakers();
                const hasCameraDevice = cameraList.length > 0;
                const hasMicrophoneDevice = micList.length > 0;
                const hasSpeakerDevice = speakerList.length > 0;
                navigator.mediaDevices.getUserMedia({ video: hasCameraDevice, audio: hasMicrophoneDevice })
                    .then((stream) => {
                        stream.getTracks().forEach(track => track.stop());
                    })
                    .catch((error) => {
                    });
                TRTC.checkSystemRequirements().then(checkResult => {
                    if (checkResult.result) {
                        this.createClient();// 创建一个实时音视频通话的客户端对象  
                    } else {
                        this.content = "SDK 不支持当前浏览器，根据用户设备类型建议用户使用 SDK 支持的浏览器"
                        console.error('checkResult', checkResult.result, 'checkDetail', checkResult.detail);
                    }
                })
            },
            checksystem(roles,playtype='off') {
                var that=this;
                TRTC.checkSystemRequirements().then(checkResult => {
                    if (checkResult.result) {// 支持进房
                        var roomId = parseInt(that.timChatGroupId);
                        that.client.join({ roomId, role: roles }).catch(error => {
                        }).then(() => {
                            if(playtype=='on'){
                                that.createStream()   //创建本地流
                            }
                        });
                    }
                })
            },
            onSdkReady: function () {
                if (!this.timInst) {
                    return
                } else {
                    let timInst = this.timInst;
                    let timToomType = this.timRoomType;
                    let groupId = this.timChatGroupId;
                    let promise =timInst.searchGroupByID(groupId);
                    promise.then((imResponse) => {//  如果已创建，则检查是否用户是否已加入，已加入则不做处理，未加入则加入；
                        this.joinTimGroup();
                        const group = imResponse.data.group; // 群组信息
                       
                    }).catch((imError) => {//  如果未创建，则先创建Group，然后用户再加入
                       
                        let promise = timInst.createGroup({
                            type: timToomType,
                            name: 'MeetingTimGroup' + groupId,
                            groupID: groupId
                        });
                        promise.then((imResponse) => { // 创建成功
                         
                            this.joinTimGroup();
                        }).catch(function (imError) {
                           
                        });
                    });
                }
            },
            joinTimGroup: function () {
                var that=this;
                if (!this.timInst) {
                    return;
                } else {
                    let promise = this.timInst.joinGroup({ groupID: this.timChatGroupId, type: this.timRoomType });
                    promise.then((imResponse) => {
                        switch (imResponse.data.status) {
                            case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
                                break;
                            case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功

                                break;
                            case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中

                                break;
                            default:
                                break;
                        }
                        that.groupmember()
                    }).catch(function (imError) {
                        console.warn('joinGroup error:', imError); // 申请加群失败的相关信息
                    });
                    return promise;
                }
            },
            groupmember:function(){
                let promise = this.timInst.getGroupMemberList({ groupID: this.timChatGroupId, count: 30, offset:0 }); // 从0开始拉取30个群成员
                promise.then(function(imResponse) {
                }).catch(function(imError) {
                    console.warn('getGroupMemberList error:', imError);
                });
            },
            
            
        }
    }
</script>
<style scoped>
    ::-webkit-scrollbar{
        display: none;
    }
    .Meet_box {
        position: absolute;
        top: 185px;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #EEF3FA;

    }

    .Meet_box_inside {
        background: #F5FAFE;
        border: 1px solid #E2E2E8;
        border-radius: 10px;
        width: 86.4%;
        margin: 40px auto;
        display: flex;
        padding: 30px 0;
    }

    .Meet_box_video {
        width:80%;
        margin: 0 auto;
    }

    .Meet_box_name {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        margin: 0px auto 10px;
        font-family: "siyuanb";
    }

    .Meet_box_detail {
        height:600px;
        position: relative;
    }

    .Meet_box_detail img {
        width: 100%;
        height: 100%;
    }

    .Meet_box_detail_btn {
        position: absolute;
        width: 95.6%;
        height: 60px;
        left: 1.2%;
        padding: 0 1%;
        background: rgb(39 39 39 / 50%);
        border-radius: 8px;
        bottom: 10px;
        z-index: 999;
        display: flex;
        align-items: center;
    }

    .audiencebox {
        position: absolute;
        height: 140px;
        width: 95.6%;
        bottom: 5px;
        left: 2.2%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .audiencebox_list {
        width: 140px;
        height: 140px;
        background: #000;
        margin-right: 20px;
    }

    .btn_min {
        margin-right: 20px;
        width: 14rem;
        height: 40px;
        border-radius: 0.8rem;
        text-align: center;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn_min span {
        font-size: 20px;
        margin-right: 10px;
    }

    .detail_input {
        margin-left: 30px;
        display: flex;
        font-size: 14px;
    }

    .detail_input input {
        width: 345px;
        height: 40px;
        background: #000000;
        opacity: 0.6;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 8px;
        margin-right: 10px;
        padding: 0 3%;
        color: #fff;
    }

    .sendbtn {
        width: 91px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 8px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
    }

    .Meet_box_mess {
        width: 15.84%;
        height: 666px;
        background: #E7EEF5;
        border: 2px solid rgba(255, 255, 255, 0.2);
        box-shadow: 6px 6px 16px rgba(163, 177, 198, 0.6), -6px -6px 16px rgba(255, 255, 255, 0.6);
        border-radius: 10px;
        margin-left: 32px;
        padding: 0 2%;
    }

    .Meet_box_mess_now {
        height: 50px;
        /* opacity: 0.6; */
        border-bottom: 1px dashed rgb(112 110 140 / 60%);
        font-family: 'siyuanb';
        font-size: 16px;
        line-height: 24px;
        color: #FF7474;
        padding: 18px 0;
        font-family: "siyuanb";
    }

    .Meet_box_mess_other div:nth-child(1) {
        font-size: 14px;
        line-height: 21px;
        margin: 20px 0;
    }

    .Meet_box_mess_other div {
        font-size: 14px;
        line-height: 30px;

    }

    /* 弹框 */
    .el-dialog {
        width: 1060px;
        height: 600px;
        background: #F5FAFE;
        border: 1px solid #E2E2E8;
        border-radius: 5px;
    }

    .el-dialog .el-dialog__header {
        border-bottom: 1px solid #E7EEF5;
        text-align: center;
        color: #706e8c;
        font-family: 'siyuanb';
    }

    .el-dialog .el-dialog__body {
        padding-top: 15px;
    }

    .dialog_name {
        text-align: center;
        margin-bottom: 12px;
        font-size: 14px;
    }

    .dialog_time {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .dialog_tit {
        text-align: center;
        color: #F47814;
        font-size: 14px;
        margin-bottom: 22px;
    }

    .dialog_list {
        height: 46px;
        background: #EEF3FA;
        border-radius: 5px;
        padding-left: 14px;
        margin-bottom: 10px;
    }

    .dialog_list_btn {
        display: flex;
    }

    .dialog_list_btn span {
        width: 60px;
        height: 30px;
        border-radius: 5px;
        display: block;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        line-height: 30px;
        cursor: pointer;
    }

    .dialog_list_btn span:nth-child(1) {
        background: #43A0F5;

    }

    .dialog_list_btn span:nth-child(2) {
        background: #FE9B4B;
    }

    .dialog_lists {
        height: 40px;
    }

    .dialog_btn {
        display: flex;
        color: #fff;
        font-size: 1.4rem;
        margin: 44px auto 0;
        width: 65%;
    }

    .dialog_btn div {
        width: 268px;
        height: 40px;
        line-height: 40px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
    }

    .dialog_btn div:nth-child(1) {
        margin-right: 30px;
    }

    /* 签到成功 */
    .qianbtn {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        font-size: 16px;

    }

    .qianbtn div {
        height: 50px;
        width: 40%;
        text-align: center;
        line-height: 50px;
        border: 1px solid #fff;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
    }

    /* 参会人 */
    .joinlist {
        height: 50px;
        /* line-height: 50px; */
        font-size: 20px;
        border-bottom: 1px dashed #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .joinlist image {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        margin-right: 10px;
        float: left;
        margin-top: 5px;
        background-size: 100% 100%;
    }

    .joinlist text:nth-child(1) {
        width: 70%;
        display: block;
        white-space: norwap;
        overflow-x: scroll;
        /* height: 50px; */
        padding: 0 10px;

    }

    .joinlist text:nth-child(2) {
        width: 20%;
        text-align: center;
        display: block;
        color: yellowgreen;

    }

    /* 上麦管理 */
    .gongbottoms {
        display: flex;
        justify-content: space-around;
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
        color: #FFFFFF;
    }

    .gongbottoms view {
        width: 45%;
        text-align: center;
        border-radius: 5px;

    }

    .gongbottoms text:nth-child(1) {
        margin-right: 10px;
    }

    /* 统计 */
    .tongqian {
        margin-bottom: 15px;
    }

    table {
        border-top: 1px solid #eee;
        border-left: 1px solid #eee;
    }

    .tonglist {
        /* height: 30px; */
        line-height: 30px;
        font-size: 14px;
        text-align: center;
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid #eee;
        align-items: center;
    }

    .tonglist text {

        /* border-right: 1px solid #eee; */
        text-align: center;
    }

    .tonglist text:nth-child(1) {
        font-weight: 700;
        width: 40%;
    }

    .tonglist text:nth-child(2) {
        width: 35%;
    }

    .tonglist text:nth-child(3) {
        width: 20%;
    }

    /* 上麦管理 */
    /* 申请上麦 */
    .maitable {
        border: 1px solid #EEEEEE;
        font-size: 14px;
        width: 100%;
    }

    .maitable tr {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #eee;
        width: 100%;
    }

    .maitable tr:nth-child(1) {
        font-weight: 700;
    }

    .maitable tr:last-child {
        border: none;
    }

    .maitable td {
        width: 33%;
        text-align: center;
        float: left;
        /* border-right: 1px solid #EEEEEE; */
    }

    .maitable td:last-child {
        border: none;
        color: #06AE56;
        cursor: pointer;
    }

    /* 表决列表 */
    .biaolist {
        padding: 10px 0;
        font-size: 18px;
        /* height: 40px; */
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #eee;
        justify-content: space-between;
        line-height: 20px;

    }

    .biaolist span:nth-child(1) {
        width: 70%;
        float: left;
        word-break: break-all;
        word-wrap: break-word;

    }

    .biaolist span:nth-child(2) {
        float: right;
        width: 25%;
        line-height: 40px;
        background: green;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }

    /* 上麦 */
    .maicon {
        text-align: center;
        line-height: 5rem;
        font-size: 1.8rem;
    }

    .twobtn {
        height: 40px;
        display: flex;
        justify-content: space-around;
        line-height: 40px;

    }

    .twobtn div:nth-child(1) {
        background: #eee;
        width: 30%;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }

    .twobtn div:nth-child(2) {
        background: #00aa00;
        width: 30%;
        text-align: center;
        border-radius: 5px;
        color: #FFFFFF;
        cursor: pointer;
    }

    /* 表决内容弹起 */
    .biaocontent {
        height: 50px;
        width: 90%;
        border: 1px solid #ccc;
        margin: 0 auto;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        color: green;
        overflow: auto;
    }

    .biaoradio {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;

    }

    .biaoradio label {
        width: 40%;
        border: 1px solid #eee;
        font-size: 16px;
        padding: 3px 5px;
        border-radius: 5px;
        text-align: center;
    }

    .biaolist {
        padding: 10px 0;
        font-size: 14px;
        /* height: 40px; */
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #eee;
        justify-content: space-between;
        line-height: 20px;

    }

    .biaolist text:nth-child(1) {
        width: 70%;
        float: left;
        word-break: break-all;
        word-wrap: break-word;

    }

    .biaolist text:nth-child(2) {
        float: right;
        width: 25%;
        line-height: 40px;
        background: green;
        color: #fff;
        text-align: center;
        border-radius: 5px;

    }
    .btn{
        color: #fff;
        padding: 0 10px;
        border-radius: 5px;
        cursor: pointer;
    }
    .surebtn{
        width: 50%;
        font-size: 14px;
        margin: 0 auto;
        margin-top: 15px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }
    /* 控制按钮 */
    .kongbtn{
        height: 30px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        bottom: 0;
        margin-bottom: 10px;
        font-size: 12px;
        text-align: center;

    }
    .kongbtn div{
       border-radius: 5px;
       line-height: 30px;
       background: #FFF;
       padding: 0 10px;
       cursor: pointer;
    }

    /* 外接设备 */
    .devicebox{

    }
    .devicebox div,.devicebox button{
        padding: 10px 18px;
        border: 1px solid #b9b9b9;
        border-radius: 10px;
        cursor: pointer;
    }
    .noticeclass{
        font-size: 16px;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        line-height: 100px;
        text-align: center;
        width: 100%
    }
    .startroomclass{
        position: absolute;
        left: 10px;
        top: 10px;
        color: #fff;
        font-size: 30px;
        cursor: pointer
    }
    .leaveroomclass{
        position: absolute;
        right: 10px;
        top: 10px;
        color: #fff;
        font-size: 30px;
        cursor: pointer
    }
    .playbtnclass{
        position: absolute;
        font-size: 100px;
        left: 45%;
        top: 40%;
        color:#fff;
        cursor: pointer
    }
</style>