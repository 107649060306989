import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'  //路由
import Router from 'vue-router'
import axios from "axios";
import store from './store/store'
import VueCookies from 'vue-cookies'

import './plugins/element.js'
import echarts from 'echarts'
import Video from 'video.js'
import AMap from 'vue-amap';
import 'video.js/dist/video-js.css'
import '../public/style.css'   //公共css
import './utils/rem'   //全局文字适配

import 'vue-event-calendar/dist/style.css'
import vueEventCalendar from 'vue-event-calendar'
import scroll from 'vue-seamless-scroll'
import VueScroller from 'vue-scroller'
import Clipboard from 'clipboard'
import dataV from '@jiaminghi/data-view'
// import 'lib-flexible'

//Vue全局挂载axios
Vue.prototype.$axios = axios;
Vue.prototype.$video = Video
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
const TcPlayer = window.TcPlayer
Vue.prototype.TcPlayer = TcPlayer
Vue.prototype.Clipboard=Clipboard;


// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: 'eac0bfbf24f3db1d0205f3977fd0d084',
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Geolocation'],
  mapStyle: "amap://styles/dark"
});


//以下代码解决路由地址重复点击的报错问题
const originalPush = Router.prototype.push
  Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

//设置baseUrl
axios.defaults.baseURL = "https://pc.pochanjie.com/api";
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';//配置请求头信息。
// axios.defaults.headers.common['accessToken'] = 'FA4C308D5E8F6409E01344ADDAEB4C71';

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.matched.some((auth) => auth.meta.requireAuth)) {
    let token = localStorage.getItem("token");
    if (token) {
        next();
    } else {
        next({
            path: "/Loginnew",
            query:{
              // type:'login'
            }
        });
    }
  } else {
    // if((to.path=='/')&&(from.path=='/'))  window.alert("如果想使用完整的功能，请使用Chrom72+版本或Firefox66+版本")
    next();
  }
})




Vue.use(AMap);
Vue.use(Router)
Vue.use(ElementUI)
Vue.use(vueEventCalendar, {locale: 'en'})
Vue.use(scroll)
Vue.use(VueCookies)
Vue.use(VueScroller)
Vue.use(dataV)
new Vue({
  router,
  store,

  render: h => h(App)
}).$mount('#app')
