<template>
    <div class="Zhaiwu">
        <div class="Zhai_title flexbe">
            <div class="Zhai_title_left">
                <div class="Zhai_title_name">友情链接</div>
            </div>
        </div>
        <div class="You_list">
            <img src="../image/you.png" alt="">
            <img src="../image/you.png" alt="">
            <img src="../image/you.png" alt="">
            <img src="../image/you.png" alt="">
        </div>
         
    </div>
</template>
 
<script>
    export default {
        name: 'Gongan',
        data(){
            return{
                current:0,
                tablist:["第一季"],
            }
        },
        created() {
           
        },
        methods:{
            tabbtn:function(index){
                this.current=index
            }
        }
    }
</script>
 
<style scoped>
.Zhaiwu{
    margin-bottom: 30px;
    height: 241px;
    padding: 0 2%;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 30%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    width: 82%;
    margin: 0 auto;
    color: #717171;
}
.Zhai_title{
    font-size: 14px;
    /* border-bottom: 1px solid #ccc; */
    line-height: 50px;
    color: #43A0F5;
}
.Zhai_title_left{
    display: flex;
    align-items: center;
    width: 80%;
    
}
.Zhai_title_name{
    font-size: 18px;
    font-family:'siyuanb';
    margin-right: 1rem;
    
}
.Zhai_title_name span{
    font-size: 14px;
    color: red;
}
.Zhai_title_right span{
    color: #fff;
    padding: 4px;
    font-size: 13px;
    margin-right: 5px;
}
.Zhai_tab{
    display: flex;
    width: 75%;
    overflow-x: auto;
    white-space: nowrap;
}
.Zhai_tab div{
    /* padding: 0 0.5rem; */
    padding-bottom: 0.1rem;
    /* border-right: 1px solid #ccc; */
    cursor: pointer;
    height: 0.2rem;
    line-height: 0.2rem;
    border-bottom: 1px solid #fff;
    padding:0 10px 5px
}

.Zhai_title_right{
    display: flex;
}

/* 内容 */
.You_list{
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.You_list img{
    width: 23.05%;
    /* height: 1.5rem; */
    background-size: 100% 100%;
}
</style>