<template>
    <div class="calendar_box">
        <el-calendar v-model="value" id="calendar" style="width: 50%;">
            <template slot="dateCell" slot-scope="{date, data}">
                <p :class="data.isSelected ? 'is-selected' : ''" @click="showid(data)" >
                    {{ data.day.slice(data.day.length-2,data.day.length)}} 
                    <div v-for="(item,index) in calendarData" :key="index">
                        <div v-if="(item.months).indexOf(data.day.split('-').slice(1)[0])!=-1">
                            <div v-if="(item.days).indexOf(data.day.split('-').slice(2).join('-'))!=-1">
                                <div class="is-selected_notice"></div>
                                <!-- <el-tooltip class="item" effect="dark" :content="item.theme" placement="right">
                                    <div class="is-selected_notices"></div>
                                </el-tooltip> -->
                            </div>
                            <div v-else></div>
                        </div>
                        <div v-else></div>
                    </div>
                </p>
            </template>
        </el-calendar>
        <div class="events">
            <!-- <div class="events_tit">2021-7-26</div> -->
            <div class="event_list flexbe" v-for="(item,index) in eventlist" :key="index" v-if="eventlist.length!==0"><span>{{item.theme}}</span><span @click="Govideodetail(item.id)">查看详情</span></div>
            <div style="line-height: 50px;" v-if="eventlist.length==0">暂时没有会议</div>
        </div>
    </div>
</template>
<script>
   
    import {MeetingLists} from '@/request/api';

    export default {
        name: 'Bottom',
        data(){
            return{
                calendarData: [
                    // { months: ['07'],days: ['15'],theme: '债权人第一次会议',id:'15' },
                    // { months: ['07'],days: ['16'],theme: '债权人第一次会议',id:'16' },
                    // { months: ['07'],days: ['02'],theme: '债权人第二次会议',id:'2'},
                ],
                eventlist:[
                    // {
                    //     notice:"债权人第一次会议",
                    //     id:'1'
                    // },{
                    //     notice:"债权人第一次会议",
                    //     id:'1'
                    // }
                ],
                value: new Date()
            }
        },
        created() {
            // for(var j=0;j<this.eventlist.length;j++){
            //     this.eventlist[j].notice="债权人第"+this.calendarData[2].id+"次会议"
            // }
            MeetingLists({
                token:localStorage.getItem('token')
            }).then(res=>{
                this.calendarData=res.data;
                this.eventlist=res.data;
            })
        },
        methods:{
            showid:function(item){
                var day=item.day.slice(item.day.length-2,item.day.length)
                var month=item.day.slice(item.day.length-5,item.day.length-3)
                var that=this;
                var aa=[];

                for(var i=0;i<that.calendarData.length;i++){
                    if(month==that.calendarData[i].months&&day==that.calendarData[i].days){
                        aa=that.calendarData[i];

                            // if(that.calendarData[i].id==1){
                                // for(var j=0;j<that.eventlist.length;j++){
                                //     that.eventlist[j].notice="债权人第"+that.calendarData[i].id+"次会议"
                                // }
                            // }
                        
                    }

                }
                that.eventlist=[]
                if(aa.length!==0){
                    that.eventlist.push(aa)

                }

            },
            Govideodetail:function(id){
                this.$router.push({path:'/Liveroom',query:{id:id}})

            }
        }
    }
</script>
 
<style>
    .calendar_box{
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        height: 290px;
        /* overflow: hidden; */
    }
    #calendar{
        width: 50%;
        height: 284px;
        background: #F5FAFE;
        border: 2px solid rgba(255, 255, 255, 0.2);
        box-shadow: 6px 6px 16px rgb(163 177 198 / 40%), -6px -6px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
    }
    .events{
        width: 43%;
        padding: 0 20px;
        overflow-y: scroll;
    }
    .events_tit{
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        font-family: 'siyuanb';
    }
    .event_list{
        /* height: 34px; */
        background: #E7EEF5;
        border-radius: 3px;
        margin-bottom: 9px;
        padding: 0 11px;
        line-height: 30px;
    }
    .event_list span:nth-child(1){
        font-size: 14px;
        overflow:hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
        width: 60%;
        /* font-family: 'siyuanb'; */
    }
    .event_list span:nth-child(2){
        color: #43A0F5;
        font-size: 14px;
        cursor: pointer;
        float: right;
    }
    .is-selected {
        background: #FF9F46;
        color: #fff;
    }
    .is-selected_notice{
        position: absolute;
        bottom: 0;
        left: 19px;
        width: 25px;
        border-bottom: 2px solid red;
    }
  
/* 日历 */
.el-calendar-table .el-calendar-day{
  height: 35px;
  padding: 0;
  position: relative
}
.el-calendar-table .el-calendar-day span{
  width: 35px;
  height: 35px;
  display: block;
  text-align: center;
  line-height: 35px;
  margin: 0 auto;
}
.el-calendar__button-group{
  /* display: none; */
}
.el-calendar__header{
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #706E8C;
  font-family: 'siyuanb';
  /* display: block; */
  border: none;
  /* padding: 0 2rem; */
}
.el-calendar-table tr:last-child{
  display: none;
}
.el-calendar-table tr:first-child td{
  border: none;
}
.el-calendar-table td{
  border: none;
  text-align: center;
}
.el-calendar-table tr td:first-child{
  border: none;
}
.el-calendar-table thead th{
  color: #8C98BF;
  font-size: 14px;
}
.el-calendar__title{
  color: #706E8C;
  font-size: 12px;
}
.el-backtop, .el-calendar-table td.is-today{
  width: 35px;
  height: 35px;
  background: #4E8FFB;
  color: #fff;
  display: block;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  margin: 0 auto;
}
.el-calendar-day p{
  width: 35px;
  height: 35px;
  display: block;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  margin: 0 auto;
}
.el-backtop, .el-calendar-table td.is-today .el-calendar-day:hover{
  background: #4E8FFB;
  border-radius: 50%;
}
/* .el-calendar-table .el-calendar-day:hover{
  background: #4E8FFB;
  color: #fff;
  border-radius: 50%;
} */
  .is_selected {
    background: #FF9F46;
    color: #fff;
  }
  .el-calendar-table .el-calendar-day:hover{
    cursor: pointer;
    background: #fff;
  }
  .el-calendar-table .el-calendar-day{
      height:35px
  }
</style>