<template>
    <div class="Login_box" style="min-width: 1200px;">
       <div class="Login_main_box">
            <div class="login_main_img">
                <img src="../../image/login.png" alt="" mode="aspectFill" style="width: 100%;height: 100%;">
            </div>
            <!-- 登录 -->
            <div class="login_main" v-if="type=='login'">
                <div id="login_container" ></div>
                    <!-- <iframe :src="maurl" frameborder="0"></iframe> -->
                <div style="text-align: center;font-size: 16px;color: green;">使用微信扫码完成登录</div>
            </div>
            <!--设置手机号 -->
            <div class="login_main register" v-if="type=='register'">
                <div class="login_main_tit">注册<span></span></div>
                <!-- <img src="../../image/register.png" alt="" class="progress"> -->
                <el-steps :active="active" finish-status="success" align-center class="stepclass">
                    <el-step title="手机短信验证码"></el-step>
                    <!-- <el-step title="设置密码"></el-step> -->
                    <!-- <el-step title="微信扫码验证"></el-step> -->
                </el-steps>
                <div class="register_first">
                    <div class="login_main_text">手机号</div>
                    <input type="text" placeholder="手机号" v-model="registerphone">
                    <div class="login_main_text">短信验证码</div>
                    <div class="codeboxx">
                        <input type="text" placeholder="请输入验证码" v-model="registercode">
                        <div @click="getcode('register')" :style="{backgroundColor:codetype==0?'#43A0F5':'#999'}">获取验证码</div> 
                    </div>
                    <div class="loginbtn" @click="next('refirst')">完成</div>
                </div>
                <!-- <div class="register_third" v-if="active==1">
                    <div class="login_main_text">设置登陆密码</div>
                    <input type="text" placeholder="请输入登录密码" v-model="registerpassword">
                    <div class="loginbtn" @click="next('resecond')">下一步</div>

                </div> -->
            </div>
       </div>
       <div class="login_bottom">Copyright © 2020-2021 谋道破产管理平台 All Rights Reserved. 鄂ICP备42011202001834号</div>
       <!-- 提示框 -->
       <Toast :toastText="content" v-show="toastShow"></Toast>
    </div>
</template>
<script type="text/javascript" src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>

import {PcScanRegister,PcUserSms,PcBindPhone} from '@/request/api';
import Toast from '../../components/Toast.vue'


export default {
    name:'Login',
    components: {
        Toast
    },
    data(){
        return{
            maurl:'',
            logintype:'input',
            active: 0,
            type:'login',
            loginphone:'',
            loginpassword:'',
            registerphone:'',
            registercode:'',
            registerpassword:'',
            forgetphone:'',
            forgetcode:'',
            forgetpassword:'',

            content:'',
            toastShow:false,
            codetype:0,
            codeforgettype:0,

            random:0,
            timestamp:'',
            token:'',
            timer:{}
        }
    },
    created: function () {
        this.getRandom(3);   //生成二维码，时间戳+3位随机数
    },
    beforeDestroy() {
        clearInterval(this.checkregister);        
        this.checkregister = null;
    },
    methods:{
        getRandom:function(num){
            this.timestamp = Date.parse(new Date());
            var random='';
            for(var i=0;i<num;i++){
                random+=Math.floor(Math.random()*10);
            }
            this.random=random;
            var that=this;
            const s = document.createElement('script')
            s.type = 'text/javascript'
            s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
            const wxElement = document.body.appendChild(s)
            wxElement.onload = function () {
                var obj = new WxLogin({
                    self_redirect:true,
                    id:"login_container", 
                    appid: "wxd24c34b5f46ba684", 
                    scope: "snsapi_login", 
                    redirect_uri: encodeURIComponent("https://pc.pochanjie.com/api/pc_user/scan?state="+that.timestamp+that.random), 
                    state: that.timestamp+that.random, 
                    style: "black",
                    href: "data:text/css;charset=utf-8;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDMwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDtkaXNwbGF5OiBub25lO30KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lfQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fSA="
                });
            }
            this.timer = setInterval(() =>{                    
                this.checkregister()
            }, 500);            
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            this.$once('hook:beforeDestroy', () => {            
                clearInterval(this.timer);                                    
            })
        },
        getcode:function(status){   //发送验证码
            if(status=='register'){
                PcUserSms({
                    token:localStorage.getItem('token'),
                    mobile:this.registerphone
                }).then(res=>{
                    if(res.code==0){
                        this.content=res.msg
                    }else{
                        this.content=res.msg
                        this.codetype=1;
                    }
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
                })
            }
        },
        
        next:function(type){
            if(type=='refirst'){  
                if(this.registerphone==''||this.registercode==''){
                    this.content="请输入手机号及其验证码"
                    this.toastShow=true
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
                }else{
                   
                    PcBindPhone({   
                        mobile:this.registerphone,
                        captcha:this.registercode,
                        token:localStorage.getItem('token')
                    }).then(res=>{
                        if(res.code==1){
                            localStorage.setItem('phone', this.registerphone);
                            this.$router.push({ name:'Home' })

                        }else{
                            this.content=res.msg;
                            this.toastShow=true;
                            setTimeout(() => {
                                this.toastShow=false;
                            }, 1500);
                        }
                        
                    })
                }
                
            }
            
        },
        checkregister:function(){
            PcScanRegister({   
               state:this.timestamp+this.random
            }).then(res=>{
                if(res.code==1){
                    this.$store.commit('$_setToken', res.data.token);
                    this.$store.commit("setname", {
                        avatar: res.data.userInfo.avatar,
                        nickname: res.data.userInfo.nickname,
                        phone: res.data.userInfo.phone,
                        uid:res.data.userInfo.uid
                    });
                    if(res.data.userInfo.phone==''){
                        this.type="register";
                    }else{
                        
                        clearInterval(this.checkregister);
                        this.content=res.msg;
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false;
                            this.$router.push({ name:'Home' })
                            this.codetype=0;
                            this.codeforgettype=0;
                        }, 1500);
                    }
                    clearInterval(this.timer);    
                }else{
                }
            })
        },
        
        
    }
}
</script>
<style scoped>
.Login_box{
    min-width: 1500px;
    /* background: #f5f5fa; */
    width: 100%;
    height: 85%;
    /* position: relative; */
    /* padding-top: 7.5%; */
    margin: 7.5% auto 0;
}
.Login_main_box{
    width: 74.8%;
    height: 650px;
    position: relative;
    background: #F4F8FB;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
}
.login_main_img{
    width: 46.37%;
    height: 100%;
    overflow: hidden;
}
.login_main{
    width: 426px;
    position: relative;
    padding: 70px 0 0 120px;
}

.login_main_tit{
    font-size: 30px;
    line-height: 36px;
    display: flex;
    align-items: center;
    
    margin-bottom: 56px;
}
.login_main_text{
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #696F79;
    margin-top: 24px;
}

.login_main input{
    border: none;
    width: 426px;
    height: 64px;
   padding: 0 30px;
    background: #FFFFFF;
    border: 1px solid #43A0F5;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px 3px rgb(0 0 0 / 11%);
    border-radius: 6px;
    margin-top: 14px;
    outline: none;
}
.forgetbtn{
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #43A0F5;
    margin-top: 15px;
    cursor: pointer;
}
.loginbtn{
    width: 426px;
    height: 64px;
    line-height: 64px;
    background: #43A0F5;
    border-radius: 6px;
    margin-top: 29px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.register{
    font-size: 14px;
    text-align: center;
    line-height: 50px;
    color: #666;
}
.register span{
    color: #0191FD;
    cursor: pointer;
}
.register .login_main_tit{
    margin: 0;
}
.progress{
    width: 427px;
    height: 66px;
    margin: 16px auto 0px;
}
.codeboxx{
    position: relative;
}
.codeboxx div{
    position: absolute;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    top: 25px;
    right: 11px;
    color: #fff;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
}
.register .loginbtn{
    margin-top: 63px;
}
.wx{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.6rem;
    color: #0191FD;
    /* background: rgba(3, 187, 122, 0.1); */
    /* width: 20%; */
    /* text-align: center; */
    height: 5rem;
    /* line-height: 30px; */
    display: flex;
    align-items: center;
}
.wxtext{
    background: rgba(3, 187, 122, 0.1);
    /* width: 10%; */
    text-align: center;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    padding: 0 10px;
}
.wx img{
    width: 50px;
    height: 50px;
}
.login_bottom{
    font-size: 15px;
    color: #999;
    text-align: center;
    /* margin-top: 100px; */
    position: absolute;
    bottom: 20px;
    width: 100%;
    min-width: 1500px;
}
/* 注册第二步 */
.register_second{
    position: relative;
    height: 200px;
}
.register_second img{
    width: 142px;
    height: 136px;
    margin: 0 auto;
    display: block;
}
.register_second div:nth-child(2){
    font-size: 14px;
    line-height: 21px;
    /* display: flex; */
    align-items: center;
    text-align: center;
    margin-top:16px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
/* 注册第三步 */
.register_third .login_main_text{
    margin-top: 85px;
}
/* 进度条 */
.stepclass{
    width: 427px;
    height: 60px;
    line-height: 21px;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 6px 6px 16px rgb(163 177 198 / 60%), -6px -6px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    margin: 32px auto 0;
    padding-top: 15px;
}

#login_container{
   margin: 0 auto;
   width: 300px;
}
#login_container iframe{
    width: 150px;
    height: 150px;
}
.impowerBox .qrcode{
    width: 200px !important;
}
</style>