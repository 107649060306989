<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header></Header>
        <div class="Election_cons">
            <div class="Network_tit flexar">选<br>举<br>通<br>道</div>
            <div class="Election_first" v-if="electionactive==0">
                <!-- <img src="../../image/first.png" alt=""> -->
                
                <div class="Election_con" v-for="(item,index) in videolists" :key="index" v-if="videolists.length!==0">
                    <div class="Election_name">{{item.theme}}</div>
                    <div class="Election_mess">
                        <div class="Election_time">{{item.start_time}}-{{item.end_time}}</div>
                        <!-- <div class="flexbe" style="height: 46px;padding-right: 10px;"><span>选举材料</span><span @click="pulldetail" style="cursor: pointer;" class="iconfont icon-a-Frame2"></span></div> -->
                        <div class="dialog_list flexbe" style="height: auto;" v-for="(items,index) in item.detail.material_files" :key='index' >
                            <span>{{items.real_name}}</span>
                            <div class="dialog_list_btn" @click="read(items.path,item.detail.user_sign_file.file)">阅读</div>
                        </div>
                        <div class="dialog_list_btn_all" @click="getsign(item,'file')" v-if="roles=='member'">{{item.detail.user_sign_file.file==0?'一键签收':'已签收'}}</div>
                    </div>
                    <div class="Election_btns themebgcolor" @click="GoElect(item)" >我要参加选举</div>
                   
                </div>
                <div @click="GoElectother" class="Election_btns themebgcolor" v-if="show_button==1">我要参加其他选举</div>

                <div v-if="videolists.length==0" style="text-align: center;font-size: 20px;margin-top: 70px;">
                    <div>目前没有新的需要选举的事项</div>
                    <div>请关注管理人发布的有关动态消息</div>
                </div>
                <!-- <Nocontent v-if="videolists.length==0"></Nocontent> -->
            </div>
            <div class="Election_first" v-if="electionactive==1">
                <!-- 选择身份 -->
                <div class="Election_second_con_other " v-if="status=='other'">
                    <div class="Election_btns" @click="electionnext('zhai')" :style="{background:type=='zhai'?'#FF9F46':'#43A0F5'}" v-if="roles=='member'">我是债权人</div>
                    <div class="Election_btns" @click="electionnext('dai')" :style="{background:type=='dai'?'#FF9F46':'#43A0F5'}" v-if="roles=='member'">我是代理人</div>
                    <div class="Election_btns" @click="electionnext('manage')" :style="{background:type=='manage'?'#FF9F46':'#43A0F5'}" v-if="roles=='manage'">我是管理员</div>
                    <div class="Election_btns" @click="electionnext('aid')" :style="{background:type=='aid'?'#FF9F46':'#43A0F5'}"  v-if="roles=='aid'">我是助手</div>
                    <div class="Election_btns" @click="electionnext('work')" :style="{background:type=='work'?'#FF9F46':'#43A0F5'}"  v-if="roles=='work'">我是法院工作人员</div>
                </div>

                <!-- 选项卡 -->

                <div class="Election_second" v-if="status=='ordinary'">
                    <div class="Manage_tab">
                        <div :class="currentzhai==index?'Manage_tab_active':''" v-for="(item,index) in managetab" :key="index" @click="tabclick(index)">{{item}}</div>
                    </div>
                    <div class="Manage_mine" v-if="currentzhai==0">
                        <div class="Election_second_tit">选举人</div>
                        <div class="Election_second_list flexbe" v-for="(item,index) in xuanlist" :key="index">
                            <span>{{item.case_user_name}}</span>
                            <div :style="{background:item.is_election==1?'green':'#1E78F4'}" @click="electionbtn(item)">{{item.is_election==1?'已选举':'去选举'}}</div>
                        </div>
                        
                    </div>
                    <div class="Manage_mine" v-if="currentzhai==1">
                        <div class="Election_tab" v-for="(item,index) in data" :key="index">
                            <div class="Election_tab_tit">{{item.name}}</div>
                            <div class="Election_tab_list flexbe" :style="{background: (indexs == 'yes' ||  indexs == 'agree') ? '#B0C7E6' :''}" v-for="(items,indexs) in item.left.data" :key="indexs">
                                <span>{{items.name}}</span>
                                <span>{{items.number}}</span>
                                <span>{{items.percent}}%</span>
                            </div>
                            <div class="Election_tab_list flexbe" :style="{background: (indexs == 'yes' ||  indexs == 'agree') ? '#B0C7E6' :''}" v-for="(items,indexs) in item.right.data" :key="indexs">
                                <span>{{items.name}}</span>
                                <span>{{items.number}}</span>
                                <span>{{items.percent}}%</span>
                            </div>
                        </div>
                    </div>
                    <div class="Manage_mine" v-if="currentzhai==2&&path!='video'">
                        <div class="Join_person" v-for="(item,index) in joinlist" :key="index" v-if="joinlist.length!==0">{{item.name}}</div>
                        <div class="Join_person" v-if="joinlist.length==0">暂无参会人</div>
                    </div>
                    <div class="Manage_mine" v-if="currentzhai==2&&path=='video'" style="padding: 20px;">
                        <div class="joinlist" v-for="(item,index) in aidlist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>助手</span>
                        </div>
                        <div class="joinlist" v-for="(item,index) in managelist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>管理员</span>
            
                        </div>
                        <div class="joinlist" v-for="(item,index) in memberlist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>债权人</span>
            
                        </div>
                        <div class="joinlist" v-for="(item,index) in otherlist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>其他</span>
            
                        </div>
                        <div class="joinlist" v-for="(item,index) in playlist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>主播</span>
            
                        </div>
                        <div class="joinlist" v-for="(item,index) in worklist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>工作人员</span>
            
                        </div>
                    </div>
                </div>
            </div>
            <div class="Election_first" v-if="electionactive==2">
                <div class="Election_third_con_other" v-if="type=='zhai'">
                    <div class="Election_third_con_list flexbe">
                        <span>姓名：</span>
                        <input type="text" placeholder="请输入姓名" v-model="zhainame">
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>手机号：</span>
                        <input type="text" placeholder="请输入手机号" v-model="zhaiphone">
                    </div>
                    <div class="Election_third_con_list" style="display: flex;align-items: center;">
                        <span>身份：</span>
                        <div style="margin-left: 20px;">
                            <el-radio-group v-model="zhaiFrom.type" style="margin-left: 10px;" >
                                <el-radio :label="1">个人</el-radio>
                                <el-radio :label="2">公司</el-radio>
                            </el-radio-group>
                        </div>
                        
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>验证码：</span>
                        <input type="text" placeholder="请输入验证码" v-model="zhaicode">
                    </div>
                    <div class="nextbtn" @click="nextstep('zhai')">登录</div>
                </div>
                <!-- 代理人 -->
                <div class="Election_third_con_other" v-if="type=='dai'">
                    <div class="Election_third_con_list flexbe">
                        <span>姓名：</span>
                        <input type="text" placeholder="请输入姓名" v-model="agent_name">
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>手机号：</span>
                        <input type="text" placeholder="请输入手机号" v-model="agent_phone">
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>区域：</span>
                        <el-cascader
                            clearable
                            v-model="areavalue"
                            :options="selectarea"
                            :props="{ expandTrigger: 'hover' }"
                            @change="handleChange">
                        </el-cascader>
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>送达地址：</span>
                        <input type="text" placeholder="请输入送达地址" v-model="agent_addr">
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>债权人姓名：</span>
                        <input type="text" placeholder="请输入债权人姓名" v-model="claim_name">
                    </div>
                    <div class="Election_third_con_list" style="display: flex;align-items: center;">
                        <span>身份：</span>
                        <div style="margin-left: 20px;">
                            <el-radio-group v-model="daiFrom.type" style="margin-left: 10px;" >
                                <el-radio :label="1">个人</el-radio>
                                <el-radio :label="2">公司</el-radio>
                            </el-radio-group>
                        </div>
                        
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>邀请码：</span>
                        <input type="text" placeholder="请输入邀请码" v-model="codes">
                    </div>
                    <div class="nextbtn" @click="nextstep('dai')">登录</div>
                </div>
                <!-- 工作人员，助手，管理员 -->
                <div class="Election_third_con_other" v-if="type=='manage'||type=='aid'||type=='work'">
                    <div class="Election_third_con_list flexbe">
                        <span>账号：</span>
                        <input type="text" placeholder="请输入账号" v-model="account">
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>密码：</span>
                        <input type="text" placeholder="请输入密码" v-model="mima">
                    </div>
                    <div class="nextbtn" @click="nextstep()">验证</div>
                </div>
            </div>
             <!-- 提示框 -->
            <Toast :toastText="content" v-show="toastShow"></Toast>

            <el-dialog title="选举债委会成员" :visible.sync="dialogTableVisible" @close='closeDialog'>
                <div class="Election_third_con_xuan">
                    <div class="Election_third_con_xuan_tit">选举人：{{case_user_name}}</div>
                    <div class="Election_third_con_xuan_hou" v-if="election_content.length!==0">候选人：（您可以点击选择下列候选人）</div>
                    <div class="selectxuan" v-if="is_election==0">
                        <div v-for="(items,index1) in election_content" :key="index1" @click="xuanbtns(items,index1)" :style="{background:items.type==1?'#1E78F4':'#a3a3a3'}">{{items.name}}</div>
                       
                    </div>
                </div>
                <div class="Election_third_con_xuan">
                    <div class="Election_third_con_xuan_tit">被选举人</div>
                    <input v-for="(items,index2) in election_content" v-if="items.type==1" type="text" :value="items.name" placeholder="请输入其他备选人名称" disabled class="inputclass">
				    <input v-for="(items,index3) in null_data" autocomplete="off" v-if="items.type==1" type="text" :value="items.name"  @input="xuaninput($event)" placeholder="请输入其他备选人名称" class="inputclass" :id="index3">
                    <!-- <input type="text" placeholder="aaa" @input="xuaninput($event)"> -->
                    <el-checkbox v-model="checked"  style="font-size: 12px;">我已阅读选举债委员会须知</el-checkbox>
                    <div class="sendbtns" @click="sendbtn">提交</div>
                    <div style="text-align: center;color: red;">{{content}}</div>
                </div>
            </el-dialog>
            
        </div>
        
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'
    import Toast from '../../components/Toast.vue'
    import Nocontent from '../../components/Nocontent.vue'
    import { getcitydata } from "../../../public/city-picker.data";

    import {ElectLists,ElectStatistical,ElectUserList,MeetingCheckIdentity,ElectSignFile,ElecElectionContent,ElectElectionList,ElectDoElection,
        MeetingLists,MeetingElectionList,MeetingStatistical,MeetingGetChrList,MeetingVoteList} from '@/request/api';

    export default {
        name: 'Election',
        components: {
            Header,
            Toast,
            Nocontent
        },
        data(){
            return{
                baseURL:'https://pc.pochanjie.com',

                radio: '1',
                checked: true,
                electionactive:0,
                status:'ordinary',
                step:'first',
                roles:'member',
                active:0,
                path:'',
                // 选项卡
                managetab:["选举","统计","参会人"],
                currentzhai:0,
                dialogTableVisible:false,

                //
                videolists:[],
                election_content:[],
                election_status:0,
                show_button:0,
                xuanlist:[],
                case_user_name:'',
                is_election:0,
                null_data:[],
                beixuanlists:[],
                othername:'',
                i:0,
                case_user_id:'',
                //其他登录
                type:'',
                account:'',
                mima:'',
                zhainame:'',
                zhaiphone:'',
                zhaicode:'',
                zhaiFrom:{
                    id: 0,
                    name: '',
                    type: 1,
                },
                region:[],
                areavalue: [],
                selectarea: [],
				agent_name:'',
				agent_phone:'',
				agent_addr:'',
				claim_name:'',
				claim_code:'',
				agent_pro:'',
				agent_city:'',
				agent_area:'',
                daiFrom:{
                    id: 0,
                    name: '',
                    type: 1,
                },
                codes:'',
                content:'',
                toastShow:false,
                aidlist:[],
                managelist:[],
                memberlist:[],
                otherlist:[],
                playlist:[],
                worklist:[],
            }
        },
        created() {
            this.path=this.$route.query.path;
            this.selectarea=getcitydata('3')
            
            if(this.path=='video'){
                this.electionactive=1;
                this.status='ordinary';
                this.major=this.$route.query.item;
                this.meeting_id=this.major.id;
                this.case_id=this.major.case_id;
                this.electionmessage()
            }
            this.videolist()
           
        },
        methods:{
            handleChange(value) {   //选择地址
                this.agent_pro=value[0];
                this.agent_city=value[1];
                this.agent_area=value[2];
                this.areavalue=value;
            },
            videolist:function(){
                ElectLists({   //选举会议列表
                    token:localStorage.getItem('token')
                    // elect_id:this.meeting_id
                }).then(res=>{
                    this.videolists=res.data;
                    // this.roles=res.data.roles;
                    // this.show_button=res.data.show_button;
                    // this.major=this.videolists[0];
                    // this.otherlist=this.videolists.slice(1)
                })
            },
            electionnext:function(type){
                this.electionactive=2;
                this.type=type;
            },
            GoElect:function(item){
                if(this.roles=='member'&&item.detail.file_status == 1){
                    if(item.detail.user_sign_file.file==0){
                        this.content="请先签收文书";
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false
                        }, 1500);
                        return
                    }
                }
               

                var that=this;
				var timestemp1 = Date.parse(new Date())
				var Time2 = new Date(item.start_time);
				var timestemp2 = Time2.getTime();
				var Time3 = new Date(item.end_time);
				var timestemp3 = Time3.getTime();
				if(timestemp2>timestemp1){
					this.content="当前会议还没有开始";
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
				}else if(timestemp1>timestemp3){
                    this.content="当前会议已结束";
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
				}else{
                    this.biaojuelist=item;
                    this.meeting_id=item.id;
                    this.case_id=item.case_id;
                    this.electionactive=1;
                    this.status="ordinary";
                    this.electionmessage();
                }
                
            },
            GoElectother:function(){
                this.electionactive=1;
                this.status='other'
            },
            electionmessage:function(){
                if(this.path=='video'){
                    MeetingElectionList({
                        token:localStorage.getItem('token'),
                        meeting_id:this.meeting_id,
                        // case_id:this.case_id,
                    }).then(res=>{
                        if(res.code==1){
                            this.xuanlist=res.data;
                        }
                    })

                    MeetingStatistical({
                        token:localStorage.getItem('token'),
                        meeting_id:this.meeting_id
                    }).then(res=>{
                        this.election_status=res.data.election_status;
                        this.election_data=res.data.election_data;
                        this.data=res.data.data;
                    })

                    MeetingGetChrList({
                        token:localStorage.getItem('token'),
                        meeting_id:this.meeting_id
                    }).then(res=>{
                        this.aidlist = res.data.aid;
                        // this.managelist = res.data.manage;
                        this.memberlist = res.data.member;
                        this.otherlist = res.data.other;
                        this.playlist = res.data.play;
                        this.worklist = res.data.work;
                    })
                }else{
                    //选举列表
                    ElectElectionList({
                        token:localStorage.getItem('token'),
                        elect_id:this.meeting_id
                    }).then(res=>{
                        this.xuanlist=res.data;
                    })

                    ElectStatistical({   //统计列表
                        token:localStorage.getItem('token'),
                        elect_id:this.meeting_id
                    }).then(res=>{
                        // this.election_status=res.data.election_status;
                        // this.election_data=res.data.election_data;
                        this.data=res.data.data;
                    })

                    ElectUserList({  //参会人列表
                        token:localStorage.getItem('token'),
                        elect_id:this.meeting_id
                    }).then(res=>{
                        this.joinlist=res.data
                    })
                }
                
            },
            pulldetail:function(){  //会议材料下拉
                if(this.pull==1){
                    this.pull=0
                }else{
                    this.pull=1
                }
            },
            read:function(path,status){   //阅读
                if(status==0){   
                    this.content="请先签收文书再进行阅读";
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
                }else{
                    window.open(this.baseURL+path)
                }
            },
            getsign:function(item,type){  //签收
				var that=this;
				if(item.detail.user_sign_file.file==1&&type=='file'){
					that.content="会议材料已签收"
                    that.toastShow=true;
                    setTimeout(() => {
                        that.toastShow=false;
                    }, 1500);
				}
				if(item.sign_excel_status==1&&type=='excel'){
					that.content="债权表已签收"
                    that.toastShow=true;
                    setTimeout(() => {
                        that.toastShow=false;
                    }, 1500);
				}
				if(item.sign_notice_status==1&&type=='notice'){
					that.content="会议公告已签收"
                    that.toastShow=true;
                    setTimeout(() => {
                        that.toastShow=false;
                    }, 1500);
				}
				if(item.sign_inform_status==1&&type=='inform'){
					that.content="会议通知已签收"
                    that.toastShow=true;
                    setTimeout(() => {
                        that.toastShow=false;
                    }, 1500);
				}
                ElectSignFile({
                    token:localStorage.getItem('token'),
                    elect_id:item.id,
                    input_type:type
                }).then(res=>{
                    if(res.code==1){
                        that.content="签收成功"
                    }else{
                        that.content=res.msg
                    }
                    that.toastShow=true;
                    setTimeout(() => {
                        that.toastShow=false;
                        that.videolist()
                    }, 1500);
                })
				
               

			},
            nextstep:function(type){
                var that=this;
				if(that.roles=='manage'||that.roles=='work'||that.roles=='aid'){
					if(that.account==''||that.mima==''){
						that.content="账号或密码为空！"
					}else{
                        MeetingCheckIdentity({
                            account:that.account,
							password:that.mima,
							type:that.roles
                        }).then(res=>{
                            that.content=res.msg;
                            that.electionactive=0
							
						})
                    }
				}else if(that.roles=='member'){
					if(type=='zhai'){  // 0 债权人 1 代理人
						if(that.zhainame==''||that.zhaiphone==''||that.zhaicode==''){
							that.content="基本信息为空"
						}else{
							MeetingCheckIdentity({
                                name:that.zhainame,
								phone:that.zhaiphone,
								attributes:that.zhaiFrom.type,
								identification_code:that.zhaicode,
								type:that.roles,
								member_type:0
                            }).then(res=>{
                                that.content=res.msg;
                                that.electionactive=0
                            })
						}
						
					}
                    if(type=='dai'){					// 0 债权人 1 代理人
						if(that.agent_name==''||that.agent_phone==''||that.agent_pro==''||that.agent_area==''||that.agent_addr==''||that.claim_name==''||that.codes==''){
							that.content="请填写必填项"
                          
						}else{
							MeetingCheckIdentity({
                                agent_name:that.agent_name,
								agent_phone:that.agent_phone,
								agent_pro:that.agent_pro,
								agent_city:that.agent_city,
								agent_area:that.agent_area,
								agent_address:that.agent_addr,
								creditor_name:that.claim_name,
								invitation_code:that.codes,
								type:that.roles,
								attributes:that.daiFrom.type,
								member_type:1
                            }).then(res=>{
                                that.content=res.msg;
                                that.electionactive=0
                            })
						}
						
					}
				}
                that.toastShow=true;
                setTimeout(() => {
                    that.toastShow=false
                }, 1500);

            },
            tabclick:function(index){
                this.currentzhai=index
            },
            electionbtn:function(item){
                this.case_user_name=item.case_user_name;
                if(item.allow==0){
					this.content='没有权限进行选举'
				}else{
					if(item.is_election==1){
						this.content="不可重复选举"
					}else{
                        this.dialogTableVisible=true;

                        if(this.path=='video'){
                            // MeetingElectionList({
                            //     token:localStorage.getItem('token'),
                            //     elect_id:this.elect_id
                            // }).then(res=>{
                            //     this.election_status=res.data.election_status;
                            var bb=[];
                            for(var i=0;i<this.election_content.length;i++){
                                var aa={};
                                
                                aa.name=this.election_content[i];
                                aa.type=0;
                                bb.push(aa)
                            }
                            this.election_content=bb;
                                for(var i =0;i < 9;i++) {
                                    var bb = {};
                                    bb.type = 1;
                                    bb.name='';
                                    this.null_data.push(bb);
                                }
                                MeetingElectionSingle({
                                    token:localStorage.getItem('token'),
                                    meeting_id:this.meeting_id,
                                    case_user_id:this.case_user_id
                                }).then(res=>{
                                    if(res.code==1){
                                        this.case_user_name=res.data.case_user_name;
                                        this.is_election=res.data.is_election;
                                    }
                                })
                            // })
                        }else{
                            this.case_user_id=item.case_user_id;
                            ElecElectionContent({   //选举种子
                                token:localStorage.getItem('token'),
                                elect_id:this.meeting_id
                            }).then(res=>{
                                if(res.code==1){
                                    
                                    this.election_status=res.data.election_status;
                                    for(var i=0;i<res.data.election_content.length;i++){
                                        var aa={}
                                        aa.name=res.data.election_content[i];
                                        aa.type=0;
                                        this.election_content.push(aa)
                                    }
                                    for(var i =0;i < 9;i++) {
                                        var bb = {};
                                        bb.type = 1;
                                        bb.name='';
                                        this.null_data.push(bb);
                                    }
                                    // ElectLists({
                                    //     elect_id:this.meeting_id,
                                    //     case_id:this.case_id,
                                    //     case_user_id:item.case_user_id
                                    // }).then(res=>{
                                    //     if(res.code==1){
                                    //         this.case_user_name=res.data.data.case_user_name;
                                    //         this.is_election=res.data.data.is_election;
                                    //     }
                                    // })
                                }
                            })
                        }
                        

					}
				}
                this.toastShow=true;
                setTimeout(() => {
                    this.toastShow=false
                }, 1500);
               
            },
            xuanbtns:function(item,index){
				if(this.election_content[index].type) {
					// 开一个框
					for(var i = 0;i < 9;i++) {
						if(this.null_data[i].type == 0) {
							this.null_data[i].type = 1;
							break;
						}
					}
				} else {
					// 关一个框
					for(var i = 8;i >= 0;i--) {
						if(this.null_data[i].type == 1) {
							this.null_data[i].type = 0;
							break;
						}
					}
				}
				var null_tmp = this.null_data;
				this.null_data = [];
				this.null_data = null_tmp;
				this.election_content[index].type = this.election_content[index].type == 0 ? 1 : 0;
				var tmp = this.election_content;
				this.election_content = [];
				this.election_content = tmp;
			},
            xuaninput:function(e){
                // this.null_data[this.i].name=e.data;
                // this.i++;
                // this.null_data.push({name:e.data,type:1})
				this.null_data[e.target.id].name=e.data;

			},
			sendbtn:function(){
				var that=this;
				that.beixuanlists=[];
				for(var i=0;i<that.election_content.length;i++){
					if(that.election_content[i].type==1){
						that.beixuanlists.push(that.election_content[i].name)
					}
				}
				for(var i=0;i<that.null_data.length;i++){
					if(that.null_data[i].type==1&&that.null_data[i].name!==''){
						that.beixuanlists.push(that.null_data[i].name)
					}
				}
				if(that.beixuanlists.length==0){
					that.content="请至少选一位候选人，最多九人"
					return
				}
				if(that.checked==false){
				    that.content="请阅读并勾选《选举债委会成员须知》"
					return
				}
				var nary=that.beixuanlists.sort();
				for(var i=0;i<that.beixuanlists.length;i++){
					if (nary[i]==nary[i+1]){
						that.content='被选举人'+nary[i]+'重复'
						return
					}
					
				}
				var beixuanlist=that.beixuanlists.toString();
                // that.toastShow=true;
                // setTimeout(() => {
                //     that.toastShow=false
                // }, 1500);
                ElectDoElection({
                    token:localStorage.getItem('token'),
                    elect_id:that.meeting_id,
                    case_user_id:that.case_user_id,
                    name:beixuanlist
                }).then(res=>{
                    if(res.code==1){
                        that.content=res.msg;
                        setTimeout(() => {
                            that.dialogTableVisible=false;
                            that.content="";
                            that.election_content=[];
                            that.null_data=[];
                        }, 2000);
                        
                        
                    }
                })
			},
            closeDialog:function(){
                this.election_content=[];
                this.null_data=[];
                // for(var i =0;i < 9;i++) {
                //     var bb = {};
                //     bb.type = 1;
                //     bb.name='';
                //     this.null_data.push(bb);
                // }
                this.dialogTableVisible=false
            }
            
        }
    }
</script>
<style scoped>
    .Election_cons{
        width: 86.4%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    .Election_first{
        position: relative;
    }
    .Network_tit{
        width: 52px;
        height: 138px;
        margin-top: 127px;
        background: #43A0F5;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        font-size: 16px;
        /* font-size: 16px; */
        color: #fff;
        text-align: center;
        /* vertical-align: middle; */
    }
    .Election_first{
        width: 90%;
        margin-top: 32px;
    }
    .Election_first img{
        display: block;
        margin: 0 auto;
        width: 55.8%;
    }
    .Election_con{
        /* height: 385px; */
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        border: 1px solid #e7eef5;
        text-align: center;
    }
    .Election_time{
        font-size: 14px;
        text-align: center;
        
        
        margin-bottom: 10px;
    }
    .Election_name{
        font-size: 16px;
        
        
        font-family: 'siyuanb';
        height: 46px;
        line-height: 46px;
        /* border-bottom: 1px solid #E7EEF5; */
    }
    .Election_mess{
        border-bottom: 1px solid #E7EEF5;
        padding: 20px 0;
    }
    .Election_name{
        /* font-size: 14px; */
        /* line-height: 21px; */
        /* text-align: center; */
        
        
        /* margin-bottom: 10px; */
    }
    .Election_tit{
        color: #F47814;
        margin-bottom: 24px;
    }
    .Election_title{
        margin-bottom: 13px;
        
    }
    .Election_btn{
        display: flex;
        margin: 0 auto;
        width: 15%;
        justify-content: space-between;
    }
    .Election_btn div:nth-child(1){
        width: 78px;
        height: 30px;
        line-height: 30px;
        border-radius: 5px;
        color: #fff;
        background: #FE9B4B;

        cursor: pointer;
    }
    .Election_btn div:nth-child(2){
        width: 78px;
        height: 30px;
        line-height: 30px;
        background: #43A0F5;

        border-radius: 5px;
        color: #fff;
        cursor: pointer;

    }
    .Election_btns{
        width: 268px;
        height: 40px;
        background: #43A0F5;
        border: 1px solid #E2E2E8;
        border-radius: 5px;
        margin: 23px auto 20px;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
    }
    .Election_btns div:nth-child(2){
        width: 268px;
        height: 40px;
        background: #FF9F46;
        border: 1px solid #E2E2E8;
        border-radius: 5px;
        margin: 0 auto;
        line-height: 40px;
        color: #fff;
        cursor: pointer;

    }
    .dialog_list{
        /* height: 46px; */
        width: 40%;
        background: #EEF3FA;
        border-radius: 5px;
        padding:10px;
        margin: 0 auto 10px;
        font-size: 14px;
    }
    .dialog_list_btn{
        width: 60px;
        height: 30px;
        border-radius: 5px;
        display: block;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        line-height: 30px;
        cursor: pointer;
        background: #43A0F5;
    }
    .dialog_list_btn_all{
        width: 120px;
        height: 30px;
        border-radius: 5px;
        display: block;
        text-align: center;
        color: #fff;
        margin: 0 auto;
        line-height: 30px;
        cursor: pointer;
        font-size: 14px;
        background: #FE9B4B;
    }
    .dialog_lists{
        height: 40px;
    }
    /* 第二部分 */
    .Election_second_con_other{
        /* height: 158px; */
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        border: 1px solid #e7eef5;
        text-align: center;
    }
    .Election_second{
        /* height: 180px; */
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        border: 1px solid #e7eef5;
        text-align: center;
        padding-bottom:20px ;
    }
    .Election_second_tit{
        font-size: 16px;
        height: 49px;
        line-height: 49px;
        
        border-bottom: 1px solid #E7EEF5;
        
        font-family: 'siyuanb';
        text-align: left;
        padding-left: 37px;
    }
    .Election_second_list{
        height: 30px;
        margin-top: 20px;
        padding: 0 37px;
        font-size: 14px;
    }
    .Election_second_list div{
        width: 100px;
        height: 30px;
        background: #43A0F5;
        border-radius: 5px;
        color: #fff;
        line-height: 30px;
        cursor: pointer;
        font-size: 14px;
    }
    /* 第三部分 */
    .Election_third_con_other{
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        border: 1px solid #e7eef5;
        text-align: center;
        padding: 32px 0;
    }
    .Election_third_con_list{
        height: 40px;
        margin: 0px auto 10px;
        width: 35%;
        
    }
    .Election_third_con_list span{
        text-align: right;
        width: 20%;
        font-family: 'siyuanb';
        font-size: 14px;
    }
    .Election_third_con_list input{
        width: 346px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #E2E2E8;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0 13px;
        outline: none;
    }
    .nextbtn{
        width: 100px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        background: #43A0F5;
        border-radius: 5px;
        margin: 30px auto 0;
        cursor: pointer;
    }
    /* 第三部分 */
    .Election_third_con{
        height: 591px;
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        border: 1px solid #e7eef5;
    }
    .Election_third_con_xuan{
        padding: 24px 44px 31px 44px;
        border-bottom: 1px solid #E7EEF5;
    }
    .Election_third_con_xuan_tit{
        font-size: 14px;
        line-height: 50px;
        font-family: 'siyuanb';
    }
    .Election_third_con_xuan_hou{
        margin: 10px 0 20px;
        font-size: 14px;
        /* line-height: 21px; */
        
        
    }
    .selectxuan{
        display: flex;
    }
    .selectxuan div{
        width: 100px;
        height: 30px;
        background: rgb(130 130 148 / 20%);
        /* opacity: 0.2; */
        border-radius: 5px;
        text-align: center;
        line-height: 30px;
        margin-right: 20px;
        cursor: pointer;
        color: #fff;
    }
    .Election_third_con_xuan input{
        width: 372px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #E2E2E8;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 10px;
        display: block;
        padding-left: 13px;
        outline: none;
    }
    .Election_third_con_xuan input:nth-child(1){
        margin-top: 20px;
    }
    .Election_third_con_xuan:last-child{
        border: none;
    }
    .sendbtns{
        width: 78px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        background: #43A0F5;
        border-radius: 5px;
        cursor: pointer;
        margin: 20px auto;
        text-align: center;
    }

    /* 选项卡 */
    .Manage_tab{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        text-align: center;
        font-size: 16px;
        font-family: 'siyuanb';
        line-height: 40px;
        cursor: pointer;
    }
    .Manage_tab .Manage_tab_active{
        background: #43A0F5;
        color: #fff;
    }
    .Manage_tab div{
        width: 33.3%;
        height: 40px;
        
        background: #fff;
        border: 1px solid #E2E2E8;
        box-sizing: border-box;
        border-radius: 5px 0px 0px 0px;
    }
    /* 统计 */
    .Election_tab{
        width: 90%;
        margin: 0 auto;
        margin-top: 18px;
    }
    .Election_tab_tit{
        height: 48px;
        background: #DAE5EF;
        border-radius: 5px;
        font-size: 14px;
        line-height: 48px;
    }
    .Election_tab_list{
        padding: 0 26px;
        font-size: 14px;
    }
    .Election_tab_list:nth-child(even){
        background: #EEF3FA;
        height: 30px;
    }
    .Election_tab_list:nth-child(odd){
        background: #E1EAF2;
        height: 37px;
    }
    
  /* 参会人 */
  .Join_person{
        line-height: 50px;
        font-size: 14px;
        padding: 0 20px;
        text-align: left;
    }
    .joinlist{
        height: 50px;
        /* line-height: 50px; */
        font-size: 16px;
        border-bottom: 1px dashed #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>