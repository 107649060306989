<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header ></Header>
        <div class="Download_box">
            <!-- <div class="Download_con"> -->
                <div class="Download_tab">
                    <div class="flexar" :class="currentfile==0?'Download_tab_active ':''" @click="mybook('0')">会<br>议<br>文<br>书</div>
                    <img src="../../image/dashed.png" alt="">
                    <div class="flexar" :class="currentfile==1?'Download_tab_active ':''" @click="mybook('1')">公<br>共<br>文<br>书</div>
                </div>
                <div style="width: 90%">
                    <div class="Download_tit">会议文件</div>
                    <div  v-if="currentfile==0">
                        <div class="Download_content" v-for="(item,index) in private" :key="index" v-if="private.length!==0">
                            <div class="book_tit">{{item.title}}</div>
                            <!-- <div class="book_time">2021年06月04日   -    2021年07月10日 </div> -->
                            <div class="book_list" >
                                <div class="book_list_tit flexbe" @click="huitran(item,index)"><span>会议</span><span  class="iconfont icon-a-Frame3" style="cursor: pointer;"></span></div>
                                <div class="book_list_con" v-for="(items,indexss) in item.meeting_data" v-if="item.meeting_data.length!==0&&item.meeting_type==1">
                                    <span style="color: #43A0F5;">{{items.title}}</span>
                                    <div v-for="(itemss,index) in items.input" :key="index" v-if="items.input.length!==0" style="display: flex;justify-content: space-between;align-items: center;line-height: 25px;margin: 5px 0;">
                                        <div>{{itemss.real_name}}</div>
                                        <div class="book_list_btn">
                                            <div @click="read(itemss.path)">阅读</div>
                                            <div @click="download(itemss.path)">下载</div>
                                        </div>
                                    </div>
                                    <div v-if="items.input.length==0"  style="line-height: 50px;font-size: 16px;">暂无内容</div>
                                </div>
                            </div>
                            <div class="book_list ">
                                <div class="book_list_tit flexbe" @click="biaotran(item,index)"><span>表决</span><span  class="iconfont icon-a-Frame3" style="cursor: pointer;"></span></div>
                                <div class="book_list_con" v-for="(items,indexss) in item.vote_data" v-if="item.vote_data.length!==0&&item.vote_type==1">
                                    <span style="color: #43A0F5;">{{items.title}}</span>
                                    <div v-for="(itemss,index) in items.input" :key="index" v-if="items.input.length!==0" style="display: flex;justify-content: space-between;align-items: center;line-height: 25px;margin: 5px 0;">
                                        <div>{{itemss.real_name}}</div>
                                        <div class="book_list_btn">
                                            <div @click="read(itemss.path)">阅读</div>
                                            <div @click="download(itemss.path)">下载</div>
                                        </div>
                                    </div>
                                    <div v-if="items.input.length==0" style="line-height: 50px;">暂无内容</div>
                                </div>
                            </div>
                            <div class="book_list ">
                                <div class="book_list_tit flexbe" @click="xuantran(item,index)"><span>选举</span><span  class="iconfont icon-a-Frame3" style="cursor: pointer;"></span></div>
                                <div class="book_list_con" v-for="(items,indexss) in item.elect_data" v-if="item.elect_data.length!==0&&item.elect_type==1">
                                    <span style="color: #43A0F5;">{{item.title}}</span>
                                    <div v-for="(itemss,index) in items.input" :key="index" v-if="items.input.length!==0" style="display: flex;justify-content: space-between;align-items: center;line-height: 25px;margin: 5px 0;">
                                        <div>{{itemss.real_name}}</div>
                                        <div class="book_list_btn">
                                            <div @click="read(itemss.path)">阅读</div>
                                            <div @click="download(itemss.path)">下载</div>
                                        </div>
                                    </div>
                                    <div v-if="items.input.length==0"  style="line-height: 50px;font-size: 16px;">暂无内容</div>

                                </div>
                            </div>
                        </div>
                        <!-- <div class="notice" >暂无内容</div> -->
                        <Nocontent v-if="private.length==0"></Nocontent>
                    </div>
                    <div class="Download_content" style="padding: 19px 0;" v-if="currentfile==1">
                        <div class="book_lists flexbe" v-for="(item,index) in publiclists" :key="index" v-if="publiclists.length!==0">
                            <span>{{item.title}} </span>
                            <div class="book_list_btn">
                                <div @click="read(item.input[0].path)">阅读</div>
                                <div @click="download(item.input[0].path)">下载</div>
                            </div>
                        </div>
                        <!-- <div class="notice" v-if="publiclists.length==0">暂无内容</div> -->
                        <Nocontent v-if="publiclists.length==0"></Nocontent>

                    </div>
                </div>
                
            <!-- </div> -->
        </div>
    </div>
   
</template>
<script>
    import Header from '../../components/Header.vue'
    import Nocontent from '../../components/Nocontent.vue'
    import {MeetingBook} from '@/request/api';
    import { getcitydata } from "../../../public/city-picker.data";

    export default {
        name:'File',
        components: {
            Header,
            Nocontent
        },
        data(){
            return{
                baseURL:'https://pc.pochanjie.com',

                close:0,
                currentfile:0,
                private:[],
                publiclists:[],
            }
        },
        created(){
            this.filelist();
        },
        methods:{
            filelist:function(){
                MeetingBook({
                    token:localStorage.getItem('token')
                }).then(res=>{
                    this.private=res.data.private;
				    this.publiclists=res.data.public;
                    for(var i=0;i<this.private.length;i++){
                        this.private[i].elect_type=0;
                        this.private[i].vote_type=0;
                        this.private[i].meeting_type=0;
                        var aa=this.private;
                        this.private=[];
                        this.private=aa;
                    }
                })
            },
            huitran:function(item,index){
				var that=this;
				if(item.meeting_type==0){
					that.private[index].meeting_type=1
				}else{
					that.private[index].meeting_type=0
				}
				var aa=that.private;
				that.private=[];
				that.private=aa;
			},
            biaotran:function(item,index){
				var that=this;
				if(item.vote_type==0){
					that.private[index].vote_type=1
				}else{
					that.private[index].vote_type=0
				}
				var aa=that.private;
				that.private=[];
				that.private=aa;
			},
			xuantran:function(item,index){
				var that=this;
				if(item.elect_type==0){
					that.private[index].elect_type=1
				}else{
					that.private[index].elect_type=0
				}
				var aa=that.private;
				that.private=[];
				that.private=aa;
			},
            mybook:function(num){
                if(num==0){
                    this.currentfile=0;
                }else{
                    this.currentfile=1
                }
            },
            read:function(path){
                window.open(this.baseURL+path);
            },
            download:function(path){
                window.open(this.baseURL+path);
            }
        }
    }
</script>
<style scoped>
.Download_box{
    background: #eef3fa;
    padding-bottom: 2rem;
    min-height: 100%;
    display: flex;
    width: 86.4%;
    margin: 0 auto;
    margin-top: 45px;
    justify-content: space-between;
}
.Download_tit{
    font-family: 'siyuanb';
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom:30px ;
}

/* 选项卡 */
.Download_con{
    position: relative;
}
.Download_tab{
    /* position: absolute;
    left: 13.4rem; */
}
.Download_tab div{
    width: 1.04rem;
    height: 2.76rem;
    background: #E7EEF5;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
}
.Download_tab .Download_tab_active{
    background: #43A0F5;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    color: #fff;
}
.Download_tab img{
    width: 4px;
    display: block;
    margin: 5px auto;
}
.Download_content{
    background: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    border: 1px solid #e7eef5; 
    text-align: center;
    margin: 0 auto;
}
.book_tit{
    font-size: 18px;
    text-align: center;
    color: red;
    margin-bottom: 10px;
    height: 49px;
    line-height: 49px;
    border-bottom: 1px solid #E7EEF5;
}
.book_time{
    font-size: 14px;
    /* line-height: 21px; */
    text-align: center;
    
    
    margin-top: 15px;
    margin-bottom: 26px;

}
.book_list{
    width: 95%;
    height: auto;
    /* left: 332px; */
    /* top: 354px; */
    background: #EEF3FA;
    border-radius: 5px;
    
    margin: 0 auto;
    padding: 15px 1.5%;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    

    
}
.book_list_tit{
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    font-family: 'siyuanb';
}
.book_list_tit span:nth-child(2){
    font-size: 35px;
}
.book_list_con{
    /* justify-content: space-between; */
    font-size: 14px;
    line-height: 21px;
    /* display: flex; */
    /* align-items: center; */
    
    margin-bottom: 30px;
    margin-top: 18px;
}
.book_list_btn{
    display: flex;
}
.book_list_btn div{
    width: 60px;
    height: 30px;
    color: #fff;
    line-height: 30px;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
}
.book_list_btn div:nth-child(1){
    background: #43A0F5;
}
.book_list_btn div:nth-child(2){
    background: #FE9B4B;
}
/* 下载模板 */
.book_lists{
    width: 95%;
    padding: 0 1.5%;
    height: 46px;
    background: #EEF3FA;
    border-radius: 5px;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    


}
</style>