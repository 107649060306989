import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import Header from '../components/Header.vue'

//直播大讲堂详情
import Live from '../views/Live/Live.vue'
import Livenew from '../views/Live/Livenew.vue'
import Livelist from '../views/Live/Livelist.vue'
//网上办公
import Network from '../views/Network/Network.vue'
import Networks from '../views/Network2/Network.vue'
//选举通道
import Election from '../views/Election/Election.vue'
// 债权人会议
import Meet from '../views/Meet/Meet.vue'
import Liveroom from '../views/Liveroom/Liveroom.vue'
//下载中心
import Download from '../views/Download/Download.vue'
//表决通道
import Vote from '../views/Vote/Vote.vue'
//债权管理
import Manage from '../views/Manage/Manage.vue'
// 法律规定
import Rulers from '../views/Rulers/Rulers'
import Rulerdetail from '../views/Rulers/Rulerdetail'
// 债务人信息
import Debtorinformation from '../views/Debtorinformation/Debtorinformation.vue'
import Debtordetail from '../views/Debtorinformation/Debtordetail.vue'
//登录
import Login from '../views/login/Login.vue'
import Loginnew from '../views/login/Loginnew.vue'
//会议文件
import File from '../views/File/File.vue'
// 日常通知
import Daynotice from '../views/Daynotice/Daynotice'
// 管理人名册
import Administrators from '../views/Administrators/Administrators'

// 首页模板
import Pochan from '../components/Pochan.vue'
import Falv from '../components/Falv.vue'
import Guanliren from '../components/Guanliren.vue'
import Guanlirenx from '../components/Guanlirenx.vue'
import Ying from '../components/Ying.vue'
import You from '../components/You.vue'
import Bottom from '../components/Bottom.vue'
import Bottommin from '../components/Bottommin.vue'

// 其他模板
import Calendar from '../components/Calendar.vue'
import About from '../views/About.vue'
import Asset from '../views/Asset.vue'
import Toast from '../components/Toast.vue'
import Nocontent from '../components/Nocontent.vue'


Vue.use(VueRouter)

const routes = [
  {
    path:'/About',
    name:'About',
    component:About
  },
  {
    path:'/Asset',
    name:'Asset',
    component:Asset
  },
  {
    path: '/',  // pc端首页
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/m_index', // 手机端首页
  //   name: MIndex,
  //   component: MIndex
  // },



  {
    path: '/Header',
    name: 'Header',
    component: Header
  },
  {
    path:'/Toast',
    name:'Toast',
    component:Toast
  },
  {
    path:'/Nocontent',
    name:'Nocontent',
    component:Nocontent
  },
  //直播大讲堂
  {
    path:'/Live',
    name:'Live',
    component:Live
  },
  {
    path:'/Livenew',
    name:'Livenew',
    component:Livenew
  },
  {
    path:'/Livelist',
    name:'Livelist',
    component:Livelist
  },
  //网上办公
  {
    path:'/Network',
    name:'Network',
    component:Network,
    meta: {
      title: "网上办公",
      requireAuth: true // 标识该路由是否需要登录
    }

  },
  {
    path:'/Networks',
    name:'Networks',
    component:Networks,
    meta: {
      title: "网上办公",
      requireAuth: true // 标识该路由是否需要登录
    }

  },
  // 选举通道
  {
    path:'/Election',
    name:'Election',
    component:Election,
    meta: {
      title: "选举通道",
      requireAuth: true // 标识该路由是否需要登录
    }

  },
  // 债权人会议
  {
    path:'/Meet',
    name:'Meet',
    component:Meet,
    meta: {
      title: "债权人会议",
      requireAuth: true // 标识该路由是否需要登录
    }

  },
  {
    path:'/Liveroom',
    name:'Liveroom',
    component:Liveroom,
    meta: {
      title: "债权人会议",
      requireAuth: true // 标识该路由是否需要登录
    }

  },
// 下载中心
  {
    path:'/Download',
    name:'Download',
    component:Download,
    meta: {
      title: "下载中心",
      requireAuth: true // 标识该路由是否需要登录
    }

  },
  // 表决通道
  {
    path:'/Vote',
    name:'Vote',
    component:Vote,
    meta: {
      title: "表决通道",
      requireAuth: true // 标识该路由是否需要登录
    }

  },
  // 债权管理
  {
    path:'/Manage',
    name:'Manage',
    component:Manage,
    meta: {
      title: "债权管理",
      requireAuth: true // 标识该路由是否需要登录
    }

  },
 // 法律规定
 {
  path:'/Rulers',
  name:'Rulers',
  component:Rulers
  },
  {
    path:'/Rulerdetail',
    name:'Rulerdetail',
    component:Rulerdetail
    },
  // 债务人信息
  {
    path: '/Debtorinformation',
    name: 'Debtorinformation',
    component: Debtorinformation
   
  },
  {
    path: '/Debtordetail',
    name: 'Debtordetail',
    component: Debtordetail,
    meta: {
      title: "债务人详情",
      requireAuth: true // 标识该路由是否需要登录
    }

   
  },
  //登录，注册
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/loginnew',
    name: 'Loginnew',
    component: Loginnew
  },
  // 会议文件
  {
    path: '/File',
    name: 'File',
    component: File,
    meta: {
      title: "会议文件",
      requireAuth: true // 标识该路由是否需要登录
    }

  },
  // 模板
  // 破产前沿
  {
    path: '/Pochan',
    name: 'Pochan',
    component: Pochan
  },
  // 法律规定
  {
    path: '/Falv',
    name: 'Falv',
    component: Falv
  },
  // 管理人名册
  {
    path: '/Guanliren',
    name: 'Guanliren',
    component: Guanliren
  },
   // 管理人选任
   {
    path: '/Guanlirenx',
    name: 'Guanlirenx',
    component: Guanlirenx
  },
  //日常通知
  {
    path: '/Daynotice',
    name: 'Daynotice',
    component: Daynotice
  },
  //管理人名册
  {
    path: '/Administrators',
    name: 'Administrators',
    component: Administrators
  },


  
  {
    path: '/ying',
    name: 'Ying',
    component: Ying
  },
  {
    path: '/you',
    name: 'You',
    component: You
  },
  {
    path: '/bottom',
    name: 'Bottom',
    component: Bottom
  },
  {
    path: '/bottommin',
    name: 'Bottommin',
    component: Bottommin
  },{
    path:'/Calendar',
    name:'Calendar',
    component:Calendar
  }
  
  
]


const router = new VueRouter({
  routes
})

export default router
