<template>
    <div class="Zhaiwu">
        <div class="Zhai_title flexbe">
            <div class="Zhai_title_left">
                <div class="Zhai_title_name">
                    <span @click="yingtab('0')" :class="yingnum==0?'Zhai_title_name_active':''">直播大讲堂</span>
                    <span @click="yingtab('1')" :class="yingnum==1?'Zhai_title_name_active':''">学术研讨会</span> 
                </div>
            </div>
            <div class="more" @click="more">更多></div>
        </div>
        <div style=" padding: 10px 0;margin: 10px 0;display: flex;">
            <div class="Ying_list" @click="Golive(item.id,item.play_schema,item.is_pay,item.allow,item.back_url)" v-for="(item,index) in bigstudylists" :key="index">
                <img :src="item.first_image[0]?baseURL+item.first_image[0].path:require('../image/ying.png')" >
                <div class="Ying_list_tit">{{item.title}}</div>
                <div class="Ying_list_desc">{{item.start_time}}——{{item.end_time}}</div>
                <div class="iconfont icon-bofang1 playbtnclass" style="font-size: 2rem;top: 25%;"></div>

            </div>
            <div style="width: 60%;display: flex;flex-wrap: wrap;justify-content: space-between;">
                <div class="Ying_lists"  @click="Golive(item.id,item.play_schema,item.is_pay,item.allow,item.back_url)"  v-for="(item,index) in minstudylists" :key="index">
                    <img :src="item.first_image[0]?baseURL+item.first_image[0].path:require('../image/ying.png')">
                    <div class="Ying_list_tit">{{item.title}}</div>
                    <div class="Ying_lists_desc morehang">{{item.start_time}}——{{item.end_time}}</div>  
                    <div class="iconfont icon-bofang1 playbtnclass" ></div>
                </div>
            </div>
        </div>
        <Nocontent v-if="bigstudylists.length==0&&minstudylists.length==0"></Nocontent>
        <!-- <div v-if="bigstudylists.length==0&&minstudylists.length==0">暂时还没有直播哦</div> -->
        <!-- <img src="../image/noShopper.png" alt="" style="display: block;margin: 0 auto;"> -->
        <el-dialog width="30%" title="微信扫码支付" :visible.sync="payVisible">
            <img :src="payurl" alt="" style="display: block;margin: 0 auto;">
            <div style="height: 30px;background: green;color: #fff;padding: 5px 10px;border-radius: 5px;margin: 20px auto 0;cursor: pointer;text-align: center;line-height: 30px;" @click="alreadypay">如已支付,请重新进入直播课堂</div>
        </el-dialog>
    </div>
</template>
 
<script>
    import Nocontent from '../components/Nocontent.vue'
    
    import {StudyList,StudySingle,StudyUserInfo} from '@/request/api';
    export default {
        name: 'Ying',
        components: {
            Nocontent
        },
        data(){
            return{
                baseURL:'https://pc.pochanjie.com',

                tablist:["第一季"],
                studylists:[],
                bigstudylists:[],
                minstudylists:[],
                yingnum:0,
                roles:'',
                payVisible:false,
                payurl:''
            }
        },
        created() {
            this.studylist()
        },
        methods:{
            yingtab:function(index){
                this.yingnum=index;
                this.studylists=[];
                this.bigstudylists=[];
                this.minstudylists=[]
				this.studylist();
            },
            studylist:function(){
                StudyList({
                    token:localStorage.getItem('token'),
                    type:this.yingnum
                }).then(res=>{
                    if(res.code==1){
						this.studylists=res.data;
                        this.bigstudylists=this.studylists.slice(0,1)
                        if(this.studylists.length>1){
                            this.minstudylists=this.studylists.slice(1)
                        }
					}
                    
                })
            },
            Golive:function(id,play_schema,pay,allow,backurl){
                if(localStorage.getItem('token')){
                    StudyUserInfo({
                        token:localStorage.getItem('token'),
                        study_id:id
                    }).then(res=>{
                        this.roles=res.data.roles;
                        if(allow==1){   //正常流程
                            if(pay==1){   //付费
                                if(this.roles=='play'&&this.roles=='aid'){   //主播不收费
                                    this.$router.push({path:'/Livenew',query:{id:id,play_schema:play_schema,roles:this.roles,backurl:backurl}})
                                }else{   //其他收费
                                    StudySingle({
                                        token:localStorage.getItem('token'),
                                        study_id:id,
                                        from:'wxpc'
                                    }).then(res=>{
                                        if(res.data.look==0){ //支付
                                            this.payVisible=true;
                                            this.payurl='https://pc.pochanjie.com'+res.data.qrcode;
                                        }else {   //不需要支付，直接进
                                            this.$router.push({path:'/Livenew',query:{id:id,play_schema:play_schema,roles:this.roles,backurl:backurl}})

                                        }
                                    })
                                }
                            }else{
                                this.$router.push({path:'/Livenew',query:{id:id,play_schema:play_schema,roles:this.roles,backurl:backurl}})
                            }
                        }else if(allow==2){
                            this.$emit('childByValue', '直播未开始')

                        }else if(allow ==3){
                            this.$emit('childByValue', '课程播放通道已关闭，若需使用该视频有关数据，请与网站管理员联系。')
                        }
                    }) 
                }else{
                    this.$router.push({path:'/Loginnew',query:{type:'login'}})
                }
                
                
            },
            alreadypay:function(){
                this.payVisible=false;
                this.studylist()
            },
            more:function(){
                this.$router.push({path:'/Livelist',query:{current:this.yingnum}})

            }
        }
    }
</script>
 
<style scoped>
.Zhaiwu{
    margin-bottom: 30px;
    height: 568px;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 30%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    width: 83%;
    margin: 0 auto;
    padding: 0 1.5%;
    color: #717171;
   
}
.Zhai_title{
    font-size: 14px;
    /* font-size: 1.4rem; */
    border-bottom: 1px solid #ccc;
    line-height: 73px;
    color: #43A0F5;
}
.Zhai_title_left{
    display: flex;
    align-items: center;
    width: 80%;
    
    /* font-family:'siyuanb'; */
    font-family: "siyuanb";
}
.Zhai_title_name{
    font-size: 18px;
    /* font-size: 1.8rem; */
    /* font-family:'siyuanb'; */
    margin-right: 1rem;
    
}
.Zhai_title_name span{
    /* font-size: 14px; */
    /* font-size: 1.4rem; */
    /* color: red; */
    margin-right: 20px;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
    cursor: pointer;
}
.Zhai_title_name .Zhai_title_name_active{
    border-color: #43A0F5;
}
.Zhai_title_right span{
    color: #fff;
    padding: 4px;
    font-size: 13px;
    /* font-size: 1.3rem; */
    margin-right: 5px;
}
.Zhai_tab{
    display: flex;
    width: 75%;
    overflow-x: auto;
    white-space: nowrap;
}
.Zhai_tab div{
    /* padding: 0 0.5rem; */
    /* padding-bottom: 0.1rem; */
    /* border-right: 1px solid #ccc; */
    cursor: pointer;
    height: 0.2rem;
    /* line-height: 0.2rem; */
    border-bottom: 1px solid #fff;
    padding:0 10px 5px
}

.Zhai_title_right{
    display: flex;
}

/* 内容 */
.Ying_list{
    background: #F5FAFE;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
    height: 449px;
    margin-right: 30px;
    width: 40%;
    cursor: pointer;
    position: relative;
}
.Ying_list img{
    width: 98%;
    height: 80%;
    margin: 1% auto;
    display: block;
    border-radius: 5px;
}
.Ying_list_tit{
    font-size: 18px;
    /* font-size: 1.8rem; */
    
    /* line-height: 0.8rem; */
    padding: 0 3%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family:'siyuanb';
    margin-bottom: 10px;

}
.Ying_list_desc{
    font-size: 14px;
    /* font-size: 1.4rem; */
    padding: 0 3%;
    
    line-height: 21px;
    /* font-weight: 400; */
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.morehang{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.Ying_lists{
    height: 216px;
    overflow: hidden;
    margin-bottom: 15px;
    width: 47%;
    /* margin-right: 20px; */
    border-radius: 5px;
    background: #F5FAFE;
    border: 1px solid #E2E2E8;
    position: relative;
}
.Ying_lists:nth-child(3n+3){
    margin-right: 0;
}
.Ying_lists img{
    width: 96%;
    height: 62%;
    margin: 2% auto;
    display: block;
    border-radius: 5px;
}
.Ying_lists_tit{
    font-size: 16px;
    /* font-size: 1.6rem; */
    
    line-height: 30px;
    padding: 0 3%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family:'siyuanb';
}
.Ying_lists_desc{
    font-size: 14px;
    /* font-size: 1.4rem; */
    padding: 0 3%;
    
    line-height: 20px;
    /* font-weight: 400; */
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.playbtnclass{
    position: absolute;
    top: 20%;
    left: 40%;
    font-size: 1rem;
    color: #fff;
}
</style>