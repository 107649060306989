<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header :current='current' ></Header>
        <div class="Debtor_box ">
            <div class="Debtor_left flexar" @click="backzhai">全部债务人</div>
            <div class="Debtor_right">
                <!-- 搜索 -->
                <div class="Debtor_searech">
                    <input type="text" placeholder="请输入您的债务人名称的关键词" v-model="searchvalue" @keyup.enter="searchbtns">
                    <div class="searchbtn" @click="searchbtns">搜索</div>
                </div>
                <div class="Debtor_con">
                    <!-- 单选 -->
                    <div class="Debtor_select_box" :style="{display:aa==0?'none':'block',visibility:aa==0?'0':'1'}">
                    
                        <div class="Debtor_select_list">
                            <div class="Debtor_select_list_name">破产案件所在地：</div>
                            <div class="Debtor_select_lists">
                                <div class="buxian" @click="unlimited" :style="{color:unlimitedtype==1?'#FF5C5C':''}">不限</div>
                                <div class="Debtor_select_lists_option">
                                    <div v-for="(items,indexs) in debtorlists" :key="indexs" :style="{color:items.type==1?'#FF5C5C':''}"  @click="selectbtn(items,indexs)">{{items.value}}</div>
                                </div>
                            </div>
                            <!--  -->
                        </div> 
                    </div>
                    <div class="closebtn" @click="slideopen">{{slideopentype?'展开':'关闭'}}</div>

                    <!-- 上升下拉按钮 -->
                    <!-- <div class="Debtor_select_pull" @mouseenter="showActive(1)" @mouseleave="showActive(0)" @click="pullbtn">
                        <img src="../../assets/switch.png" alt="" v-if="active==0">
                        <img src="../../assets/switch-hover.png" alt="" v-if="active==1">
                        <span class="iconfont " :class="type=='kai'?'icon-shangjiantou':'icon-shanglajiantou'"></span>
                    </div> -->
               
                    <div class="Debtor_right_bottom">
                        <div class="Debtor_zhainum">共<span>{{totalnum}}</span>位债务人</div>
                        <div class="Debtor_zhai_list" v-for="(item,index) in DebtorList">
                            <div class="flexbe">
                                <div class="Debtor_zhai_list_con">
                                    <div class="Debtor_zhai_list_name"> 
                                        <span>{{item.company_name}}</span>
                                        <div class="Debtor_zhai_list_con_type" v-if="item.is_show==1">
                                            <div class="iconfont icon-Frame-18" style="font-size: 23px;margin-right: 5px;"></div>
                                            <div>申报结束</div>
                                        </div>
                                     </div>
                                    <div class="Debtor_zhai_list_con_list"><div>受理破产案件法院:</div><div>{{item.court}}</div></div>
                                    <div class="Debtor_zhai_list_con_list"><div>管理人:</div><div>{{item.link_man}}</div></div>
                                    <div class="Debtor_zhai_list_con_list"><div>债权申报截止日期:</div><div>{{item.claims_expire}}</div></div>
                                    <div class="Debtor_zhai_list_con_list"><div>债权申报地点:</div><div>{{item.address}}</div></div>
                                    <div class="Debtor_zhai_list_con_list"><div>管理人联系电话:</div><div>{{item.phone}}</div></div>
                                    <div class="Debtor_zhai_list_con_list"><div>管理人电子邮箱:</div><div>{{item.email}}</div></div>
                                    <div class="Debtor_zhai_list_con_list"><div>管理人微信号:</div><div>{{item.weixin}}</div></div>
                                </div>
                                <div class="Debtor_zhai_list_con_btn">
                                    <div :class="item.is_show==1?'graybgcolor':'themebgcolor'"  @click="debtordetail(item.id,item.is_show,'zhai',index)">我是债权人，我要申报债权</div>
                                    <div :class="item.is_show==1?'graybgcolor':'orangebgcolor'"  @click="debtordetail(item.id,item.is_show,'dai',index)">我是代理人，我要代为申报债权</div>
                                    <div v-if="item.is_show==1&&supplytype==true&&detailcurrent==index" class="orangebgcolor" @click="supply(item.id,type)">补充注册</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                     <!--分页-->
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="5"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalnum">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 提示框 -->
        <Toast :toastText="content" v-show="toastShow"></Toast>
        <el-dialog title="提示" :visible.sync="dialogTableVisible" >
            <div style="text-align: center;font-size: 15px;" v-if="type=='zhai'">您已有申报的债权,是否申报新债权？</div>
            <div class="flexar" style="height: 50px;" v-if="type=='zhai'">
                <div style="text-align: center;background: #43A0F5;color: #fff;padding: 5px 20px;border-radius: 5px;cursor: pointer;" @click="lookmine">查看我的申报</div>
                <div style="text-align: center;background: orange;color: #fff;padding: 5px 20px;border-radius: 5px;cursor: pointer;" @click="looknew('zhai')">申报新的债权</div>
            </div>
            <div style="text-align: center;font-size: 15px;" v-if="type=='dai'">您已有代理的债权,是否代理新债权？</div>
            <div class="flexar" style="height: 50px;" v-if="type=='dai'">
                <div style="text-align: center;background: #43A0F5;color: #fff;padding: 5px 20px;border-radius: 5px;cursor: pointer;" @click="lookmine">查看我的代理</div>
                <div style="text-align: center;background: orange;color: #fff;padding: 5px 20px;border-radius: 5px;cursor: pointer;" @click="looknew('dai')">代理新的债权</div>
            </div>
        </el-dialog>
    </div>
    
</template>
<script>
    import Header from '../../components/Header.vue'
    import Toast from '../../components/Toast.vue'

    import {ZhaiquanList,ClaimsGetCheck,PcCaseSelect,PcCaseList,PcCaseSelfList} from '@/request/api';
    import { getcitydata } from "../../../public/city-picker.data";

    export default {
        name: 'Debtorinformation',
        components: {
            Header,
            Toast
        },
        data(){
            return{
                Debtor_com:'',
                unlimitedtype:1,
                searchvalue:'',
                pro:'',
                debtorlists:[],
                slideopentype:false,
                active:0,
                type:'kai',
                aa:1,
                current:1,
                // 分页
                totalnum:0,
                currentPage: 1,
                getindex:2,
                page:1,
                limit:5,
                // 列表内容
                DebtorList:[],

                 // 其他
                content:'',
                toastShow:false,
                dialogTableVisible:false,
                id:'',
                type:'',
                supplytype:false,
                detailcurrent:-1,
            }
        },
        mounted () {
    　  },
        computed: {

        },
        created() {
            this.Zhaiquan('','');
            this.debtorlists=getcitydata('1')
            for(var i=0;i<this.debtorlists.length;i++){
                this.debtorlists[i].type=0;
            }
        },
        methods:{
            backzhai:function(){
                this.searchvalue='';
                this.Zhaiquan('','');
            },
            slideopen:function(){
                if(this.slideopentype==true){
                    this.debtorlists=getcitydata('1')
                    this.slideopentype=false
                }else{
                    this.debtorlists=this.debtorlists.slice(0,13)
                    this.slideopentype=true
                }
            },
            unlimited:function(){
                this.pro='';
                for(var i=0;i<this.debtorlists.length;i++){
                    this.debtorlists[i].type=0;
                }
                this.unlimitedtype=1;
                this.Zhaiquan('','');
            },
            searchbtns:function(){   //搜索
                this.Zhaiquan(this.searchvalue,this.pro);
            },
            Zhaiquan:function(name,pro){
                if(localStorage.getItem('token')){
                    PcCaseSelfList({
                        company:name,
                        pro:pro,
                        page:this.page,
                        limit:this.limit,
                        token:localStorage.getItem('token')
                    }).then(res => {   
                        this.DebtorList=res.data.rows;
                        this.totalnum=res.data.totalNumber;
                        // if(res.code==1){
                        // }
                    })
                }else{
                    PcCaseList({
                        company:name,
                        pro:pro,
                        page:this.page,
                        limit:this.limit,
                    }).then(res => {   
                        this.DebtorList=res.data.rows;
                        this.totalnum=res.data.totalNumber;
                        // if(res.code==1){
                        // }
                    })
                }
                

            },
            showActive(index) {
                this.active = index;
            },
            getinput:function(e){
                this.searchvalue=e.detail.value;
            },
            pullbtn:function(){
                if(this.type=='kai'){
                    this.type='guan';
                    this.aa=0
                }else{
                    this.type='kai'
                    this.aa=1
                }
            },
            selectbtn:function(items,indexs){
                for(var i=0;i<this.debtorlists.length;i++){

                    // if(i==indexs){
                        // for(var j=0;j<this.debtorlists[i].con.length;j++){
                            this.debtorlists[i].type=0;
                        // }
                    // }
                }
                this.debtorlists[indexs].type=1;

                this.unlimitedtype=0
                this.pro=items.name;
                this.Zhaiquan(this.searchvalue,this.pro);
            },
            debtordetail:function(id,show,type,index){
                this.id=id;
                this.type=type;
                this.detailcurrent=index;
                if(show==1){
                    this.content="您若已经通过其他途径申报债权，请点击“补充注册”加入线上债权人团队，以便正常使用本系统收取法院或管理人发送的文书、参加债权人会议。"
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false;
                        this.supplytype=true;
                    }, 3000);
                    
                }else{
                    if(type=='zhai'){
                        ClaimsGetCheck({
                            token:localStorage.getItem('token'),
                            case_id:id,
                            type:0
                        }).then(res => {   
                            // if(res.status==410000){
                            //     this.content=res.msg;
                            //     this.toastShow=true;
                            //     setTimeout(() => {
                            //         this.toastShow=false
                            //         this.$router.push({path:'/Loginnew',query:{type:'login'}})
                            //     }, 1500);
                            // }else{
                                if(res.data.check==1){   //已有申报债权
                                    this.dialogTableVisible=true;
                                }else if(res.data.check==0){   //正常流程
                                    this.$router.push({path:'/Debtordetail',query:{case_id:id,type:'zhai'}})
                                }else{
                                    this.content="申报次数已达上限，请联系管理人"
                                    this.toastShow=true;
                                    setTimeout(() => {
                                        this.toastShow=false
                                    }, 1500);
                                }
                            // }
                        })
                    }else{
                        ClaimsGetCheck({
                            token:localStorage.getItem('token'),
                            case_id:id,
                            type:1
                        }).then(res => {   
                            // if(res.status==410000){
                            //     this.content=res.msg;
                            //     this.toastShow=true;
                            //     setTimeout(() => {
                            //         this.toastShow=false
                            //         this.$router.push({path:'/Loginnew',query:{type:'login'}})
                            //     }, 1500);
                            // }else{
                                if(res.data.check==1){
                                    this.dialogTableVisible=true;
                                }else if(res.data.check==0){   //正常流程
                                    this.$router.push({path:'/Debtordetail',query:{case_id:id,type:'dai'}})
                                }else{
                                    this.content="申报次数已达上限，请联系管理人"
                                    this.toastShow=true;
                                    setTimeout(() => {
                                        this.toastShow=false
                                    }, 1500);
                                }
                            // }
                        })
                    }
                    
                }
            },
            supply:function(id,type){
                this.$router.push({path:'/Debtordetail',query:{case_id:id,type:type}})
            },
            lookmine:function(){
                this.$router.push({path:'/Manage',query:{case_id:this.id}})
            },
            looknew:function(type){
                this.$router.push({path:'/Debtordetail',query:{case_id:this.id,type:type}})
            },
            // 分页
            handleSizeChange:function(val){
                this.limit=val;
                this.page=1;
                this.currentPage=1;

                this.Zhaiquan()
                // this.Listlength()

            },
            handleCurrentChange:function(val) {
                this.page=val;
                this.currentPage=val;
                this.Zhaiquan()

                // this.Listlength()

            }
        }
    }

</script>
<style scoped>
.Debtor_box{
    position: relative;
    height: auto;
    display: flex;
    width: 86.4%;
    margin: 0 auto;
    margin-top: 27px;
    justify-content: space-between;
    padding-bottom: 60px;
}
.Debtor_left{
    width: 2.5rem;
    height: 0.6rem;
    position: relative;
    margin-top: 20px;
    background: #43A0F5;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    /* font-size: 1.6rem; */
    font-size: 16px;
    color: #fff;
    text-align: center;
    cursor: pointer;
}
.Debtor_right{
    width: 90%;
    /* margin: 0 auto; */
    position: relative;
    height: auto;
}
/* 搜索 */

.Debtor_searech{
    margin-top: 27px;
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
}
.Debtor_searech input{
    width: 400px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 13px;
    outline: none;
    font-size: 16px;
}
.searchbtn{
    width: 80px;
    height: 40px;
    background: #43A0F5;
    border-radius: 5px;
    margin-left: 10px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    font-size: 16px;
}
.Debtor_con{
    width: 96.8%;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    padding: 20px;
    /* position: absolute; */
    z-index: 999;
    position: relative;
}


/* 选择列表 */
.Debtor_select_box{
    /* border-bottom: 1px solid #ccc; */
    position: relative;
    transition: width 1s ease-out, opacity 1s ease-in, visibility 1s ease-in;
    /* visibility: 0;
    opacity: 0; */
}
.Debtor_select_list{
    display: flex;
    padding: 0.1rem 0;
    font-size: 20px;
    line-height: 40px;
    
}
.Debtor_select_list_name{
    width: 15%;
    text-align: right;
    cursor: pointer;
}
.Debtor_select_lists{
    width: 90%;
    display: flex;
}
.buxian{
    width: 5%;
    padding: 0 0.5rem;
    font-size: 20px;
    cursor: pointer;
    float: left;
}
.Debtor_select_lists_option{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
}
.Debtor_select_lists_option div{
    padding: 0 10px;
    font-size: 20px;
    cursor: pointer;
}
.Debtor_select_pull{
    margin: 0 auto;
    width: auto;
    position: relative;
    text-align: center;
}
.Debtor_select_pull span{
    color: #fff;
    font-size: 17px;
    top: -5px;
    position: absolute;
    z-index: 999;
    height: 40px;
    left: 49.5%;
}
/* bottom */
.Debtor_right_bottom{
    padding: 30px 0;
}
.Debtor_zhainum{
    font-size: 16px;
    font-family:'siyuanb';
    line-height: 50px;
}
.Debtor_zhainum span{
    
    font-size: 20px;
}
.Debtor_zhai_list{
    padding: 33px 41px;
    /* box-shadow: 0px 0px 10px #888; */
    width: 91.85%;
    margin: 0 auto;
    position: relative;
    background: #fff;
    margin-bottom: 10px;
}
.Debtor_zhai_list:last-child{
    margin-bottom: 38px;
}
.Debtor_zhai_list_name{
    font-size: 20px;
    line-height: 24px;
    
    margin-bottom: 15px;
    font-family: 'siyuanb';
    display: flex;
}
.Debtor_zhai_list_con{
    width: 60%;
    font-size: 18px;
}
.Debtor_zhai_list_con_list{
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 30px;
    color: #828294;
}
.Debtor_zhai_list_con_list div:nth-child(2){
    margin-left: 10px;
}
.Debtor_zhai_list_con_btn{
    width: 30%;
    height: 4rem;
    background: #FFFFFF;
    box-shadow: 0 0 2rem rgb(126 123 160 / 20%);
    border-radius: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
}
.Debtor_zhai_list_con_btn div{
    width: 268px;
    height: 40px;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    margin: 0 auto;
    cursor: pointer;
}
.generalbgcolor{
    background: #C5C5C5;
}
.Debtor_zhai_list_con_btn div:nth-child(1){
    /* margin-top: 55px;
    margin-bottom: 30px; */
}
.Debtor_zhai_list_con_type{
    color: #FF5C5C;
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 66px;
}

/*分页*/
.el-pagination{
    position: absolute;
    bottom: -30px;
    right: 20px;
}



.closebtn{
    padding: 5px 0;
    background: #43a0f5;
    width: 5%;
    text-align: center;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    left: 5%;
    top: 70px;
    font-size: 14px;
    position: absolute;
}
</style>