<template>
    <div class="Bottoms_box">
        <div class="bottom_inline">
            <div class="Bottom_mess">
                <span>湖北谋道清算事务管理有限公司版权所有 丨 免责声明 丨 <a href="https://beian.miit.gov.cn/">鄂ICP备20008082号-1 </a></span>
                <!-- <span style="margin-left: 15px;">联系电话:19872005565(周一至周五 9:00-17:00)</span> -->
            </div>
            <div class="Bottom_list"><span class="iconfont icon-Frame-26"></span>19872005565(周一至周五 9:00-17:00)</div>
            <div class="Bottom_list"><span class="iconfont icon-Frame-22"></span>武汉市东西湖区长青办事处革新大道167号LED产业园项目办公楼1-5层第二层204号</div>
            <div class="Bottom_list"><span class="iconfont icon-Frame-25"></span>673538065@qq.com</div>
            <div class="Bottom_list"><span class="iconfont icon-beian"></span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011202001834" style="color:#706E8C">鄂公网安备 42011202001834号</a> </div>
            
        </div>
        <div style="width: 45%;margin-top: 20px;text-align: center;">
            <div style="display: flex;
            justify-content: space-around;
            text-align: center;font-size: 12px;">
                <div style="width: 15%;">
                    <img src="../image/ma.jpg" alt="" style="width: 100%">
                    <div>破产管理小程序</div>
                </div>
                <div style="width: 15%;">
                    <img src="../image/pochan.jpg" alt="" style="width: 100%">
                    <div>破产管理公众号</div>
                </div>
                <div style="width: 15%;">
                    <img src="../image/gong.jpg" alt="" style="width: 100%">
                    <div>破产管家公众号</div>
                </div>
                <div style="width: 15%;">
                    <img src="../image/pogong.jpg" alt="" style="width: 100%">
                    <div>破产界公众号</div>
                </div>
                <div style="width: 15%;">
                    <img src="../image/wx.jpg" alt="" style="width: 100%;">
                    <div>机构联系微信号</div>
                </div>
               
                
               
            </div>
            <!-- <div style="margin-top: 1.2rem;font-size: 1.4rem;">技术支持：山西精易创诚网络科技有限公司</div> -->

        </div>
    </div>
</template>    
<script>
    export default {
        name: 'Bottom',
        data(){
            return{
               
            }
        },
        created() {
           
        },
        methods:{
           
        }
    }
</script>
 
<style scoped>
.Bottoms_box{
    /* min-width: 1500px; */
    width: 90%;
    height: 200px;
    background: #DDE6EF;
    margin-top: 37px;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
}
.bottom_inline{
    width: 70%;
    margin: 0 auto;
    font-size: 14px;
    padding-top: 18px;
}
.Bottom_mess{
    height:50px;
    line-height: 50px;
}
.Bottom_list{
    margin-bottom: 10px;
}
.Bottom_list span{
    margin-right: 24px;
    font-size: 18px;
}
a{
    color: #706E8C;
    margin-right: 5px;
}
</style>