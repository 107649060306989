<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header :current='current'></Header>
        <div class="Rulers_box">
            <div class="Ruler_box">
                <div class="Rulers_tit flexbe">
                    <span>（2021）豫14破申4号竞争选任管理人公告</span>
                    <div class="Rulers_btn"><div style="margin-right: 10px;" class="iconfont icon-Frame2"></div><div class="iconfont icon-Frame-110"></div></div>
                </div>
                <div class="Rulers_mess">
                    <span style="margin-right: 58px;">发布时间：2021年6月2日</span>
                    <span>发布人：河南商丘市中级人民法院</span>
                </div>
                <div class="Rulers_con">         正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文
                    正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文
                </div>
                    
            </div>
        </div>
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'
    export default {
        name: 'Rulers',
        components: {
            Header
        },
        data(){
            return{
                current:2,
                id:'',
            }
        },
        created(){
            this.id=this.$route.query.id
        },
        methods:{
        }
    }
</script>
<style scoped>
.Rulers_box{
    position: absolute;
    top: 185px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #EEF3FA;
}
.Ruler_box{
    width: 84.4%;;
    margin: 0 auto;
    position: relative;
    height: auto;
    background: #E7EEF5;
    border: 0.2rem solid rgba(255, 255, 255, 0.2);
    box-shadow: 0.9rem 0.9rem 1.6rem rgb(163 177 198 / 60%), -0.9rem -0.9rem 1.6rem rgb(255 255 255 / 60%);
    border-radius: 1rem;
    margin-top: 47px;
    padding: 22px 14px;
}
.Rulers_tit{

}
.Rulers_tit span:nth-child(1){
    font-size: 18px;
    font-family: 'siyuanb';
}
.Rulers_btn{
    display: flex;
}
.Rulers_btn div{
    width: 40px;
    height: 40px;
    background: #43A0F5;
    opacity: 0.3;
    border-radius: 5px;
    color: #fff;
    font-size: 30px;
    text-align: center;
    line-height: 4rem;
    cursor: pointer;
}
.Rulers_mess{
    font-size: 14px;
    margin:10px auto 26px;
}
.Rulers_con{
    font-size: 14px;
    line-height: 21px;
    text-indent: 2em;
}
</style>