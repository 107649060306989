<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header :current="current"></Header>
        <div class="Network_box">
            <div class="Network_tit flexar">债<br>权<br>申<br>报</div>
            <div class="Debtor_box">
                <!-- <img src="../../image/png" alt="" class="stepimg"> -->
                <el-steps :active="active" finish-status="success" align-center class="stepclass">
                    <el-step title="第一步"></el-step>
                    <el-step title="第二步"></el-step>
                    <el-step title="第三步"></el-step>
                </el-steps>
                <div class="Debtor_box_mess">
                    <div class="Debtor_box_basic_mess">
                        <div class="Debtor_box_basic_mess_tit">债务人信息</div>
                        <div class="Debtor_box_basic_mess_name">债务人：{{basic.company_name}}</div>
                        <div class="Debtor_box_basic_mess_list">破产案件受理法院：{{basic.court}}</div>
                        <div class="Debtor_box_basic_mess_list">债权申报截止日期：{{basic.claims_expire}}</div>
                        <div class="Debtor_box_basic_mess_list">破产管理人：{{basic.link_man}}</div>
                        <div class="Debtor_box_basic_mess_list">债权申报地点：{{basic.address}} </div>
                        <div class="Debtor_box_basic_mess_list">管理人联系电话：{{basic.phone}} </div>
                        <div class="Debtor_box_basic_mess_list">管理人邮箱：{{basic.email}}</div>
                        <div class="Debtor_box_basic_mess_list">管理人微信：{{basic.weixin}}</div>
                    </div>
                    <!-- 第一步 -->
                    <div class="Debtor_box_first" v-if="active==0&&type=='zhai'">
                        <div class="Debtor_box_basic_mess_tit">债权人信息</div>
                        <div class="Debtor_box_first_select" v-if="startChecking==false">
                            <span>是企业或者机构请勾选</span>
                            <el-checkbox v-model="attributes"></el-checkbox>
                        </div>
                        <div class="Debtor_box_first_mess">
                            <div class="Debtor_box_first_list" v-if="attributes==0">
                                <span>本人姓名：</span>
                                <input type="text" placeholder="请输入本人姓名" v-model="minename" :disabled="CreditorMessDisabled">
                            </div>
                            <div class="Debtor_box_first_list" v-if="attributes==0">
                                <span>本人手机号：</span>
                                <input type="text" placeholder="请输入本人手机号" v-model="minephone" :disabled="CreditorMessDisabled">
                            </div>
                            <div class="Debtor_box_first_list" v-if="attributes==1">
                                <span>公司名称：</span>
                                <input type="text" placeholder="请输入公司名称" v-model="minename" :disabled="CreditorMessDisabled">
                            </div>
                            <div class="Debtor_box_first_list" v-if="attributes==1">
                                <span>联系人手机号：</span>
                                <input type="text" placeholder="请输入联系人手机号" v-model="minephone" :disabled="CreditorMessDisabled">
                            </div>
                            <div class="Debtor_box_first_list" v-if="startChecking==false">
                                <span>身份识别码：</span>
                                <input type="text" placeholder="请输入身份识别码" v-model="IdentificationCode" :disabled="CreditorMessDisabled">
                            </div>
                            <div class="checkbtn" @click="StartValidation('zhai')" :style="{background:startChecking==true?'gray':''}">开始验证</div>
                        </div>
                        <div class="Debtor_box_first_other">
                            <div class="Debtor_box_first_list">
                                <span>是否有代理人：</span>
                                <el-radio-group v-model="haveagentForm.type" style="margin-left: 10px;" @change="aaa">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="2">否</el-radio>
                                </el-radio-group>
                            </div>
                            
                            <div class="Debtor_box_first_other_con" v-if="agenttype==true">
                                <button class="btns" :data-clipboard-text="invitation_code" @click="copy">邀请码:{{invitation_code}}</button>
                                <div style="font-size: 14px;">{{codecontent}}</div>
                            </div>
                            <div class="Debtor_box_first_btn" @click="next('first','')">准备申报债权</div>
                        </div>
                    </div>
                    <div class="Debtor_box_first" v-if="active==0&&type=='dai'">
                        <div class="Debtor_box_basic_mess_tit">代理人信息</div>
                        <div class="Debtor_box_first_list">
                            <span>*代理人姓名：</span>
                            <input type="text" placeholder="请输入代理人姓名" v-model="agent_name" :disabled="AgentMessDisabled">
                        </div>
                        <div class="Debtor_box_first_list">
                            <span>*代理人手机号：</span>
                            <input type="text" placeholder="请输入代理人手机号" v-model="agent_phone" :disabled="AgentMessDisabled">
                        </div>
                        <div class="Debtor_box_first_list">
                            <span>*代理地址：</span>
                            <el-cascader
                                clearable
                                v-model="agentareavalue"
                                :options="selectarea"
                                :props="{ expandTrigger: 'hover' }"
                                @change="agenthandleChange"
                                style="width:370px;
                                background: #FFFFFF;">
                            </el-cascader>
                        </div>
                        <div class="Debtor_box_first_list" >
                            <span>*街道门牌号：</span>
                            <input type="text" placeholder="请输入公司名称" v-model="agent_addr" :disabled="AgentMessDisabled">
                        </div>
                        <div class="Debtor_box_first_select" v-if="agentstartChecking==false">
                            <span>是企业或者机构请勾选</span>
                            <el-checkbox v-model="attributes"></el-checkbox>
                        </div>
                        <div class="Debtor_box_first_mess">
                            <div class="Debtor_box_first_list" v-if="attributes==0">
                                <span>债权人姓名：</span>
                                <input type="text" placeholder="请输入债权人姓名" v-model="claim_name" :disabled="AgentMessDisabled">
                            </div>
                            <div class="Debtor_box_first_list" v-if="attributes==0">
                                <span>债权人邀请码：</span>
                                <input type="text" placeholder="请输入债权人邀请码" v-model="claim_code" :disabled="AgentMessDisabled">
                            </div>
                            <div class="Debtor_box_first_list" v-if="attributes==1">
                                <span>债权人姓名：</span>
                                <input type="text" placeholder="请输入债权人单位名称" v-model="claim_name" :disabled="AgentMessDisabled">
                            </div>
                            <div class="Debtor_box_first_list" v-if="attributes==1">
                                <span>债权人邀请码：</span>
                                <input type="text" placeholder="请输入债权人发送给您的邀请码" v-model="claim_code" :disabled="AgentMessDisabled">
                            </div>
                            <div class="checkbtn" @click="StartValidation('dai')" :style="{background:agentstartChecking==true?'gray':''}">开始验证</div>
                        </div>
                        <div class="Debtor_box_first_btn" @click="next('first','')">准备申报债权</div>

                    </div>
                    <!-- 第二步 -->
                    <div class="Debtor_box_second" v-if="active==1&&type=='zhai'">
                        <div class="Debtor_box_basic_mess_tit">债权人信息</div>
                        <div class="Debtor_box_second_mess">
                            <div class="Debtor_box_second_mess_basic">
                                <img :src="avatar" alt="">
                                <span>微信昵称：{{name}}</span>
                            </div>
                            <div clsss="Debtor_box_second_basic_list" style="font-size: 16px;">债权人姓名：{{creditor.name}}</div>
                            <div clsss="Debtor_box_second_basic_list" style="font-size: 16px;">债权人联系电话：{{creditor.phone}}</div>
                        </div>
                        <div class="Debtor_box_second_book">
                            <div class="Debtor_box_second_book_notice">作为债权人，我已经收到了管理人通过本系统或其他途径送达的法律文书</div>
                            <div class="Debtor_box_second_book_box">
                                <div class="Debtor_box_second_booklist" v-for="(item,index) in adminlist" :key="index">
                                    <span>{{item.title}}</span>
                                    <div class="Debtor_box_second_bookbtn">
                                        <div @click="readbook(item.id,item.input[0].path,index,'admin')">阅读</div>
                                        <div @click="getsign(item,index,'admin',item.type==0)" :style="{color:item.sign_status==0?'#108ee9':'#999'}">{{item.sign_status==0?'签收':'已签收'}}</div>
                                    </div>
                                </div>
                                <div class="Debtor_box_second_booklist" v-for="(item,index) in normallist" :key="index">
                                    <span>{{item.title}}</span>
                                    <div class="Debtor_box_second_bookbtn">
                                        <div @click="readbook(item.id,item.input[0].path,index,'normal')">阅读</div>
                                        <div @click="getsign(item,index,'normal',item.type==0)" :style="{color:item.sign_status==0?'#108ee9':'#999'}">{{item.sign_status==0?'签收':'已签收'}}</div>
                                    </div>
                                </div>

                            </div>
                            <div class="Debtor_box_second_btn">
                                <div class="surebtn themebgcolor" @click="next('second','creditor')" v-if="basic.is_next==1">已确认信息，现在自己申报</div>
                                <div class="surebtn orangebgcolor" @click="next('second','agent')" v-if="basic.is_next==1">已确认信息，请代理人申报</div>
                                <div class="notice" v-if="basic.is_next!==1">线上验证身份已完成，请关注系统或短信通知，若有疑问，请致电管理人。</div>
                                <div class="surebtn themebgcolor" @click="sureclaim" v-if="basic.is_next!==1">确认</div>
                            </div>
                        </div> 
                    </div>
                    <div class="Debtor_box_second" v-if="active==1&&type=='dai'">
                        <div class="Debtor_box_basic_mess_tit">代理人信息</div>
                        <div class="Debtor_box_second_mess">
                            <div class="Debtor_box_second_mess_basic">
                                <img :src="avatar" alt="">
                                <span>微信昵称：{{name}}</span>
                            </div>
                            <div clsss="Debtor_box_second_basic_list" style="font-size: 16px;">债权人姓名：{{creditor.name}}</div>
                            <div clsss="Debtor_box_second_basic_list" style="font-size: 16px;">债权人联系电话：{{creditor.phone}}</div>
                        </div>
                        <div class="Debtor_box_second_book">作为代理人，我已经收到了管理人通过本系统或其他途径送达的法律文书</div>
                        <div class="Debtor_box_second_book_box">
                            <div class="Debtor_box_second_booklist" v-for="(item,index) in adminlist" :key="index">
                                <span>{{item.title}}</span>
                                <div class="Debtor_box_second_bookbtn">
                                    <div @click="readbook(item.id,item.input[0].path,index,'admin')">阅读</div>
                                    <div @click="getsign(item,index,'admin',item.type==0)" :style="{color:item.sign_status==0?'#108ee9':'#999'}">{{item.sign_status==0?'签收':'已签收'}}</div>
                                </div>
                            </div>
                            <div class="Debtor_box_second_booklist" v-for="(item,index) in normallist" :key="index">
                                <span>{{item.title}}</span>
                                <div class="Debtor_box_second_bookbtn">
                                    <div @click="readbook(item.id,item.all_input[0].path,index,'normal')">阅读</div>
                                    <div @click="getsign(item,index,'normal',item.type==0)" :style="{color:item.sign_status==0?'#108ee9':'#999'}">{{item.sign_status==0?'签收':'已签收'}}</div>
                                </div>
                            </div>

                        </div>
                        <div class="Debtor_box_second_btn">
                            <div class="surebtn themebgcolor" @click="next('second','agent')" v-if="basic.is_next==1">
                                已确认并代理申报
                            </div>
                            <div class="notice" v-if="basic.is_next!==1">线上验证身份已完成，请关注系统或短信通知，若有疑问，请致电管理人。</div>
                            <div class="surebtn themebgcolor" @click="sureclaim" v-if="basic.is_next!==1">确认</div>
                        </div>
                    </div>
                    <!-- 第三步 -->
                    <div class="Debtor_box_third" v-if="active==2">
                        <div class="Debtor_box_third_tit">债权申报</div>
                        <div class="Debtor_box_third_tab flexbe">
                            <div :class="currentstep==1?'Debtor_box_third_tab_active':'Debtor_box_third_tab_common'">1.申请人信息</div>
                            <div :class="currentstep==2?'Debtor_box_third_tab_active':'Debtor_box_third_tab_common'">2.填写申报材料</div>
                            <div :class="currentstep==3?'Debtor_box_third_tab_active':'Debtor_box_third_tab_common'">3.上传申报材料</div>
                        </div>
                        <div class="Debtor_box_third_con" v-if="currentstep==1">
                            <div class="Debtor_box_third_basic_tit">债权人申报基本信息</div>   
                            <!-- attributes:0->个人 -->
                            <div class="Debtor_box_third_basic_list">
                                <div><span>*</span>{{attributes==0?'姓名或名称':'公司名称'}}</div>
                                <input type="text" :placeholder="attributes==0?'请输入姓名或名称':'请输入公司名称'" v-model="zhainame" :disabled="firstdis">
                            </div>
                            <div class="Debtor_box_third_basic_list" v-if="attributes==1">
                                <div><span></span>法定代表人</div>
                                <input type="text" placeholder="请输入法定代表人姓名" v-model="com_user" :disabled="firstdis">
                            </div>
                            <div class="Debtor_box_third_basic_list" v-if="attributes==1">
                                <div><span></span>法定代表人身份证号</div>
                                <input type="text" placeholder="请输入法定代表人身份证号" v-model="com_userid" :disabled="firstdis">
                            </div>
                            <div class="Debtor_box_third_basic_list">
                                <div><span>*</span>{{attributes==0?'手机号码':'法定代表人电话'}} </div>
                                <input type="tel" :placeholder="attributes==0?'请输入手机号码':'请输入法定代表人电话'" v-model="zhaiphone">
                            </div>
                            <div class="Debtor_box_third_basic_list">
                                <div><span>*</span>{{attributes==0?'身份证号':'统一社会信用代码'}}</div>
                                <input type="" :placeholder="attributes==0?'请输入身份证号':'请输入统一社会信用代码'" v-model="zhaiid" :disabled="firstdis">
                            </div>
                            <div class="Debtor_box_third_basic_list">
                                <div><span>*</span>选择地址</div>
                                <!-- <input type="text" placeholder="请选择地址"> -->
                                <el-cascader
                                    clearable
                                    v-model="areavalue"
                                    :options="selectarea"
                                    :props="{ expandTrigger: 'hover' }"
                                    @change="handleChange">
                                </el-cascader>
                            </div>
                            <div class="Debtor_box_third_basic_list">
                                <div><span>*</span>街道门牌 <span v-if="attributes==0">管理人向您送达文书地址</span></div>
                                <input type="text" placeholder="请输入街道门牌" v-model="zhaiaddress" :disabled="firstdis">
                            </div>
                            <div class="Debtor_box_third_basic_list">
                                <div><span></span>电子邮箱</div>
                                <input type="text" placeholder="请输入电子邮箱" v-model="zhaimail" :disabled="firstdis">
                            </div>
                            <div class="Debtor_box_third_basic_list" v-if="attributes==0">
                                <div><span></span>微信号 <span>注意不是微信昵称</span></div>
                                <input type="text" placeholder="请输入微信号" v-model="zhaiwx" :disabled="firstdis">
                            </div>
                            
                            <div class="Debtor_box_third_basic_list" v-if="attributes==1">
                                <div><span></span>联系人姓名</div>
                                <input type="text" placeholder="请输入联系人姓名" v-model="com_catuser" :disabled="firstdis">
                            </div>
                            <div class="Debtor_box_third_basic_list" v-if="attributes==1">
                                <div><span></span>联系人电话号码</div>
                                <input type="text" placeholder="请输入联系人电话号码" v-model="com_catphone" :disabled="firstdis">
                            </div>

                            <div class="Debtor_box_third_basic_btn flexar">
                                <div class="themebgcolor" @click="keepstepfirst()">保存</div>
                                <div class="orangebgcolor" @click="keepstepfirst('firststep')">下一步</div>
                            </div>
                        </div>

                        <div class="Debtor_box_third_con" v-if="currentstep==2">
                            <div class="Debtor_box_third_basic_list" style="border-bottom: 1px dashed #44a0f5;padding-bottom: 10px;">
                                <div><span>*</span>申报债权类型</div>
                                <select name="public-choice" v-model="index3" @change="addclaimtype">                                        
                                    <option :value="index" v-for="(item,index) in claimtype" :key="index">{{item}}</option>                                    
                                </select>
                            </div>
                            
                          
                            <div v-if="index3==0">
                                <div class="Debtor_box_third_basic_tit">债权人申报材料</div>
                                <div class="Debtor_box_third_basic_shen">
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>申报债权本金</div>
                                        <input type="number" placeholder="请输入申报债权本金" v-model="principal" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>申报债权利息</div>
                                        <input type="number" placeholder="请输入申报债权利息" v-model="interest" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>申报债权其他损失</div>
                                        <input type="number" placeholder="请输入申报债权其他损失" v-model="loss" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>申报债权总额</div>
                                        <input type="number" placeholder="请输入申报债权总额" v-model="allmoney" disabled>
                                    </div>    
                                </div>
                                <div class="Debtor_box_third_radio">
                                    <div class="Debtor_box_third_radio_list_min" style="display: block;"> 
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有财产担保</div>
                                            <el-radio-group v-model="guaranteeForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="guaranteeForm.type==1">
                                            <input type="text" placeholder="请输入担保人名称" v-model="guaranteename" :disabled="seconddis">
                                            <input type="text" placeholder="请输入担保金额" v-model="guaranteemoney" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>财产担保是否办理登记</div>
                                            <el-radio-group v-model="registerForm.type">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <textarea name="" id="" cols="30" rows="10" placeholder="请输入担保物情况" v-model="register" v-if="registerForm.type==1" :disabled="seconddis"></textarea>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有连带债权人</div>
                                            <el-radio-group v-model="creditorForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="creditorForm.type==1">
                                            <input type="text" placeholder="请输入连带债权人名称" v-model="creditor" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有连带债务人</div>
                                            <el-radio-group v-model="obligorForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="obligorForm.type==1">
                                            <input type="text" placeholder="请输入连带债务人名称" v-model="obligor" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有生效判决书</div>
                                            <el-radio-group v-model="judgmentForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="judgmentForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="judgmentbook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入发文机关名称" v-model="judgmentoffice" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有生效调解书</div>
                                            <el-radio-group v-model="mediateForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="mediateForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="mediatebook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入发文机关名称" v-model="mediateoffice" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有生效仲裁裁决书</div>
                                            <el-radio-group v-model="arbitrationForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="arbitrationForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="arbitrationbook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入发文机关名称" v-model="arbitrationoffice" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有公证书</div>
                                            <el-radio-group v-model="fairForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="fairForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="fairbook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入发文机关名称" v-model="fairoffice" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min" style="display: block;">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否进入执行程序</div>
                                            <el-radio-group v-model="implementForm.type">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="implementForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="implementbook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入执行法院名称" v-model="implementoffice" :disabled="seconddis"> 
                                            <input type="text" placeholder="请输入执行法院执行员名称" v-model="implementperson" :disabled="seconddis">
                                            <input type="text" placeholder="请输入执行法院执行人电话" v-model="implementphone" :disabled="seconddis">
                                            <input type="text" placeholder="请输入已经收取执行款数额" v-model="implementmoney" :disabled="seconddis">
                                        </div>
                                    </div>
                                    
                                </div>

                                <div class="Debtor_box_third_basic_list">
                                    <div><span>*</span>债权人收取破产财产分配账户名</div>
                                    <input type="text" placeholder="请填写债权人收取破产财产分配账户名" v-model="data_bankruptcyname" :disabled="seconddis">
                                </div>
                                <div class="Debtor_box_third_basic_list">
                                    <div><span>*</span>债权人收取破产财产分配款银行账户</div>
                                    <input type="text" placeholder="请填写债权人收取破产财产分配款银行账户" v-model="data_bankruptcyaccount" :disabled="seconddis">
                                </div>
                                <div class="Debtor_box_third_basic_list">
                                    <div><span>*</span>债权人收取破产财产分配款开户银行</div>
                                    <input type="text" placeholder="请填写具体开户行，如：建设银行xx支行" v-model="data_bankruptcyback" :disabled="seconddis">
                                </div>
                                <div class="Debtor_box_third_basic_list">
                                    <div><span>*</span>债权性质 <span @click="dialogObligatoryVisible=true" style="cursor: pointer;">债权性质介绍</span></div>
                                    <select name="public-choice" v-model="natureSelected" @change="getnature">                                        
                                        <option :value="index" v-for="(item,index) in naturelist" :key="index">{{item}}</option>                                    
                                    </select>
                                </div>
                                <div class="Debtor_box_third_basic_list">
                                    <div><span>*</span>基础法律关系 <span>请根据您的实际情况选择</span></div>
                                    <select name="public-choice" v-model="lawSelected" @change="getlaw">                                        
                                        <option :value="index" v-for="(item,index) in lawlist" :key="index">{{item}}</option>                                    
                                    </select>
                                </div>
                                <div class="Debtor_box_third_radio_list">
                                    <div><span>*</span>债权是否为求偿权 <span @click="recourse" style="cursor: pointer;">求偿权介绍</span></div>
                                    <el-radio-group v-model="ClaimForm.type">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="0">否</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="Debtor_box_third_radio_list">
                                    <div><span>*</span>债权是否为代位权 <span @click="daiweibtn" style="cursor: pointer;">代位权介绍</span></div>
                                    <el-radio-group v-model="subrogationForm.type">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="0">否</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="Debtor_box_third_radio_list">
                                    <div><span>*</span>是否主张物权期待权 <span>房屋买卖</span></div>
                                    <el-radio-group v-model="expectForm.type">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="0">否</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="Debtor_box_third_basic_list" style="margin-top: 20px;">
                                    <div><span>*</span>债权申报的事实与理由</div>
                                    <textarea name="" id="" cols="30" rows="10" placeholder="请简述债权申报的事实与理由" v-model="fact" :disabled="seconddis"></textarea>
                                </div>
                            </div>

                        
                            <div v-if="index3==1">
                                <div class="Debtor_box_third_basic_tit">债权人申报材料</div>
                                <div class="Debtor_box_third_basic_shen">
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>小区名称</div>
                                        <input type="text" placeholder="请输入小区名称" v-model="estate_name" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>栋数-单元-房号</div>
                                        <input type="text" placeholder="请输入栋数-单元-房号" v-model="room_info" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>房屋性质</div>
                                        <select name="public-choice" v-model="room_type" @change="roomtypes">                                        
                                            <option :value="index" v-for="(item,index) in roomtype" :key="index">{{item}}</option>                                    
                                        </select>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>有无房屋买卖合同</div>
                                            <el-radio-group v-model="housing_contract_has">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="housing_contract_has==1">
                                            <input type="text" placeholder="请输入合同编号" v-model="housing_contract_number" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否办理备案登记</div>
                                            <el-radio-group v-model="housing_contract_registration">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>有无其他补充协议</div>
                                            <el-radio-group v-model="other_supplemental_has">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="other_supplemental_has==1">
                                            <input type="text" placeholder="协议名称" v-model="other_supplemental_name" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否办理按揭</div>
                                            <el-radio-group v-model="mortgage">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否办理预告登记</div>
                                            <el-radio-group v-model="advance_notice_registration">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否入住</div>
                                            <el-radio-group v-model="is_stay_in">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否持有购房款发票</div>
                                            <el-radio-group v-model="invoice_has">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否存在借贷转买卖</div>
                                            <el-radio-group v-model="loan_swap">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否存在以材料款或工程款抵债</div>
                                            <el-radio-group v-model="project_for_payment">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>建筑面积</div>
                                        <input type="number" placeholder="请输入建筑面积" v-model="room_area" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>实际付款情况</div>
                                        <select name="public-choice" v-model="payment_condition">                                        
                                            <option :value="index" v-for="(item,index) in paymentlists" :key="index">{{item}}</option>                                    
                                        </select>
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>已付金额</div>
                                        <input type="number" placeholder="请输入已付金额" v-model="payment_condition_money" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>合同应付购房款数额</div>
                                        <input type="number" placeholder="请输入合同应付购房款数额" v-model="contract_due" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>欠开发商购房款数额</div>
                                        <input type="number" placeholder="请输入欠开发商购房款数额" v-model="owe_developer" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有生效判决书</div>
                                            <el-radio-group v-model="judgmentForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="judgmentForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="judgmentbook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入发文机关名称" v-model="judgmentoffice" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有生效调解书</div>
                                            <el-radio-group v-model="mediateForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="mediateForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="mediatebook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入发文机关名称" v-model="mediateoffice" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有生效仲裁裁决书</div>
                                            <el-radio-group v-model="arbitrationForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="arbitrationForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="arbitrationbook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入发文机关名称" v-model="arbitrationoffice" :disabled="seconddis">
                                        </div>
                                    </div>
                                    
                                    <div class="Debtor_box_third_radio_list_min" style="display: block;">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否进入执行程序</div>
                                            <el-radio-group v-model="implementForm.type">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="implementForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="implementbook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入执行法院名称" v-model="implementoffice" :disabled="seconddis"> 
                                            <input type="text" placeholder="请输入执行法院执行员名称" v-model="implementperson" :disabled="seconddis">
                                            <input type="text" placeholder="请输入执行法院执行人电话" v-model="implementphone" :disabled="seconddis">
                                        </div>
                                    </div>
                                    
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>基础法律关系 <span>请根据您的实际情况选择</span></div>
                                        <select name="public-choice" v-model="lawSelected" @change="getlaw">                                        
                                            <option :value="index" v-for="(item,index) in lawlist" :key="index">{{item}}</option>                                    
                                        </select>
                                    </div>
                                    <div class="Debtor_box_third_basic_list" style="margin-top: 20px;">
                                        <div><span>*</span>债权申报的事实与理由</div>
                                        <textarea name="" id="" cols="30" rows="10" placeholder="请简述债权申报的事实与理由" v-model="fact" :disabled="seconddis"></textarea>
                                    </div>
                                </div>
                            </div>

                          
                            <div v-if="index3==2">
                                <div class="Debtor_box_third_basic_tit">债权人申报材料</div>
                                <div class="Debtor_box_third_basic_shen">
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>申报债权本金</div>
                                        <input type="number" placeholder="请输入申报债权本金" v-model="principal"  :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>申报债权利息</div>
                                        <input type="number" placeholder="请输入申报债权利息" v-model="interest" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>申报债权延迟履约金</div>
                                        <input type="number" placeholder="请输入申报债权延迟履约金" v-model="declare_claims_delay" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>申报债权违约金</div>
                                        <input type="number" placeholder="请输入申报债权违约金" v-model="declare_claims_penalty" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>申报债权其他损失</div>
                                        <input type="number" placeholder="请输入申报债权其他损失" v-model="loss" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>申报债权总额</div>
                                        <input type="number" placeholder="请输入申报债权总额" v-model="allmoney" disabled>
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>小区名称</div>
                                        <input type="text" placeholder="请输入小区名称" v-model="estate_name" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>栋数-单元-房号</div>
                                        <input type="text" placeholder="请输入栋数-单元-房号" v-model="room_info" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>房屋性质</div>
                                        <select name="public-choice" v-model="room_type" @change="roomtypes">                                        
                                            <option :value="index" v-for="(item,index) in roomtype" :key="index">{{item}}</option>                                    
                                        </select>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>有无房屋买卖合同</div>
                                            <el-radio-group v-model="housing_contract_has">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="housing_contract_has==1">
                                            <input type="text" placeholder="请输入合同编号" v-model="housing_contract_number" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否办理备案登记</div>
                                            <el-radio-group v-model="housing_contract_registration">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>有无其他补充协议</div>
                                            <el-radio-group v-model="other_supplemental_has">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="other_supplemental_has==1">
                                            <input type="text" placeholder="协议名称" v-model="other_supplemental_name" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否办理按揭</div>
                                            <el-radio-group v-model="mortgage">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否办理预告登记</div>
                                            <el-radio-group v-model="advance_notice_registration">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否入住</div>
                                            <el-radio-group v-model="is_stay_in">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否持有购房款发票</div>
                                            <el-radio-group v-model="invoice_has">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否存在借贷转买卖</div>
                                            <el-radio-group v-model="loan_swap">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否存在以材料款或工程款抵债</div>
                                            <el-radio-group v-model="project_for_payment">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>建筑面积</div>
                                        <input type="number" placeholder="请输入建筑面积" v-model="room_area" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>实际付款情况</div>
                                        <select name="public-choice" v-model="payment_condition">                                        
                                            <option :value="index" v-for="(item,index) in paymentlists" :key="index">{{item}}</option>                                    
                                        </select>
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>已付金额</div>
                                        <input type="number" placeholder="请输入已付金额" v-model="payment_condition_money" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>合同应付购房款数额</div>
                                        <input type="number" placeholder="请输入合同应付购房款数额" v-model="contract_due" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span></span>欠开发商购房款数额</div>
                                        <input type="number" placeholder="请输入欠开发商购房款数额" v-model="owe_developer" :disabled="seconddis">
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有生效判决书</div>
                                            <el-radio-group v-model="judgmentForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="judgmentForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="judgmentbook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入发文机关名称" v-model="judgmentoffice" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有生效调解书</div>
                                            <el-radio-group v-model="mediateForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="mediateForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="mediatebook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入发文机关名称" v-model="mediateoffice" :disabled="seconddis">
                                        </div>
                                    </div>
                                    <div class="Debtor_box_third_radio_list_min">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否有生效仲裁裁决书</div>
                                            <el-radio-group v-model="arbitrationForm.type">
                                                <el-radio :label="1">有</el-radio>
                                                <el-radio :label="0">无</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="arbitrationForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="arbitrationbook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入发文机关名称" v-model="arbitrationoffice" :disabled="seconddis">
                                        </div>
                                    </div>
                                    
                                    <div class="Debtor_box_third_radio_list_min" style="display: block;">
                                        <div style="display: flex;">
                                            <div class="Debtor_box_third_radio_list_tit"><span>*</span>是否进入执行程序</div>
                                            <el-radio-group v-model="implementForm.type">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div class="Debtor_box_third_radio_min" v-if="implementForm.type==1">
                                            <input type="text" placeholder="请输入文书文号名称" v-model="implementbook" :disabled="seconddis">
                                            <input type="text" placeholder="请输入执行法院名称" v-model="implementoffice" :disabled="seconddis"> 
                                            <input type="text" placeholder="请输入执行法院执行员名称" v-model="implementperson" :disabled="seconddis">
                                            <input type="text" placeholder="请输入执行法院执行人电话" v-model="implementphone" :disabled="seconddis">
                                        </div>
                                    </div>
                                    
                                    <div class="Debtor_box_third_basic_list">
                                        <div><span>*</span>基础法律关系 <span>请根据您的实际情况选择</span></div>
                                        <select name="public-choice" v-model="lawSelected" @change="getlaw">                                        
                                            <option :value="index" v-for="(item,index) in lawlist" :key="index">{{item}}</option>                                    
                                        </select>
                                    </div>
                                    <div class="Debtor_box_third_basic_list" style="margin-top: 20px;">
                                        <div><span>*</span>债权申报的事实与理由</div>
                                        <textarea name="" id="" cols="30" rows="10" placeholder="请简述债权申报的事实与理由" v-model="fact" :disabled="seconddis"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="Debtor_box_third_basic_btn flexar">
                                <div class="orangebgcolor" @click="backstepfirst">上一步</div>
                                <div class="themebgcolor" @click="keepstepsecond()">保存</div>
                                <div class="orangebgcolor" @click="keepstepsecond('secondstep')">下一步</div>
                            </div>
                        </div>

                        <div class="Debtor_box_third_con" v-if="currentstep==3">
                            <div class="Debtor_box_third_upfile_tit">附件要求</div>
                            <div class="Debtor_box_third_upfile_con">
                                <div class="Debtor_box_third_upfile_con_tit">上传债权申报材料须知</div>
                                <div class="Debtor_box_third_upfile_cons">1.债权人需要上传提交的基础材料：自然人提交身份证复印件；企业法人提交营业执照、法定代表人身份证明书及其身份证复印件；有代理人的，提交授权委托书、代理人身份证、律师执业证复印件、律师事务所公函；管理人认为需要提交的其他基础材料。</div>
                                <div class="Debtor_box_third_upfile_cons">2.债权人需要上传提交的证据材料：合同、协议、借条、欠条、银行转账凭证、判决书、裁定书、调解书、对账单等能够证明自己对债务人享有债权的权利凭证；管理人认为需要补充提交的其他证据材料。(若证据材料少于10页的可连同基础材料一并提交)</div>
                                <div class="Debtor_box_third_upfile_radio">
                                    <el-radio v-model="readneed.type" :label="1" style="text-overflow: ellipsis; 
                                    white-space: normal;
                                    line-height: 22px; 
                                    vertical-align: middle;
                                    display: block;
                                    width: 90%;">我已阅读《上传债权申报材料须知》的要求。我承诺按此要求，选择通过下列方式上传并提交纸质的债权申报材料。如果因为我没有及时完整地提交基础材料和证据材料，导致管理人无法对我申报的债权进行确认时，我自愿承担相应的法律后果。</el-radio>
                                </div>
                                
                            </div>
                            <div class="Debtor_box_third_upfile_tit">选择提交方式</div>
                            <div class="Debtor_box_third_upfile_method">
                                <el-radio-group v-model="upfilemethod.type" style="margin-left: 10px;" @change="radioChangemethod">
                                    <el-radio :label="1" class="Debtor_box_third_upfile_radio radioline">
                                        <div style="text-overflow: ellipsis; 
                                        white-space: normal;
                                        line-height: 22px; 
                                        vertical-align: middle;
                                        display: block;
                                        width: 90%;">我决定使用电脑通过本网站上传债权申报材料，并及时向管理人提交纸质材料。</div>
                                                   
                                    </el-radio>
                                    <el-radio :label="2" class="Debtor_box_third_upfile_radio radioline">
                                        <div style="text-overflow: ellipsis; 
                                        white-space: normal;
                                        line-height: 22px; 
                                        vertical-align: middle;
                                        display: block;
                                        width: 90%;">我决定使用手机通过扫码上传债权申报材料，并及时向管理人提交纸质材料。</div>
                                    </el-radio>
                                    <!-- <el-radio :label="3" class="Debtor_box_third_upfile_radio radioline">
                                        <div style="text-overflow: ellipsis; 
                                        white-space: normal;
                                        line-height: 22px; 
                                        vertical-align: middle;
                                        display: block;
                                        width: 90%;">我决定使用电子邮件发送债权申报材料，并及时向管理人提交纸质材料。</div>
                                    </el-radio> -->
                                    
                                </el-radio-group>
                                <!-- <div class="Debtor_box_third_upfile_radio">
                                    <el-radio v-model="upfilemethodcheck" :label="10" style="text-overflow: ellipsis; 
                                    white-space: normal;
                                    line-height: 22px; 
                                    vertical-align: middle;
                                    display: block;
                                    width: 90%;">我已阅读《上传债权申报材料须知》的要求。我承诺按此要求，选择通过下列方式上传并提交纸质的债权申报材料。如果因为我没有及时完整地提交基础材料和证据材料，导致管理人无法对我申报的债权进行确认时，我自愿承担相应的法律后果。</el-radio>
                                </div> -->
                                <!-- <div class="Debtor_box_third_upfile_radio radioline">
                                    <el-radio v-model="radio"></el-radio>
                                    <div>
                                        <span>我决定通过本系统上传证据材料</span>
                                        <div style="color: #FF5C5C;">如果您没有上传证据材料，管理人对您申报的债权将不予以确认！</div>
                                    </div>                                      
                                </div>
                                <div class="Debtor_box_third_upfile_radio radioline">
                                    <el-radio ></el-radio>
                                    <div>我决定用邮件上传证据材料，请管理人查收。如果我没有通过电子邮件或现场向管理人提交证件材料，导致管理人不能对我申报的债权进行确认时我自愿承担相应的不利后果。</div>
                                </div> -->
                                <div class="addfile" v-if="upfilemethod.type==1||upfilemethod.type==2" @click="adddata">添加材料</div>

                                <div class="addfile_mode" v-if="adddatashow==true" style="margin-top: 10px;">
                                    <input type="text" v-model="evidence_name" placeholder="*债权构成所依据的证据名称" class="long" :disabled="disabled1">
                                    <input type="text" v-model="evidence_aim" placeholder="*该证据的证明或主张"  class="long" :disabled="disabled1">
                                    <div class="flexbe">
                                        <input type="text" v-model="evidence_num" placeholder="*证据份数"  class="short" :disabled="disabled1">
                                        <input type="text" v-model="evidence_page" placeholder="*证据页数"  class="short" :disabled="disabled1">
                                    </div>
                                    <div class="Debtor_box_third_radio_list">
                                        <div style="text-align: left;font-family: 'siyuanr'; margin-right: 0;font-size: 14px;">证据有无原件</div>
                                        <el-radio-group v-model="evidence.type">
                                            <el-radio :label="1">有</el-radio>
                                            <el-radio :label="0">无</el-radio>
                                        </el-radio-group>
                                    </div>
                                    <div style="color: #43A0F5;font-size: 14px;" v-if="fileList.length==0">{{evidence.type==0?'请上传证据复印件':'请上传证据原件'}}</div>
                                    <img src="../../image/upfile.png" alt="" style="margin-top: 18px;margin-bottom: 6px;" @click="upfilebtn" >
                                    <!-- v-if="evidence.type==1" -->
                                    <div v-for="(item,index) in evidenceList" :key="index" style="font-size: 14px;" >
                                        <div v-if="item.extension=='doc'||item.extension=='docx'||item.extension=='xlsx'||item.extension=='pdf'" class="evidencedocument flexbe" >
                                            <div style="width: 60%;word-wrap: break-word;">{{item.path}}</div>
                                            <div @click="evidencedetail(item.path,'document')">{{(item.extension=='doc'||item.extension=='docx'||item.extension=='xlsx'||item.extension=='pdf')?'下载':'阅读'}}</div>
                                            <div @click="evidencecancel(item.path,'document')">删除</div>
                                        </div>
                                        <div v-if="item.extension=='png'||item.extension=='jpg'||item.extension=='jpeg'" class="evidenceimg flexbe">
                                            <img :src="baseURL+item.path" alt="" mode="aspectFill">
                                            <div @click="evidencedetail(item.path,'img')">预览</div>
                                            <div @click="evidencecancel(item.path,'img')">删除</div>
                                        </div>
                                       
                                    </div>
                                    <!-- <div style="font-size: 12px;">支持扩展名：.rar .zip .doc .docx .pdf .jpg...</div> -->
                                    <div class="Debtor_box_third_upfile_methodbtn flexar" style="margin-top: 17px;">
                                        <div class="orangebgcolor" @click="delData">删除资料</div>
                                        <div class="themebgcolor" @click="keepData">保存资料</div>
                                    </div>
                                </div>
                                
                                <div style="margin-top: 10px;font-size: 14px;color: #999;border-bottom: 1px solid #eee;padding-bottom: 10px;border: 1px dashed #999;border-radius: 5px; padding: 10px;" v-for="(fujian,index1) in fulist" :key="index1"> <!-- v-if="adddatashow1==true" -->
                                    <div style="line-height: 30px;white-space: norwap;word-break: break-all;">证据名称：{{fujian.name}}</div>
                                    <div style="line-height: 30px;white-space: norwap;word-break: break-all;">证据主张：{{fujian.purpose}}</div>
                                    <div style="line-height: 30px;white-space: norwap;word-break: break-all;">证据份数：{{fujian.number}}</div>
                                    <div style="line-height: 30px;white-space: norwap;word-break: break-all;">证据页数：{{fujian.page}}</div>
                                    
                                    <div style="line-height: 30px;">证据有无原件:{{fujian.has_original==0?'无':'有'}}</div>
                                    <div style="line-height: 30px;" v-if="fujian.has_original==1">
                                        已上传的文件：
                                    </div>
                                    <div style="display: flex;justify-content: space-around;flex-wrap: wrap;">
                                        <div class="fileimage" v-for="(item,index) in fujian.file_input" :key="index" >
                                            <!-- <image :src="item.path" mode=""></image> -->
                                            {{baseURL+item.path}}
                                        </div>
                                    </div>
                                </div>
                                

                            </div>
                            <div class="Debtor_box_third_upfile_tit">声明</div>
                            <div class="Debtor_box_third_upfile_method" style="font-size: 16px;">
                                <!-- <div>作为债权人本人谨此声明，对本《债权申报表》所记载的相关内容，系由本人认真阅读填写要求后，如实所做的相关记录。所有申报债权的证据、文件与原件相一致，不存在变造、伪造等情形。本人保证提供的街道门牌及联系方式准确、有效，管理人向本人提供的地址邮寄或向本人提供的邮箱所发邮件的相关文件，以邮局邮戳或计算机电子数据到达本人邮箱的日期为准。由本《债权申报表》记载的相关内容所产生的相应法律责任，本人愿意承担相应后果。</div> -->
                                <div style="margin-bottom: 15px;">作为债权人本人谨此声明，对本《债权申报表》所记载的相关内容，系由本人认真阅读填写要求后，如实所做的相关记录。所有申报债权的证据、文件与原件相一致，不存在变造、伪造等情形。本人保证提供的街道门牌及联系方式准确、有效，管理人向本人提供的地址邮寄或向本人提供的邮箱所发邮件的相关文件，以邮局邮戳或计算机电子数据到达本人邮箱的日期为准。由本《债权申报表》记载的相关内容所产生的相应法律责任，本人愿意承担相应后果。</div>
                                
                                <div class="Debtor_box_third_upfile_radio">
                                    <el-radio v-model="affirms.type" :label="1">已经阅读并知悉前列声明的内容，并自愿对此作出声明</el-radio>
                                </div>
                                    
                            </div>
                            <div class="Debtor_box_third_upfile_methodbtn flexbe" style="margin-top: 17px;">
                                <!-- <div class="orangebgcolor" @click="Gomailpost" :class="sureshow==false?'themebgcolor':'graybgcolor'">通过邮件提交</div> -->
                                <div class="themebgcolor" @click="Surepost" :class="sureshow==true?'themebgcolor':'graybgcolor'">确认提交</div>
                                <div class="orangebgcolor" @click="backstepsecond">上一步</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹出框 -->
        <el-dialog title="债权性质介绍" :visible.sync="dialogObligatoryVisible">
            <div v-for="(item,index7) in obligatorys" :key="index7" class="Obligatorycontent">
                <div style="font-size: 13px;line-height: 30px;">{{item.title}}</div>
                <div style="line-height: 20px;color: #999999;">{{item.cons}}</div>
            </div>
        </el-dialog>
        <el-dialog title="求偿权介绍" :visible.sync="dialogRecourseVisible" :append-to-body="true">
            <div style="line-height: 20px;color: #999;">{{recourses}}</div>
        </el-dialog>
        <el-dialog title="代位权介绍" :visible.sync="dialogDaiweiVisible" :append-to-body="true">
            <div style="line-height: 20px;color: #999;">{{daiwei}}</div>
        </el-dialog>

        <el-dialog title="上传文件" :visible.sync="dialogTableVisible" >
            <!-- <div class="upfile_tab">
                <div @click="changeupfile(1)" :style="{color:upfilestatus==1?'red':''}">本地上传</div>
                <div @click="changeupfile(2)" :style="{color:upfilestatus==2?'red':''}">扫码上传</div>
            </div> -->
            <div class="upfile_con" v-if="upfilemethod.type==1">
                <el-upload
                    class="upload-demo"
                    action=""
                    :headers="myHeaders"
                    :http-request="uploadSectionFile"
                    :auto-upload="true"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :on-error="uploadFileError"
                    :on-success="uploadFileSuccess"
                    :before-upload="beforeUpload"

                    multiple
                    :limit="3"
                    :on-exceed="handleExceed"   
                    :file-list="fileList">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <span style="color: #999;font-size: 12px;margin-left: 10px;">1.支持扩展名：.jpg .png .jpeg .doc .docx .xlsx .pdf .zip</span>
                    
                    <!-- :on-exceed绑定的方法则是处理超出数量后的动作 -->
                    <!-- :before-remove="beforeRemove" -->

                </el-upload>
                <div style="color: red;text-align: center;width: 100%;line-height: 50px;">{{evidencecontent}}</div>
                <div class="upfilebtnclass" @click="closeupfilelocal" v-if="fileList.length!==0">确认</div>

            </div>
            <div class="upfile_con" v-if="upfilemethod.type==2">
                <div class="upfile_con_up">
                    <img :src="baseURL+imgFile" alt="">
                    <div class="upfile_con_notice" style="font-size: 15px;">
                        <div>1.可使用浏览器扫码功能进行上传</div> 
                        <div>2.可使用微信扫一扫功能进行上传</div> 
                        <div>3.支持扩展名：.jpg .png .jpeg .doc .docx .xlsx .pdf .zip</div>
                        <div>4.可上传文件的最大限制为4MB</div> 
                        <div>5.每上传一组材料，请重新进行扫码</div>
                    </div>
                </div>
                <div style="line-height: 50px;text-align: center;font-size: 20px;color: red;">手机扫码上传成功后，点击确定按钮完成上传。</div>
                <div class="upfilebtnclass" @click="closeupfile">确认</div>
            </div>
        </el-dialog>
       

        
        <!-- 提示框 -->
        <Toast :toastText="content" v-show="toastShow"></Toast>

        <!-- 图片放大 -->
        
        <div style='position:fixed;top:0;width:100%;height:100%;background-color:rgb(0,0,0,0.65)' v-if="imgbigtype==true" @click="closeimgbig">
            <div class='modal' >
                <img :src="imgbig"/>
            </div>
        </div>
       
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'
    import Toast from '../../components/Toast.vue'
    // import cityjson from '../../../public/city.json'
    import { getcitydata } from "../../../public/city-picker.data";
    // import pdf from 'vue-pdf'
    import {CaseSingle,CreditorStatus,CreditorCheck,CaseUserInfo,creditorSignStatus,AgentCheck,ClaimsGetUserInfo,ClaimsSetUserInfo,ClaimsGetInfo,ClaimsGetInfofdc,ClaimsGetInfotf,ClaimsSetInfo,ClaimsSetInfofdc,ClaimsSetInfotf,CreditorDoSign,ClaimsGetEvidenceList,ClaimsSetEvidence,ClaimsSubmit,PcScanUpload,
        GetArea,PcFilesGet,PcFilesUpload} from '@/request/api';

    export default {
        name: 'Debtordetail',
        components: {
            Header,
            Toast,
            // pdf
        },
        data(){
            return{
                baseURL:'https://pc.pochanjie.com',
                current:1,
                radio: '1',
                currentstep:1,
                active: 0,
                //    基本信息
                basic:{},
                case_id:'',
                //第一步 债权人基本信息
                type:'',
                minename:'',
                minephone:'',
                attributes:0,
                IdentificationCode:'',
                haveagentForm:{
                    id: 0,
                    name: '',
                    type: 2,
                },
                CreditorMessDisabled:false,
                creditor_id:'',
                startChecking:false,    //验证完成的状态
                invitation_code:'',
                codecontent:'因您委托有代理人，请长按邀请码进行复制后，发送给代理人，并通知代理人通过“破产管理”公众号或小程序进入本系统，代为您申报债权。',
                agenttype:false,    //是否有代理人状态

                //第一步 代理人信息
                AgentMessDisabled:false,
                agent_name:'',
                agent_phone:'',
                agent_addr:'',
                agent_pro:'',
				agent_city:'',
				agent_area:'',
                agentareavalue:'',
                agentstartChecking:false,
                claim_name:'',
                claim_code:'',
                agent_id:'',

                //第二步 债权人信息，签收文书等
                creditor:{},
                avatar:localStorage.getItem('avatar'),

                name:localStorage.getItem('nickname'),
                adminlist:[],
                normallist:[],
                // pdfurl:"https://pc.pochanjie.com/uploads/attach/2020/08/20200821/6b67ea84c3cb523d36edc986e6c87d47.pdf",
                // numPages: null, // pdf 总页数


                //债权申报 第一步 申请人信息
                claims_id:0,
                // claims_id:3650,
                // 选择地址
                areavalue: [],
                selectarea: [],
                zhainame:'',
                zhaiphone:'',
                zhaiid:'',
                // zhaiarea:'',
                zhaiaddress:'',
                zhaimail:'',
                zhaiwx:'',
                pro:'',
                city:'',
                area:'',
                //公司其他信息
                com_user:'',
                com_userid:'',
                com_catuser:'',
                com_catphone:'',
                firstdis:false,

                // 债权申报 第二步 填写申报材料
                seconddis:false,
                composite_status:'',
                principal:'',
                interest:'',
                loss:'',
                data_allmoney:0,

                guaranteeForm: {   //是否有财产担保
                    id: 0,
                    name: '',
                    type:0,
                },
                guaranteename:'',
                guaranteemoney:'',
                registerForm: {   //是否担保是否办理备案登记
                    id: 0,
                    name: '',
                    type:0,
                },
                register:'',
                creditorForm:{   //是否连带债权人
                    id:0,
                    name: '',
                    type:0,
                },
                creditor:'',
                obligorForm:{   //是否连带债务人
                    id:0,
                    name: '',
                    type:0,
                },
                obligor:'',
                judgmentForm:{  //生效判决书
                    id:0,
                    name: '',
                    type:0,
                },
                judgmentbook:'',
                judgmentoffice:'',
                mediateForm:{   //生效调解书
                    id:0,
                    name: '',
                    type:0,
                },
                mediatebook:'',
                mediateoffice:'',
                arbitrationForm:{   //仲裁调解书
                    id:0,
                    name: '',
                    type:0,
                },
                arbitrationbook:'',
                arbitrationoffice:'',
                fairForm:{   //公证书
                    id:0,
                    name: '',
                    type:0,
                },
                fairbook:'',
                fairoffice:'',
                implementForm:{   //执行程序
                    id:0,
                    name: '',
                    type:0,
                },
                implementbook:'',
                implementoffice:'',
                implementperson:'',
                implementphone:'',
                implementmoney:'',
                data_bankruptcyname:'', //账户
                data_bankruptcyaccount:'',
                data_bankruptcyback:'',
                natureSelected: 0,    //债权
                dialogObligatoryVisible:false,  //债权性质弹框
                naturelist:["普通债权","劳动债权","有财产担保债权","税款债权","优先受偿权","抵销权","求偿权","共益债务","其他"],
                // 债权性质介绍
				obligatorys:[{
					title:'1.普通债权',
					cons:'普通债权是指不能优先受偿的债权。公司破产后，对公司剩余财产的分配顺序上最后的是普通债权。破产宣告前成立的无财产担保的债权;虽有财产担保但放弃优先受偿权的债权;虽有财产担保，但担保物的价款低于债权额，其未能受偿的债权等，属于普通债权。'
				 },{
					 title:'2.劳动债权',
					 cons:'劳动债权是指在企业破产清算中应优先分配的因破产宣告前的劳动关系而发生的债权，包括破产企业所欠劳动者的工资(劳动报酬)、工资性待遇、因企业破产解除劳动合同依法应支付给劳动者的经济补偿金和欠缴的社会保险费用。'
				 },{
					 title:'3.有财产担保债权',
					 cons:'有财产担保债权是指债务人以不动产、动产、权利提供担保所对应的债权。有财产担保债权的担保权包括抵押权、质权和留置权。'
				 },{
					 title:'4.税款债权',
					 cons:'税款债权是指债务人欠缴税款以及欠缴税款对应的各类附加属于税款债权。债务人在破产案件受理前因欠缴税款产生的滞纳金属于普通债权。'
				 },{
					 title:'5.优先受偿权',
					 cons:'①建设工程价款优先受偿权建设工程价款优先受偿权是指发包人未按照约定支付价款的，承包人可以催告发包人在合理期限内支付价款。发包人逾期不支付的，除按照建设工程的性质不宜折价、拍卖的以外，承包人可以与发包人协议将该工程折价，也可以申请人民法院将该工程依法拍卖。建设工程的价款就该工程折价或者拍卖的价款优先受偿。消费者已支付商品房的全部或者大部分购买款项的，承包人就该商品房享有的工程价款优先受偿权不得对抗买受人。②船舶优先权船舶优先权，是指特定海事债权人依法享有的、当债务人不履行或不能履行债务时，以船舶为标的的对担保物优先受偿的权利。船舶优先权是海商法赋予海事债权人的一项特权。③航空器优先权民用航空器优先权是指债权人向民用航空器所有人，承租人提出赔偿请求，对产生该赔偿请求的民用航空器具有优先受偿的权利。民用航空器优先权属于法定优先权，是与抵押权，质权和留置权并行的担保特定债权，并且是对某一特定财产优先受偿的一种担保物权。'
				 },{
					 title:'6.抵销权',
					 cons:'抵销权是指当事人互负到期债务，该债务的标的物种类、品质相同的，任何一方可以将自己的债务与对方的债务抵销，但依照法律规定或者按照合同性质不得抵销的除外。当事人主张抵销的，应当通知对方。通知自到达对方时生效。'
				 },{
					 title:'7. 共益债务',
					 cons:'共益债务是指人民法院受理破产申请后,为了全体债权人的共同利益以及破产程序顺利进行而发生的债务。'
				 }],
                lawSelected: '',
                lawlist:["借款合同","民间借贷","买卖合同","建设工程合同","运输合同","保管合同","委托合同","仓储合同","服务合同","知识产权与竞争","商标合同",
					"专利合同","技术合同","证券","期货交易","信托合同","保险合同","票据","信用证","不当得利","无因管理","侵权责任","其他"],
                ClaimForm:{    //求偿权
                    id:0,
                    name: '',
                    type:0,
                },
                dialogRecourseVisible:false,   //求偿权弹框
                recourses:'求偿权是指债务人的保证人或者其他连带债务人，已经代替债务人清偿债务的，以其对债务人的求偿权向管理人申报债权。债务人的保证人或者其他连带债务人尚未代替债务人清偿债务的，以其对债务人的将来求偿权向管理人申报债权。但是，债权人已经向管理人申报全部债权的除外。',
                subrogationForm:{  //代位权
                    id:0,
                    name: '',
                    type:0,
                },
                dialogDaiweiVisible:false,   //代位权弹框
                daiwei:'代位权是指债权人的债权到期前，债务人的权利存在诉讼时效期间即将届满或者未及时申报破产债权等情形，影响债权人的债权实现的，债权人可以代为向债务人的相对人请求向其债务人履行、向破产管理人申报或者作出其他必要的行为。',
                expectForm:{   //物权期待权
                    id:0,
                    name: '',
                    type:0,
                },
                fact:'',
                
                //新增
                typeindex:0,
				declare_claims_delay:'',
				declare_claims_delay_focus:'',
				declare_claims_penalty:'',
				declare_claims_penalty_focus:'',
                estate_name:'',
				room_info:'',
				roomtype:['住宅','商铺','写字楼','还建房'],
				room_type:0,
				housing_contract_has:0,    //有无房屋买卖合同
				housing_contract_number:'',  
				housing_contract_registration:0,    //是否办理备案登记
				other_supplemental_has:0,    //有无其他补充协议
				other_supplemental_name:'',  
				mortgage:0,     //是否办理按揭
				advance_notice_registration:0,    //是否办理预告登记
				is_stay_in:0,   //是否入住  
				invoice_has:0,    //是否持有购房款发票
				loan_swap:0,    //是否存在借贷转买卖
				project_for_payment:0,  //是否存在以材料款或工程款抵债
				room_area:'',    //建筑面积
				room_area_focus:'',
				payment_condition:0,    //实际付款情况
				paymentlists:['只付了定金或意向金','未全额付款','已全额付款','还建房'],
				payment_condition_money:'',    //已付金额
				payment_condition_money_focus:'',
				contract_due:'',  //合同应付购房款数额
				contract_due_focus:'',
				owe_developer:'',   //欠开发商
				owe_developer_focus:'',
				declare_appeal:0,
                claimtype:["破产债权","房地产物权","退房退款"],
                index3:0,

                // 第三步的上传
                sureshow:true,
                datashow:false,
                readneed:{
                    id:0,
                    name: '',
                    type:0,
                },
                affirms:{
                    id:0,
                    name: '',
                    type:0,
                },
                adddatashow:false,
                disabled1:false,
                upfilemethod:{
                    id:0,
                    name: '',
                    type:0,
                },
                upfilemethodcheck:10,
                evidence:{
                    id:0,
                    name: '',
                    type:0, //证据有无原件
                },
                fulist:[],
                evidence_name:'',
				evidence_aim:'',
				evidence_num:'',
				evidence_page:'',
                imagestring1:'',
                dialogTableVisible:false,
                upfilestatus:1,
                imgFile:'',
                fileList: [],
                myHeaders:{'content-type': 'application/json','Authori-zation':'Bearer ' + localStorage.getItem('token')},
                srcarr1:[],
                evidenceList:[],
                evidencecontent:"",
                evidenceid:1,
                // 其他
                content:'',
                toastShow:false,
                imgbig:'',
                imgbigtype:false,
                aa:[]
            }
        },
        watch:{
            index3(index3){
                var that=this;
                if(this.index3==0){  //普通
                    that.moneyclaims()
                }else if(this.index3==1){    //房地产物权
                    that.roomclaims()
                }else{    //退房退款
                    that.backclaims()
                }
            }
        },
        computed:{
			allmoney(){    //计算申报债权总额
				if(this.index3==0){    //普通
					var data_allmoney1=parseFloat(this.principal||0)+parseFloat(this.interest||0)+parseFloat(this.loss||0);
					this.data_allmoney=data_allmoney1.toFixed(2)
					return this.data_allmoney 
				}else if(this.index3==2){    //退款退房
					var data_allmoney1=parseFloat(this.principal||0)+parseFloat(this.interest||0)+parseFloat(this.loss||0)+parseFloat(this.declare_claims_delay||0)+parseFloat(this.declare_claims_penalty||0);
					this.data_allmoney=data_allmoney1.toFixed(2)
					return this.data_allmoney 
				}
			},
					
		},
        created(){
            this.selectarea=getcitydata('3')
            this.case_id=this.$route.query.case_id;
            this.type=this.$route.query.type;
            this.claims_id=this.$route.query.claims_id;
            this.creditor_id=this.$route.query.creditor_id;
            this.typeindex=this.$route.query.typeindex;
            
            if(this.$route.query.from=='Manage'){
                this.active=2;
                if(this.typeindex==0){
                    this.index3=0;
                    this.moneyclaims()

                }else if(this.typeindex==2){  //房地产物权
                    this.index3=1;
                    this.roomclaims()
                }else{   //退房退款
                    this.index3=2;
                    this.backclaims()

                }
                this.getclaimsinfo()
            }
            

　　　　　　　//如果没有这句代码，select中初始化会是空白的，默认选中就无法实现
            this.natureSelected = this.naturelist[0];
            this.lawSelected = this.lawlist[0];

            CaseSingle({    //债务人信息
                case_id:this.case_id,
                token:localStorage.getItem('token')
            }).then(res => {   
                this.basic=res.data;
            })

            CreditorStatus({  //获取债权人信息
                case_id:this.case_id,
                token:localStorage.getItem('token')
            }).then(res => {   
                if(res.code==0){    //没有验证，正常流程
						
                }else{    //验证过，只读
                    this.startChecking=true;  
                    this.CreditorMessDisabled=true;
                    this.creditor_id=res.data.id;
                    this.invitation_code=res.data.invitation_code;
                    this.minename=res.data.name;
                    this.minephone=res.data.phone;
                    this.attributes=res.data.attributes;
                }
            })
            
            // GetArea({    //获取省市区

            // }).then(res=>{
            //     this.selectarea=res.data;
            // })
           
        },
        methods:{
            //第一步 债权人基本信息
            StartValidation:function(type){   //开始验证
                var that=this;
                if(type=='zhai'){
                    if(that.CreditorMessDisabled==false){   //可点击状态时，才可以点击
                        if(that.IdentificationCode==""){
                            that.content="请联系破产管理人";
                            that.toastShow=true;
                            setTimeout(() => {
                                that.toastShow=false
                            }, 1500);
                        }else{
                            CreditorCheck({
                                token:localStorage.getItem('token'),
                                case_id:that.case_id,
                                name:that.minename,
                                phone:that.minephone,
                                identification_code:that.IdentificationCode,
                                attributes:that.attributes==true?'1':'0'
                            }).then(res => {  
                                if(res.code==1){    //验证成功,不可修改，不可点击变色
                                    that.startChecking=true;
                                    that.CreditorMessDisabled=true;
                                    that.creditor_id=res.data.id;
                                    that.invitation_code=res.data.invitation_code;    //邀请码
                                    that.content="验证成功";
                                    if(that.haveagentForm.type==1){
                                        that.agenttype=true
                                    }
                                }else{
                                    that.content="验证失败,请联系管理人索取身份识别码"
                                }
                                this.toastShow=true
                                setTimeout(() => {
                                    this.toastShow=false
                                }, 1500);
                            }) 
                        }
                    }else{
                        that.content="您已验证过信息"
                        that.toastShow=true;
                        setTimeout(() => {
                            that.toastShow=false
                        }, 1500);
                    }
                }else{
                    if(that.AgentMessDisabled==false){
                        if(!that.agent_name||!that.agent_phone||!that.agent_pro||!that.agent_addr){
                            that.content="请填写必填项"
                            that.toastShow=true;
                            setTimeout(() => {
                                that.toastShow=false
                            }, 1500);
                        }else{
                            AgentCheck({
                                token:localStorage.getItem('token'),
                                case_id:this.case_id,
                                agent_name:this.agent_name,
                                agent_phone:this.agent_phone,
                                agent_pro:this.agent_pro,
                                agent_city:this.agent_city,
                                agent_area:this.agent_area,
                                agent_address:this.agent_addr,
                                creditor_name:this.claim_name,
                                invitation_code:this.claim_code,
                                attributes:that.attributes==true?'1':'0'
                            }).then(res=>{
                                that.agent_id=res.data.agent_id;     //代理人id
                                that.creditor_id=res.data.creditor_id;    //债权人id
                                if(res.code==0){         //验证不通过
                                    that.content=res.msg;
                                    that.toastShow=true;
                                    setTimeout(() => {
                                        that.toastShow=false
                                    }, 1500);
                                }
                                if(res.code==1){   
                                    that.content=res.msg;
                                    that.toastShow=true;
                                    setTimeout(() => {
                                        that.toastShow=false
                                    }, 1500);
                                    that.AgentMessDisabled=true;
                                    that.agentstartChecking=true;
                                }
                                // if(res.code==2){
                                //     that.content="债权人不能为自己代理，请您选择其他代理人进行操作。"
                                //     that.toastShow=true;
                                //     setTimeout(() => {
                                //         that.toastShow=false
                                //     }, 1500);
                                // }
                            })
                        }
                    }
                }
            },
            copy:function(){
                var clipboard = new this.Clipboard(".btns"); //在main.js中引用
                var that=this;
                clipboard.on("success", e => {
                    that.content="邀请码已复制"
                    that.toastShow=true;
                    setTimeout(() => {
                        that.toastShow=false
                        
                    }, 2000);
                })
                clipboard.on("error", e => {
                    // 不支持复制
                    Message({
                    message: "该浏览器不支持自动复制",
                    type: "warning"
                    });
                    // 释放内存
                    clipboard.destroy();
                });
            },
            agenthandleChange(value) {   //选择地址
                this.agent_pro=value[0];
                this.agent_city=value[1];
                this.agent_area=value[2];
                this.agentareavalue=value;
            },
            aaa:function(e){
                if(e==1){
                    this.agenttype=true
                }else{
                    this.agenttype=false
                }
            },
            next:function(type,roles){
                if(type=='first'){
                    if(this.type=="zhai"){
                        if(this.startChecking==true){
                            this.active=1;
                            CaseUserInfo({  //债权人信息 
                                creditor_id:this.creditor_id,
                                token:localStorage.getItem('token')
                            }).then(res => {  
                                this.creditor=res.data.creditor
                            })

                            creditorSignStatus({   //获取文件签收状态
                                token:localStorage.getItem('token'),
                                creditor_id:this.creditor_id
                            }).then(res => {  
                                this.adminlist=res.data.admin;
                                this.normallist=res.data.normal;
                                for(var i=0;i<this.adminlist.length;i++){
                                    this.adminlist[i].type=0;
                                }
                                for(var i=0;i<this.normallist.length;i++){
                                    this.normallist[i].type=0;
                                }
                            })
                        }else{
                            this.content="请先验证身份"
                            this.toastShow=true;
                            setTimeout(() => {
                                this.toastShow=false
                            }, 1500);
                        }
                    }
                    if(this.type=='dai'){
                        if(this.agentstartChecking==true){
                            this.active=1;
                            CaseUserInfo({  //债权人信息 
                                creditor_id:this.creditor_id,
                                agent_id:this.agent_id,
                                token:localStorage.getItem('token')
                            }).then(res => {  
                                this.creditor=res.data.creditor
                            })

                            creditorSignStatus({   //获取文件签收状态
                                token:localStorage.getItem('token'),
                                creditor_id:this.creditor_id
                            }).then(res => {  
                                this.adminlist=res.data.admin;
                                this.normallist=res.data.normal;
                                for(var i=0;i<this.adminlist.length;i++){
                                    this.adminlist[i].type=0;
                                }
                                for(var i=0;i<this.normallist.length;i++){
                                    this.normallist[i].type=0;
                                }
                            })
                        }else{
                            this.content="请先验证身份"
                            this.toastShow=true;
                            setTimeout(() => {
                                this.toastShow=false
                            }, 1500);
                        }
                    }
                }
                if(type=="second"){
                    var readstatus=true;
                    for(var i=0;i<this.adminlist.length;i++){
                        if(this.adminlist[i]['sign_status']==0){
                            readstatus=false;
                        }
                    }
                    for(var i=0;i<this.normallist.length;i++){
                        if(this.normallist[i]['sign_status']==0){
                            readstatus=false;
                        }
                    }
                    if(readstatus){
                        // if(roles=='creditor'){    //债权人
                            this.active=2;
                            //进行到债权申报流程
                            this.getclaimsinfo()
                        // }else{  //代理人
                        //     this.$router.push({path:'/Manage'})
                        // }
                    }else{
                        this.content="存在未签收文件";
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false
                        }, 1500);
                    }
                }
               
            },


            
            //第二步，签收文书
            readbook:function(id,path,index,type){
                window.open('https://pc.pochanjie.com'+path);
                if(type=='admin'){
                    this.adminlist[index].type=1
                }else{
                    this.normallist[index].type=1
                }
            },
            getsign:function(item,index,type){
                if(item.sign_status==1){
					return
				}else{
					if(item.type==0){
						this.content="请先阅读该文件";
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false
                        }, 1500);
						return
					}else{
                        CreditorDoSign({
                            token:localStorage.getItem('token'),
                            creditor_id:this.creditor_id,
                            file_id:item.id
                        }).then(res => {
                            if(res.code==1){
                                if(type=='admin'){
                                    this.adminlist[index]['sign_status']=1;
                                }else{
                                    this.normallist[index]['sign_status']=1;
                                }
                                this.content="签收成功",
                                this.toastShow=true;
                                setTimeout(() => {
                                    this.toastShow=false
                                }, 1500);
                                
                            }
                        })
						
					}
				}
            },
            sureclaim:function(){
                this.$router.push({path:'/Manage'})
            },


            //债权申报 第一步 申请人信息
            getclaimsinfo:function(){
                //获取申请人信息
                ClaimsGetUserInfo({ 
                    token:localStorage.getItem('token'),
                    creditor_id:this.creditor_id
                }).then(res=>{
                    this.attributes=res.data.attributes;
                    this.zhainame=res.data.name;
                    this.zhaiid=res.data.id_number;
                    this.zhaiphone=res.data.phone;
                    this.zhaiaddress=res.data.address;
                    this.zhaimail=res.data.email;
                    this.areavalue=[res.data.pro,res.data.city,res.data.area]
                    this.zhaiwx=res.data.weixin;
                    this.com_user=res.data.legal_person;
                    this.com_userid=res.data.legal_person_id;
                    this.com_catuser=res.data.link_name;
                    this.com_catphone=res.data.link_phone;
                    this.pro=res.data.pro;
                    this.city=res.data.city;
                    this.area=res.data.area;
                })
            },
            handleChange(value) {   //选择地址
                this.pro=value[0];
                this.city=value[1];
                this.area=value[2];
                this.areavalue=value;
            },
            keepstepfirst:function(type){   //保存
                //个人
                var that=this;
                if(this.attributes==0&&(this.zhainame==''||this.zhaiphone==''||this.zhaiid==''||this.areavalue==[]||this.zhaiaddress=='')){
                    this.content='请填写必填项';
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false;
                    }, 1500);
                    return;
                    this.currentstep=1;
                }  else{
                    ClaimsSetUserInfo({   //保存第一步信息
                        token:localStorage.getItem('token'),
                        creditor_id:this.creditor_id,
                        // attributes:this.attributes==true?'1':'0',
                        // name:this.zhainame,
                        id_number:this.zhaiid,
                        // phone:this.zhaiphone,
                        pro:this.pro,
                        city:this.city,
                        area:this.area,
                        address:this.zhaiaddress,
                        email:this.zhaimail,
                        weixin:this.zhaiwx,
                        legal_person:this.com_user,
                        legal_person_id:this.com_userid,
                        link_name:this.com_catuser,
                        link_phone:this.com_catphone
                    }).then(res=>{
                        if(res.code==1){
                            this.content=res.msg;
                            this.firstdis=true;
                            if(type=='firststep'){
                                that.firststep()
                            }
                        }else{
                            this.content=res.msg;
                        }
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                    })
                }
                //企业
                if(this.attributes==1&&(this.zhainame==''||this.zhaiphone==''||this.zhaiid==''||this.areavalue==[]||this.zhaiaddress=='')){
                    this.content='请填写必填项';
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false;
                    }, 1500);
                    return;
                    this.currentstep=1;
                }else{
                    ClaimsSetUserInfo({   //保存第一步信息
                        token:localStorage.getItem('token'),
                        creditor_id:this.creditor_id,
                        // attributes:this.attributes,
                        // name:this.zhainame,
                        id_number:this.zhaiid,
                        // phone:this.zhaiphone,
                        pro:this.pro,
                        city:this.city,
                        area:this.area,
                        address:this.zhaiaddress,
                        email:this.zhaimail,
                        weixin:this.zhaiwx,
                        legal_person:this.com_user,
                        legal_person_id:this.com_userid,
                        link_name:this.com_catuser,
                        link_phone:this.com_catphone
                    }).then(res=>{
                        if(res.code==1){
                            this.content=res.msg;
                            this.firstdis=true;
                            if(type=='firststep'){
                                that.firststep()
                            }
                        }else{
                            this.content=res.msg;
                        }
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                    })
                }
            },
            firststep:function(){
                var that=this;
                setTimeout(() => {
                    this.currentstep=2;
                    if(this.index3==0){  //普通
                        that.moneyclaims()
                    }else if(this.index3==1){    //房地产物权
                        that.roomclaims()
                    }else{    //退房退款
                        that.backclaims()
                    }
                }, 1500);
                
            },
            
            //第二步新增
            //破产债权
            moneyclaims:function(){
                var that=this;
                ClaimsGetInfo({   //获取第二步信息,第一次填不需要传claims_id
                    token:localStorage.getItem('token'),
                    claims_id:this.claims_id
                }).then(res=>{
                    if(res.data.composite_status==1){   //不可编辑
                        this.seconddis=true;
                    }
                    that.principal=res.data.declare_claims_principal==0?'':res.data.declare_claims_principal;     //本金
                    that.interest=res.data.declare_claims_interest==0?'':res.data.declare_claims_interest;   //利息
                    
                    that.loss=res.data.declare_claims_other==0?'':res.data.declare_claims_other;    //其他损失
                    that.data_allmoney=res.data.declare_claims_total==0?'':res.data.declare_claims_total;     //总额
                    this.composite_status=res.data.composite_status;
                    this.guaranteeForm.type=res.data.property_guarantee_has;   //财产担保radio
                    this.guaranteename=res.data.property_guarantee_person;
                    this.guaranteemoney=res.data.property_guarantee_money==0?'':res.data.property_guarantee_money;

                    this.registerForm.type=res.data.property_guarantee_registration;  //财产担保是否有登记
                    this.register=res.data.property_guarantee_item;     //担保物

                    this.creditorForm.type=res.data.joint_creditor_has;   //是否有连带债权人
                    this.creditor=res.data.joint_creditor_name;

                    this.obligorForm.type=res.data.joint_debtor_has;   //是否有连带债务人
                    this.obligor=res.data.joint_debtor_name;

                    this.judgmentForm.type=res.data.effective_judgment_has;   //是否有生效判决书
                    this.judgmentbook=res.data.effective_judgment_number;
                    this.judgmentoffice=res.data.effective_judgment_office;

                    this.mediateForm.type=res.data.effective_conciliation_statement_has;    //是否有生效调解书
                    this.mediatebook=res.data.effective_conciliation_statement_number;
                    this.mediateoffice=res.data.effective_conciliation_statement_office;

                    this.arbitrationForm.type=res.data.arbitration_award_force_has;    //是否有生效仲裁裁决书
                    this.arbitrationbook=res.data.arbitration_award_force_number;
                    this.arbitrationoffice=res.data.arbitration_award_force_office;

                    this.fairForm.type=res.data.notarization_has;    //是否有公证书
                    this.fairbook=res.data.notarization_number;
                    this.fairoffice=res.data.notarization_office;

                    this.implementForm.type=res.data.executive_program_is;    //是否进入执行程序
                    this.implementbook=res.data.executive_program_number;
                    this.implementperson=res.data.executive_program_person;
                    this.implementoffice=res.data.executive_program_court;
                    this.implementphone=res.data.executive_program_phone;
                    this.implementmoney=res.data.executive_program_already_charge==0?'':res.data.executive_program_already_charge;

                    this.data_bankruptcyname=res.data.charge_allocation_name;
                    this.data_bankruptcyaccount=res.data.charge_allocation_bank_card_number;
                    this.data_bankruptcyback=res.data.charge_allocation_bank_name;
                    
                    this.natureSelected=res.data.claims_nature;    //债权性质
                    this.lawSelected=res.data.fundamental_legal_relationship;   //法律关系
                    this.ClaimForm.type=res.data.recourse;   //求偿权
                    this.subrogationForm.type=res.data.subrogation;   //代位权
                    this.expectForm.type=res.data.property;   //物权期待权
                    this.fact=res.data.facts_and_reasons;   //事实与理由
                })
            },
            //房地产物权
            roomclaims:function(){
                var that=this;
                ClaimsGetInfofdc({
                    token:localStorage.getItem('token'),
                    claims_id:this.claims_id
                }).then(res=>{
                    that.judgmentForm.type=res.data.effective_judgment_has;   //是否有生效判决书
                    that.judgmentbook=res.data.effective_judgment_number;
                    that.judgmentoffice=res.data.effective_judgment_office;

                    this.mediateForm.type=res.data.effective_conciliation_statement_has;    //是否有生效调解书
                    this.mediatebook=res.data.effective_conciliation_statement_number;
                    this.mediateoffice=res.data.effective_conciliation_statement_office;

                    this.arbitrationForm.type=res.data.arbitration_award_force_has;    //是否有生效仲裁裁决书
                    this.arbitrationbook=res.data.arbitration_award_force_number;
                    this.arbitrationoffice=res.data.arbitration_award_force_office;

                    this.implementForm.type=res.data.executive_program_is;    //是否进入执行程序
                    this.implementbook=res.data.executive_program_number;
                    this.implementperson=res.data.executive_program_person;
                    this.implementoffice=res.data.executive_program_court;
                    this.implementphone=res.data.executive_program_phone;

                    this.lawSelected=res.data.fundamental_legal_relationship;   //法律关系
                    that.fact=res.data.facts_and_reasons;   //事实与理由
                    
                    that.estate_name=res.data.estate_name;
                    that.room_info=res.data.room_info;    //房屋信息
                    that.room_type=res.data.room_type;   //房屋性质  0住宅  1商铺  2写字楼 3还建房
                    that.housing_contract_has=res.data.housing_contract_has;    //房租合同  0无  1有
                    that.housing_contract_number=res.data.housing_contract_number;    //房屋合同编号
                    that.housing_contract_registration=res.data.housing_contract_registration;   //房屋合同登记  0否  1是
                    that.other_supplemental_has=res.data.other_supplemental_has;    //有无其他补充协议
                    that.other_supplemental_name=res.data.other_supplemental_name;   //协议名称
                    that.mortgage=res.data.mortgage;    //按揭   0否  1是
                    that.advance_notice_registration=res.data.advance_notice_registration;   //预告登记   0否  1是
                    that.is_stay_in=res.data.is_stay_in;    //入住   0否  1是
                    that.invoice_has=res.data.invoice_has;    //发票   0无  1有
                    that.loan_swap=res.data.loan_swap;   //借贷转买卖   0否  1是
                    that.project_for_payment=res.data.project_for_payment;   //工程款抵债   0否  1是
                    that.room_area=res.data.room_area==0?'':res.data.room_area;   //建筑面积
                    that.payment_condition=res.data.payment_condition;   //付款情况   0定金意向金  1未全额  2全额  3还建房
                    that.payment_condition_money=res.data.payment_condition_money==0?'':res.data.payment_condition_money;   //付款情况  金额
                    that.contract_due=res.data.contract_due==0?'':res.data.contract_due;    //合同应付金额
                    that.owe_developer=res.data.owe_developer==0?'':res.data.owe_developer;   //欠开发商
                })
            },
            //退房退款
            backclaims:function(){
                var that=this;
                ClaimsGetInfotf({
                    token:localStorage.getItem('token'),
                    claims_id:that.claims_id
                }).then(res=>{
                    that.composite_status=res.data.composite_status;
                    that.principal=res.data.declare_claims_principal==0?'':res.data.declare_claims_principal;     //本金
                    that.interest=res.data.declare_claims_interest==0?'':res.data.declare_claims_interest;   //利息
                    
                    that.declare_claims_delay=res.data.declare_claims_delay==0?'':res.data.declare_claims_delay;    //申报债权延迟
                    that.declare_claims_penalty=res.data.declare_claims_penalty==0?'':res.data.declare_claims_penalty;    //申报债权违约
                    
                    that.loss=res.data.declare_claims_other==0?'':res.data.declare_claims_other;    //其他损失
                    that.data_allmoney=res.data.declare_claims_total==0?'':res.data.declare_claims_total;     //总额
                    that.judgmentForm.type=res.data.effective_judgment_has;   //是否有生效判决书
                    that.judgmentbook=res.data.effective_judgment_number;
                    that.judgmentoffice=res.data.effective_judgment_office;

                    this.mediateForm.type=res.data.effective_conciliation_statement_has;    //是否有生效调解书
                    this.mediatebook=res.data.effective_conciliation_statement_number;
                    this.mediateoffice=res.data.effective_conciliation_statement_office;

                    this.arbitrationForm.type=res.data.arbitration_award_force_has;    //是否有生效仲裁裁决书
                    this.arbitrationbook=res.data.arbitration_award_force_number;
                    this.arbitrationoffice=res.data.arbitration_award_force_office;

                    this.implementForm.type=res.data.executive_program_is;    //是否进入执行程序
                    this.implementbook=res.data.executive_program_number;
                    this.implementperson=res.data.executive_program_person;
                    this.implementoffice=res.data.executive_program_court;
                    this.implementphone=res.data.executive_program_phone;

                    this.lawSelected=res.data.fundamental_legal_relationship;   //法律关系
                    that.fact=res.data.facts_and_reasons;   //事实与理由
                    
                    that.estate_name=res.data.estate_name;
                    that.room_info=res.data.room_info;    //房屋信息
                    that.room_type=res.data.room_type;   //房屋性质  0住宅  1商铺  2写字楼 3还建房
                    that.housing_contract_has=res.data.housing_contract_has;    //房租合同  0无  1有
                    that.housing_contract_number=res.data.housing_contract_number;    //房屋合同编号
                    that.housing_contract_registration=res.data.housing_contract_registration;   //房屋合同登记  0否  1是
                    that.other_supplemental_has=res.data.other_supplemental_has;    //有无其他补充协议
                    that.other_supplemental_name=res.data.other_supplemental_name;   //协议名称
                    that.mortgage=res.data.mortgage;    //按揭   0否  1是
                    that.advance_notice_registration=res.data.advance_notice_registration;   //预告登记   0否  1是
                    that.is_stay_in=res.data.is_stay_in;    //入住   0否  1是
                    that.invoice_has=res.data.invoice_has;    //发票   0无  1有
                    that.loan_swap=res.data.loan_swap;   //借贷转买卖   0否  1是
                    that.project_for_payment=res.data.project_for_payment;   //工程款抵债   0否  1是
                    that.room_area=res.data.room_area==0?'':res.data.room_area;   //建筑面积
                    that.payment_condition=res.data.payment_condition;   //付款情况   0定金意向金  1未全额  2全额  3还建房
                    that.payment_condition_money=res.data.payment_condition_money==0?'':res.data.payment_condition_money;   //付款情况  金额
                    that.contract_due=res.data.contract_due==0?'':res.data.contract_due;    //合同应付金额
                    that.owe_developer=res.data.owe_developer==0?'':res.data.owe_developer;   //欠开发商
                    that.declare_appeal=res.data.declare_appeal;    //申报诉求   0不要求  1要求交房
                })
            },
            addclaimtype:function(e){
				var that=this;
				this.index3 = e.target.value;   
                
            },
            
            // 债权申报 第二步 填写申报材料
            getnature(e){
            },
            getlaw(){
            },
            // obligatory:function(){
            //     this.dialogObligatoryVisible=true;
            // },
            recourse:function(){
                this.dialogRecourseVisible=true;
            },
            daiweibtn:function(){
                this.dialogDaiweiVisible=true;
            },
            //新增
            roomtypes(){

            },
            keepstepsecond:function(type){
                var that=this;
                if(this.index3==0){    //普通
                    if(this.principal==''||this.data_allmoney==''||this.data_bankruptcyname==''||this.data_bankruptcyaccount==''||this.data_bankruptcyback==''||this.fact==''){
                        this.content="请填写必填项";
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                        this.currentstep=2;
                        
                        return
                    }
                    if(this.guaranteeForm.type==1&&(this.guaranteename==''||this.guaranteemoney=='')){
                        this.content="请填写财产担保必填项"
                        this.toastShow=true
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                        return

                    }
                    if(this.registerForm.type==1&&this.register==''){
                        this.content="请输入担保物情况"
                        this.toastShow=true
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                        return

                    }
                    if(this.creditorForm.type==1&&this.creditor==''){
                        this.content="请输入连带债权人名称"
                        this.toastShow=true
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                        return

                    }
                    if(this.obligorForm.type==1&&this.obligor==''){
                        this.content="请输入连带债务人名称"
                        this.toastShow=true
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                        return

                    }
                    if(this.fairForm.type==1&&(this.fairbook==''||this.fairoffice=='')){
                        this.content="请填写公证书必填项"
                        this.toastShow=true
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                        return

                    }
                }else if(this.index3==1){  //房地产物权
                    if(!that.estate_name||!that.room_info||!that.room_area){
                        this.content="请填写必填项";
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                        this.currentstep=2;
                        
                        return
                    }
                    
                }else{    //退房退款
                    if(!that.estate_name||!that.principal||!that.data_allmoney||!that.room_info||!that.room_area){
                        this.content="请填写必填项";
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                        this.currentstep=2;
                        
                        return
                    }
                }
                
                if(this.judgmentForm.type==1&&(this.judgmentbook==''||this.judgmentoffice=='')){
                    this.content="请填写生效判决书必填项"
                    this.toastShow=true
                    setTimeout(() => {
                        this.toastShow=false;
                    }, 1500);
                    return

                }
                if(this.mediateForm.type==1&&(this.mediatebook==''||this.mediateoffice=='')){
                    this.content="请填写生效调解书必填项"
                    this.toastShow=true
                    setTimeout(() => {
                        this.toastShow=false;
                    }, 1500);
                    return

                }
                if(this.arbitrationForm.type==1&&(this.arbitrationbook==''||this.arbitrationoffice=='')){
                    this.content="请填写生效仲裁裁决书必填项"
                    this.toastShow=true
                    setTimeout(() => {
                        this.toastShow=false;
                    }, 1500);
                    return

                }
                
                if(this.implementForm.type==1&&(this.implementbook==''||this.implementoffice==''||this.implementperson==''||this.implementphone=='')){
                    this.content="请填写执行程序必填项"
                    this.toastShow=true
                    setTimeout(() => {
                        this.toastShow=false;
                    }, 1500);
                    return
                    
                }

                var that=this;
                if(this.index3==0){
                    ClaimsSetInfo({   //第二步保存
                        token:localStorage.getItem('token'),
                        claims_id:that.claims_id,
                        creditor_id:that.creditor_id,
                        declare_claims_principal:that.principal,
                        declare_claims_interest:that.interest,
                        declare_claims_other:that.loss,
                        declare_claims_total:that.data_allmoney,
                        property_guarantee_has:that.guaranteeForm.type,
                        property_guarantee_person:that.guaranteename,
                        property_guarantee_money:that.guaranteemoney,
                        property_guarantee_registration:that.registerForm.type,
                        property_guarantee_item:that.register,
                        joint_creditor_has:that.creditorForm.type,
                        joint_creditor_name:that.creditor,
                        joint_debtor_has:that.obligorForm.type,
                        joint_debtor_name:that.obligor,
                        effective_judgment_has:that.judgmentForm.type,
                        effective_judgment_number:that.judgmentbook,
                        effective_judgment_office:that.judgmentoffice,
                        effective_conciliation_statement_has:that.mediateForm.type,
                        effective_conciliation_statement_number:that.mediatebook,
                        effective_conciliation_statement_office:that.mediateoffice,
                        arbitration_award_force_has:that.arbitrationForm.type,
                        arbitration_award_force_number:that.arbitrationbook,
                        arbitration_award_force_office:that.arbitrationoffice,
                        notarization_has:that.fairForm.type,
                        notarization_number:that.fairbook,
                        notarization_office:that.fairoffice,
                        executive_program_is:that.implementForm.type,
                        executive_program_number:that.implementbook,
                        executive_program_court:that.implementoffice,
                        executive_program_person:that.implementperson,
                        executive_program_phone:that.implementphone,
                        executive_program_already_charge:that.implementmoney,
                        charge_allocation_name:that.data_bankruptcyname,
                        charge_allocation_bank_card_number:that.data_bankruptcyaccount,
                        charge_allocation_bank_name:that.data_bankruptcyback,
                        claims_nature:that.natureSelected,
                        fundamental_legal_relationship:that.lawSelected,
                        recourse:that.ClaimForm.type,
                        subrogation:that.subrogationForm.type,
                        property:that.expectForm.type,    //物权期待权
                        facts_and_reasons:that.fact,
                    
                    }).then(res=>{
                        if(res.code==0){
                            this.content=res.msg;
                        }else{
                            this.content=res.msg;
                            this.claims_id=res.data.claims_id;
                            this.seconddis=true;
                            if(type=='secondstep'){
                                that.secondstep()
                            }
                        }
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                    })
                }else if(this.index3==1){
                    ClaimsSetInfofdc({
                        token:localStorage.getItem('token'),
                        claims_id:that.claims_id,
                        creditor_id:that.creditor_id,
                        estate_name:that.estate_name,
                        
                        effective_judgment_has:that.judgmentForm.type,
                        effective_judgment_number:that.judgmentbook,
                        effective_judgment_office:that.judgmentoffice,
                        effective_conciliation_statement_has:that.mediateForm.type,
                        effective_conciliation_statement_number:that.mediatebook,
                        effective_conciliation_statement_office:that.mediateoffice,

                        arbitration_award_force_has:that.arbitrationForm.type,
                        arbitration_award_force_number:that.arbitrationbook,
                        arbitration_award_force_office:that.arbitrationoffice,

                        executive_program_is:that.implementForm.type,
                        executive_program_number:that.implementbook,
                        executive_program_court:that.implementoffice,
                        executive_program_person:that.implementperson,
                        executive_program_phone:that.implementphone,
                        executive_program_already_charge:that.implementmoney,

                        facts_and_reasons:that.fact,
                        room_info:that.room_info,
                        room_type:that.room_type,
                        housing_contract_has:that.housing_contract_has,
                        housing_contract_number:that.housing_contract_number,
                        housing_contract_registration:that.housing_contract_registration,
                        other_supplemental_has:that.other_supplemental_has,
                        other_supplemental_name:that.other_supplemental_name,
                        mortgage:that.mortgage,
                        advance_notice_registration:that.advance_notice_registration,
                        is_stay_in:that.is_stay_in,
                        invoice_has:that.invoice_has,
                        loan_swap:that.loan_swap,
                        project_for_payment:that.project_for_payment,
                        room_area:that.room_area,
                        payment_condition:that.payment_condition,
                        payment_condition_money:that.payment_condition_money,
                        contract_due:that.contract_due,
                        owe_developer:that.owe_developer,
                        fundamental_legal_relationship:that.lawSelected,
                    }).then(res=>{
                        if(res.code==0){
                            this.content=res.msg;
                        }else{
                            this.content=res.msg;
                            this.claims_id=res.data.claims_id;
                            this.seconddis=true;
                            if(type=='secondstep'){
                                that.secondstep()
                            }
                        }
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                    })
                }else{
                    ClaimsSetInfotf({   //第二步保存
                        token:localStorage.getItem('token'),
                        claims_id:that.claims_id,
                        creditor_id:that.creditor_id,
                        declare_claims_principal:that.principal,
                        declare_claims_interest:that.interest,
                        declare_claims_other:that.loss,
                        declare_claims_total:that.data_allmoney,
                        declare_claims_delay:that.declare_claims_delay,
                        declare_claims_penalty:that.declare_claims_penalty,
                        property_guarantee_has:that.guaranteeForm.type,
                        property_guarantee_person:that.guaranteename,
                        property_guarantee_money:that.guaranteemoney,
                        property_guarantee_registration:that.registerForm.type,
                        property_guarantee_item:that.register,
                        joint_creditor_has:that.creditorForm.type,
                        joint_creditor_name:that.creditor,
                        joint_debtor_has:that.obligorForm.type,
                        joint_debtor_name:that.obligor,
                        effective_judgment_has:that.judgmentForm.type,
                        effective_judgment_number:that.judgmentbook,
                        effective_judgment_office:that.judgmentoffice,
                        effective_conciliation_statement_has:that.mediateForm.type,
                        effective_conciliation_statement_number:that.mediatebook,
                        effective_conciliation_statement_office:that.mediateoffice,

                        arbitration_award_force_has:that.arbitrationForm.type,
                        arbitration_award_force_number:that.arbitrationbook,
                        arbitration_award_force_office:that.arbitrationoffice,

                        notarization_has:that.fairForm.type,
                        notarization_number:that.fairbook,
                        notarization_office:that.fairoffice,
                        executive_program_is:that.implementForm.type,
                        executive_program_number:that.implementbook,
                        executive_program_court:that.implementoffice,
                        executive_program_person:that.implementperson,
                        executive_program_phone:that.implementphone,
                        executive_program_already_charge:that.implementmoney,
                        charge_allocation_name:that.data_bankruptcyname,
                        charge_allocation_bank_card_number:that.data_bankruptcyaccount,
                        charge_allocation_bank_name:that.data_bankruptcyback,
                        claims_nature:4,
                        fundamental_legal_relationship:that.lawSelected,
                        recourse:that.ClaimForm.type,
                        subrogation:that.subrogationForm.type,
                        property:that.expectForm.type,    //物权期待权
                        facts_and_reasons:that.fact,
                        estate_name:that.estate_name,
                        room_info:that.room_info,
                        room_type:that.room_type,
                        housing_contract_has:that.housing_contract_has,
                        housing_contract_number:that.housing_contract_number,
                        housing_contract_registration:that.housing_contract_registration,
                        other_supplemental_has:that.other_supplemental_has,
                        other_supplemental_name:that.other_supplemental_name,
                        mortgage:that.mortgage,
                        advance_notice_registration:that.advance_notice_registration,
                        is_stay_in:that.is_stay_in,
                        invoice_has:that.invoice_has,
                        loan_swap:that.loan_swap,
                        project_for_payment:that.project_for_payment,
                        room_area:that.room_area,
                        payment_condition:that.payment_condition,
                        payment_condition_money:that.payment_condition_money,
                        contract_due:that.contract_due,
                        owe_developer:that.owe_developer,
                    }).then(res=>{
                        if(res.code==0){
                            this.content=res.msg;
                        }else{
                            this.content=res.msg;
                            this.claims_id=res.data.claims_id;
                            this.seconddis=true;
                            if(type=='secondstep'){
                                that.secondstep()
                            }
                        }
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                    })
                }
                
            },
            backstepfirst:function(){
                this.currentstep=1;
            },
            backstepsecond:function(){
                this.currentstep=2;
            },
            secondstep:function(){
                setTimeout(() => {
                    this.currentstep=3;
                    ClaimsGetEvidenceList({    //获取第三步信息
                        token:localStorage.getItem('token'),
                        claims_id:this.claims_id
                    }).then(res=>{
                        if(res.code==1){
                            this.fulist=res.data;
                            if(res.data.length!==0){
                                this.evidenceid=res.data.length+1;
                            }
                        }else{
                            this.content=res.msg;
                            this.toastShow=true;
                            setTimeout(() => {
                                this.toastShow=false
                            }, 1500);
                        }
                    })
                }, 1500);
            },
            //债权申报第三步 上传文件
            //上传
            // uploadSectionFile(param){
            //     var fileObj = param.file;
            //     var form = new FormData();
            //     // 文件对象
            //     form.append("file", fileObj);
            //     this.$axios.post('/claims/upload',form).then(res => {
            //         param.onSuccess(res)
            //     }).catch(({err}) => {
            //         param.onError(err)
            //     })  
            // },
            uploadSectionFile(param) {
                let fileObj = param.file;
                let form = new FormData();
                form.append('file', fileObj);
                // this.$axios.post(`/base/upload`, form, {
                //     process: function (event) {
                //         param.file.percent = event.loaded / event.total * 100;
                //         param.onprogress(param.file);
                //     }
                // }).then(res => {
                //     this.uploadSuccess(res);
                // }).catch(res => {
                //     param.onError(res)
                // });
                var that=this;
                this.$axios.post('/base/upload',form).then(res => {
                    param.onSuccess(res)
                    // that.fileList.push({name:res.data.data.info.fileName,url:res.data.data.src});
                    // that.uploadSuccess(res);
                   
                }).catch(({err}) => {
                    param.onError(err)
                })  
            },
            //上传失败
            uploadFileError(err, file, fileList){
            },
            //上传成功
            uploadFileSuccess(response, file, fileList){
                file.response=response.data.data;
                this.fileList.push(file);
            },
            uploadSuccess(response) {
                if (response) {
                    this.$message({message: '文件上传成功', type: 'success'});
                    this.$emit('uploadSuccess', response);
                    
                }
            },
            //移除数据
            handleRemove(file, fileList) {
                this.fileList=fileList;
            },
            handlePreview(file) {
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            beforeUpload (file) {
                const isLt2M = file.size / 1024 / 1024 < 1
                if (!isLt2M) {
                    const fileItem = {}
                    this.$message.warning(`${file.name}大小超过1MB`)
                    fileItem.message = '[' + file.name + '] 大小超过1MB '
                    // fileItem.statusCode = 500
                    // this.fileList.unshift(fileItem)
                }
                return isLt2M
            },
            radioChangemethod:function(e){
                this.datashow=true;
                this.sureshow=true;
                this.upfilemethod.type=e;
				
			},
            adddata:function(){
				this.adddatashow=true;   
				this.disabled1=false;
			},
            upfilebtn:function(){
                this.dialogTableVisible=true;
                PcScanUpload({
                    evidenceid:this.evidenceid,
                    claimsid:this.claims_id
                }).then(res=>{
                    this.imgFile=res.data.path
                })
            },
           
            // changeupfile:function(id){
            //     this.upfilestatus=id;
          
            //     PcScanUpload({
            //         evidenceid:this.evidenceid,
            //         claimsid:this.claims_id

            //     }).then(res=>{
            //         this.imgFile=res
            //     })
            // },
            closeupfilelocal:function(){  //本地上传后确认按钮，关闭上传框，同时获取上传列表
                if(this.fileList.length==0){
                    this.evidencecontent="请上传证据";
                }else{
                    this.evidencecontent="";
                    for(var i=0;i<this.fileList.length;i++){
                        this.srcarr1.push(this.fileList[i].response.path)
                    }
                    var aa=this.srcarr1.join(',')
                    this.dialogTableVisible=false;
                    PcFilesUpload({
                        files:aa,
                        evidenceid:this.evidenceid,
                        claimsid:this.claims_id
                    }).then(res=>{
                        if(res.code==1){
                            PcFilesGet({
                                evidenceid:this.evidenceid,
                                claimsid:this.claims_id
                            }).then(res=>{
                                // this.srcarr1=[];
                                this.fileList=[];
                                this.evidenceList=res.data;
                                
                            })
                        }
                    
                    })
                }
                
            },
            closeupfile:function(){    //手机上传后确认按钮，关闭上传框，同时获取上传列表
                this.dialogTableVisible=false;
                PcFilesGet({
                    evidenceid:this.evidenceid,
                    claimsid:this.claims_id
                }).then(res=>{
                    this.evidenceList=res.data;
                    for(var i=0;i<this.evidenceList.length;i++){
                        this.srcarr1.push(this.evidenceList[i].path)
                    }
                })
            },
            changereadneed:function(){
                if(this.readneed==0){
                    this.readneed=1
                }else{
                    this.readneed==0
                }

            },
            evidencedetail:function(path,type){
                if(type=='img'){
                    this.imgbig=this.baseURL+path;
                    this.imgbigtype=true
                }else{
                    window.open(this.baseURL+path)
                }
            },
            closeimgbig:function(){
                this.imgbigtype=false
            },
            evidencecancel:function(path,type){
                for(var i=0;i<this.evidenceList.length;i++){
                    if(path==this.evidenceList[i].path){
                        this.evidenceList.splice(i,1)
                    }
                }
                var srcarr2=[];
                for(var i=0;i<this.evidenceList.length;i++){
                    srcarr2.push(this.evidenceList[i].path)
                }
                this.srcarr1=srcarr2;

            },
            delData:function(){    //删除数据
				var that=this;
				that.evidence_name='';
				that.evidence_aim='';
				that.evidence_num='';
				that.evidence_page='';
				that.evidence.type=0;
				that.srcarr1=[];
                that.evidenceList=[];
				that.imagestring='';
				that.adddatashow=false;
                if(that.evidenceid>1){
                    that.evidenceid--
                }
               
				// that.addbtnshow=true;
			},
            keepData:function(){    //保存数据
				var that=this;
                // if(that.evidence.type==0){
                //     // that.srcarr1=[];
                //     if(that.evidence_name==''||that.evidence_aim==''||that.evidence_num==''||that.evidence_page==''){
                //         that.content="请完整填写资料"
                //     }else{
                //         that.data()
                //     }
                // }else{
                    if(that.srcarr1.length==0){
                        that.content="请上传材料"
                    }else{
                        if(that.evidence_name==''||that.evidence_aim==''||that.evidence_num==''||that.evidence_page==''){
                            that.content="请完整填写资料"
                        }else{
                            that.keepdata()
                        }
                    }
                // }
                that.toastShow=true;
                setTimeout(() => {
                    that.toastShow=false;
                }, 3000);
			},
            
            keepdata:function(){
                var that=this;

                var fujian={
                    name:that.evidence_name,
                    purpose:that.evidence_aim,
                    number:that.evidence_num,
                    page:that.evidence_page,
                    has_original:that.evidence.type,
                }
                var aaarr=[];
                // var bbarr=that.srcarr1.join(',');
                if(that.srcarr1.length>0){
                    for(var i=0;i<that.srcarr1.length;i++){
                        aaarr.push({path:that.baseURL+that.srcarr1[i]})
                    }
                }
                fujian.file_input=aaarr;
                that.imagestring1=that.srcarr1.join(',');
                ClaimsSetEvidence({   //保存证据
                    token:localStorage.getItem('token'),
                    id:0,    //第一次为0
                    claims_id:that.claims_id,
                    name:that.evidence_name,
                    purpose:that.evidence_aim,
                    number:that.evidence_num,
                    page:that.evidence_page,
                    has_original:that.evidence.type,
                    file_input:that.imagestring1,
                    from:2
                }).then(res=>{
                    if(res.code==1){
                        that.disabled1=true;
                        // that.id1=res.data.id;
                        that.content=res.msg;
                        that.evidence_name='';
                        that.evidence_aim='';
                        that.evidence_num='';
                        that.evidence_page='';
                        that.evidence.type=0;
                        that.srcarr1=[];
                        that.evidenceList=[];
                        that.evidenceid++;
                        that.fileList=[];
                        that.imagestring='';
                        var fulistsort=[];
                        fulistsort.push(fujian);
                        var fulistsorts=[];
                        fulistsorts=fulistsort.concat(that.fulist)
                        that.fulist=fulistsorts;//.reverse()数组倒序
                        that.adddatashow=false;
                        
                //         // that.addbtnshow=true;
                    }
                })
                that.toastShow=true;
                setTimeout(() => {
                    that.toastShow=false;
                }, 3000);
                
            },
            Gomailpost:function(){   //邮件提交
                var that=this;
				if(that.sureshow==false){
                    if(that.readneed.type==0){
                        that.content="请阅读并勾选《上传债权申报材料告知书》"
                    }
                    if(that.affirms.type==0){
                        that.content="请先阅读并勾选《声明》"
                    }
                    that.toastShow=true;
                    setTimeout(() => {
                        that.toastShow=false
                    }, 1500);
					if(that.readneed.type==1&&that.affirms.type==1){  //勾选上下
                        that.content="因您选择通过发送邮件，请将证据材料压缩后注明与债权人名称一致的文件名，发送至管理人指定的电子邮箱，并及时告知管理人。您通过本系统确认提交的行为，只证明您完成了部分申报事项。如果您确认提交申报材料后但并未提交，管理人将对您的债权不予确认，请慎重选择后确认提交。"
                        that.toastShow=true;
                        setTimeout(() => {
                            that.toastShow=false
                            that.$router.push({path:'/Manage'})
                        }, 3000);
						
					}
                    
				}
            },
            Surepost:function(){   //确认提交
                var that=this;
				if(that.sureshow==true){
					if(that.fulist.length!==0){
                        if(that.readneed.type==0){
                            that.content="请阅读并勾选《上传债权申报材料告知书》"
                        }
                        if(that.affirms.type==0){
                            that.content="请先阅读并勾选《声明》"
                        }
                    
						if(that.readneed.type==1&&that.affirms.type==1){
                            ClaimsSubmit({
                                token:localStorage.getItem('token'),
                                claims_id:that.claims_id
                            }).then(res=>{
                                that.content="债权申报已完成,请等待管理人审查债权,请注意查收债权审查结果,请关注债权人会议召开日期"
                                setTimeout(() => {
                                    that.$router.push({path:'/Manage'})
                                },4000)

                            })
                        }
					}else{
						// that.content="请上传证据材料后，再确认提交，或者通过邮件提交。"
						that.content="请上传证据材料后，再确认提交。"
					}
					that.toastShow=true;
                    setTimeout(() => {
                        that.toastShow=false
                    }, 2000);
				}
            }

            
            
        }
    }
</script>
<style scoped>
.Network_box{
    background: #eef3fa;
    padding-bottom: 2rem;
    min-height: 100%;
    display: flex;
    width: 86.4%;
    margin: 0 auto;
    margin-top: 32px;
    justify-content: space-between;
}
.Network_tit{
    width: 52px;
    height: 138px;
    background: #43A0F5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-top: 82px;
}
.Debtor_box{
    width: 95%;
}
.Debtor_box .stepimg{
    margin: 0 auto;
    display: block;
    width: 748px;
    height: 66px;
    margin-top: 32px;
    margin-bottom: 29px;
}
.Debtor_box_mess{
    width: 94%;
    margin: 34px auto 0;
    padding: 20px ;
    background: #F5FAFE;
    border: 1px solid #E7EEF5;
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}
/* 基本信息 */
.Debtor_box_basic_mess{
    width: 35%;
    height: 6rem;
    background: #EEF3FA;
    border-radius: 10px;
    padding: 13px 24px;
}
.Debtor_box_basic_mess_tit{
    font-family: 'siyuanb';
    font-size: 18px;
    line-height: 24px;
}
.Debtor_box_basic_mess_name{
    font-size: 16px;
    color: #FF5C5C;
    margin: 16px auto 12px;
}
.Debtor_box_basic_mess_list{
    font-size: 16px;
    line-height: 30px;
}

/* 第一步 */
.Debtor_box_first{
    width: 50%;
    /* background: #EEF3FA; */
    border-radius: 0.2rem;
    padding: 13px 24px
}
.Debtor_box_first_select{
    border-bottom: 1px dashed rgb(138 138 138 / 30%);
    margin-top: 12px;
    padding-bottom: 19px;
    font-size: 16px;
    line-height: 24px;
}
.Debtor_box_first_select label{
    margin-left: 10px;
}
.Debtor_box_first_mess{
    padding: 20px 0;
    border-bottom: 1px dashed rgb(138 138 138 / 30%);
}
.Debtor_box_first_list{
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: flex-start;
}
.Debtor_box_first_list:last-child{
    margin-bottom: 20px;
}
.Debtor_box_first_list span{
    font-family: 'siyuanb';
    font-size: 14px;
    width: 30%;
    text-align: right;
}
.Debtor_box_first_list input{
    width: 372px;
    height: 40px;
    padding: 0 13px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
}
.checkbtn{
    width: 120px;
    height: 30px;
    background: #43A0F5;
    border-radius: 5px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    line-height: 30px;
    font-size: 12px;
    cursor: pointer;
}
.Debtor_box_first_btn{
    background: #43A0F5;
    border: 1px solid #E2E2E8;
    border-radius: 10px;
    margin: 0 auto;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    width: 45%;
}
.Debtor_box_first_other_con{
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 5px;
    margin: 20px auto;
    width: 70%;
    text-align: center;
}
.Debtor_box_first_other_con button:nth-child(1){
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    border: none;
    background: none;
    margin: 0 auto;
    display: block;
    cursor: pointer;
}
/* 第二步 */


.Debtor_box_second{
    width: 45%;
    /* background: #EEF3FA; */
    border-radius: 10px;
    padding: 13px 24px;
}
.Debtor_box_second_mess{
    padding: 19px 0 10px;
    border-bottom: 1px dashed rgb(138 138 138 / 30%);
}
.Debtor_box_second_mess_basic{
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 20px;
}
.Debtor_box_second_mess_basic img{
    width: 38px;
    height: 38px;
    margin-right: 24px;
    background: #B8DAE9;
    border-radius: 5px;
}
.Debtor_box_second_mess_basic_list{
    font-size: 14px;
    line-height: 30px;
}

.Debtor_box_second_book{
    margin-top: 10px;
    font-size: 14px;
}
.Debtor_box_second_book_notice{
    font-size: 16px;
    /* line-height: 30px; */
    color: #FF5C5C;
}
.Debtor_box_second_book_box{
    background: #FFFFFF;
    border: 0.7px solid #DFE5F9;
    box-sizing: border-box;
    border-radius: 12px;
    margin-top: 10px;
}
.Debtor_box_second_booklist{
    background: #FAFBFF;
    border-bottom: 0.7px solid #DFE5F9;
    box-sizing: border-box;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    padding-left: 20px;
    height: 40px;
}
.Debtor_box_second_booklist:last-child{
    border: none;
}
.Debtor_box_second_bookbtn{
    display: flex;
    width: 30%;
}
.Debtor_box_second_bookbtn div{
    font-size: 13px;
    margin-right: 30px;
    cursor: pointer;
}
.Debtor_box_second_bookbtn div:nth-child(1){
    color: #43A0F5;
}
.Debtor_box_second_bookbtn div:nth-child(2){
    color: #FE9B4B;
}
.Debtor_box_second_btn{
    margin-top: 26px;
}
.Debtor_box_second_btn .surebtn{
    width: 268px;
    height: 40px;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    margin: 20px auto;
    cursor: pointer;
}
.Debtor_box_second_btn .notice{
	font-size: 14px;
	color: #FD2001;
	line-height: 25px;
	margin-bottom: 10px;
}
.surebtn{
	width: 90%;
	height: 45px;
	 /* background: #0197F8; */
	margin: 0 auto;
	border-radius: 5px;
	text-align: center;
	color: #fff;
	line-height: 45px;
	margin-top: 20px;
	font-size:14px ;
}
/* 第三步 */
.Debtor_box_third{
    width: 45%;
    /* background: #EEF3FA; */
    border-radius:10px;
    padding: 13px 24px 28px;
}
.Debtor_box_third_tit{
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
}

.Debtor_box_third_tab{
    height: 30px;
    margin-top: 19px;
    font-size: 18px;
}
.Debtor_box_third_tab div{
    height: 30px;
    box-sizing: border-box;
    border-radius: 5px;
    line-height: 30px;
    padding: 0 15px;
    cursor: pointer;
}
.Debtor_box_third_tab_active{
    background: #43A0F5;
    color: #fff;
}
.Debtor_box_third_tab_common{
    background: #FFFFFF;
    border: 1px solid #E2E2E8;

}
.Debtor_box_third_basic_tit{
    margin-top: 20px;
    line-height: 30px;
    font-size: 18px;
}
.Debtor_box_third_basic_list{
    margin-top: 10px;
    position:relative
}
.Debtor_box_third_basic_list div{
    font-size: 16px;
    font-family: 'siyuanb';
    display: flex;
    align-items: center;
}
.Debtor_box_third_basic_list span:nth-child(1){
    color: #FF5C5C;
}
.Debtor_box_third_basic_list span:nth-child(2){
    font-size: 12px;
    color: #FF9F46;
    font-family: 'siyuanr';
    position: absolute;
    right: 25px;
    top: 0;
}
.Debtor_box_third_basic_list input{
    width: 100%;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 10px;
    outline: none;
    font-size: 16px;
    display: block;
}
.Debtor_box_third_basic_list select{
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 10px;
    padding-left: 13px;
    outline: none;
    line-height: 4rem;
    cursor: pointer;
    margin-right: 10px;
    display: block;
}
.Debtor_box_third_basic_btn{
    width: 100%;
    margin: 0 auto;
    margin-top: 27px;
}
.Debtor_box_third_basic_btn div{
    width: 30%;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
}
/* 第三步第二项 */
.Debtor_box_third_basic_shen{
    padding-bottom: 20px;
    border-bottom: 1px dashed rgb(138 138 138 / 30%);
}
.Debtor_box_third_radio_list{
    margin-top: 20px;
    display: flex;
}
.Debtor_box_third_radio_list div{
    font-size: 14px;
    font-family: 'siyuanb';
    width: 35%;
    text-align: right;
    margin-right: 37px;
}
.Debtor_box_third_radio_list div:nth-child(1){
    position: relative
}

.Debtor_box_third_radio_list label{
    font-family: 'siyuanr';
}
.Debtor_box_third_radio_list span{
    color: #FF5C5C;
}
.Debtor_box_third_radio_list span:nth-child(2){
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 12px;
    color: #FF9F46;
    font-family: 'siyuanr';
}
.Debtor_box_third_radio_list_min{
    margin-top: 20px;
}
.Debtor_box_third_radio_list_tit{
    font-size: 14px;
    font-family: 'siyuanb';
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.Debtor_box_third_radio_list_tit span{
    color:#FF5C5C ;
}
.Debtor_box_third_radio_min{
    margin-top: 14px;
}
.Debtor_box_third_radio_min input{
    font-size: 16px;
    width: 6rem;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 10px;
    display: block;
    padding: 0 10px;
    outline: none;
}
textarea{
    width: 85%;
    height: 160px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    outline: none;
    font-size: 16px;
}
/* 第三步第三项 */
.Debtor_box_third_upfile_tit{
    font-family: 'siyuanb';
    margin-top: 20px;
    font-size: 16px;
    margin-bottom: 2px; 
}
.Debtor_box_third_upfile_con{
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 25px;
}
.Debtor_box_third_upfile_con_tit{
    font-size: 18px;
    text-align: center;
}
.Debtor_box_third_upfile_cons{
    margin: 8px auto 12px;
    line-height: 21px;
    font-size: 16px;
}
.Debtor_box_third_upfile_radio{
    display: flex;
    /* align-items: center; */
    font-size: 18px;
}
.Debtor_box_third_upfile_radio label{
    margin: 0.3rem 0.5rem 0 0;
}
.Debtor_box_third_upfile_radio label div{
    
}
.radioline{
    padding: 15px 0;
    border-bottom: 1px dashed rgb(138 138 138 / 30%)
}
.Debtor_box_third_upfile_method{
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 30px;
}
.addfile{
    width: 120px;
    height: 30px;
    background: #43A0F5;
    border-radius: 5px;
    margin: 15px auto 10px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
}
.addfile_mode .long{
    width: 410px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 10px;
    padding-left: 13px;
    font-size: 14px;
    display: block;
}
.addfile_mode .short{
    width: 200px;
    height: 40px;
    left: 1050px;
    top: 1040px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 13px;
    font-size: 14px;
}
.Debtor_box_third_upfile_methodbtn{
    width: 90%;
    margin: 0 auto;
}
.Debtor_box_third_upfile_methodbtn div{
    width: 40%;
    height: 40px;
    border-radius:10px;
    color: #fff;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

/* 上传弹框 */
.upfile_tab{
    display: flex;
    justify-content: space-around;
    padding: 0 25%;
    line-height: 70px;
    font-size: 25px;
}
.upfile_tab div{
    cursor: pointer;
}
.upfile_con_up{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: space-around;
}
.upfile_con_up img{
    width: 300px;
    height: 100%;
}
.upfilebtnclass{
    width: 10%;
    background: red;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    margin: 0 auto;
    cursor: pointer;
}
.graybgcolor{
    background-color: #E2E2E8;
}

/* 证据 */
.evidenceimg{
    position: relative;
    width: 70px;
    height: 70px;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
}
.evidenceimg img{
    width: 60%;
    height: 100%;
    background-size: cover;
}
.evidenceimg div{
    cursor: pointer;
}
.evidencedocument{
    line-height: 20px;
    margin-top: 15px;

}
.evidencedocument div{
    cursor: pointer;
}
.fileimage{
    word-break: break-all;
}

/* 图片放大 */

.modal {
    margin:10% auto;
    width: 50%;
    z-index: 1;
}
 
.modal img {
    display: block;
    padding: 10px;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 2px 6px rgb(0, 0, 0, 0.2), 0 10px 20px rgb(0, 0, 0, 0.2);
    border-radius: 12px;
    /* border: 1px solid white; */
}
@keyframes zoom {
    from {transform: scale(0.1)}
    to {transform: scale(1)}
}
 
.modal img {
    animation-name: zoom;
    animation-duration: 0.6s;
}
.el-radio__label{
    font-size: 16px;
}
</style>