import { get, post } from './http'

//首页等信息
export const InformationList=p => get('information/lists',p);   //文章列表
export const personSms=p => get('base/sms_record',p);   //日常通知
export const MeetingBook=p => get('meeting/book',p);   //会议文件
export const DownloadCenter=p => get('base/down_center',p);   //下载模板
export const SystemDocuments=p => get('base/documents',p);   //文书
export const PersonInstitutionList=p => get('institution/lists',p);   //管理人名册列表
export const InformationSingle=p => get('information/single',p);   //公告详情
// export const InformationRelease=p => get('information/release',p);   //公告信息

export const InformationBanner=p => get('base/info',p);   //BANNER
export const InstitutionCheck=p => get('institution/check',p);   //管理人名册进入权限

//网上办公
export const OfficeCheck=p => get('office/check',p);   //检测是否可以进入
export const caseSelfList=p => get('office/cases',p);   //案件列表
export const PcCaseMember=p => get('office/users',p);   //案件相关左侧人员
export const PcCaseUserDialogue=p => get('office/dialogue',p);   //案件相关右侧内容
export const CaseSetContent=p => get('office/set_content',p);   //网上办公区域案件内容提交


//债权申报
export const ZhaiquanList =p => get('cases/all_list',p);   //获取债务人列表,首页轮播  (登录前)
export const PcCaseList =p => get('pc_case/list',p);   //获取债务人列表,筛选页面（登录前）
export const PcCaseSelfList =p => get('cases/self_list',p);   //获取债务人列表（登录后）  
export const CaseSingle =p => get('cases/single',p);   //破产案件信息  

// 取消接口
export const ClaimsGetCheck =p => get('claims/get_check',p);   //检测当前债权是否为第一次注册  
export const PcCaseSelect=p => get('pc_case/select',p);   //获取债务人列表筛选项
export const BookSingle=p => get('book/single',p);   //债权申报阅读文书
export const GetArea=p => get('get_area',p);   //获取省市区

//债权人
export const CreditorStatus =p => get('crew/creditor_status',p);   //获取债权人信息
export const CreditorCheck =p => get('crew/creditor_check',p);   //验证债权人信息 
export const CaseUserInfo =p => get('crew/user_info',p);   //债权人信息 
export const creditorSignStatus =p => get('crew/get_sign_status',p);   //获取文件签收状态
export const CreditorDoSign =p => get('crew/creditor_do_sign',p);   //签收文件
//代理人
export const AgentCheck=p => get('crew/agent_check',p);   //债权申报代理信息验证

//申报步骤
export const ClaimsGetUserInfo=p => get('claims/get_user_info',p);   //获取债权个人信息（第一步）
export const ClaimsSetUserInfo=p => get('claims/set_user_info',p);   //保存债权个人信息
export const ClaimsGetInfo=p => get('claims/get_info',p);   //获取其他信息（第二步）
export const ClaimsGetInfofdc=p => get('claims/get_info_fdc',p);   //获取其他信息（第二步）
export const ClaimsGetInfotf=p => get('claims/get_info_tf',p);   //获取其他信息（第二步）
export const ClaimsSetInfo=p => get('claims/set_info',p);   //保存其他信息（第二步）
export const ClaimsSetInfofdc=p => get('claims/set_info_fdc',p);   //保存其他信息（第二步）
export const ClaimsSetInfotf=p => get('claims/set_info_tf',p);   //保存其他信息（第二步）
export const ClaimsGetEvidenceList=p => get('claims/get_evidence_list',p);   //获取证据信息（第三步）
export const PcFilesUpload=p => post('base/h5_upload',p);   //pc本地上传
export const PcFilesGet=p => get('base/h5_get_files',p);   //pc获取上传文件
export const ClaimsSetEvidence=p => get('claims/set_evidence',p);   //保存证据信息（第三步）
export const ClaimsSubmit=p => get('claims/submit',p);   //提交
export const PcScanUpload=p => get('base/h5_qrcode',p);   //pc扫码上传二维码图片

// 会议，选举，表决
//会议
export const MeetingLists=p => get('meeting/lists',p);   //会议列表
export const MeetingSingle=p => get('meeting/single',p);   //会议详情

export const MeetingVoteList=p => get('meeting/vote_list',p);   //会议表决列表(主页右侧)
export const MeetingCheckVote=p => get('meeting/check_vote',p);   //检测会议是否能表决
export const MeetingElectionList=p => get('meeting/election_list',p);   //会议选举列表(主页右侧)
export const MeetingElectionSingle=p => get('meeting/election_single',p);   //会议检测选举个人
export const MeetingDoElection=p => get('meeting/do_election',p);   //提交会议候选人


export const TrtcSetMeetingPlay=p => get('trtc/set_meeting_play',p);   //开启直播接口
export const MeetingSignFile=p => get('meeting/sign_file',p);   //会议签收文件
export const MeetingCheckSign=p => get('meeting/check_sign',p);   //检测是否可以签到
export const MeetingDoSing=p => get('meeting/do_sign',p);   //会议签到

export const MeetingDoVote=p => get('meeting/do_vote',p);   //会议表决操作
export const MeetingStatistical=p => get('meeting/statistical',p);   //会议统计
export const MeetingGetChrList=p => get('meeting/get_chr_list',p);   //会议参会人列表


// export const ElectlectionMember=p => get('elect/election_member',p);   //选举人列表
//表决
export const VoteLists=p => get('ballot/lists',p);   //表决列表
export const BallotSingle=p => get('ballot/single',p);   //表决详情
export const VoteSignFile=p => get('ballot/sign_file',p);   //表决签收文书
export const VoteCheckSign=p => get('ballot/check_sign',p);   //表决检测是否可以签到（没有用到）
export const VoteDoSing=p => get('ballot/do_sign',p);   //表决会议签到（没有用到）
export const VoteVoteList=p => get('ballot/vote_list',p);   //表决列表
export const VoteCheckVote=p => get('ballot/check_vote',p);   //检测表决是否能表决
export const VoteDoVote=p => get('ballot/do_vote',p);   //表决表决操作
export const VoteStatistical=p => get('ballot/statistical',p);   //表决统计
export const VoteUserList=p => get('ballot/user_list',p);   //表决参会人列表

//选举
export const ElectLists=p => get('elect/lists',p);   //选举列表
export const ElectSingle=p => get('elect/single',p);   //选举详情
export const ElectSignFile=p => get('elect/sign_file',p);   //选举签收文件
export const ElectCheckSign=p => get('elect/check_sign',p);   //检测是否可以签到 (没有用到)
export const ElectDoSing=p => get('elect/do_sign',p);   //选举会议签到(没有用到)
export const ElecElectionContent=p => get('elect/election_content',p);   //选举种子
export const ElectElectionList=p => get('elect/election_list',p);   //检测选举列表
export const ElectElectionSingle=p => get('elect/election_single',p);   //检测选举列表-个人
export const ElectDoElection=p => get('elect/do_election',p);   //提交选举候选人
export const ElectStatistical=p => get('elect/statistical',p);   //选举统计
export const ElectUserList=p => get('elect/user_list',p);   //选举参会人列表


// export const MeetingUserList=p => get('meeting/user_list',p);   //参会人列表

//直播大讲堂
export const StudyList=p => get('study/lists',p);   //学习列表
export const StudySingle=p => get('study/single',p);   //学习详情
export const StudyGetSig=p => get('study/get_sig',p);   //获取sig
export const StudyGetRoomInfo=p => get('study/get_room_info',p);   //房间基本信息
export const StudyUserInfo=p => get('study/user_info',p);   //学习列表信息
export const StudyUrl=p => get('trtc/get_study_url',p);   //直播外部链接
export const StopPushStudy=p => get('trtc/stop_push_study',p);   //外接设备关闭流(新)
export const StudyEditStatus=p => get('/trtc/set_study_play',p);   //控制直播按钮


export const MeetingCheckIdentity=p => get('meeting/check_identity',p);   //其他会议验证身份

//债权管理
export const ClaimsGetManageList=p => get('claims/get_list',p);   //可管理的案件列表
export const ClaimsCheckRecord=p => get('claims/check_record',p);   //可管理的案件详情
export const ClaimsSign=p => get('claims/sign',p);   //债权案件签收
export const ClaimsObjection=p => get('claims/objection',p);   //上传异议申请表


//房间后的操作
export const MeetingGetVoteStatus=p => get('meeting/get_vote_status',p);   //表决是否显示
export const MeetingGetStatisticStatus=p => get('meeting/get_statistic_status',p);   //统计是否显示
export const MeetingGetParticipantStatus=p => get('meeting/get_participant_status',p);   //参会人是否显示
export const MeetingGetJintype=p => get('meeting/get_jintype',p);   //禁言操控
export const MeetingSetJintype=p => get('meeting/set_jintype',p);   //设置禁言状态
export const MeetingSetStatisticStatus=p => get('meeting/set_statistic_status',p);   //设置统计状态
export const MeetingSetVoteStatus=p => get('meeting/set_vote_status',p);   //设置表决状态
export const MeetingSetParticipantStatus=p => get('meeting/set_participant_status',p);   //设置参会人状态

export const MeetingGetOnlySig=p => get('meeting/get_sig',p);   //获取sig
export const MeetingGetRoomInfo=p => get('meeting/get_room_info',p);   //房间基本信息
export const MeetingStatus=p => get('meeting/status',p);   //会议状态：表决，统计，参会人，禁言等操作的get,set



export const MeetingDoPreVote=p => get('meeting/do_pre_vote',p);   //设置会议提前表决



//新加参会人
export const MeetingSetChr=p => get('meeting/set_chr',p);   //参会人列表设置



// 外接设备
export const MeetingUrl=p => get('trtc/get_meeting_url',p);   //外接设备获取播放流
export const StopPushMeeting=p => get('trtc/stop_push_meeting',p);   //外接设备关闭流(新)
export const PullStream=p => get('trtc/pull_stream',p);   //外接设备
export const TrtcGetRoomId=p => get('trtc/get_room_id',p);   //获取roomid

export const StopPush=p => get('stop_push',p);   //外接设备关闭流

 
export const MeetingGetPlayData=p => get('meeting/get_play_data',p);   //获取外接设备地址



//暂不需要
export const PersonIsShow=p => get('person/is_show',p);   //首页展示帮助
export const PersonGetHomePoint=p => get('person/get_home_point',p);   //首页红点展示
export const PersonSetHomePoint=p => get('person/set_home_point',p);   //首页设置红点
export const PersonReadSms=p => get('person/read_sms',p);   //日常通知红点

export const PersonJoinContent=p => get('person/join_content',p);   //行业内容
export const PersonDoJoin=p => get('person/do_join',p);   //加盟申请
export const PersonUseHelp=p => get('person/use_help',p);   //帮助操作
export const PersonApplySingle=p => get('person/apply_single',p);   //查看申请
export const PersonApplyCase=p => get('person/apply_case',p);   //帮助申请
export const PersonApplyAdd=p => get('person/apply_add',p);   //申请提交
export const PersonApplyList=p => get('person/apply_list',p);   //申请列表
export const PersonFeedback=p => get('person/feedback',p);   //提交反馈
export const PersonAq=p => get('person/aq',p);   //疑问
export const ClaimsManageOne=p => get('claims/manage_one',p);   //债权人信息
export const PersonGetPersonPoint=p => get('person/get_person_point',p);   //个人中心红点

//登录
export const PcScanRegister=p => post('pc_user/register',p);   //查看二维码状态
export const PcUserSms=p => post('pc_user/sms',p);   //验证码发送
export const PcBindPhone=p => get('pc_user/bind_phone',p);   //绑定手机号

export const PcLogin=p => post('pc_login',p);   //登录
export const RegisterReset=p => post('register/reset',p);   //忘记密码按钮
export const WeChatScan=p => get('we_chat/scan',p);   //获取二维码
export const PcSendCode=p => post('pc_send_code',p);   //忘记密码验证码发送
export const RegisterVerifyNewcode=p => get('register/verify_newcode',p);   //验证码验证
export const WechatSaomma=p => get('wechat_saoma',p);   //微信扫码验证
export const WechatLoginout=p => get('wechat_loginout',p);   //退出登录

//测试
export const UploadH5=p => get('upload_h5',p);   //退出登录


