<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header></Header>
        <div class="Download_box">
            <!-- <div class="Download_con"> -->
                <div class="Download_tab">
                    <div class="flexar" :class="currentdown==0?'Download_tab_active ':''" @click="mybook('0')">我<br>的<br>文<br>书</div>
                    <img src="../../image/dashed.png" alt="">
                    <div class="flexar" :class="currentdown==1?'Download_tab_active ':''" @click="mybook('1')">下<br>载<br>模<br>板</div>
                </div>
                <div style="width: 90%">
                    <div class="Download_tit">下载中心</div>
                    <div v-if="currentdown==0">
                        <div class="Download_content" v-for="(item,index) in documents" :key="index" v-if="documents.length!==0">
                            <div class="book_tit">{{item.title}}</div>
                            <!-- <div class="book_time">2021年06月04日   -    2021年07月10日 </div> -->
                            <div class="book_list flexbe" v-for="items in item.data" v-if="item.data.length!==0">
                                <span>{{items.title}}</span>
                                <div style="display: flex;">
                                    <div class="book_list_btn" @click="read(items.input[0].path)"  v-if="item.download==1">阅读</div>
                                    <div class="book_list_btn"  @click="download(items.input[0].path)" v-if="item.download==1">下载</div>
                                </div>
                            </div>
                            <div class="book_list" style="text-align: center;font-size: 16px;" v-if="item.data.length==0">暂无内容</div>
                        </div>
                        <!-- <div class="nocontent" >暂无内容</div> -->
                        <Nocontent v-if="documents.length==0" ></Nocontent>
                        
                    </div>
                    <div class="Download_content" v-if="currentdown==1">
                        <div class="book_list flexbe" v-for="item in minelists" v-if="minelists.length!==0">
                            <span>{{item.name}}</span>
                            <div style="display: flex;">
                                <div class="book_list_btn" @click="read(item.input[0].path)" >阅读</div>
                                <div class="book_list_btn"  @click="download(item.input[0].path)">下载</div>
                            </div>
                        </div>
                        <div class="book_list flexbe" v-for="item in publiclists" v-if="publiclists.length!==0">
                            <span>{{item.title}}</span>
                            <div style="display: flex;">
                                <div class="book_list_btn" @click="read(item.input[0].path)" >阅读</div>
                                <div class="book_list_btn"  @click="download(item.input[0].path)">下载</div>
                            </div>
                        </div>
                        <div class="book_list flexbe" v-for="item in meetinglists" v-if="meetinglists.length!==0">
                            <span>{{item.title}}</span>
                            <div style="display: flex;">
                                <div class="book_list_btn" @click="read(item.input[0].path)" >阅读</div>
                                <div class="book_list_btn"  @click="download(item.input[0].path)">下载</div>
                            </div>
                        </div>
                        <!-- <div class="nocontent">暂时没有下载列表哦~</div> -->
                        <Nocontent v-if="minelists.length==0&&publiclists.length==0&&meetinglists.length==0" ></Nocontent>
                        
                    </div>
                </div>
               
            <!-- </div> -->
        </div>
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'
    import Nocontent from '../../components/Nocontent.vue'
    import {DownloadCenter,SystemDocuments} from '@/request/api';

    export default {
        name: 'Download',
        components: {
            Header,
            Nocontent
        },
        data(){
            return{
                baseURL:'https://pc.pochanjie.com',

                currentdown:0,
                // 下载模板
                publiclists:[],
                meetinglists:[],
                minelists:[],
                documents:[],
                // meeting_file:[],
            }
        },
        created(){
            this.getbook()
            this.documentbook()
        },
        methods:{
            getbook:function(){
                DownloadCenter({
                    token:localStorage.getItem('token')
                }).then(res=>{
                    this.publiclists=res.data.public.data;
                    // this.meetinglists=res.data.file.meeting.data;
                    this.minelists=res.data.list;
                })
            },
            documentbook:function(){
                SystemDocuments({
                    token:localStorage.getItem('token')
                }).then(res=>{
                    this.documents=res.data.documents;
				    // this.meeting_file=res.data.meeting_file;
                })
            },
            mybook:function(num){
                this.currentdown=num
            },
            download:function(path){
                window.open(this.baseURL+path);

            },
            read:function(path){
                window.open(this.baseURL+path);
            }
        }
    }
</script>
<style scoped>
.Download_tit{
   font-family: 'siyuanb';
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom:30px ;
}
.Download_box{
    background: #eef3fa;
    padding-bottom: 1px;
    min-height: 100%;
    display: flex;
    width: 86.4%;
    margin: 0 auto;
    margin-top: 45px;
    justify-content: space-between;
}
/* 选项卡 */
.Download_con{
    position: relative;
}
.Download_tab{
    /* position: absolute;
    left: 13.4rem; */
}
.Download_tab div{
    width: 1.04rem;
    height: 2.76rem;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
}
.Download_tab .Download_tab_active{
    background: #43A0F5;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    color: #fff;
}
.Download_tab img{
    width: 4px;
    display: block;
    margin: 5px auto;
}
.Download_content{
    background: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    border: 1px solid #e7eef5;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
}
.book_tit{
    font-size: 18px;
    text-align: center;
    height: 49px;
    line-height: 49px;
    border-bottom: 1px solid #E7EEF5;
    color: red;
}
.book_time{
    font-size: 14px;
    /* line-height: 21px; */
    text-align: center;
    
    
    margin-top: 15px;
    margin-bottom: 26px;

}
.book_list{
    width: 95%;
    height: 46px;
    background: #EEF3FA;
    border-radius: 5px;
    margin: 0 auto;
    padding: 0 1.5%;
    margin: 10px;
    font-size: 14px;
    line-height: 46px;
    align-items: center;

}
.book_list:nth-child(1){
    margin-top: 20px;
}
.book_list_btn{
    width: 60px;
    height: 30px;
    border-radius: 5px;
    color: #fff;
    line-height: 30px;
    margin-right: 10px;
    cursor: pointer;
}
.book_list_btn:nth-child(1){
    background: #43A0F5;
}
.book_list_btn:nth-child(2){
    background: #FE9B4B;

}
</style>