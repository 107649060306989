<template>
    <div class="toast" >
        {{toastText}}
    </div>
        
</template>
 
<script>

    export default {
        name: 'Toast',
        data(){
            return{
                toastShow:true
            }
        },
        props: {
            toastText: String,
            required: true
        },
        created() {
            // var that=this;
            // setTimeout(function(){
            //     that.toastShow = false
            // }, 1500)
        },
        methods:{
            toast (str) {    //提示框
                let v = this
                v.toastText = str
                v.toastShow = true
                setTimeout(function(){
                v.toastShow = false
                }, 1500)
            },

        }
    }
</script>
 
<style scoped>


</style>