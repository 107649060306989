<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header></Header>
        <div class="Meet_box">
            <div class="Meet_box_inside">
                <div class="Meet_box_video">
                    <div class="Meet_box_name">{{major.theme}}</div>
                    <div style="line-height: 3rem;font-size: 16px; color: #999;text-align: center;">
                        {{major.start_time}}-{{major.end_time}}</div>
                    <div class="Meet_box_detail">
                        <!-- 主播 -->
                        <div style="background: #000;height: 100%;">
                            <div id='local_stream' class="local-stream" style="height: 100%;" :style="{display:localtype?'block':'none'}">
                            </div>
                        </div>
                        <!-- 主播未进入 -->
                        <div style="font-size: 16px;color: #fff;position: absolute;top: 0;left: 0;line-height: 100px;text-align: center;width: 100%;" v-if="roles!=='play'&&roomtype==false&&videostart==true">主播还未进房，请耐心等待...</div>
                        <!-- 主播退房 -->
                        <div class="iconfont icon-Frame-31" @click="leaveRoom" v-if="roles=='play'&&leavetype==true" style="position: absolute;right: 10px;top: 10px;color: #fff;font-size: 30px;cursor: pointer;"></div>
                        <!-- 观众4个 -->
                        <div class="audiencebox" :style="{bottom:roles=='aid'?'120px':'60px'}">
                            <div v-for="item in list" class="audiencebox_list">
                                <div :id="item.id" class="distant-stream" v-html="item.remoteStream"
                                    @click="getVideo(item.id)" style="height: 90%;"></div>
                            </div>
                            <div style="width: 40%;height: 100px;overflow: scroll;position: relative;">
                                <div ref="my_scroller" class="scrollclass" id="aaaa"> 
                                    <div class="infinite-list-item" v-for="(item,index) in huilist" :key="index"
                                        style="line-height: 20px;font-size: 12px;word-break: break-all;color: #fff;height:auto"
                                        >
                                        {{item.left}}:{{item.right}}
                                    </div>
                                </div>
                            </div>
                            <!-- <scroller style="width: 40%;height: 100px;" ref="my_scroller">
                                <div class="infinite-list-item" v-for="(item,index) in huilist" :key="index"
                                    style="line-height: 20px;font-size: 12px;margin: 5px 0;word-break: break-all;color: #fff;height:auto"
                                    >
                                    {{item.left}}:{{item.right}}
                                    </div>
                            </scroller> -->
                        </div>
                        <!-- 播放按钮 -->
                        <div class="iconfont icon-bofang" v-if="playbtn==true" @click="GoSign"
                            style="position: absolute;font-size: 100px;left: 45%;top: 40%;color:#fff;cursor: pointer;">
                        </div>
                        <!-- 普通操作按钮 -->
                        <div class="Meet_box_detail_btn" v-if="videostart==true&&leavetype==false" :style="{bottom:(roles=='aid'||roles=='play')?'70px':'10px'}">
                            <div class="btn_min" style="background: #43A0F5;" @click="biaojue"
                                v-if="vote_status=='bbb'&&roles!=='aid'"><span class="iconfont icon-Frame-19"></span>表决
                            </div>
                            <div class="btn_min" style="background: #43A0F5;" @click="biaojue" v-if="roles=='aid'"><span
                                    class="iconfont icon-Frame-19"></span>表决</div>
                            <div class="btn_min" style="background: #20CB8D;" @click="tongji"
                                v-if="statistic_status=='bbb'&&roles!=='aid'"><span
                                    class="iconfont icon-Frame-17"></span>统计</div>
                            <div class="btn_min" style="background: #20CB8D;" @click="tongji" v-if="roles=='aid'"><span
                                    class="iconfont icon-Frame-17"></span>统计</div>
                            <div class="btn_min" style="background: #FF896F;" @click="joinperson"
                                v-if="participant_status=='bbb'&&roles!=='aid'"><span
                                    class="iconfont icon-Frame-20"></span>参会人</div>
                            <div class="btn_min" style="background: #FF896F;" @click="joinperson" v-if="roles=='aid'">
                                <span class="iconfont icon-Frame-20"></span>参会人
                            </div>
                            <div class="btn_min" style="background: #F6C000;" @click="shangmai"
                                v-if="roles!=='manage'&&roles!=='aid'&&roles!=='play'"><span class="iconfont icon-Frame-16"></span>上麦
                            </div>
                            <div class="btn_min" style="background: #F75C45;" @click="maiguan"
                                v-if="roles=='manage'||roles=='aid'||roles=='play'"><span class="iconfont icon-Frame-16"></span>上麦管理
                            </div>
                            <div class="detail_input">
                                <input type="text" placeholder="请输入发送内容" v-model="huicon" @keyup.enter="sendcon">
                                <div class="sendbtn" @click="sendcon">发送</div>
                            </div>
                        </div>
                        <!-- 控制按钮 专属助手 -->
                        <div class="kongbtn" v-if="(roles=='aid'||roles=='play')&&videostart==true&&leavetype==true">
                            <div @click="jinyanbtn">
                                {{jintype=='aaa'?'开始禁言':'解除禁言'}}
                            </div>
                            <div @click="tongjibtn">
                                {{statistic_status=='bbb'?'显示统计':'隐藏统计'}}
                            </div>
                            <div @click="votebtn">
                                {{vote_status=='bbb'?'显示表决':'隐藏表决'}}
                            </div>
                            <div @click="joinbtn">
                                {{participant_status=='bbb'?'显示参会人':'隐藏参会人'}}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="Meet_box_mess">
                    <div class="Meet_box_mess_now">
                        <div>当前会议</div>
                        <div>{{major.theme}}</div>
                    </div>
                    <div class="Meet_box_mess_other">
                        <div>其他会议</div>
                        <div v-for="(items,index) in videolists" :key="index" v-if="videolists.length!==0">
                            <span @click="changevideo(items,index)" style="cursor: pointer"
                                :style="{color:index==current?'red':''}">{{items.theme}}</span>
                        </div>
                        <div v-if="videolists.length==0">暂无其他会议</div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="会议文书签收(请先签收文书再进行阅读)" :visible.sync="booksignvisible">
            <div class="dialog_name">{{major.theme}}</div>
            <div class="dialog_time">{{major.start_time}}-{{major.end_time}}</div>
            <div class="dialog_tit" v-if="major.file_status==1">阅读并签收以下文书</div>
            <div class="dialog_list" style="height: auto;" v-for="(item,file) in major.file_input" :key='file'
                v-if="major.file_input.length!==0">
                <div class="flexbe" style="height: 46px;padding-right: 10px;"><span>会议材料</span><span @click="pulldetail"
                        style="cursor: pointer;" class="iconfont icon-a-Frame2"></span></div>
                <div class="dialog_lists flexbe" v-if="pull==1">
                    <span>{{item.real_name}}</span>
                    <div class="dialog_list_btn">
                        <span @click="read(item.path,major.sign_file_status)">阅读</span>
                        <span @click="getsign(major,'file')"
                            v-if="roles=='member'">{{major.sign_file_status==0?'未签收':'已签收'}}</span>
                    </div>
                </div>
            </div>
            <div>
                <div class="dialog_list flexbe" v-for="(excelbook,excel) in major.excel_input"
                    v-if="major.excel_input.length!==0" :key="excel">
                    <span>债权表</span>
                    <div class="dialog_list_btn">
                        <span @click="read(excelbook.path,major.sign_excel_status)">阅读</span>
                        <span @click="getsign(major,'excel')"
                            v-if="roles=='member'">{{major.sign_excel_status==0?'未签收':'已签收'}}</span>
                    </div>
                </div>
            </div>
            <div>
                <div class="dialog_list flexbe" v-for="(noticebook,notice) in major.notice_input"
                    v-if="major.notice_input.length!==0" :key="notice">
                    <span>会议公告</span>
                    <div class="dialog_list_btn">
                        <span @click="read(noticebook.path,major.sign_notice_status)">阅读</span>
                        <span @click="getsign(major,'notice')"
                            v-if="roles=='member'">{{major.sign_notice_status==0?'未签收':'已签收'}}</span>
                    </div>
                </div>
            </div>
            <div>
                <div class="dialog_list flexbe" v-for="(majorbook,inform)  in major.inform_input"
                    v-if="major.inform_input.length!==0" :key="inform">
                    <span>会议通知</span>
                    <div class="dialog_list_btn">
                        <span @click="read(majorbook.path,major.sign_inform_status)">阅读</span>
                        <span @click="getsign(major,'inform')"
                            v-if="roles=='member'">{{major.sign_inform_status==0?'未签收':'已签收'}}</span>
                    </div>
                </div>
            </div>

            <div class="dialog_btn flexar">
                <div class="themebgcolor" @click="passageway(major)" v-if="roles=='member'&&major.pre_vote_status==1">
                    表决选举通道</div>
                <div class="orangebgcolor" @click="joinvideo(major)">我要参加会议</div>
            </div>
            <div style="color: red;text-align: center;">{{content}}</div>
        </el-dialog>
        <!-- 签到成功 -->
        <el-dialog title="签到成功" :visible.sync="signsuccessvisible">
            <div style="line-height: 20px;font-size: 16px;text-align: center;color: red;margin-top: 10px;">
                进入会议时请允许使用麦克风权限，否则无法正常参会！</div>
            <div class="qianbtn">
                <div @click="nojin" style="color: #43a0f5;border-color: #43a0f5;">暂不进入</div>
                <div @click="enter(major)" class="themebgcolor">进入会议</div>
            </div>
            <div style="color: red;text-align: center;line-height: 50px;font-size: 17px;">{{devicescontent}}</div>
        </el-dialog>
        <!-- 选择设备 -->
        <!-- <el-dialog width="30%" title="请选择您的设备" :visible.sync="deviceVisible">
            <div class="flexar devicebox">
                <div @click="deviceselect('local')" :style="{background:device=='local'?'orange':'',color:device=='local'?'#fff':'',borderColor:device=='local'?'orange':''}">本地设备</div>
                <div @click="deviceselect('outlocal')" :style="{background:device=='outlocal'?'orange':'',color:device=='outlocal'?'#fff':''}">外接设备</div>
            </div>
            <div style="color: red;text-align: center;line-height: 50px;font-size: 17px;">{{devicescontent}}</div>
        </el-dialog> -->
        <el-dialog width="30%" title="播放地址" :visible.sync="pusherVisible">
            <div style="color: red;text-align: center;line-height: 50px;font-size: 17px;">{{pusheraddress}}</div>

            <div class="flexar devicebox">
                <div @click="pusherplay('cancel')">取消</div>
                <!-- <div @click="pusherplay('sure')" :style="{background:device=='sure'?'orange':'',color:device=='sure'?'#fff':''}">复制</div> -->
                <button class="btns" :data-clipboard-text="pusheraddress" @click="copy">复制</button>
            </div>
        </el-dialog>
        
        <!-- 选举还是表决 -->
        <el-dialog title="选举表决通道" :visible.sync="voteelectvisible">
            <div class="qianbtn">
                <div @click="GoElect" class="themebgcolor">进入选举</div>
                <div @click="GoVote" class="themebgcolor">进入表决</div>
            </div>
        </el-dialog>
        <!-- 表决内容弹起 -->
        <el-dialog title="选择你对以下需要表决事项的真实意见" :visible.sync="biaotanvisible">
            <div class="biaocontent">{{biaotitle}} </div>
            <div class="biaolist" v-for="(people,index2) in memberbiao" :key='index2'>
                <div style="float: left;">
                    {{people.case_user_name}}
                </div>
                <div style="display: flex;justify-content: space-around;width: 50%;"
                    v-if="people.allow==1&&people.is_vote==0">
                    <div v-for="(agree,index1) in items1" :key="index1"
                        @click="agree(people.case_user_id,index2,index1)"
                        :style="{background:people.result==index1+1?'#0b9af9':'#ccc'}" class="btn">{{agree}}</div>
                </div>
                <div style="color: #999999;" v-if="people.allow==0">
                    暂时不允许表决
                </div>
                <div style="color: #999999;" v-if="people.allow==1&&people.is_vote==1">
                    表决结果：{{people.result==0?'未表决':people.result==1?'同意':'反对'}}
                </div>
                <div class="surebtn alipaybgcolor" @click="ti(people.result)" style="width: 20%;margin: 0;"
                    v-if="people.allow==1&&people.is_vote==0">
                    提交
                </div>
            </div>
            <el-dialog width="30%" title="请选择您的意见" :visible.sync="yiinnerVisible" append-to-body> </el-dialog>

        </el-dialog>
        <!-- 表决 -->
        <el-dialog title="请对以下表决事项进行操作" :visible.sync="biaojuevisible">
            <div class="biaojue" v-for="(item,index) in biaolist" :key="index" v-if="roles=='member'">
                <div class=""> {{item.label}}</div>
                <div class="biaolist" v-for="(people,index2) in item.biaopeople" :key='index2'>
                    <div style="float: left;">
                        {{people.case_user_name}}
                    </div>
                    <div style="display: flex;justify-content: space-around;width: 50%;"
                        v-if="people.allow==1&&people.is_vote==0">
                        <div @click="agreememberlist(people.case_user_id,index,index2,index1)"
                            v-for="(agree,index1) in items1" :key="index1"
                            :style="{background:people.result==index1+1?'#0b9af9':'#ccc'}" class="btn">{{agree}}</div>
                    </div>
                    <div style="color: #999999;" v-if="people.allow==0">
                        暂时不允许表决
                    </div>
                    <div style="color: #999999;" v-if="people.allow==1&&people.is_vote==1">
                        表决结果：{{people.result==0?'未表决':people.result==1?'同意':'反对'}}
                    </div>
                    <div class="surebtn themebgcolor" @click="tijiao(item.vote_id,people.result,index)"
                        style="width: 20%;margin: 0;" v-if="people.allow==1&&people.is_vote==0">
                        提交
                    </div>
                </div>
            </div>
            <div v-if="roles=='manage'||roles=='aid'||roles=='play'">
                <div class="biaolist" v-for="(items,indexs) in biaolist" :key="indexs">
                    <span>{{items.label}}</span>
                    <span @click="fabiao(items.vote_id,items.label)">发起表决</span>
                </div>
            </div>
            <el-dialog width="30%" title="已发起表决" :visible.sync="innerVisible" append-to-body> </el-dialog>
            <el-dialog width="30%" title="提示" :visible.sync="yijianinnerVisible" append-to-body> {{content}}</el-dialog>

        </el-dialog>

        <!-- 参会人 -->
        <el-dialog title="参加会议的债权人" :visible.sync="joinvisible">
            <div class="joinlist" v-for="(item,index) in joinlist" :key="index">
                <span>{{item.name}}</span>
                <span>{{item.sign_from==0?'代理人':'本人'}}</span>
            </div>
        </el-dialog>
        <!-- 统计 -->
        <el-dialog title="统计" :visible.sync="tongjivisible">
            <div class="tongqian" v-for="(item,index) in tongjidata" :key="index">
                <div style="background: #0B2F5C;color: #FFFFFF;padding: 0 40px;text-align: center;line-height: 35px;">
                    <!-- <text class="iconfont">&#xe612;</text> -->
                    {{item.name}}
                </div>
                <div style="padding: 0 30px;margin-top: 10px;">
                    <div class="tonglist" :style="{background: (indexs == 'yes' ||  indexs == 'agree') ? '#B0C7E6' :''}"
                        v-for="(items,indexs) in item.left.data" :key="indexs">
                        <span>{{items.name}}</span>
                        <span>{{items.number}}</span>
                        <span>{{items.percent}}%</span>
                    </div>
                    <div class="tonglist"
                        :style="{background: (indexss == 'yes' ||  indexss == 'agree') ? '#B0C7E6' :''}"
                        v-for="(itemss,indexss) in item.right.data" :key="indexss">
                        <span>{{itemss.name}}</span>
                        <span>{{itemss.number}}</span>
                        <span>{{itemss.percent}}%</span>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 上麦申请 -->
        <el-dialog title="上麦申请" :visible.sync="maivisible">
            <div class="maicon">申请成为嘉宾，和大家进行视频互动。</div>
            <div class="maicon">提交申请之后需等待审核通过。</div>
            <div class="twobtn">
                <div @click="cancelmai">取消</div>
                <div @click="suremai">发起申请</div>
            </div>
            <div style="color: red;text-align: center;line-height: 50px;font-size: 17px;">{{devicescontent}}</div>
        </el-dialog>
        <!-- 上麦管理 -->
        <el-dialog title="上麦管理" :visible.sync="maiguanvisible">
            <table class="maitable">
                <tr>
                    <td>身份</td>
                    <td>名称</td>
                    <td>操作</td>
                    <!-- colspan="2" -->
                </tr>
                <tr v-for="(item,index) in maiarr">
                    <td>{{item.roles=='member'?'债权人':item.roles=='work'?'法院工作人员':'其他'}}</td>
                    <td>{{item.nickname}}</td>
                    <td @click="maibtn(item)">{{item.status==1?'下麦':'上麦'}}</td>
                    <!-- <td>移除</td> -->
                </tr>
            </table>
        </el-dialog>

        <!-- 提示框 -->
        <Toast :toastText="content" v-show="toastShow"></Toast>
    </div>
</template>
<script src="http://apps.bdimg.com/libs/jquery/1.9.1/jquery.min.js"></script>
<!-- <script src="https://web.sdk.qcloud.com/player/tcplayerlite/release/v2.4.1/TcPlayer-2.4.1.js" charset="utf-8"></script> -->
<!-- <script src="https://cloudcache.tencent-cloud.com/open/qcloud/video/vcplayer/TcPlayer-2.3.2.js" charset="utf-8"></script> -->
<script>
    import Header from '../../components/Header.vue'
    import Toast from '../../components/Toast.vue'
    // import VideoPlayer from '../../components/VideoPlayer.vue'


    import {
        MeetingLists,MeetingSingle, MeetingSignFile, MeetingCheckSign, MeetingDoSing, ElectDoSing, VoteDoSing, MeetingUserList, MeetingGetVoteStatus, MeetingGetStatisticStatus, MeetingGetParticipantStatus,
        MeetingCheckVote, MeetingVoteList, MeetingDoVote, MeetingStatistical, MeetingGetJintype, MeetingSetJintype, MeetingSetStatisticStatus, MeetingSetVoteStatus, MeetingSetParticipantStatus,
        MeetingUrl,PullStream,MeetingGetSig,StopPush,MeetingSetPlayData,MeetingGetPlayData} from '@/request/api';

    import LibGenerateTestUserSig from '../../../public/lib-generate-test-usersig.min.js'

    //导入sdk
    import TRTC from "trtc-js-sdk";
    import TIM from "tim-js-sdk";
    export default {
        name: 'Meet',
        components: {
            Header,
            Toast,
            // VideoPlayer
        },
        data() {
            return {
                videoUrl: 'rtmp://117.132.5.213:1935/live/openUrl/WFZqpGg',
                booksignvisible: false,
                signsuccessvisible: false,
                deviceVisible:false,
                roomtype:false, //主播是否进入房间
                device:'',
                videodetail:{},
                pull: 0,
                nowvideoname: '',

                //视频
                // userId: 'user_' + parseInt(Math.random() * 100000000),//用户id --可更改 
                userId: localStorage.getItem('uid'),
                // userId: 'video1539',
                // roomId: 888888,//房间号--加入相同房间才能聊
                client: '',//客户端服务
                list: [], // 远端流列表
                remoteStream:"",
                remoteStreamobj:{},
                isVedio: true, // 当前本地是否打开摄像头
                isAudio: true, // 当前本地是否打开麦克风
                members_: new Map(), // 成员
                isHave: false, //当前列表是否有该成员
                localStream: '',//本地流
                anchor_id:'',
                // tim
                timInst: '',
                timChatGroupId: '',
                timRoomType: TIM.TYPES.GRP_CHATROOM, // 最新版的API中没有该类型，但为了兼容小程序端，才使用这个和小程序保持一致
                playbtn: true,
                videostart: false,  //开始会议
                leavetype:false,   //退出状态
                //会议
                videolists: [],
                otherlist: [],
                roles: '',
                show_button: 0,
                major: [],
                current: 0,
                voteelectvisible: false,
                meeting_id: '',
                // 参会人
                participant_status: 'aaa',
                joinlist: [],
                joinvisible: false,
                //统计
                statistic_status: 'aaa',
                tongjivisible: false,
                election_status: '',
                election_data: '',
                tongjidata: '',
                theme: '',

                //表决
                vote_status: 'aaa',
                biaolist: [],
                biaojuevisible: false,
                innerVisible: false,
                yiinnerVisible:false,   //表决内部弹出
                yijianinnerVisible:false,    
                items1: ["同意", "反对"],
                biaocurrent: -1,
                case_user_id: '',
                biaotan: false,
                biaotanvisible: false,
                memberbiao: [],
                result: '',
                biaotitle: '',
                // 上麦
                maivisible: false,
                mailist: {},

                //上麦管理
                maiguanvisible: false,
                maiarr: [],

                //禁言
                jintype: 'aaa',

                // 聊天
                huilist: [],
                huicon: '',
                scrollTop: 0,
                count: 5,

                //播放弹框
                pusherVisible:false,
                pusheraddress:'',
                playLinkAddr:'',
                itemid:'',
                requestId:'',

                content: '',
                toastShow: false,

                clientRole: 'audience',
                microphonestype:false,
                camerastype:false,
                devicescontent:'',
                play_schema:0,
                host_id:0,
                localtype:false,
            }
        },
        created() {
            // onCameraDidReady();
            TRTC.getCameras().then(devices => {   //摄像头
                if(devices.length>0){   //没有摄像头
                    this.camerastype=true
                }
            })
            TRTC.getMicrophones().then(devices => {   //麦克风
                if(devices.length>0){
                    this.microphonestype=true

                }
            })
        },
        mounted() {
            this.videolist();
            
        },
        destroyed() {
            this.leaveRoom();
            this.logoutIm();
        },
        methods: {
            // scroll: function(e) {
			// 	this.scrollTop = e.detail.scrollTop
			// },
            // 聊天回复
            sendcon: function () {
                if (this.huicon == '') {
                    this.content = "发送内容为空";
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
                } else {
                    var roles = '';
                    if (this.roles == 'member') {
                        roles = "债权人"
                    } else if (this.roles == 'manage') {
                        roles = "主持"
                    } else if (this.roles == 'play') {
                        roles = "主播"
                    } else if (this.roles == 'work') {
                        roles = "法院工作人员"
                    } else {
                        roles = "助手"
                    }

                    if (this.roles !== 'aid' && this.jintype == 'bbb') {
                        this.content = "正在禁言中"
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false
                        }, 1500);
                    } else {
                        var that = this;
                        let message = this.timInst.createCustomMessage({
                            to: that.timChatGroupId,
                            conversationType: TIM.TYPES.CONV_GROUP,
                            payload: {
                                data: '聊天',
                                description: this.huicon,
                                extension: localStorage.getItem('nickname') + '(' + roles + ')'
                            },
                        });
                        this.timInst.sendMessage(message).then(function (res) {
                        }).catch(function (err) {
                            console.warn('聊天失败:', err);
                        });
                        this.huilist.push({ left: localStorage.getItem('nickname') + '(' + roles + ')', right: this.huicon })
                        this.huicon = '';
                        setTimeout(function() {
                            document.getElementById("aaaa").scrollIntoView(false);
                        },10)
                    }
                }
            },
            getList: function () {
                if (this.count >= 10) {
                    this.content = "加载完成";
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
                };
                this.getData();
            },
            getData() {
                this.list.push(this.count);
                this.count++;
            },
            // 禁言操作
            jinyanbtn: function () {
                var that = this;
                this.jintype = this.jintype == 'aaa' ? 'bbb' : 'aaa';
                let message = this.timInst.createCustomMessage({
                    to: that.timChatGroupId,
                    conversationType: TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: '禁言',
                        description: this.jintype,
                        extension: this.jintype
                    },
                });
                this.timInst.sendMessage(message).then(function (res) {
                }).catch(function (err) {
                    console.warn('禁言操作失败', err);
                });
                let message2 = this.timInst.createCustomMessage({
                    to: that.timChatGroupId,
                    conversationType: TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: '聊天',
                        description: this.jintype == 'aaa' ? '解除禁言' : '开始禁言',
                        extension: localStorage.getItem('nickname') + '(助手)'
                    },
                });
                this.timInst.sendMessage(message2).then(function (res) {
                }).catch(function (err) {
                    console.warn('聊天失败', err);
                });
                MeetingSetJintype({
                    meeting_id: this.meeting_id,
                    jintype: this.jintype
                }).then(res => {

                })
            },
            // 统计操作
            tongjibtn: function () {    //统计控制
                var that = this;
                this.statistic_status = this.statistic_status == 'aaa' ? 'bbb' : 'aaa';
                let message = this.timInst.createCustomMessage({
                    to: that.timChatGroupId,
                    conversationType: TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: '统计',
                        description: this.statistic_status,
                        extension: this.statistic_status
                    },
                });
                this.timInst.sendMessage(message).then(function (res) {
                }).catch(function (err) {
                    console.warn('统计操作失败', err);
                });
                let message2 = this.timInst.createCustomMessage({
                    to: that.timChatGroupId,
                    conversationType: TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: '聊天', // 用于标识该消息是骰子类型消息
                        description: this.statistic_status == 'aaa' ? '隐藏统计' : '显示统计',
                        extension: localStorage.getItem('nickname') + '(助手)'
                    },
                });
                this.timInst.sendMessage(message2).then(function (res) {
                }).catch(function (err) {
                    console.warn('聊天失败', err);
                });
                MeetingSetStatisticStatus({
                    meeting_id: this.meeting_id,
                    statistic_status: this.statistic_status
                }).then({

                })
            },
            // 表决操作
            votebtn: function () {
                var that = this;
                this.vote_status = this.vote_status == 'aaa' ? 'bbb' : 'aaa';
                let message = this.timInst.createCustomMessage({
                    to: that.timChatGroupId,
                    conversationType: TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: '显示表决',
                        description: this.vote_status,
                        extension: this.vote_status
                    },
                });
                this.timInst.sendMessage(message).then(function (res) {
                }).catch(function (err) {
                    console.warn('表决操作失败', err);
                });
                let message2 = this.timInst.createCustomMessage({
                    to: that.timChatGroupId,
                    conversationType: TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: '聊天', // 用于标识该消息是骰子类型消息
                        description: this.vote_status == 'aaa' ? '隐藏表决' : '显示表决',
                        extension: localStorage.getItem('nickname') + '(助手)'
                    },
                });
                this.timInst.sendMessage(message2).then(function (res) {
                }).catch(function (err) {
                    console.warn('聊天失败', err);
                });
                MeetingSetVoteStatus({
                    meeting_id: this.meeting_id,
                    vote_status: this.vote_status
                }).then(res => {

                })
            },
            // 参会人操作
            joinbtn: function () {
                var that = this;
                this.participant_status = this.participant_status == 'aaa' ? 'bbb' : 'aaa';
                let message = this.timInst.createCustomMessage({
                    to: that.timChatGroupId,
                    conversationType: TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: '参会人',
                        description: this.participant_status,
                        extension: this.participant_status
                    },
                });
                this.timInst.sendMessage(message).then(function (res) {
                }).catch(function (err) {
                    console.warn('参会人操作失败', err);
                });
                let message2 = this.timInst.createCustomMessage({
                    to: that.timChatGroupId,
                    conversationType: TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: '聊天', // 用于标识该消息是骰子类型消息
                        description: this.participant_status == 'aaa' ? '隐藏参会人' : '显示参会人',
                        extension: localStorage.getItem('nickname') + '(助手)'
                    },
                });
                this.timInst.sendMessage(message2).then(function (res) {
                }).catch(function (err) {
                    console.warn('聊天失败', err);
                });
                MeetingSetParticipantStatus({
                    meeting_id: this.meeting_id,
                    participant_status: this.participant_status
                }).then({

                })

            },
            //上麦管理
            maiguan: function () {
                this.maiguanvisible = true;
            },
            maibtn: function (item) {
                var that = this;

                if (item.status == 0) {
                    var statuslength = 0
                    for (var i = 0; i < this.maiarr.length; i++) {
                        if (this.maiarr[i].status == 1) {
                            statuslength++
                        }
                    }
                    if (statuslength >= 4) {
                        this.content = "上麦人数已达上限";
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false
                        }, 1500);
                        return
                    } else {   //同意上麦操作
                        let message = this.timInst.createCustomMessage({
                            to: that.timChatGroupId,
                            conversationType: TIM.TYPES.CONV_GROUP,
                            payload: {
                                data: 'agree_wheat', // 用于标识该消息是骰子类型消息
                                description: item.userID, // 获取骰子点数
                                extension: item.nickname
                            },
                        });
                        this.timInst.sendMessage(message).then(function (res) {
                        }).catch(function (err) {
                            console.warn('同意上麦失败', err);
                        });
                    }
                    this.mailist[item.userID].status = 1;
                } else {   //下麦操作
                    let message = this.timInst.createCustomMessage({
                        to: that.timChatGroupId,
                        conversationType: TIM.TYPES.CONV_GROUP,
                        payload: {
                            data: 'delete_wheat', // 用于标识该消息是骰子类型消息
                            description: item.userID, // 获取骰子点数
                            extension: item.nickname
                        },
                    });
                    this.timInst.sendMessage(message).then(function (res) {
                    }).catch(function (err) {
                        console.warn('强制下麦失败', err);
                    });
                    this.mailist[item.userID].status = 0;
                }

                var arr = []
                for (let i in this.mailist) {
                    arr.push(this.mailist[i]); //属性

                }
                this.maiarr = [];
                this.maiarr = arr
            },
            // 上麦
            shangmai: function () {
                this.maivisible = true;
            },
            cancelmai: function () {
                this.maivisible = false
            },
            suremai: function () {
                //上麦申请
                // 发送文本消息，Web 端与小程序端相同
                // 1. 创建消息实例，接口返回的实例可以上屏
                // let onSdkReady = function(event) {
                if(this.camerastype==false||this.microphonestype==false){
                    this.devicescontent="麦克风或摄像头设备没有连接,连接后请重新进入会议"
                }else{
                    var that = this;
                    let message = this.timInst.createCustomMessage({
                        to: that.timChatGroupId,
                        conversationType: TIM.TYPES.CONV_GROUP,
                        payload: {
                            data: 'apply_wheat', // 用于标识该消息是骰子类型消息
                            description: this.roles, // 获取骰子点数
                            extension: localStorage.getItem('nickname')
                        },
                    });
                    this.timInst.sendMessage(message).then(function (res) {
                        that.maivisible = false;
                    }).catch(function (err) {
                        console.warn('申请上麦失败', err);
                    });
                }
                
                // };
                // this.timInst.on(TIM.EVENT.SDK_READY, onSdkReady);




            },
            // 参会人
            joinperson: function () {
                this.joinvisible = true;
                MeetingUserList({
                    meeting_id: this.meeting_id
                }).then(res => {
                    this.joinlist = res.data.list
                })
            },
            // 统计
            tongji: function () {
                this.tongjivisible = true;
                MeetingStatistical({
                    token:localStorage.getItem('token'),
                    meeting_id: this.meeting_id
                }).then(res => {
                    this.election_status = res.data.election_status;
                    this.election_data = res.data.election_data;
                    this.tongjidata = res.data.data;
                })
            },
            // 表决
            biaojue: function () {
                this.biaojuevisible = true;
                MeetingVoteList({
                    meeting_id: this.meeting_id,
                    case_id: this.case_id
                }).then(res => {
                    var that = this;
                    // that.election_content=res.data.election_content;
                    // that.election_status=res.data.election_status;
                    that.biaolist = res.data.list;
                    for (var i = 0; i < that.biaolist.length; i++) {
                        MeetingCheckVote({
                            meeting_id: that.meeting_id,
                            case_id: that.case_id,
                            vote_id: that.biaolist[i].vote_id,
                            i: i
                        }).then(res => {
                            if (res.data.check == 1) {
                                that.biaolist[res.data.i]['biaopeople'] = res.data.list;
                                var tmp = that.biaolist;
                                that.biaolist = [];
                                that.biaolist = tmp;
                            }
                            // else{
                            //     that.content=res.data.message;
                            //     that.toastShow=true;
                            //     setTimeout(() => {
                            //         that.toastShow=false
                            //     }, 1500);
                            // }
                        })

                    }
                })
            },
            agreememberlist: function (id, index, index2, index1) {
                this.case_user_id = id;
                this.biaolist[index].biaopeople[index2].result = index1 + 1;

                var aa = this.biaolist;
                this.biaolist = [];
                this.biaolist = aa;
            },
            tijiao: function (id, result, index) {
                var that = this;
                if (result == 0) {
                    that.yijianinnerVisible=true;
                    that.content="请选择您的意见"
                } else {
                    MeetingDoVote({
                        meeting_id: that.meeting_id,
                        case_user_id: that.case_user_id,
                        vote_id: id,
                        result: result   //1同意2不同意0啥也没选
                    }).then(res => {
                        if (res.data.check == 1) {
                            that.yijianinnerVisible=true;
                            that.content = res.data.message
                            MeetingCheckVote({
                                meeting_id: that.meeting_id,
                                case_id: that.case_id,
                                vote_id: id,
                                i: index
                            }).then(res => {
                                if (res.data.check == 1) {
                                    that.biaolist[res.data.i]['biaopeople'] = res.data.list;
                                    var aa = that.biaolist;
                                    that.biaolist = [];
                                    that.biaolist = aa;
                                }
                            })
                        } else {
                            that.yijianinnerVisible=true;
                            that.content = res.data.message
                        }
                    })
                }
                that.toastShow = true;
                setTimeout(() => {
                    that.toastShow = false
                }, 1500);
            },
            // 表决内容弹起
            agree: function (case_user_id, p_index, r_index) {
                this.memberbiao[p_index].result = r_index + 1;
                this.result = r_index + 1;
                this.case_user_id = case_user_id;
                var tmp = this.memberbiao;
                this.memberbiao = [];
                this.memberbiao = tmp;
            },
            ti: function (result) {     //债权人提交表决
                var that = this;
                if (result == 0) {
                    that.yiinnerVisible=true;
                } else {
                    MeetingDoVote({
                        meeting_id: that.meeting_id,
                        case_user_id: that.case_user_id,
                        vote_id: that.vote_id,
                        result: result   //1同意2不同意0啥也没选
                    }).then(res => {
                        that.biaotanvisible = false;
                        MeetingCheckVote({
                            meeting_id: that.meeting_id,
                            case_id: that.case_id,
                            vote_id: that.vote_id
                        }).then(res => {
                            if (res.data.check == 1) {
                                that.memberbiao = res.data.list;
                            }
                        })
                    })
                }

            },
            fabiao: function (id, label) {
                var that = this;
                let message = this.timInst.createCustomMessage({
                    to: that.timChatGroupId,
                    conversationType: TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: '表决', // 用于标识该消息是骰子类型消息
                        description: id + '',
                        extension: label
                    },
                });
                var that = this;
                this.timInst.sendMessage(message).then(function (res) {
                    that.innerVisible = true;
                }).catch(function (err) {
                    console.warn('发起表决失败', err);
                });
            },
            // 视屏
            //语音互动直播(VoiceChatRoom) 视频互动直播(Live) 语音通话(VoiceCall) 视频通话(VideoCall)
            //第一步：快速跑通demo,创建应用，获取SDKAPPID，SECRETKEY
            //SDKAppID（应用标识/应用 ID）是腾讯云后台用来区分不同 TRTC 应用的唯一标识
            //UserID（用户标识）用于在一个 TRTC 应用中唯一标识一个用户。
            //集成：npm install trtc-js-sdk --save
            //第二步：创建链接  创建 Client 对象

            createClient(userId) {
                const config = this.genTestUserSig(userId)
                const sdkAppId = config.sdkAppId
                const userSig = config.userSig   //获取签名
                this.client = TRTC.createClient({
                    mode: 'live',
                    sdkAppId,
                    userId,
                    userSig
                });
                //注册远程监听，要放在加入房间前--这里用了发布订阅模式
                this.subscribeStream(this.client)
                this.checksystem();
                this.playStream(this.client)
            },
            initIM: function () {
                let options = {
                    SDKAppID: 1400395034
                };
                this.timInst = TIM.create(options); //创建 IM SDK 实例

                // 设置 SDK 日志输出级别，
                this.timInst.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
                // 监听事件，例如：
                this.timInst.on(TIM.EVENT.SDK_READY, function (event) {
                    // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
                    // event.name - TIM.EVENT.SDK_READY
                });
                this.timInst.on(TIM.EVENT.SDK_READY, this.onSdkReady, this);
                this.loginIm()
                var that = this;
                let onMessageReceived = function (event) {
                    for (var i = 0; i < event.data.length; i++) {
                        if (event.data[i].payload.data == 'apply_wheat') {
                            if (typeof (that.mailist[event.data[i].from]) == 'undefined') {
                                that.mailist[event.data[i].from] = {
                                    userID: event.data[i].from,
                                    status: 0,
                                    roles: event.data[i].payload.description,
                                    nickname: event.data[i].payload.extension
                                }
                                if (that.roles == 'aid') {
                                    that.content = event.data[i].extension + "申请上麦"
                                }
                                var arr = []
                                for (let i in that.mailist) {
                                    arr.push(that.mailist[i]); //属性

                                }
                                that.maiarr = arr
                            } else {
                                that.mailist[event.data[i].from].roles = event.data[i].payload.description;
                                that.mailist[event.data[i].from].nickname = event.data[i].payload.extension;
                                var arr = []
                                for (let i in that.mailist) {
                                    arr.push(that.mailist[i]); //属性

                                }
                                that.maiarr = arr
                            }

                        }

                        if (event.data[i].payload.data == "agree_wheat") {
                            if (event.data[i].payload.description == that.client.userId_) {   //播放流
                                var roomId = that.meeting_id;
                                that.client.join({ roomId, role: 'audience' }).catch(error => {
                                }).then(() => {
                                    localStorage.setItem('anchor_id', event.data[i].payload.description);
                                    that.createStream(that.userId)//创建本地流

                                    // that.playStream(that.client) //播放远端流
                                });
                            }
                            that.mailist[event.data[i].payload.description].status = 1;
                            var arr = []
                            for (let i in that.mailist) {
                                arr.push(that.mailist[i]); //属性
                            }
                            that.maiarr = [];
                            that.maiarr = arr
                        }
                        if (event.data[i].payload.data == "delete_wheat") {
                            if (event.data[i].payload.description == that.client.userId_) {
                                that.client.switchRole('audience').catch(error => {
                                }).then(() => {
                                    for (var i = 0; i < that.list.length; i++) {
                                        if (that.list[i].userId == that.client.userId_) {
                                            that.list.splice(i, 1)
                                        }
                                    }
                                });
                            }
                            that.mailist[that.client.userId_].status = 0;
                            var arr = []
                            for (let i in that.mailist) {
                                arr.push(that.mailist[i]); //属性

                            }
                            that.maiarr = [];
                            that.maiarr = arr
                        }
                        if (event.data[i].payload.data == "表决") {
                            if (that.roles == 'member') {
                                that.biaotanvisible = true;
                                that.biaotitle = event.data[i].payload.extension
                                that.vote_id = event.data[i].payload.description
                                that.result = 0;
                                MeetingCheckVote({
                                    meeting_id: that.meeting_id,
                                    case_id: that.case_id,
                                    vote_id: that.vote_id
                                }).then(res => {
                                    if (res.data.check == 1) {
                                        that.memberbiao = res.data.list;
                                    }
                                })
                            }
                        }
                        if (event.data[i].payload.data == '禁言') {
                            that.jintype = event.data[i].payload.description;
                            var message = that.jintype == 'aaa' ? '解除禁言' : '开始禁言';
                            that.content = message;
                            that.toastShow = true;
                            setTimeout(() => {
                                that.toastShow = false
                            }, 1500);

                        }
                        if (event.data[i].payload.data == '统计') {
                            that.statistic_status = event.data[i].payload.description;
                        }
                        if (event.data[i].payload.data == '显示表决') {
                            that.vote_status = event.data[i].payload.description;
                        }
                        if (event.data[i].payload.data == '参会人') {
                            that.participant_status = event.data[i].payload.description;
                        }
                        if (event.data[i].payload.data == '聊天') {
                            that.huilist.push({ left: event.data[i].payload.extension, right: event.data[i].payload.description })
                            setTimeout(function() {
                                document.getElementById("aaaa").scrollIntoView(false);
                            },10)
                        }
                        if (event.data[i].payload.data == '主播进房') {
                            // that.anchor_id = event.data[i].payload.description;
	                        localStorage.setItem('anchor_id', event.data[i].payload.description);
                            that.huilist.push({ left: '主播', right: '主播进房'})
                            setTimeout(function() {
                                document.getElementById("aaaa").scrollIntoView(false);
                            },10)
                        }
                        if (event.data[i].payload.data == '主播退房') {
                            that.playbtn=true;
                            that.localtype=false;
                            that.huilist.push({ left: event.data[i].payload.description, right: event.data[i].payload.extension })
                            setTimeout(function() {
                                document.getElementById("aaaa").scrollIntoView(false);
                            },10)
                            that.client.unsubscribe(this.remoteStreamobj);
                            // this.remoteStreamobj.close()
                            $("#local_stream").children().remove();

                        }

                        
                    }
                    // event.data - 存储 Message 对象的数组 - [Message]
                };
                that.timInst.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
            },
            loginIm: function () {
                let userID = this.userId, userSig = '';
                if (this.timInst) {
                    let sigResult = this.genTestTimSig(userID);
                    userSig = sigResult.userSig;
                    let options = { userID: userID, userSig: userSig };
                    let promise = this.timInst.login(options);
                    promise.then((res) => {
                        if (res.data.repeatLogin === true) {
                            // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
                        }
                    }).catch(function (err) {
                        console.warn('TIM登录失败:', err); // 登录失败的相关信息
                    });
                }
            },
            checksystem() {
                TRTC.checkSystemRequirements().then(checkResult => {
                    if (checkResult.result) {
                        // 支持进房
                        var roomId = parseInt(this.meeting_id);
                        this.client.join({ roomId, role: 'anchor' }).catch(error => {
                        }).then(() => {
                            //主播的时候创建本地流，游客不能创建本地流 ||this.roles=='aid'
                            if(this.roles=='play'&&this.play_schema==0){
                                if (this.clientRole == 'anchor') {
                                    this.createStream(this.userId)   //创建本地流
                                    var that=this;
                                    let message = this.timInst.createCustomMessage({
                                        to: that.timChatGroupId,
                                        conversationType: TIM.TYPES.CONV_GROUP,
                                        payload: {
                                            data: '主播进房',
                                            description: this.userId
                                        },
                                    });
                                    localStorage.setItem('anchor_id',this.userId)
                                    that.timInst.sendMessage(message).then(function (res) {
                                    }).catch(function (err) {
                                        console.warn('主播进房失败', err);
                                    });
                                }
                            }else{
                                localStorage.setItem('anchor_id',0)
                            }
                            //任何身份，播放远端流
                            // this.playStream(this.client) 

                        });
                        if (checkResult.isH264DecodeSupported) {
                            // 支持拉流
                        }
                        if (checkResult.isH264EncodeSupported) {
                            // 支持推流
                        }
                    }
                })
            },
            onSdkReady: function () {
                if (!this.timInst) {
                    return
                } else {
                    let timInst = this.timInst;
                    let timToomType = this.timRoomType;

                    // 以会议房间号为GroupId，查询Group是否已被创建
                    let groupId = this.timChatGroupId;
                    let promise = this.timInst.searchGroupByID(groupId);
                    promise.then((imResponse) => {//  如果已创建，则检查是否用户是否已加入，已加入则不做处理，未加入则加入；
                        this.joinTimGroup();
                        const group = imResponse.data.group; // 群组信息
                       
                    }).catch((imError) => {//  如果未创建，则先创建Group，然后用户再加入
                       
                        let promise = timInst.createGroup({
                            type: timToomType,
                            name: 'MeetingTimGroup' + groupId,
                            groupID: groupId
                        });
                        promise.then((imResponse) => { // 创建成功
                         
                            this.joinTimGroup();
                        }).catch(function (imError) {
                           
                        });
                    });
                }
            },
            joinTimGroup: function () {
                if (!this.timInst) {
                    return;
                } else {
                    let promise = this.timInst.joinGroup({ groupID: this.timChatGroupId, type: this.timRoomType });
                    promise.then((imResponse) => {
                        switch (imResponse.data.status) {
                            case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
                                console.log('...等待管理员同意');
                                break;
                            case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
                                console.log('...加群成功');
                                console.log(imResponse.data.group); // 加入的群组资料
                                break;
                            case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
                                console.log('...已经在群中');
                                break;
                            default:
                                break;
                        }
                    }).catch(function (imError) {
                        console.log('进群失败');
                        console.warn('joinGroup error:', imError); // 申请加群失败的相关信息
                    });

                    return promise;
                }



            },
            getTimRooms: function () {
                let promise = this.timInst.getGroupList();
                promise.then(function (imResponse) {
                    console.log(imResponse.data.groupList); // 群组列表
                }).catch(function (imError) {
                    console.warn('getGroupList error:', imError); // 获取群组列表失败的相关信息
                });
            },
            getTimRoom: function () {
                let promise = this.timInst.getGroupProfile({ groupID: this.timChatGroupId });
                promise.then(function (imResponse) {
                    console.log(imResponse.data.group);
                }).catch(function (imError) {
                    console.warn('getGroupProfile error:', imError); // 获取群详细资料失败的相关信息
                });
            },
            searchTimRoom: function () {
                console.log('searchTimRoom..');
                let promise = this.timInst.searchGroupByID(this.timChatGroupId);
                promise.then(function (imResponse) {
                    console.log('searchTimRoom....');
                    const group = imResponse.data.group; // 群组信息
                    console.log(group);
                }).catch(function (imError) {
                    console.warn('searchGroupByID error:', imError); // 搜素群组失败的相关信息
                });
            },
            logoutIm: function () {
                if (this.timInst) {
                    let promise = this.timInst.logout();
                    promise.then((imResponse) => {
                        console.log('登出成功');
                        console.log(imResponse.data); // 登出成功
                    }).catch(function (imError) {
                        console.warn('logout error:', imError);
                    });
                }
            },
            //加入房间
            // joinRoom(client, roomId) {
            //     //anchor：主播， audience：观众
            //     client.join({ roomId, role: 'anchor' }).catch(error => {
            //         console.log('进房失败 ' + error);
            //     }).then(() => {
            //         console.log('进房成功');
            //         this.createStream(this.userId)//创建本地流
            //         this.playStream(this.client) //播放远端流
            //     });
            // },

            //创建本地音视频流
            createStream(userId) {
                const localStream = TRTC.createStream({ userId, audio: true, video: true });
                this.localStream = localStream;
                var id=localStream.id_;
                localStream.initialize().catch(error => {
                }).then(() => {
                    if(this.clientRole=='anchor'){
                        this.localtype=true
                        localStream.play('local_stream');// 创建好后才能播放 本地流播放 local_stream 是div的id
                    }else{
                        this.remoteStream = `<div id="${'remote_stream-' + id}" style="width:100%;height:100%"></div>`
                        if (!this.isHave) {
                            this.list.push({
                                id,
                                userId: userId,
                                remoteStream: this.remoteStream,
                                origin: localStream
                            })
                        } else {
                            this.list.splice(this.list.findIndex((v) => v.userId === userId), 1, {
                                id,
                                userId: userId,
                                remoteStream: this.remoteStream,
                                origin: localStream
                            })
                        }
                        this.$nextTick(() => {
                        //播放
                            // $("#local_stream").empty();
                            localStream.play('remote_stream-' + id);
                        
                            let audio = $('#audio').clone()
                            audio.attr('id', 'audio_' + id)
                            audio.css({
                                'width': '14px',
                                'height': '21px',
                                'background': 'none',
                                'margin-left': 'auto'
                            })
                            let unaudio = $('#unaudio').clone()
                            unaudio.attr('id', 'unaudio_' + id)
                            unaudio.css({
                                'width': '14px',
                                'height': '21px',
                                'background': 'none',
                                'margin-left': 'auto'
                            })

                            let mask = $('#mask').clone()
                            mask.text('')
                            mask.attr('id', 'mask_' + id)
                            mask.css({
                                'width': '100%',
                                'height': '100%',
                                'text-align': 'center',
                                'z-index': '99'
                            })
                            mask.appendTo($('#player_' + id))
                            mask.hide()

                            let status = $('<div>', {
                                id: 'status_' + id
                            })
                            status.css({
                                'width': '100%',
                                'height': '100%',
                                'text-align': 'center',
                                'color': '#fff',
                                'display': 'flex',
                                'align-items': 'center',
                                'justify-content': 'center'
                            })
                            status.html('<span>已离开会议<span>')
                            status.appendTo($('#player_' + id))
                            status.hide()
                            console.log(4198649, $('#audio').clone())
                            $('#player_' + id).css({ 'position': 'relative' })
                            let bottom = $('<div/>', { id: 'user_' + id })
                            bottom.html(`<span style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">${localStream.userId_}</span>`)
                            bottom.css({
                                'width': '100%',
                                'height': '20%',
                                'line-height': '35px',
                                'color': '#fff',
                                'background': '#3c3d40',
                                'display': 'flex',
                                'align-items': 'center',
                                'z-index': '99',
                                'margin-top': 'auto'
                            })
                            audio.appendTo(bottom)
                            unaudio.appendTo(bottom)
                            bottom.appendTo($('#' + id).parent())
                        })
                    }
                
                    this.publishStream(localStream, this.client) //创建好后才能发布
                    this.updateStream()
                });
            },

            //发布本地音视频流
            publishStream(localStream, client) {
                client.publish(localStream).catch(error => {
                    console.log('本地流发布失败 ' + error);
                }).then(() => {
                    $('#local_stream').addClass('mainVedio').parent().addClass('active')
                    console.log('本地流发布成功');
                });
            },
            // 远端监听
            updateStream() {
                this.client.on('stream-removed', (event) => {
                    const remoteStream = event.stream;
                    $(`${'#mask_' + remoteStream.getId()}`).hide()
                    $(`${'#status_' + remoteStream.getId()}`).show();
                    for (var i = 0; i < this.list.length; i++) {
                        if (this.list[i].userId == remoteStream.getUserId()) {
                            this.list.splice(i, 1)
                        }
                    }
                })
                // 关闭摄像头
                this.client.on('mute-video', (evt) => {
                    let streamId = this.getUidByStreamId(evt.userId)
                    if (streamId) {
                        $('#mask_' + streamId).show()
                    }
                })
                // 打开摄像头
                this.client.on('unmute-video', (evt) => {
                    let streamId = this.getUidByStreamId(evt.userId)
                    if (streamId) {
                        $('#mask_' + streamId).hide()
                    }
                })
                // 关闭语音
                this.client.on('mute-audio', (evt) => {
                    let streamId = this.getUidByStreamId(evt.userId)
                    $(`${'#user_' + streamId}`).find('#audio_' + streamId).hide().next().show()
                })
                // 打开语音
                this.client.on('unmute-audio', (evt) => {
                    let streamId = this.getUidByStreamId(evt.userId)
                    $(`${'#user_' + streamId}`).find('#unaudio_' + streamId).hide().prev().show()
                })
            },
            // 根据id获取uid
            getUidByStreamId(streamId) {
                for (let [uid, stream] of this.members_) {
                    if (stream.getUserId() == streamId) {
                        return uid
                    }
                }
            },
            //订阅远端流--加入房间之前
            //远端流通过监听事件client.on('stream-added')获取，收到该事件后，通过 Client.subscribe() 订阅远端音视频流。
            subscribeStream(client) {
                client.on('stream-added', event => {
                    const remoteStream = event.stream;
                    this.isHave = false
                    for (let [uid, stream] of this.members_) {
                        if (stream.getUserId() === remoteStream.getUserId()) {
                            $('#user_' + stream.getId()).remove()
                            this.members_.delete(stream.getId())
                            this.isHave = true
                        }
                    }
                    this.members_.set(remoteStream.getId(), remoteStream)
                    //订阅远端流
                    client.subscribe(remoteStream);
                    if(this.roles=='play'&&this.play_schema==1&&remoteStream.getUserId()=='LINUX'+this.host_id){
                        remoteStream.muteAudio()
                    }
                });
                client.on('stream-removed', (event) => {
                    const remoteStream = event.stream;
                    $("#local_stream").children().remove();
                    this.localtype=false
                   
                })
            },

            //播放远端流
            playStream(client) {
                client.on('stream-subscribed', event => {
                    const remoteStream = event.stream;
                    const id = remoteStream.getId()
                    if((this.play_schema==1&&remoteStream.getUserId()=="LINUX"+this.host_id)||(this.play_schema==0&&remoteStream.getUserId()==this.host_id)){
                        //大屏
                        this.localtype=true;

                        this.remoteStreamobj=remoteStream;
                        remoteStream.play('local_stream')
                        this.roomtype=true;
                    }
                    if((this.play_schema==1&&remoteStream.getUserId()!="LINUX"+this.host_id)||(this.play_schema==0&&remoteStream.getUserId()!=this.host_id)){
                        //小屏
                        this.remoteStream = `<div id="${'remote_stream-' + id}" style="width:100%;height:100%"></div>`
                        if (!this.isHave) {
                            this.list.push({
                                id,
                                userId: remoteStream.getUserId(),
                                remoteStream: this.remoteStream,
                                origin: remoteStream
                            })
                        } else {
                            this.list.splice(this.list.findIndex((v) => v.userId === remoteStream.getUserId()), 1, {
                                id,
                                userId: remoteStream.getUserId(),
                                remoteStream: this.remoteStream,
                                origin: remoteStream
                            })
                        }

                        //做了dom操作 需要使用$nextTick(),否则找不到创建的标签无法进行播放
                        this.$nextTick(() => {
                            //播放
                            remoteStream.play('remote_stream-' + remoteStream.getId());
                            let audio = $('#audio').clone()
                            audio.attr('id', 'audio_' + id)
                            audio.css({
                                'width': '14px',
                                'height': '21px',
                                'background': 'none',
                                'margin-left': 'auto'
                            })
                            let unaudio = $('#unaudio').clone()
                            unaudio.attr('id', 'unaudio_' + id)
                            unaudio.css({
                                'width': '14px',
                                'height': '21px',
                                'background': 'none',
                                'margin-left': 'auto'
                            })

                            let mask = $('#mask').clone()
                            mask.text('')
                            mask.attr('id', 'mask_' + id)
                            mask.css({
                                'width': '100%',
                                'height': '100%',
                                'text-align': 'center',
                                'z-index': '99'
                            })
                            mask.appendTo($('#player_' + id))
                            mask.hide()

                            let status = $('<div>', {
                                id: 'status_' + id
                            })
                            status.css({
                                'width': '100%',
                                'height': '100%',
                                'text-align': 'center',
                                'color': '#fff',
                                'display': 'flex',
                                'align-items': 'center',
                                'justify-content': 'center'
                            })
                            status.html('<span>已离开会议<span>')
                            status.appendTo($('#player_' + id))
                            status.hide()
                            console.log(4198649, $('#audio').clone())
                            $('#player_' + id).css({ 'position': 'relative' })
                            let bottom = $('<div/>', { id: 'user_' + id })
                            bottom.html(`<span style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">${remoteStream.getUserId()}</span>`)
                            bottom.css({
                                'width': '100%',
                                'height': '20%',
                                'line-height': '35px',
                                'color': '#fff',
                                'background': '#3c3d40',
                                'display': 'flex',
                                'align-items': 'center',
                                'z-index': '99',
                                'margin-top': 'auto'
                            })
                            audio.appendTo(bottom)
                            unaudio.appendTo(bottom)
                            bottom.appendTo($('#' + id).parent())
                        })
                    }
                    // if(remoteStream.getUserId()==localStorage.getItem('anchor_id')||localStorage.getItem('anchor_id')=='000'){   //主播
                    //     console.log("主播大屏主播大屏主播大屏主播大屏主播大屏主播大屏主播大屏主播大屏主播大屏")
                    //     remoteStream.play('local_stream')
                    //     this.roomtype=true;
                    // }else{   
                    //     console.log("观众小屏观众小屏观众小屏观众小屏观众小屏观众小屏观众小屏观众小屏观众小屏观众小屏")
                        
                    // }

                    
                });
            },

            //退出音视频
            leaveRoom() {
                console.log(this.client)
                if (this.client) {
                    // $("#local_stream").empty();
                    if(this.roles=='play'){   //主播退房提示
                        console.log("主播退房")
                        if(this.play_schema==1){
                            StopPush({
                                requestId:this.requestId
                            }).then(res=>{

                            })
                        }
                        var that = this;
                        let message = this.timInst.createCustomMessage({
                            to: that.timChatGroupId,
                            conversationType: TIM.TYPES.CONV_GROUP,
                            payload: {
                                data: '主播退房', // 用于标识该消息是骰子类型消息
                                description: '主播', // 获取骰子点数
                                extension: '主播退房'
                            },
                        });
                        this.timInst.sendMessage(message).then(function (res) {
                         
                            // that.maivisible = false;
                        }).catch(function (err) {
                            console.warn('主播退房失败', err);
                        });
                        this.leavetype=false;
                        this.playbtn=true;
                    }
                    this.client.leave().then(() => {
                        console.log('退房成功')
                        $("#local_stream").children().remove();
                        this.localtype=false;
                        this.localStream.stop(); // 停止本地流，关闭本地流内部的音视频播放器
                        this.localStream.close();// 关闭本地流，释放摄像头和麦克风访问权限
                        this.localStream = null;
                        this.client = null
                        // 退房成功，可再次调用client.join重新进房开启新的通话。
                    }).catch(error => {
                        console.log('退房失败 ' + error);// 错误不可恢复，需要刷新页面。
                    });
                    
                }
            },

            //切换角色
            changeroles() {
                client.switchRole('anchor').catch(error => {
                }).then(() => {
                    // 角色切换成功，现在是主播角色
                });
            },

            // async closeRoom() {
            //     await this.client.unpublish(this.localStream)
            //     console.log(this.client)
            //     // leave the room
            //     await this.client.leave()
            //     this.localStream.stop()
            //     this.localStream.close()
            //     this.localStream = null
            //     // this.isJoined_ = false;
            //     $('#local_stream').parent()
            //         .children('.info')
            //         .remove()
            // },

            // 将自己切换到主视频
            // switchMain () {
            //     if ($('#local_stream').hasClass('mainVedio')) { return }
            //     $('.mainVedio').removeClass('mainVedio').parent().removeClass('active')
            //     $('#local_stream').addClass('mainVedio')
            //     $('#warp').addClass('active')
            // },
            getVideo(id) {
                if ($('#' + id).hasClass('mainVedio')) { return }
                $('.mainVedio').removeClass('mainVedio').parent().removeClass('active')
                $('#' + id).addClass('mainVedio').css({ 'height': '93%' }).parent().addClass('active')
            },


            //获取用户签名 trtc
            genTestUserSig(userID) {
                /**
                 * 腾讯云 SDKAppId，需要替换为您自己账号下的 SDKAppId。
                 *
                 * 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav ) 创建应用，即可看到 SDKAppId，
                 * 它是腾讯云用于区分客户的唯一标识。
                 */
                // const SDKAPPID = 1400557280;
                const SDKAPPID = 1400395034;
                /**
                 * 签名过期时间，建议不要设置的过短
                 * <p>
                 * 时间单位：秒
                 * 默认时间：7 x 24 x 60 x 60 = 604800 = 7 天
                 */
                const EXPIRETIME = 604800;
                /**
                 * 计算签名用的加密密钥，获取步骤如下：
                 *
                 * step1. 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav )，如果还没有应用就创建一个，
                 * step2. 单击“应用配置”进入基础配置页面，并进一步找到“帐号体系集成”部分。
                 * step3. 点击“查看密钥”按钮，就可以看到计算 UserSig 使用的加密的密钥了，请将其拷贝并复制到如下的变量中
                 *
                 * 注意：该方案仅适用于调试Demo，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。
                 * 文档：https://cloud.tencent.com/document/product/647/17275#Server
                 */
                const SECRETKEY =
                    // "033d0bd0ad41ed53ba93025edd6e184b4f436c40a6bd65e72ab7ca03fbcdf0e2";
                    "d81962caea352dbf6f53da1acd36d0b9443eba17c1e1dcda99ab7027e64ea37a";

                // a soft reminder to guide developer to configure sdkAppId/secretKey
                if (SDKAPPID === "" || SECRETKEY === "") {
                    alert(
                        "请先配置好您的账号信息： SDKAPPID 及 SECRETKEY " +
                        "\r\n\r\nPlease configure your SDKAPPID/SECRETKEY in js/debug/GenerateTestUserSig.js"
                    );
                }
                const generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
                const userSig = generator.genTestUserSig(userID);
                return {
                    sdkAppId: SDKAPPID,
                    userSig: userSig
                };
            },
            genTestTimSig(userID) {
                var SDKAPPID = 1400395034;
                var EXPIRETIME = 604800;
                var SECRETKEY = 'd81962caea352dbf6f53da1acd36d0b9443eba17c1e1dcda99ab7027e64ea37a';
                var generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
                var userSig = generator.genTestUserSig(userID);
                return {
                    SDKAppID: SDKAPPID,
                    userSig: userSig
                };

            },


            videolist: function () {
                MeetingLists({    //会议列表
                    token:localStorage.getItem('token')

                }).then(res => {
                    this.videolists = res.data.list;
                    this.roles = res.data.roles;
                    console.log(this.roles)
                    //anchor：主播， audience：观众
                    //  this.roles == 'member'
                    if (this.roles == 'play' ) {  
                        this.clientRole = 'anchor'
                    } else {
                        this.clientRole = 'audience'
                    }
                    // this.roles='aid'
                    this.show_button = res.data.show_button;
                    this.major = this.videolists[0];
                    MeetingGetSig({
                        meeting_id:this.videolists[0].id,
                        roles:this.roles
                    }).then(res=>{
                        this.host_id=res.data.host_id

                    })
                })
            },
            changevideo: function (item, index) {   //切换会议
                console.log(item)
                MeetingSingle({
                    token:localStorage.getItem('token'),
                    meeting_id:item.meeting_id
                }).then(res=>{
                    if(res.code==1){

                    }
                })
                this.major = [];
                var aa = item;
                this.major = aa;
                this.current = index;
                this.playbtn = true;
                // 还有操作
                this.leaveRoom(this.client);
                this.logoutIm();
                //
                MeetingGetSig({
                    meeting_id:item.id,
                    roles:this.roles
                }).then(res=>{
                    this.host_id=res.data.host_id
                })
            },
            GoSign: function () {
                this.booksignvisible = true;
            },
            pulldetail: function () {  //会议材料下拉
                if (this.pull == 1) {
                    this.pull = 0
                } else {
                    this.pull = 1
                }
            },
            read: function (path, status) {   //阅读
                // console.log(path,status)
                if (status == 0) {
                    this.content = "请先签收文书再进行阅读";
                    // this.toastShow=true;
                    // setTimeout(() => {
                    //     this.toastShow=false
                    // }, 5000);
                } else {
                    window.open(path)
                }
            },
            getsign: function (item, type) {  //签收
                var that = this;
                if (item.sign_file_status == 1 && type == 'file') {
                    that.content = "会议材料已签收";
                    return
                }
                if (item.sign_excel_status == 1 && type == 'excel') {
                    that.content = "债权表已签收";
                    return

                }
                if (item.sign_notice_status == 1 && type == 'notice') {
                    that.content = "会议公告已签收";
                    return

                }
                if (item.sign_inform_status == 1 && type == 'inform') {
                    that.content = "会议通知已签收";
                    return
                }
                MeetingSignFile({
                    token:localStorage.getItem('token'),
                    // case_id: item.case_id,
                    meeting_id: item.id,
                    input_type: type
                }).then(res => {
                    if (res.data.check == 1) {
                        that.content = "签收成功";
                        MeetingLists({    //会议列表
                            token:localStorage.getItem('token')

                        }).then(res => {
                            for (var i = 0; i < res.data.list.length; i++) {
                                if (item.id == res.data.list[i].id) {
                                    that.major = [];
                                    var aa = res.data.list[i];
                                    that.major = aa
                                }
                            }

                        })
                        // console.log(this.videolists)
                    } else {
                        that.content = "签收失败"
                    }
                })

                // that.toastShow=true;
                // setTimeout(() => {
                //     that.toastShow=false;
                //     that.videolist()
                // }, 1500);

            },
            joinvideo: function (item) {   //参加会议
                if (this.roles == 'member' && item.file_status == 1) {
                    if (item.sign_file_status == 0 || item.sign_excel_status == 0 || item.sign_notice_status == 0 || item.sign_inform_status == 0) {
                        this.content = "请先签收所有文书";
                        // this.toastShow=true;
                        // setTimeout(() => {
                        //     this.toastShow=false
                        // }, 1500);
                        return
                    }
                }


                var that = this;
                var timestemp1 = Date.parse(new Date())
                var Time2 = new Date(item.start_time);
                var timestemp2 = Time2.getTime();
                var Time3 = new Date(item.end_time);
                var timestemp3 = Time3.getTime();
                if (timestemp2 > timestemp1) {
                    this.content = "当前会议还没有开始";
                } else if (timestemp1 > timestemp3) {
                    this.content = "当前会议已结束";
                } else {
                    MeetingCheckSign({
                        token:localStorage.getItem('token'),
                        meeting_id: item.id,
                        // case_id: item.case_id
                    }).then(res => {
                        if (res.data.allow == 1) {   //允许签到
                            if (res.data.sign_status == 0) {   //没有签收文书，可以签收
                                MeetingDoSing({
                                    token:localStorage.getItem('token'),
                                    meeting_id: item.id,
                                    // case_id: item.case_id
                                }).then(res => {
                                    if (res.data.check == 0) {
                                        this.content = res.data.message
                                    } else {
                                        that.signsuccessvisible = true;
                                    }
                                })
                            } else {
                                that.signsuccessvisible = true;
                            }
                        } else {
                            that.content = "请检查未签收文件"
                            that.videolist()
                        }
                    })

                }
                // this.toastShow=true;
                // setTimeout(() => {
                //     this.toastShow=false
                // }, 1500);
            },
            nojin: function () {   //不进入会议
                this.signsuccessvisible = false;
                this.booksignvisible = false;
                // this.playbtn=true;
            },
            pusherplay:function(type){
                this.device=type;
                this.leavetype=false;
                this.pusherVisible=false;
                this.playbtn=true;
            },
            copy:function(){
                this.leavetype=false;
                this.pusherVisible=false;
                this.playbtn=true;
                this.localtype=true;
                var _this = this;
                // var clipboard = new Clipboard(".btn"); //单页面引用
            　　var clipboard = new this.Clipboard(".btns"); //在main.js中引用
                clipboard.on("success", e => {
                    // 释放内存
                    clipboard.destroy();
                    var that=this;
                    // if(this.requestId==''){    //第一次进来播放
                        PullStream({
                            room_id:this.itemid,//69
                            play_url:this.playLinkAddr//rtmp://play.pochanjie.com/live/meeting69
                        }).then(res=>{   
                            this.requestId=res.data.requestId;
                            // MeetingSetPlayData({
                            //     meeting_id:this.itemid,
                            //     play_address:this.pusheraddress,
                            //     play_id:this.requestId
                            // }).then(res=>{
                                
                            // });
                        })
                    // }
                   
                   
                    this.playbtn=false;
                    this.leavetype=true;
                    var userId=that.userId;    //主播自己的userid
                    const config = that.genTestUserSig(userId)
                    const sdkAppId = config.sdkAppId
                    const userSig = config.userSig   //获取签名
                    var client = TRTC.createClient({
                        mode: 'live',
                        sdkAppId,
                        userId,
                        userSig
                    });
                    that.client=client;
                    this.timChatGroupId = this.meeting_id + 'GRP';
                    that.initIM();   
                    this.subscribeStream(client)
                    this.checksystem();
                    this.playStream(client)
                    let message = this.timInst.createCustomMessage({
                        to: that.timChatGroupId,
                        conversationType: TIM.TYPES.CONV_GROUP,
                        payload: {
                            data: '主播进房',
                            description: this.userId
                        },
                    });
                    localStorage.setItem('anchor_id',this.userId)
                    that.timInst.sendMessage(message).then(function (res) {
                        console.log(res);
                    }).catch(function (err) {
                        console.warn('主播进房失败', err);
                    });
                });
                clipboard.on("error", e => {
                    // 不支持复制
                    Message({
                    message: "该浏览器不支持自动复制",
                    type: "warning"
                    });
                    // 释放内存
                    clipboard.destroy();
                });
            },
            enter: function (item) {   //进入会议	
                this.videodetail=item;
                this.signsuccessvisible = false;
                this.booksignvisible = false;
                this.play_schema=item.play_schema;

                if(this.roles=='play'){   //主播，选择直播方式
                    if(item.play_schema==0){  //内部
                        if(this.camerastype==false||this.microphonestype==false){
                            this.devicescontent="麦克风或摄像头设备没有连接,连接后请重新进入会议"
                        }else{
                            this.deviceVisible=false;
                            this.playbtn = false;

                            setTimeout(() => {
                                this.videostart = true;
                                this.meeting_id = item.id;
                                this.timChatGroupId = this.meeting_id + 'GRP';

                                this.case_id = item.case_id;
                                MeetingGetJintype({
                                    meeting_id: this.meeting_id
                                }).then(res => {
                                    this.jintype = res.data.jintype
                                })
                                MeetingGetVoteStatus({
                                    meeting_id: this.meeting_id
                                }).then(res => {
                                    this.vote_status = res.data.vote_status
                                })
                                MeetingGetStatisticStatus({
                                    meeting_id: this.meeting_id
                                }).then(res => {
                                    this.statistic_status = res.data.statistic_status
                                })
                                MeetingGetParticipantStatus({
                                    meeting_id: this.meeting_id
                                }).then(res => {
                                    this.participant_status = res.data.participant_status
                                })
                                // TRTC.onCameraDidReady().then(res=>{
                                //     console.log(res)
                                // })
                                
                                const micList = TRTC.getMicrophones();
                                const cameraList = TRTC.getCameras();
                                const speakerList = TRTC.getSpeakers();
                                const hasCameraDevice = cameraList.length > 0;
                                const hasMicrophoneDevice = micList.length > 0;
                                const hasSpeakerDevice = speakerList.length > 0;
                                navigator.mediaDevices.getUserMedia({ video: hasCameraDevice, audio: hasMicrophoneDevice })
                                    .then((stream) => {
                                        console.log("获取音视频流成功")
                                        stream.getTracks().forEach(track => track.stop());
                                    })
                                    .catch((error) => {
                                        console.log("获取音视频流失败", error)

                                    });
                                TRTC.checkSystemRequirements().then(checkResult => {
                                    // console.error(checkResult, 'TRTC.checkSystemRequirements');
                                    if (checkResult.result) {
                                        this.createClient(this.userId);// 创建一个实时音视频通话的客户端对象
                                        this.initIM();   
                                        this.leavetype=true;
                                    } else {
                                        this.content = "SDK 不支持当前浏览器，根据用户设备类型建议用户使用 SDK 支持的浏览器"
                                        console.error('checkResult', checkResult.result, 'checkDetail', checkResult.detail);
                                    }
                                })
                            }, 1000);
                        }
                    }else{   //外部
                        this.deviceVisible=false;
                        this.playbtn = false;
                        this.videostart = true;
                        this.meeting_id=item.id
                        
                        // MeetingGetPlayData({
                        //     meeting_id:item.id
                        // }).then(res=>{
                        //     if(res.data.check==0){   //调原来代码 生成地址 并上传。
                                MeetingUrl({
                                    meeting_id:item.id
                                }).then(res => {
                                    this.pusherVisible=true;
                                    this.pusheraddress=res.data.push;
                                    this.itemid=item.id;
                                    this.playLinkAddr=res.data.play;
                                })
                        //     }else{    //已有地址
                        //         this.pusherVisible=true;
                        //         this.pusheraddress=res.data.play_address;
                        //         this.requestId=res.data.play_id;
                        //     }
                        // })

                        
                
                    }
                    
                }else{   //非主播播放
                    this.playbtn = false;
                    if(this.camerastype==false||this.microphonestype==false){
                        this.devicescontent="麦克风或摄像头设备没有连接,连接后请重新进入会议";
                    }
                    setTimeout(() => {
                        this.deviceVisible=false;
                        this.videostart = true;
                        this.meeting_id = item.id;
                        this.timChatGroupId = this.meeting_id + 'GRP';

                        this.case_id = item.case_id;
                        MeetingGetJintype({
                            meeting_id: this.meeting_id
                        }).then(res => {
                            this.jintype = res.data.jintype
                        })
                        MeetingGetVoteStatus({
                            meeting_id: this.meeting_id
                        }).then(res => {
                            this.vote_status = res.data.vote_status
                        })
                        MeetingGetStatisticStatus({
                            meeting_id: this.meeting_id
                        }).then(res => {
                            this.statistic_status = res.data.statistic_status
                        })
                        MeetingGetParticipantStatus({
                            meeting_id: this.meeting_id
                        }).then(res => {
                            this.participant_status = res.data.participant_status
                        })

                        const cameraList = TRTC.getCameras();
                        const micList = TRTC.getMicrophones();
                        // const speakerList = TRTC.getSpeakers();
                        const hasCameraDevice = cameraList.length > 0;
                        const hasMicrophoneDevice = micList.length > 0;
                        // const hasSpeakerDevice = speakerList.length > 0;
                        console.log(cameraList,micList)
                        navigator.mediaDevices.getUserMedia({ video: hasCameraDevice, audio: hasMicrophoneDevice })
                            .then((stream) => {
                                console.log("获取音视频流成功")
                                stream.getTracks().forEach(track => track.stop());
                            })
                            .catch((error) => {
                                console.log("获取音视频流失败", error)

                            });
                        TRTC.checkSystemRequirements().then(checkResult => {
                            // console.error(checkResult, 'TRTC.checkSystemRequirements');
                            if (checkResult.result) {
                                this.createClient(this.userId);// 创建一个实时音视频通话的客户端对象
                                this.initIM();   
                            } else {
                                this.content = "SDK 不支持当前浏览器，根据用户设备类型建议用户使用 SDK 支持的浏览器"
                                console.error('checkResult', checkResult.result, 'checkDetail', checkResult.detail);
                            }
                        })
                    }, 1000);
                }
            },
            //选举表决通道
            passageway: function (item) {
                console.log(item)
                if (item.file_status == 0) {  //文书开
                    console.log("进入表决选举通道")
                    this.voteelectvisible = true;
                } else {
                    if (item.sign_file_status == 0 || item.sign_excel_status == 0 || item.sign_notice_status == 0 || item.sign_inform_status == 0) {   //4本文书已阅读
                        this.content = "请先签收所有文书"
                    } else {
                        this.voteelectvisible = true;
                    }
                }

            },
            GoVote: function () {
                this.$router.push({ path: '/Vote', query: { path: 'video', item: this.major } })
            },
            GoElect: function () {
                this.$router.push({ path: '/Election', query: { path: 'video', item: this.major } })
            },
        }
    }
</script>
<style scoped>
    .Meet_box {
        position: absolute;
        top: 185px;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #EEF3FA;

    }

    .Meet_box_inside {
        background: #F5FAFE;
        border: 0.1rem solid #E2E2E8;
        border-radius: 0.5rem;
        width: 86.4%;
        margin: 4rem auto;
        display: flex;
        padding: 3rem 0;
    }

    .Meet_box_video {
        width: 70.23%;
        margin-left: 101px;
    }

    .Meet_box_name {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        margin: 0px auto 10px;
        font-family: "siyuanb";
    }

    .Meet_box_detail {
        height: 607px;
        position: relative;
    }

    .Meet_box_detail img {
        width: 100%;
        height: 100%;
    }

    .Meet_box_detail_btn {
        position: absolute;
        width: 95.6%;
        height: 60px;
        left: 1.2%;
        padding: 0 1%;
        background: rgb(39 39 39 / 50%);
        border-radius: 8px;
        /* bottom: 10px; */
        z-index: 999;
        display: flex;
        align-items: center;
    }

    .audiencebox {
        position: absolute;
        height: 140px;
        width: 95.6%;
        bottom: 80px;
        left: 2.2%;
        display: flex;
        justify-content: flex-start;
    }

    .audiencebox_list {
        width: 140px;
        height: 140px;
        background: #000;
        margin-right: 20px;
    }

    .btn_min {
        margin-right: 20px;
        width: 14rem;
        height: 4rem;
        border-radius: 0.8rem;
        text-align: center;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn_min span {
        font-size: 20px;
        margin-right: 10px;
    }

    .detail_input {
        margin-left: 30px;
        display: flex;
    }

    .detail_input input {
        width: 345px;
        height: 40px;
        background: #000000;
        opacity: 0.6;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 8px;
        margin-right: 10px;
        padding: 0 3%;
        color: #fff;
    }

    .sendbtn {
        width: 91px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 8px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
    }

    .Meet_box_mess {
        width: 15.84%;
        height: 666px;
        background: #E7EEF5;
        border: 2px solid rgba(255, 255, 255, 0.2);
        box-shadow: 6px 6px 16px rgba(163, 177, 198, 0.6), -6px -6px 16px rgba(255, 255, 255, 0.6);
        border-radius: 10px;
        margin-left: 32px;
        padding: 0 2%;
    }

    .Meet_box_mess_now {
        height: 50px;
        /* opacity: 0.6; */
        border-bottom: 1px dashed rgb(112 110 140 / 60%);
        font-family: 'siyuanb';
        font-size: 16px;
        line-height: 24px;
        color: #FF7474;
        padding: 18px 0;
        font-family: "siyuanb";
    }

    .Meet_box_mess_other div:nth-child(1) {
        font-size: 14px;
        line-height: 21px;
        margin: 20px 0;
    }

    .Meet_box_mess_other div {
        font-size: 14px;
        line-height: 30px;

    }

    /* 弹框 */
    .el-dialog {
        width: 1060px;
        height: 600px;
        background: #F5FAFE;
        border: 1px solid #E2E2E8;
        border-radius: 5px;
    }

    .el-dialog .el-dialog__header {
        border-bottom: 1px solid #E7EEF5;
        text-align: center;
        color: #706e8c;
        font-family: 'siyuanb';
    }

    .el-dialog .el-dialog__body {
        padding-top: 15px;
    }

    .dialog_name {
        text-align: center;
        margin-bottom: 12px;
        font-size: 14px;
    }

    .dialog_time {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .dialog_tit {
        text-align: center;
        color: #F47814;
        font-size: 14px;
        margin-bottom: 22px;
    }

    .dialog_list {
        height: 46px;
        background: #EEF3FA;
        border-radius: 5px;
        padding-left: 14px;
        margin-bottom: 10px;
    }

    .dialog_list_btn {
        display: flex;
    }

    .dialog_list_btn span {
        width: 60px;
        height: 30px;
        border-radius: 5px;
        display: block;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        line-height: 30px;
        cursor: pointer;
    }

    .dialog_list_btn span:nth-child(1) {
        background: #43A0F5;

    }

    .dialog_list_btn span:nth-child(2) {
        background: #FE9B4B;
    }

    .dialog_lists {
        height: 40px;
    }

    .dialog_btn {
        display: flex;
        color: #fff;
        font-size: 1.4rem;
        margin: 44px auto 0;
        width: 65%;
    }

    .dialog_btn div {
        width: 268px;
        height: 40px;
        line-height: 40px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
    }

    .dialog_btn div:nth-child(1) {
        margin-right: 30px;
    }

    /* 签到成功 */
    .qianbtn {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        font-size: 16px;

    }

    .qianbtn div {
        height: 50px;
        width: 40%;
        text-align: center;
        line-height: 50px;
        border: 1px solid #fff;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
    }

    /* 参会人 */
    .joinlist {
        height: 50px;
        /* line-height: 50px; */
        font-size: 20px;
        border-bottom: 1px dashed #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .joinlist image {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        margin-right: 10px;
        float: left;
        margin-top: 5px;
        background-size: 100% 100%;
    }

    .joinlist text:nth-child(1) {
        width: 70%;
        display: block;
        white-space: norwap;
        overflow-x: scroll;
        /* height: 50px; */
        padding: 0 10px;

    }

    .joinlist text:nth-child(2) {
        width: 20%;
        text-align: center;
        display: block;
        color: yellowgreen;

    }

    /* 上麦管理 */
    .gongbottoms {
        display: flex;
        justify-content: space-around;
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
        color: #FFFFFF;
    }

    .gongbottoms view {
        width: 45%;
        text-align: center;
        border-radius: 5px;

    }

    .gongbottoms text:nth-child(1) {
        margin-right: 10px;
    }

    /* 统计 */
    .tongqian {
        margin-bottom: 15px;
    }

    table {
        border-top: 1px solid #eee;
        border-left: 1px solid #eee;
    }

    .tonglist {
        /* height: 30px; */
        line-height: 30px;
        font-size: 14px;
        text-align: center;
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid #eee;
        align-items: center;
    }

    .tonglist text {

        /* border-right: 1px solid #eee; */
        text-align: center;
    }

    .tonglist text:nth-child(1) {
        font-weight: 700;
        width: 40%;
    }

    .tonglist text:nth-child(2) {
        width: 35%;
    }

    .tonglist text:nth-child(3) {
        width: 20%;
    }

    /* 上麦管理 */
    /* 申请上麦 */
    .maitable {
        border: 1px solid #EEEEEE;
        font-size: 14px;
        width: 100%;
    }

    .maitable tr {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #eee;
        width: 100%;
    }

    .maitable tr:nth-child(1) {
        font-weight: 700;
    }

    .maitable tr:last-child {
        border: none;
    }

    .maitable td {
        width: 33%;
        text-align: center;
        float: left;
        /* border-right: 1px solid #EEEEEE; */
    }

    .maitable td:last-child {
        border: none;
        color: #06AE56;
        cursor: pointer;
    }

    /* 表决列表 */
    .biaolist {
        padding: 10px 0;
        font-size: 18px;
        /* height: 40px; */
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #eee;
        justify-content: space-between;
        line-height: 20px;

    }

    .biaolist span:nth-child(1) {
        width: 70%;
        float: left;
        word-break: break-all;
        word-wrap: break-word;

    }

    .biaolist span:nth-child(2) {
        float: right;
        width: 25%;
        line-height: 40px;
        background: green;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }

    /* 上麦 */
    .maicon {
        text-align: center;
        line-height: 5rem;
        font-size: 1.8rem;
    }

    .twobtn {
        height: 40px;
        display: flex;
        justify-content: space-around;
        line-height: 40px;

    }

    .twobtn div:nth-child(1) {
        background: #eee;
        width: 30%;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }

    .twobtn div:nth-child(2) {
        background: #00aa00;
        width: 30%;
        text-align: center;
        border-radius: 5px;
        color: #FFFFFF;
        cursor: pointer;
    }

    /* 表决内容弹起 */
    .biaocontent {
        height: 50px;
        width: 90%;
        border: 1px solid #ccc;
        margin: 0 auto;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        color: green;
        overflow: auto;
    }

    .biaoradio {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;

    }

    .biaoradio label {
        width: 40%;
        border: 1px solid #eee;
        font-size: 16px;
        padding: 3px 5px;
        border-radius: 5px;
        text-align: center;
    }

    .biaolist {
        padding: 10px 0;
        font-size: 14px;
        /* height: 40px; */
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #eee;
        justify-content: space-between;
        line-height: 20px;

    }

    .biaolist text:nth-child(1) {
        width: 70%;
        float: left;
        word-break: break-all;
        word-wrap: break-word;

    }

    .biaolist text:nth-child(2) {
        float: right;
        width: 25%;
        line-height: 40px;
        background: green;
        color: #fff;
        text-align: center;
        border-radius: 5px;

    }
    .btn{
        color: #fff;
        padding: 0 10px;
        border-radius: 5px;
        cursor: pointer;
    }
    .surebtn{
        width: 50%;
        font-size: 14px;
        margin: 0 auto;
        margin-top: 15px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }
    /* 控制按钮 */
    .kongbtn{
        height: 30px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        bottom: 0;
        margin-bottom: 10px;
        font-size: 12px;
        text-align: center;

    }
    .kongbtn div{
       border-radius: 5px;
       line-height: 30px;
       background: #FFF;
       padding: 0 10px;
       cursor: pointer;
    }

    /* 外接设备 */
    .devicebox{

    }
    .devicebox div,.devicebox button{
        padding: 1rem 2rem;
        border: 0.1rem solid #b9b9b9;
        border-radius: 0.5rem;
        cursor: pointer;
    }
</style>