<template>
    <div class="content">
        <img src="../image/no.png" alt="">
    </div>
</template>    
<script>
    export default {
        name: 'Nocontent',
        data(){
            return{
               
            }
        },
        created() {
           
        },
        methods:{
           
        }
    }
</script>
 
<style scoped>
.content{
    width: 60%;
    margin: 0 auto;
}
img{
    display: block;
    margin: 0 auto;
    width: 60%;
}
</style>