<template>
    <div class="Bottom_box">
        <div class="grids_title flexbe">
           <div class="grids_title_name">投资人招募</div>
           <div class="more" @click="Gomore">更多></div>
        </div>
        <div class="grids_con" @click="Godetail(item)" v-for="(item,index) in touzilist" :key="index">
           <img :src="item.first_image[0].path" alt="">
           <div class="grids_con_mess">
                <div>{{item.title}}</div>
                <div>{{item.author}}</div>
                <div>{{item.create_time}}</div>
            </div>
        </div>
        
    </div>
</template>
 
<script>
import {InformationList} from '@/request/api';
import Common from '../request/common.vue'

    export default {
        name: 'touzi',
        data(){
            return{
                touzilist:[],
               
            }
        },
        created() {
            this.touzi();
           
        },
        methods:{
            // 7块
            touzi:function(){
                InformationList({
                    plate:'4',   //公告id
                    page:1,
                    limit:3,
                    pro:'',
                    city:'',
                    plate_class:0,
                    from:'pc'
                }).then(res => { 
                    this.touzilist=res.data.rows;
                })
            },
            Gomore:function(){
               this.$router.push({path:'/Rulers',query:{plate:'4',current:2}})
               Common.current=4;
            },
            Godetail:function(item){
                // this.$router.push('/Rulerdetail')
                window.open(item.pdf_input[0].path)

            }
        }
    }
</script>
 
<style scoped>


</style>