<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header></Header>
        <div class="Network_box">
            <div class="Network_tit flexar">债<br>权<br>管<br>理</div>
            <div class="Manage_box">
                <el-select v-model="value" placeholder="请选择案件" @change="jobExperienceChange">
                    <el-option
                      v-for="(item,index) in conlist_c"
                      :key="index"
                      :value="item.company_name+'    编号:'+item.sn">
                    </el-option>
                </el-select>
                <div v-if="casetype==0" style="text-align: center;line-height: 100px;font-size: 20px;">请先选择案件</div>
                <div class="Manage_con" v-if="casetype==1">
                    <div class="Manage_tab">
                        <div :class="currentzhai==index?'Manage_tab_active':''" v-for="(item,index) in managetab" :key="index" @click="tabclick(index)">{{item}}</div>
                    </div>
                    <div class="Manage_public">
                        <div class="company_name">{{company_name}}</div>
                        <div class="company_time">{{claims_create}}——{{claims_expire}}</div>
                    </div>
                    <div class="Manage_mine" v-if="currentzhai==0">
                        <div class="Manage_mine_list">债权编号：{{sn}}</div>
                        <div class="Manage_mine_list"> 债权性质：{{claims_nature}}</div>
                        <div class="Manage_mine_list">债权审核进度：{{status}}</div>
                        <div class="Manage_mine_lists_box" @click.stop="look1" v-if="type==0">
                            <div class="Manage_mine_lists_tit"><span>债权人银行账户信息</span><span  style="cursor: pointer;">查看详情</span></div>
                            <div class="Manage_mine_lists" v-if="show1">收款户名：{{charge_allocation_name}}</div>
                            <div class="Manage_mine_lists" v-if="show1">银行账户：{{charge_allocation_bank_card_number}}</div>
                            <div class="Manage_mine_lists" v-if="show1">开户银行：{{charge_allocation_bank_name}}</div>
                        </div>
                        <div class="Manage_mine_lists_box" @click.stop="look2" v-if=" [0,1].includes(type) ">
                            <div class="Manage_mine_lists_tit"><span>债权人申报债权信息</span><span  style="cursor: pointer;">查看详情</span></div>
                            <div class="Manage_mine_lists" v-if="show2">本金：{{user_declare_claims_principal}}元</div>
                            <div class="Manage_mine_lists" v-if="show2">利息：{{user_declare_claims_interest}}元</div>
                            <div class="Manage_mine_lists" v-if="show2">其他损失：{{user_declare_claims_other}}元</div>
                            <div class="Manage_mine_lists" v-if="show2&&type==1">债权违约金：{{user_declare_claims_penalty}}元</div>
                            <div class="Manage_mine_lists" v-if="show2&&type==1">债权延迟履约金：{{user_declare_claims_delay}}元</div>
                            <div class="Manage_mine_lists" v-if="show2">债权总额：{{user_declare_claims_total}}元</div>
                        </div>
                        <div class="Manage_mine_lists_box" @click.stop="look3" v-if=" [0,1].includes(type) ">
                            <div class="Manage_mine_lists_tit"><span>管理人审查债权信息</span><span  style="cursor: pointer;">查看详情</span></div>
                            <div class="Manage_mine_lists" v-if="show3">本金：{{enter_declare_claims_principal}}元</div>
                            <div class="Manage_mine_lists" v-if="show3">利息：{{enter_declare_claims_interest}}元</div>
                            <div class="Manage_mine_lists" v-if="show3">其他损失：{{enter_declare_claims_other}}元</div>
                            <div class="Manage_mine_lists" v-if="show3&&type==1">债权违约金：{{enter_declare_claims_penalty}}元</div>
                            <div class="Manage_mine_lists" v-if="show3&&type==1">债权延迟履约金：{{enter_declare_claims_delay}}元</div>
                            <div class="Manage_mine_lists" v-if="show3">债权总额：{{enter_declare_claims_total}}元</div>
                        </div>
                        

                        <div class="evidencebox">
                            <div>我的证据</div>
                            <div style="display: flex;justify-content: flex-start;flex-direction: row;flex-wrap: wrap;margin-top: 15px;" >
                                <div v-for="(item,index) in evidence" v-if="evidence[index].file_input.length!==0" style="display: flex;justify-content: flex-start;flex-direction: row;flex-wrap: wrap;">
                                    <img :src="baseURL+items.path" mode="" v-if="items.type=='png'||items.type=='jpg'||items.type=='jpeg'" v-for="items in item.file_input" @click="look(items.path)" style="width: 80px;height: 80px;margin: 0 10px;cursor: pointer;"></img>
                                    <div style="width: 200px;word-wrap:break-word;cursor: pointer;" v-if="items.type=='doc'||items.type=='docx'||items.type=='xlsx'||items.type=='pdf'" v-for="items in item.file_input" @click="openword(items.path)">{{items.path}}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="shen_box" v-if="currentzhai==1">
                        <div v-if="casetype==1">
                            <div class="shen_tit">{{status}}</div>
                            <div class="againsend" @click="againsends" v-if="status!=='审核结束'&&is_submit==0" >
                                {{buttonname}}
                            </div>
                        </div>
                        <div class="LegalPart"  v-for="(item,index) in list" :key="index" v-if="list.length!==0">
                            <table style="margin-top: 10px;fonweight: 700;width: 100%;">
                                <tr class="shen_list">
                                    <td>状态</td>
                                    <td style="color: #FD2001;">{{item.check_link}}{{item.check_result}}</td>
                                </tr>
                                <!-- <tr class="shen_list">
                                    <td>审核部门</td>
                                    <td>{{item.department}}</td>
                                </tr> -->
                                <tr class="shen_list">
                                    <td>审核时间</td>
                                    <td>{{item.default_create_time}}</td>
                                </tr>
                                <tr class="shen_list">
                                    <td>审核结果</td>
                                    <td>{{item.check_result}}</td>
                                </tr>
                                <tr class="shen_list">
                                    <td>审核说明</td>
                                    <td>{{(item.check_description==''||item.check_description==null)?'暂无审核说明':item.check_description}}</td>
                                </tr>
                                <tr class="shen_list">
                                    <td style="border: none;">
                                        <div>审核通知附件</div>
                                    </td>
                                    <td>
                                        <div v-if="item.check_file.length==0">
                                            暂无附件
                                        </div>
                                        <div v-for="(items,indexs) in item.check_file" :key="indexs" style="line-height: 30px;" v-if="item.check_file.length!==0">
                                            {{items.real_name}}
                                            <span class="alipaycolor" style="fonsize: 12px;float: right;margin-right: 10px;" @click="xiazai(items.path)">下载</span>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div v-if="list.length==0" class="nocontent">暂无审核内容</div>
                    </div>
                    <div class="result_box" v-if="currentzhai==2">
                        <div class="result_list" v-if="declaration_is_show==1">
                            <!-- 确认书：先阅读。其他：先签收 -->
                            <div>债权申报表</div>
                            <div class="flexbe">
                                <span class="zhaiload" v-if="declaration_send_status==1" @click="xiazai(declaration_file[0].path)">下载</span>
                                <span class="zhaisign" v-if="declaration_send_status==1" @click="getsign('declaration',declaration_sign_status)" >{{declaration_sign_status==1?'已签收':'确认签收'}}</span>
                                <span>{{declaration_send_status==0?'待后台发送':'后台已发送'}}</span>
                            </div>
                            
                        </div>
                        <div class="bookbox">
                            <div class="result_list" v-if="inform_is_show==1">
                                <span>债权审查确认通知书</span>
                                <div class="result_btn">
                                    <span class="zhaiload" v-if="inform_send_status==1" @click="loading(inform_file[0].path,'que')">阅读或下载</span>
                                    <!-- <span v-if="send_status==1" @click="yulan(inform_file[0].path,'shen','que')">阅读</span> -->
                                    <span class="zhaisign" v-if="inform_send_status==1" @click="getsign('inform',inform_sign_status)" >{{inform_sign_status==1?'已签收':'确认签收'}}</span>
                                    <span>{{inform_send_status==0?'待后台发送':'后台已发送'}}</span>
                                </div>
                                
                            </div>
                            <div class="result_list" v-if="confirmation_is_show==1">
                                <span>债权确认书</span>
                                <div class="result_btn">
                                    <span class="zhaiload" v-if="confirmation_send_status==1" @click="loading(confirmation_file[0].path,'zhai')">阅读或下载</span>
                                    <span class="zhaisign" v-if="confirmation_send_status==1" @click="getsign('confirmation',confirmation_sign_status)"  style="margin-right: 20px;">{{confirmation_sign_status==1?'已提交':'确认提交'}}</span>
                                    <!-- <span v-if="send_status==1" @click="yulan(confirmation_file[0].path,'zhai','zhai')">阅读</span> -->
                                    <span>{{confirmation_send_status==0?'待后台发送':'后台已发送'}}</span>

                                </div>
                                
                            </div>
                            
                            <div class="yiyibtn themebgcolor" style="margin-top: 10px;" @click="objectionbtn" v-if="objection_button==1" >  
                                我有异议
                                <div style="color: red;font-size: 18px;">对于管理人审查确认的您的债权若无异议，请点击“确认提交”</div>
                            </div>
                            
                            <div class="result_list" v-if="objection==1" >
                                <span>债权异议复核审查确认通知书</span>
                                <div class="result_btn">
                                    <!-- <span v-if="objection_send_status==1" @click="getsign('objection',objection_sign_status)" :style="{color:objection_sign_status==0?'#333':'#ccc'}">{{objection_sign_status==1?'已签收':'确认签收'}}</span>
                                    <span v-if="objection_send_status==1" @click="yulan(back_input[0].path,'fuhe','fuhe')">阅读</span>
                                    <span v-if="objection_send_status==1" @click="loading(back_input[0].path,'fuhe','fuhe')">下载</span> -->
                                    <span class="zhaiload" v-if="objection_send_status==1" @click="loading(back_input[0].path,'fuhe')">阅读或下载</span>
                                    <span class="zhaisign" v-if="objection_send_status==1" @click="getsign('objection',objection_sign_status)">{{objection_sign_status==1?'已签收':'确认签收'}}</span>
                                    <span>{{objection_send_status==0?'待后台发送':'后台已发送'}}</span>
                                </div>
                            </div>
                        </div>
                       
                        <div v-if="review_opinion_is_show!==1&&confirmation_is_show!==1&&has_objection!==1&&objection!==1" class="nocontent">暂无债权结果</div>
                    </div>
                </div>
                <el-dialog title="提示" :visible.sync="dialogTableVisible">
                    <div class="zhailist2">1.请先阅读“债权确认书”，再确认是否确定提交。</div>
                    <div class="zhailist2">2.对债权数额、债权性质无异议的，请直接确认提交；若有异议，可选择“我有异议”，填写“异议申请表”。</div>
                    <div class="zhailist2">3.您可以不经提出异议而在债权人会议核查结束以后的15日内，直接向法院提起债权确认的诉讼。</div>
                    <!-- <div class="zhailist2">4.若需下载、打印“债权确认书”，请通过微信电脑端进入小程序，在【债权管理】操作。</div> -->
                    <div class="zhailist1" style="color: red;font-size: 22px;">请慎重选择</div>
                    <div style="display: flex;justify-content: space-around;">
                        <div class="flexbtn themebgcolor" @click="zhaibtn " >取消</div>
                        <div class="flexbtn themebgcolor" @click="zhaibtns">确定</div>
                    </div>
                </el-dialog>
                <el-dialog title="重要提示" :visible.sync="dialogExcelVisible">
                    <div style="text-indent: 2em;font-size: 16px;">1.对管理人审查确认的您本人的债权持有异议，需及时通过本系统填写提交异议申请表，并附上相关证据材料</div>
                    <div style="text-indent: 2em;font-size: 16px;">2.对管理人审查确认的 他人的债权提出异议的，需要下载并填写《异议申请表》，并亲自交到管理人处。</div>
                    <div style="text-indent: 2em;font-size: 16px;">3.管理人针对您的异议，有可能不予解释或调整。您若仍有异议，需在债权人会议核查结束后十五日之内，向受理破产案件的法院提起债权确认的诉讼。该期间为除斥期间，逾期则不再享有诉权。</div>
                    <div style="text-indent: 2em;font-size: 16px;">4.法律依据：请查阅并理解《最高人民法院关于<text class="iconfont" style="font-size: 12px;">&#xe606;</text>中华人民共和国破产法<text class="iconfont" style="font-size: 12px;">&#xe621;</text>若干问题的规定(三)》第八条、第九条之规定。
                    </div>
                    <div class="flexar">
                        <div @click="xiafile" class="setexcel themebgcolor">下载异议申请表</div>
                        <div @click="chuanfile" class="setexcel themebgcolor">填写异议申请表</div>
                    </div>
                </el-dialog>
                <el-dialog title="填写异议申请表" :visible.sync="dialogYiVisible">
                    <div class="yilist">
                        <span style="float: left;">异议人：</span>
                        <input type="text" placeholder="请填写异议人" v-model="personi" style="width: 45%;">
                        (提出异议的债权人)
                    </div>
                    <div>异议要求：</div>
                    <div class="yilist">
                        要求管理人在审查确认异议人的债权数额的基础上，调整增加异议人的债权数额
                        <input type="digit" v-model="addi" style="width: 50px;display: inline-block;">元
                    </div>
                    
                    <div style="font-size: 14px;line-height: 50px;">异议的事实与理由：<span style="font-size: 12px;">（简要描述相关事实和理由）</span></div>
                    <textarea placeholder="请简要描述相关事实和理由" v-model="facti"></textarea>
                    <div style="font-size: 14px;line-height: 50px;">证据名称：</div>
                    <textarea placeholder="请填写文字" v-model="zhengdata"></textarea>
                    <div style="color: red;font-size: 16px;">请先阅读或下载《债权确认书》</div>
                    <div class="tijiao themebgcolor" @click="yiyiti">提交</div>
                </el-dialog>

            </div>
             <!-- 提示框 -->
            <Toast :toastText="content" v-show="toastShow"></Toast>

             <!-- 图片放大 -->
            <div style='position:fixed;top:0;width:100%;height:100%;background-color:rgb(0,0,0,0.65);z-index: 999;' v-if="imgbigtype==true" @click="closeimgbig">
                <div class='modal' >
                    <img :src="imgbig"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'
    import Toast from '../../components/Toast.vue'

    import {ClaimsGetManageList,ClaimsCheckRecord,ClaimsSign,ClaimsObjection,ClaimsGetEvidenceList } from '@/request/api';

    export default {
        name: 'Manage',
        components: {
            Header,
            Toast
        },
        data(){
            return{
                baseURL:'https://pc.pochanjie.com',

                conlist_c: [],
                case_id:'',
                value: '',
                managetab:["我的申报","案件进度","审核结果"],
                currentzhai:0,
                casetype:0,
                type:'',
                // 内容详情
                company_name:'',
                claims_create:'',
                claims_expire:'',
                claims_id:'',
                creditor_id:'',
                can_detail:'',
                sn:'',
                claims_nature:'',
				check_result:'',
				charge_allocation_name:'',
				charge_allocation_bank_card_number:'',
				charge_allocation_bank_name:'',
				user_declare_claims_principal:'',
				user_declare_claims_interest:'',
				user_declare_claims_other:'',
                user_declare_claims_penalty:'',
                user_declare_claims_delay:'',
                user_declare_claims_total:'',
				enter_declare_claims_principal:'',
				enter_declare_claims_interest:'',
				enter_declare_claims_other:'',
                enter_declare_claims_delay:'',
                enter_declare_claims_penalty:'',
				enter_declare_claims_total:'',

				show1:false,
				show2:false,
				show3:false,
				documents:[],
				list:[],
				evidence:[],
				declaration_is_show:0,
				declaration_file:[],
				declaration_sign_status:0,
				declaration_send_status:0,
				
				review_opinion_is_show:0,
				review_opinion_file:[],
				review_opinion_status:0,
				sign_review_opinion_status:0,
				
				confirmation_is_show:0,
				confirmation_file:[],
				confirmation_send_status:0,
				confirmation_sign_status:0,
				objection_button:0,
				objection:0,
				has_objection:-1,
				objection_check:0,
				objection_send_status:0,
				result_money:0,
				back_input:[],
				objection_sign_status:0,
				
				inform_is_show:0,
				inform_file:[],
				inform_send_status:0,
				inform_sign_status:0,
				
                dialogTableVisible:false,
				dialogExcelVisible:false,
                dialogYiVisible:false,
                
                // 异议申请表
                personi:'',
				moneyi:'',
				addi:'',
				reducei:'',
				facti:'',
				srcarr1:[],
				imagestring1:'',
				claims_id:'',
				zhengdata:'',

				buttonname:'',
				status:'',
				is_submit:0,
				noqian:false,
				objectionbox:false,    //我有异议弹框
                file:'',
				
				zhaibox:false,   //债权确认书
				zhaiquebook:0,  //债权确认书阅读状态

                //其他
                content:'',
                toastShow:false,

                imgbigtype:false,
                imgbig:''
            }
        },
        created() {
            this.claimlist();
            this.case_id=this.$route.query.case_id;
            // 如果不选择案件，内容详情则为空

        },
        methods:{
            jobExperienceChange:function(e){
                var esn=e.lastIndexOf('\:')
                var evalue=e.substring(esn+1,e.length)

                this.casetype=1;
                for(var i=0;i<this.conlist_c.length;i++){
					if(evalue==this.conlist_c[i].sn){
						this.company_name=this.conlist_c[i].company_name;
						this.claims_create=this.conlist_c[i].claims_create;
						this.claims_expire=this.conlist_c[i].claims_expire;
						this.claims_id=this.conlist_c[i].claims_id;
                        this.type=this.conlist_c[i].type;
						this.case_id=this.conlist_c[i].case_id;
						this.creditor_id=this.conlist_c[i].creditor_id;
						this.can_detail=this.conlist_c[i].can_detail;
						this.sn=this.conlist_c[i].sn;
						this.claims_nature=this.conlist_c[i].claims_nature;
						this.check_result=this.conlist_c[i].check_result;
						this.charge_allocation_name=this.conlist_c[i].charge_allocation_name;
						this.charge_allocation_bank_card_number=this.conlist_c[i].charge_allocation_bank_card_number;
						this.charge_allocation_bank_name=this.conlist_c[i].charge_allocation_bank_name;

						this.user_declare_claims_principal=this.conlist_c[i].user_declare_claims_principal;
						this.user_declare_claims_interest=this.conlist_c[i].user_declare_claims_interest;
						this.user_declare_claims_other=this.conlist_c[i].user_declare_claims_other;
                        this.user_declare_claims_penalty=this.conlist_c[i].user_declare_claims_penalty;
                        this.user_declare_claims_delay=this.conlist_c[i].user_declare_claims_delay;
						this.user_declare_claims_total=this.conlist_c[i].user_declare_claims_total;

						this.enter_declare_claims_principal=this.conlist_c[i].enter_declare_claims_principal;
						this.enter_declare_claims_interest=this.conlist_c[i].enter_declare_claims_interest;
						this.enter_declare_claims_other=this.conlist_c[i].enter_declare_claims_other;
                        this.enter_declare_claims_penalty=this.conlist_c[i].enter_declare_claims_penalty;
                        this.enter_declare_claims_delay=this.conlist_c[i].enter_declare_claims_delay;
						this.enter_declare_claims_total=this.conlist_c[i].enter_declare_claims_total;
					}
				}
                var that=this;
				that.detail()
            },
            claimlist:function(){
                ClaimsGetManageList({    //案件列表
                    token:localStorage.getItem('token')
                }).then(res=>{
                    this.conlist_c=res.data;
                })
            },
            detail:function(){
                ClaimsCheckRecord({
                    token:localStorage.getItem('token'),
                    claims_id:this.claims_id
                }).then(res=>{
                    this.status=res.data.title.status;
					this.is_submit=res.data.title.is_submit;
					this.buttonname=res.data.title.button;
					this.list=res.data.list;
					// this.evidence=res.data.evidence_declaration.evidence;
					this.documents=res.data.documents;
                    if(res.data.declaration!=undefined){
                        this.declaration_is_show=res.data.declaration.is_show;
                        if(this.declaration_is_show==1){
                            this.declaration_file=res.data.declaration.files;
                            this.declaration_send_status=res.data.declaration.send_status;
                            this.declaration_sign_status=res.data.declaration.sign_status;
                        }
                    }
					
					// this.review_opinion_is_show=res.data.review_opinion.is_show;
					// if(this.review_opinion_is_show==1){
					// 	this.review_opinion_file=res.data.review_opinion.review_opinion_file;
					// 	this.review_opinion_status=res.data.review_opinion.review_opinion_status;
					// 	this.sign_review_opinion_status=res.data.review_opinion.sign_review_opinion_status;
					// }
                    this.objection_button=res.data.objection_button;   //我有异议
					this.confirmation_is_show=res.data.confirmation.is_show;
					if(this.confirmation_is_show==1){
						this.confirmation_file=res.data.confirmation.files;
						this.confirmation_send_status=res.data.confirmation.send_status;
						this.confirmation_sign_status=res.data.confirmation.sign_status;
					}
					this.objection=res.data.objection.is_show;
					if(this.objection==1){
						this.has_objection=res.data.objection.has_objection;
						this.back_input=res.data.objection.files;
						this.objection_sign_status=res.data.objection.sign_status;
						this.objection_send_status=res.data.objection.send_status;
						// this.result_money=res.data.objection.objection_check.result_money;
						// this.back_input=res.data.objection.objection_check.back_input;
						// this.objection_sign_status=res.data.objection.objection_check.objection_sign_status;
					}
					this.inform_is_show=res.data.inform.is_show;
					if(this.inform_is_show==1){
						this.inform_file=res.data.inform.files;
						this.inform_send_status=res.data.inform.send_status;
						this.inform_sign_status=res.data.inform.sign_status;
					}
					
					if(this.is_submit==0){
						if(this.status=='审核结束'){
							this.mayclick=false;
						}else{
							this.mayclick=true;
						}
					}else{
						this.mayclick=true;
					}
                })
                ClaimsGetEvidenceList({    //获取第三步信息
                    token:localStorage.getItem('token'),
                    claims_id:this.claims_id
                }).then(res=>{
                    if(res.code==1){
                        this.evidence=res.data;
                        // if(res.data.length!==0){
                        //     this.evidenceid=res.data.length+1;
                        // }
                    }
                })
            },
            // 我的申报
            look1:function(){
				this.show1=!this.show1;
				var tmp = this.conlist_c;
				this.conlist_c = [];
				this.conlist_c = tmp;
			},
			look2:function(){
				this.show2=!this.show2;
				var tmp = this.conlist_c;
				this.conlist_c = [];
				this.conlist_c = tmp;
			},
			look3:function(){
				this.show3=!this.show3;
				var tmp = this.conlist_c;
				this.conlist_c = [];
				this.conlist_c = tmp;
			},
            loading:function(url,type){    //文件下载
                if(type=='que'){
                    if(this.inform_sign_status==0){
                        this.content="请先签收《债权审查确认通知书》,才可阅读、下载、打印"
                        this.toastShow=true
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                    }else{
                        window.open(this.baseURL+url)
                    }  
                }
                
                if(type=='zhai'){    //债权确认书
                    if(this.inform_sign_status==0){
                        this.content="请先签收并阅读《债权审查确认通知书》"
                        this.toastShow=true
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                    }else{
                        window.open(this.baseURL+url)
                    }
                }

                if(type=='fuhe'){
                    // if(this.objection_sign_status==0){
                    //     this.content="请先签收并阅读《债权异议复核审查确认通知书》"
                    //     this.toastShow=true
                    //     setTimeout(() => {
                    //         this.toastShow=false;
                    //     }, 1500);
                    // } else{
                        window.open(this.baseURL+url)
                    // } 
                }

                
            },
            xiazai:function(url){
                window.open(this.baseURL+url)
            },
            getsign:function(file,status){    //签收
				var that=this;
				if(status==0){
					if(file=='confirmation'&& that.has_objection ==0){
                        that.dialogTableVisible=true;
                        that.file=file;
						that.content='请先阅读，若无异议，请确认提交';
                        that.toastShow=true;
                        setTimeout(() => {
                            that.toastShow=false;
                           
                        }, 1500);
					}else{
                        ClaimsSign({
                            token:localStorage.getItem('token'),
                            claims_id:that.claims_id,
                            file:file
                        }).then(res=>{
                            if(res.code==1){
                                that.content=res.msg;
                                that.toastShow=true;
                                setTimeout(() => {
                                    that.toastShow=false;
                                }, 1500);
                            }
                            that.detail();
                        })	
                    }
                    
				}
			},

            // 案件进度
            againsends:function(){   //重新提交
				if(this.is_submit==0){
                    this.$router.push({path:'/Debtordetail',query:{creditor_id:this.creditor_id,claims_id:this.claims_id,case_id:this.case_id,from:'Manage',typeindex:this.type}})
				}else{
					return;
				}
			},
            // 审核结果
            zhaibtn:function(){
                this.dialogTableVisible=false;
            },
            zhaibtns:function(){
                this.dialogTableVisible=false;
                var　that=this;
                ClaimsSign({
                    id:that.claims_id,
                    file:this.file
                }).then(res=>{
                    that.detail();
                })	
            },
            objectionbtn:function(){
                this.dialogExcelVisible=true;
            },
            xiafile:function(){
				var that=this;
				window.open("https://pc.pochanjie.com/uploads/20220120/95349088c6d5b280805abcecc38d074a.pdf")
			},
            chuanfile:function(){
                this.dialogYiVisible=true;
            },
            yiyiti:function(){
				var that=this;
                if(that.personi==''){
                    that.content="请填写提出异议的债权人";
                    return
                }
				if(that.addi==''){
					that.content="请填写管理人调整增加的债权数额"  
                    return

				}
				if(that.facti==''){
					that.content="请填写事实和理由"
                    return

				}
                if(that.zhengdata==''){
                    that.content="请填写证据名称"
                    return

                }

				// if(that.addi!==''||that.facti!==''){
                //     that.content="数据正确"
                    ClaimsObjection({
                        token:localStorage.getItem('token'),
                        claims_id:that.claims_id,
                        creditor:that.personi,
                        // amount:that.moneyi,
                        add_amount:that.addi,
                        // reduce_amount:that.reducei,
                        fact:that.facti,
                        apply_input:that.zhengdata
                    }).then(res=>{
                        that.content=res.msg;
                        setTimeout(() => {
                            that.dialogYiVisible=false;
                            that.dialogExcelVisible=false;
                            that.addi='';
                            that.facti='';
                            that.personi='';
                            that.zhengdata=''
                        }, 1500);
                        that.detail()

                    })
                // }
                
			},

            

            tabclick:function(index){
                this.currentzhai=index
            },

            openword:function(path){
                window.open('https://view.officeapps.live.com/op/view.aspx?src=' + path)  // 微软提供的方法
            },
            look:function(path){
                this.imgbigtype=true;
                this.imgbig=this.baseURL+path;
            },
            closeimgbig:function(){
                this.imgbigtype=false
            },
            
        }
    }
</script>
<style scoped>
    .Network_box{
        background: #eef3fa;
        padding-bottom: 2rem;
        min-height: 100%;
        display: flex;
        width: 86.4%;
        margin: 0 auto;
        margin-top: 45px;
        justify-content: space-between;
    }
    .Network_tit{
        width: 52px;
        height: 138px;
        background: #43A0F5;
        border: 2px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        margin-top: 82px;
    }
    .Manage_box{
        width: 90%;
    }
    .el-select{
        width: 372px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #E2E2E8;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .Manage_con{
        margin: 30px auto 0;
        /* margin-top: 5rem; */
        background: #ffffff;
        border: 1px solid #e7eef5;
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        padding: 0 0 40px 0;
    }
    .Manage_tab{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        text-align: center;
        font-size: 16px;
        font-family: 'siyuanb';
        line-height: 40px;
        cursor: pointer;
    }
    .Manage_tab .Manage_tab_active{
        background: #43A0F5;
        color: #fff;
    }
    .Manage_tab div{
        width: 33.3%;
        height: 40px;
        
        background: #fff;
        border: 1px solid #E2E2E8;
        box-sizing: border-box;
        border-radius: 5px 0px 0px 0px;
    }
    /* 第一部分内容 */
    .company_name{
        font-size: 22px;
        line-height: 24px;
        /* display: flex; */
        /* align-items: center; */
        text-align: center;
        
        
        margin: 31px auto 12px;
    }
    .company_time{
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        
        
    }
    .Manage_mine{
        font-size: 18px;
        line-height: 30px;
        
        
        margin: 30px 38px;
    }
    .Manage_mine_lists_box{
        width: 97.6%;
        background: #EEF3FA;
        border-radius: 5px;
        padding: 0 18px;
        margin: 0 auto;
        margin-top: 8px;
    }
    .Manage_mine_lists_tit{
        display: flex;
        justify-content: space-between;
        line-height: 40px;
        margin-bottom: 15px;
    }
    /* 第二部分 */
    .shen_box{
        background: #FFFFFF;
        border: 0.7px solid #DFE5F9;
        box-sizing: border-box;
        border-radius: 12px;
        width: 43.3%;
        margin: 16px auto 30px;
    }
    .shen_tit{
        color: red;
        text-align: center;
        height: 45px;
        font-size: 20px;
        font-family: 'siyuanb';
        line-height: 45px;
    }
    .shen_list{
        display: flex;
        justify-content: space-between;
        background: #FAFBFF;
       
        box-sizing: border-box;
        padding: 0 20px;
        border-left: 0;
        border-right: 0;
        height: 40px;
        align-items: center;
        font-size: 22px;
        color:#706E8C ;
    }
    .shen_list:nth-child(1){
        border: 0.7px solid #DFE5F9;
    }
    .shen_list:nth-child(1) span:nth-child(2){
        color:  #FF7474;
    }
    .shen_list td:nth-child(1){
        width: 30%;
    }
    .shen_list td:nth-child(2){
        line-height: 20px;
    }
    .againsend{
        width: 90%;
        height: 45px;
        margin: 0 auto;
        border-radius: 5px;
        text-align: center;
        color: #fff;
        line-height: 45px;
        font-size:18px;
        cursor: pointer;
        background: #43A0F5;
    }
    .yiyibtn{
        width: 60%;
        height: 45px;
        margin: 0 auto;
        border-radius: 5px;
        text-align: center;
        color:#fff;
        line-height: 45px;
        font-size:18px;
        cursor: pointer;
    }
    .graycolor{
        background: #EEEEEE;
    }
    .nocontent{
        text-align: center;
        line-height: 100px;
        font-size: 16px;
    }
    /* 第三部分 */
    .result_list{
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        width: 97%;
        margin: 0 auto;
        border-bottom: 1px dashed #8a8a8a4d;
        height: 60px;
        align-items: center;
    }

    .result_list:last-child{
        border: none;
    }
    .result_btn{
        display: flex;
        height: 60px;
        align-items: center;
    }
    .result_btn div{
        width: 60px;
        height: 30px;
        border-radius: 5px;
        text-align: center;
        line-height: 30px;
        margin-right: 10px;
        color: #fff;
        cursor: pointer;
    }
    .result_btn div:nth-child(1){
        background: #43A0F5;
    }
    .result_btn div:nth-child(2){
        background: #20CB8D;

    }
    .result_btn div:nth-child(3){
        background: #FE9B4B;

    }

    /* */
    .booklist{
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 50px;
    }
    .zhaiload{
        background: green;
        color: #fff;
        padding: 0 10px;
        border-radius: 5px;
        cursor: pointer;
        margin: 0 20px;

    }
    .zhaisign{
        margin: 0 20px;
        background: orange;
        color: #fff;
        padding: 0 10px;
        border-radius: 5px;
        cursor: pointer;
    }
    .zhailist1{
        text-align: center;
        font-size: 22px;
        line-height: 50px;

    }
    .zhailist2{
        line-height: 30px;
        font-size: 24px;
        text-align: left;
        margin-bottom: 10px;
    }
    .flexbtn{
        width: 40%;
        height: 4rem;
        line-height: 4rem;
        text-align: center;
        color: #fff;
        border-radius: 5px;
        font-size: 20px;
        cursor: pointer;
    }
    .objectionboxs{
        background: #fff;
        width: 60%;
        height: 400px;
        padding: 20px;
        border-radius: 10px;
        margin: 0 auto;
        margin-top: 60px;
        font-size: 18px;
        overflow-y: scroll;
    }
    .setexcel{
        width: 40%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin: 0 auto;
        border-radius: 10px;
        /* background: #108ee9; */
        color: #FFFFFF;
        font-size: 18px;
        margin-top: 25px;
        cursor: pointer;
    }
    .activehide{
        display: none;
    }

    .yilist{
        width: 100%;
        height: 50px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .yilist text:nth-child(1){
        float: left;
    }
    .yilist input:nth-child(2){
        float: left;
        width: 20%;
        outline: none;
        height: 30px;
        line-height: 30px;
    }
    .yilist text:nth-child(3){
        /* float: left; */
        font-size: 16px;
        display: block;
        color: #999999;
    }
    textarea{
        border: 1px solid #a2a0a0;
        border-radius: 10px;
        padding: 10px;
        font-size: 18px;
        width: 300px;
        height: 200px;
    }
    .tijiao{
        width: 40%;
        height: 45px;
        margin: 0 auto;
        border-radius: 5px;
        text-align: center;
        color: #fff;
        line-height: 45px;
        margin-top: 30px;
        font-size:18px;
        cursor: pointer;
    }

/* 图片放大 */

.modal {
    margin:auto;
    width: 50%;
    z-index: 1;
}
 
.modal img {
    display: block;
    padding: 10px;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 2px 6px rgb(0, 0, 0, 0.2), 0 10px 20px rgb(0, 0, 0, 0.2);
    border-radius: 12px;
    /* border: 1px solid white; */
}
</style>