<template>
    <div class="Bottom_box">
        <div class="grids_title flexbe">
           <div class="grids_title_name">管理人名册</div>
           <div class="more" @click="Gomore">更多></div>
           <!-- <dv-decoration-10 style="width:90%;height:5px;position: absolute;bottom: 0;" :color="['#43A0F5','#ccc']" dur="6"/> -->
        </div>
        <!-- <div class="grids_con_guanliren" v-for="(item,index) in namelist">
           <span></span>{{item}}
        </div> -->
        <el-carousel :interval="3000" arrow="always" style="width: 100%;" v-if="guanlirenlist.length!==0">
            <el-carousel-item v-for="(item,index) in guanlirenlist" :key="index" >
                <div class="Day_con_list">
                    <div class="Admin_list_card">
                        <div class="Admin_list_card_name">{{item.link_man}} <span>机构联系人</span></div>
                        <div class="Admin_list_card_mess"><span class="iconfont icon-Frame-21"></span>{{item.name}}</div>
                        <div class="Admin_list_card_mess"><span class="iconfont icon-Frame-23"></span>{{item.phone}}</div>
                        <div class="Admin_list_card_mess"><span class="iconfont icon-Frame-22"></span>{{item.address}}</div>
                        <img src="../image/logo.png" alt="">
                    </div>
                    <div class="Admin_list_message flexbe">
                        <div class="Admin_list_message_con_tit">
                            <!-- <span class="iconfont icon-Frame3" style="font-size: 45px;"></span> -->
                            联系方式
                        </div>
        
                        <div class="Admin_list_message_con">
                            <div>
                                <span class="iconfont icon-Frame-26"></span> 咨询电话
                            </div>
                            <div>{{item.phone}}</div>
                        </div>
                        <div class="Admin_list_message_con">
                            <div>
                                <span class="iconfont icon-Frame-24"></span> 加我微信
                            </div>
                            <div>{{item.weixin==''?'暂无微信':item.weixin}}</div>
                        </div>
                    </div>
                </div>
                
            </el-carousel-item>
        </el-carousel>
        <Nocontent v-if="guanlirenlist.length==0"></Nocontent>
    </div>
</template>
 
<script>
import {PersonInstitutionList,InstitutionCheck} from '@/request/api';
import Nocontent from '../components/Nocontent.vue'

    export default {
        name: 'Guanliren',
        components: {
            Nocontent
        },
        data(){
            return{
               pro:'',
               city:'',
               page:'1',
               guanlirenlist:[],
               namelist:[],
               toastShow:false,
               content:'',
               token:localStorage.getItem('token'),
            }
        },
        created() {
           this.list()
        },
        methods:{
            list:function(){
                PersonInstitutionList({
                    token:localStorage.getItem('token'),
                    pro:this.pro,
					city:this.city,
					page:this.page,
					limit:5,
                    type: -1,
                    name:''
                }).then(res=>{
                    // var aa=[];
                    this.guanlirenlist=res.data.rows;
                    // for(var i=0;i<aa.length;i++){
                    //     this.namelist.push(aa[i].name)
                    // }
                    // this.guanlirenlist=aa.slice(0,1)
                })
            },
           Gomore:function(){
                this.$router.push('/Administrators')
                // InstitutionCheck({

                // }).then(res=>{
                //     if(res.data.check==1){
                //         this.$router.push('/Administrators')
                //     }else{
                //         this.$emit('childByValue', '暂无权限进入')
                //         // this.content='暂无权限进入'
                //         // this.toastShow=true;
                //         // setTimeout(() => {
                //         //    this.toastShow=false
                //         // }, 1500);
                //     }
                // })
           }
        }
    }
</script>
 
<style scoped>


.grids_con_guanliren{
    /* height: 45px; */
    display: flex;
    align-items: center;
    color: #828294;
    /* font-size: 16px; */
    font-size: 1.6rem;
    font-family:'siyuanb';
    margin-bottom: 20px;
}

.grids_con_guanliren span{
    width: 12px;
    height: 12px;
    background: #6F6C99;
    border-radius: 50%;
    margin-right: 9px;
    opacity: 0.6;
}
.Day_con_list{
    /* width: 32.3%; */
    /* height: 172px; */
    /* margin-right: 20px; */
    position: relative;
    margin-bottom: 24px;
}
.Day_con_list:nth-child(3n+3) {
    margin-right: 0;
}
.Admin_list_card{
    height: 130px;
    background: radial-gradient(55.69% 86.43% at 82.36% 8.6%, #59597C 0%, #333E63 100%);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 40%);
    backdrop-filter: blur(20px);
    border-radius: 8px;
    padding: 25px 20px;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
}
.Admin_list_card_name{
    font-size: 18px;
    font-family: 'siyuanb';
}
.Admin_list_card_name span{
    font-size: 14px;
    font-family: 'siyuanr';
    margin-left: 29px;
}
.Admin_list_card_mess{
    font-size: 14px;
    margin-top: 13px;
}
.Admin_list_card_mess span{
    margin-right: 6px;
}
.Day_con_list img{
    width: 107px;
    height: 107px;
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 50%;
}
/* 卡片下 */
.Admin_list_message_con_tit{
    height: 64px;
    width: 27.62%;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    line-height: 64px;
    background: linear-gradient(
210.55deg
, #FE9B4B 19.14%, #FFA861 154.68%);
    box-shadow: 0px 10px 20px rgb(18 61 101 / 15%), inset -8px -6px 30px rgb(255 255 255 / 18%);
    border-radius: 8px;
}
.Admin_list_message_con{
    background: linear-gradient(
210.55deg
, #EBF2F9 19.14%, #C6D7EB 154.68%);
    box-shadow: 0px 10px 20px rgb(18 61 101 / 15%), inset -8px -6px 30px rgb(255 255 255 / 18%);
    border-radius: 8px;
    width: 33.8%;
    height: 64px;
    text-align: center;
    font-size: 14px;
}
.Admin_list_message_con div:nth-child(1){
    margin: 8px auto 7px;
}
.Admin_list_message_con div:nth-child(1) span{
    color: #FEA55E;
    font-size: 18px;
}
.el-carousel__indicators--horizontal{
  display: none;
}
.el-carousel__arrow{
  display: none;
}
</style>