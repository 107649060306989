<template>
    <div class="Bottom_box">湖北谋道清算实务管理有限公司   版权所有
    </div>
</template>
 
<script>
    export default {
        name: 'Bottommin',
        data(){
            return{
               
            }
        },
        created() {
           
        },
        methods:{
           
        }
    }
</script>
 
<style scoped>
.Bottom_box{
    /* min-width: 1500px; */
    background: #1265a0;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 0.8rem;
}

</style>