<template>
    <div class="backcolor"  style="min-width: 1200px;">
        <Header></Header>
        <div class="Meet_box">
            <div class="Meet_box_inside">
                <div class="Meet_box_video">
                    <div class="Meet_box_detail">
                        <!-- 主播 -->
                        <div id='local_stream' class="local-stream" style="height: 100%;background-color: #000000;">
                        </div>
                        <!-- 主播未进入 -->
                        <div style="font-size: 16px;color: #fff;position: absolute;top: 0;left: 0;line-height: 100px;text-align: center;width: 100%;" v-if="roles!=='play'&&roomtype==false&&videostart==true">主播还未进房，请耐心等待...</div>
                        <!-- 主播退房 -->
                        <div class="iconfont icon-Frame-31" @click="leaveRoom" v-if="roles=='play'&&leavetype==true" style="position: absolute;right: 10px;top: 10px;color: #fff;font-size: 30px;cursor: pointer;"></div>
                        <!-- 观众4个 -->
                        <div class="audiencebox" :style="{bottom:roles=='aid'?'120px':'60px'}">
                            <div style="width: 40%;height: 100px;overflow: scroll;position: relative;">
                                <div ref="my_scroller" class="scrollclass" id="aaaa"> 
                                    <div class="infinite-list-item" v-for="(item,index) in huilist" :key="index"
                                        style="line-height: 20px;font-size: 12px;word-break: break-all;color: #fff;height:auto"
                                        >
                                        {{item.left}}:{{item.right}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 播放按钮 -->
                        <div class="iconfont icon-bofang" v-if="playbtn==true" @click="GoSign"
                            style="position: absolute;font-size: 100px;left: 45%;top: 40%;color:#fff;cursor: pointer;">
                        </div>
                        <!-- 普通操作按钮 -->
                        <div class="Meet_box_detail_btn" v-if="videostart==true" >
                            
                            <div class="detail_input">
                                <input type="text" placeholder="请输入发送内容" v-model="huicon" @keyup.enter="sendcon">
                                <div class="sendbtn" @click="sendcon">发送</div>
                            </div>
                        </div>
                        

                    </div>
                </div>
                
            </div>
        </div>
        <el-dialog width="30%" title="播放地址" :visible.sync="pusherVisible">
            <div style="color: red;text-align: center;line-height: 50px;font-size: 17px;">{{pusheraddress}}</div>

            <div class="flexar devicebox">
                <button class="btns" @click="pusherplay('cancel')">取消</button>
                <!-- <div @click="pusherplay('sure')" :style="{background:device=='sure'?'orange':'',color:device=='sure'?'#fff':''}">复制</div> -->
                <button class="btns" :data-clipboard-text="pusheraddress" @click="copy">复制</button>
            </div>
        </el-dialog>
        <!-- 提示框 -->
        <Toast :toastText="content" v-show="toastShow"></Toast>
    </div>
</template>
<script src="http://apps.bdimg.com/libs/jquery/1.9.1/jquery.min.js"></script>
<!-- <script src="https://web.sdk.qcloud.com/player/tcplayerlite/release/v2.4.1/TcPlayer-2.4.1.js" charset="utf-8"></script> -->
<!-- <script src="https://cloudcache.tencent-cloud.com/open/qcloud/video/vcplayer/TcPlayer-2.3.2.js" charset="utf-8"></script> -->
<script>
    import Header from '../../components/Header.vue'
    import Toast from '../../components/Toast.vue'
    // import VideoPlayer from '../../components/VideoPlayer.vue'


    import {StudyUrl,PullStream} from '@/request/api';

    import LibGenerateTestUserSig from '../../../public/lib-generate-test-usersig.min.js'

    //导入sdk
    import TRTC from "trtc-js-sdk";
    import TIM from "tim-js-sdk";
    export default {
        name: 'Meet',
        components: {
            Header,
            Toast,
            // VideoPlayer
        },
        data() {
            return {
                videoUrl: 'rtmp://117.132.5.213:1935/live/openUrl/WFZqpGg',
                signsuccessvisible: false,
                deviceVisible:false,
                roomtype:false, //主播是否进入房间
                device:'',
                videodetail:{},
                pull: 0,
                nowvideoname: '',

                //视频
                // userId: 'user_' + parseInt(Math.random() * 100000000),//用户id --可更改 
                userId: localStorage.getItem('uid'),
                // userId: 'video1539',
                // roomId: 888888,//房间号--加入相同房间才能聊
                client: '',//客户端服务
                list: [], // 远端流列表
                remoteStream: '',
                isVedio: true, // 当前本地是否打开摄像头
                isAudio: true, // 当前本地是否打开麦克风
                members_: new Map(), // 成员
                isHave: false, //当前列表是否有该成员
                localStream: '',//本地流
                anchor_id:'',
                // tim
                timInst: '',
                timChatGroupId: '',
                timRoomType: TIM.TYPES._CHATROOM, // 最新版的API中没有该类型，但为了兼容小程序端，才使用这个和小程序保持一致
                playbtn: true,
                videostart: false,  //开始会议
                leavetype:false,   //退出状态
                //会议
                otherlist: [],
                roles: '',
                show_button: 0,
                current: 0,
                voteelectvisible: false,
                meeting_id: '',
                // 参会人
                participant_status: 'aaa',
                joinlist: [],
                joinvisible: false,
                //统计
                statistic_status: 'aaa',
                tongjivisible: false,
                election_status: '',
                election_data: '',
                tongjidata: '',
                theme: '',

                //表决
                vote_status: 'aaa',
                biaolist: [],
                biaojuevisible: false,
                innerVisible: false,
                yiinnerVisible:false,   //表决内部弹出
                yijianinnerVisible:false,    
                items1: ["同意", "反对"],
                biaocurrent: -1,
                case_user_id: '',
                biaotan: false,
                biaotanvisible: false,
                memberbiao: [],
                result: '',
                biaotitle: '',
                // 上麦
                maivisible: false,
                mailist: {},

                //上麦管理
                maiguanvisible: false,
                maiarr: [],

                //禁言
                jintype: 'aaa',

                // 聊天
                huilist: [],
                huicon: '',
                scrollTop: 0,
                count: 5,

                //播放弹框
                pusherVisible:false,
                pusheraddress:'',
                playLinkAddr:'',
                itemid:'',
                requestId:'',

                content: '',
                toastShow: false,

                clientRole: 'audience',
                microphonestype:false,
                camerastype:false,
                devicescontent:'',

                play_schema:'',
                pusherVisible:false,

            }
        },
        created() {
            this.id=this.$route.query.id;
            this.play_schema=this.$route.query.play_schema;
            this.roles=this.$route.query.roles;
            TRTC.getCameras().then(devices => {   //摄像头
                if(devices.length>0){   //没有摄像头
                    this.camerastype=true
                }
            })
            TRTC.getMicrophones().then(devices => {   //麦克风
                if(devices.length>0){
                    this.microphonestype=true

                }
            })
        },
        mounted() {
        },
        destroyed() {
            this.leaveRoom();
            this.logoutIm();
        },
        methods: {
            // 聊天回复
            sendcon: function () {
                if (this.huicon == '') {
                    this.content = "发送内容为空";
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
                } else {
                    var roles = '';
                    if (this.roles == 'member') {
                        roles = "债权人"
                    } else if (this.roles == 'manage') {
                        roles = "主持"
                    } else if (this.roles == 'play') {
                        roles = "主播"
                    } else if (this.roles == 'work') {
                        roles = "法院工作人员"
                    } else {
                        roles = "助手"
                    }

                   
                    var that = this;
                    let message = this.timInst.createCustomMessage({
                        to: that.timChatGroupId,
                        conversationType: TIM.TYPES.CONV_GROUP,
                        payload: {
                            data: '聊天',
                            description: this.huicon,
                            extension: localStorage.getItem('nickname') + '(' + roles + ')'
                        },
                    });
                    this.timInst.sendMessage(message).then(function (res) {
                    }).catch(function (err) {
                        console.warn('聊天失败:', err);
                    });
                    this.huilist.push({ left: localStorage.getItem('nickname') + '(' + roles + ')', right: this.huicon })
                    this.huicon = '';
                    setTimeout(function() {
                        document.getElementById("aaaa").scrollIntoView(false);
                    },10)
                }
            },
            
           
           
            // 视屏
            //语音互动直播(VoiceChatRoom) 视频互动直播(Live) 语音通话(VoiceCall) 视频通话(VideoCall)
            //第一步：快速跑通demo,创建应用，获取SDKAPPID，SECRETKEY
            //SDKAppID（应用标识/应用 ID）是腾讯云后台用来区分不同 TRTC 应用的唯一标识
            //UserID（用户标识）用于在一个 TRTC 应用中唯一标识一个用户。
            //集成：npm install trtc-js-sdk --save
            //第二步：创建链接  创建 Client 对象

            createClient(userId) {
                const config = this.genTestUserSig(userId)
                const sdkAppId = config.sdkAppId
                const userSig = config.userSig   //获取签名
                this.client = TRTC.createClient({
                    mode: 'live',
                    sdkAppId,
                    userId,
                    userSig
                });
                //注册远程监听，要放在加入房间前--这里用了发布订阅模式
                this.subscribeStream(this.client)
                this.checksystem();
                this.playStream(this.client)
                
                //初始化后才能加入房间

            },
            initIM: function () {
                let options = {
                    SDKAppID: 1400395034
                };
                this.timInst = TIM.create(options); //创建 IM SDK 实例

                // 设置 SDK 日志输出级别，
                this.timInst.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
                // 监听事件，例如：
                this.timInst.on(TIM.EVENT.SDK_READY, function (event) {
                    // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
                    // event.name - TIM.EVENT.SDK_READY
                });
                this.timInst.on(TIM.EVENT.SDK_READY, this.onSdkReady, this);
                this.loginIm()
                var that = this;
                let onMessageReceived = function (event) {
                    for (var i = 0; i < event.data.length; i++) {
                        
                        if (event.data[i].payload.data == '聊天') {
                            that.huilist.push({ left: event.data[i].payload.extension, right: event.data[i].payload.description })
                            setTimeout(function() {
                                document.getElementById("aaaa").scrollIntoView(false);
                            },10)
                        }
                        if (event.data[i].payload.data == '主播进房') {
                            // that.anchor_id = event.data[i].payload.description;
	                        localStorage.setItem('anchor_id', event.data[i].payload.description);
                            that.huilist.push({ left: '主播', right: '主播进房'})
                            setTimeout(function() {
                                document.getElementById("aaaa").scrollIntoView(false);
                            },10)
                        }
                        if (event.data[i].payload.data == '主播退房') {
                            $("#local_stream").children().remove();
                            that.huilist.push({ left: event.data[i].payload.description, right: event.data[i].payload.extension })
                            setTimeout(function() {
                                document.getElementById("aaaa").scrollIntoView(false);
                            },10)
                            $("#local_stream").children().remove();
                        }

                        
                    }
                    // event.data - 存储 Message 对象的数组 - [Message]
                };
                that.timInst.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
            },
            loginIm: function () {
                let userID = this.userId, userSig = '';
                if (this.timInst) {
                    let sigResult = this.genTestTimSig(userID);
                    userSig = sigResult.userSig;
                    let options = { userID: userID, userSig: userSig };
                    let promise = this.timInst.login(options);
                    promise.then((res) => {
                        if (res.data.repeatLogin === true) {
                            // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
                        }
                    }).catch(function (err) {
                        console.warn('TIM登录失败:', err); // 登录失败的相关信息
                    });
                }
            },
            checksystem() {
                TRTC.checkSystemRequirements().then(checkResult => {
                    if (checkResult.result) {
                        // 支持进房
                        var roomId = parseInt(this.meeting_id);
                        this.client.join({ roomId, role: 'anchor' }).catch(error => {
                        }).then(() => {
                            //主播的时候创建本地流，游客不能创建本地流 ||this.roles=='aid'
                            if(this.roles=='play'){
                                this.clientRole = 'anchor'
                                if (this.clientRole == 'anchor') {
                                    this.createStream(this.userId)   //创建本地流
                                    var that=this;
                                    let message = this.timInst.createCustomMessage({
                                        to: that.timChatGroupId,
                                        conversationType: TIM.TYPES.CONV_GROUP,
                                        payload: {
                                            data: '主播进房',
                                            description: this.userId
                                        },
                                    });
                                    localStorage.setItem('anchor_id',this.userId)
                                    that.timInst.sendMessage(message).then(function (res) {
                                    }).catch(function (err) {
                                        console.warn('主播进房失败', err);
                                    });
                                }
                            }else{
                                localStorage.setItem('anchor_id','000')
                            }
                            //任何身份，播放远端流
                            this.playStream(this.client) 

                        });
                        if (checkResult.isH264DecodeSupported) {
                            // 支持拉流
                        }
                        if (checkResult.isH264EncodeSupported) {
                            // 支持推流
                        }
                    }
                })
            },
            onSdkReady: function () {
                if (!this.timInst) {
                    return
                } else {
                    let timInst = this.timInst;
                    let timToomType = this.timRoomType;

                    // 以会议房间号为GroupId，查询Group是否已被创建
                    let groupId = this.timChatGroupId;
                    let promise = this.timInst.searchGroupByID(groupId);
                    promise.then((imResponse) => {//  如果已创建，则检查是否用户是否已加入，已加入则不做处理，未加入则加入；
                        this.joinTimGroup();
                        const group = imResponse.data.group; // 群组信息
                    }).catch((imError) => {//  如果未创建，则先创建Group，然后用户再加入
                        console.warn('searchGroupByID error:', imError); // 搜素群组失败的相关信息
                        let promise = timInst.createGroup({
                            type: timToomType,
                            name: 'MeetingTimGroup' + groupId,
                            groupID: groupId
                        });
                        promise.then((imResponse) => { // 创建成功
                            this.joinTimGroup();
                        }).catch(function (imError) {
                            console.warn('createGroup error:', imError); // 创建群组失败的相关信息
                        });
                    });
                }
            },
            joinTimGroup: function () {
                if (!this.timInst) {
                    return;
                } else {
                    let promise = this.timInst.joinGroup({ groupID: this.timChatGroupId, type: this.timRoomType });
                    promise.then((imResponse) => {
                        switch (imResponse.data.status) {
                            case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
                                console.log('...等待管理员同意');
                                break;
                            case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
                                console.log('...加群成功');
                                console.log(imResponse.data.group); // 加入的群组资料
                                break;
                            case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
                                console.log('...已经在群中');
                                break;
                            default:
                                break;
                        }
                    }).catch(function (imError) {
                        console.log('进群失败');
                        console.warn('joinGroup error:', imError); // 申请加群失败的相关信息
                    });

                    return promise;
                }



            },
            getTimRooms: function () {
                let promise = this.timInst.getGroupList();
                promise.then(function (imResponse) {
                    console.log(imResponse.data.groupList); // 群组列表
                }).catch(function (imError) {
                    console.warn('getGroupList error:', imError); // 获取群组列表失败的相关信息
                });
            },
            getTimRoom: function () {
                let promise = this.timInst.getGroupProfile({ groupID: this.timChatGroupId });
                promise.then(function (imResponse) {
                    console.log(imResponse.data.group);
                }).catch(function (imError) {
                    console.warn('getGroupProfile error:', imError); // 获取群详细资料失败的相关信息
                });
            },
            searchTimRoom: function () {
                console.log('searchTimRoom..');
                let promise = this.timInst.searchGroupByID(this.timChatGroupId);
                promise.then(function (imResponse) {
                    console.log('searchTimRoom....');
                    const group = imResponse.data.group; // 群组信息
                    console.log(group);
                }).catch(function (imError) {
                    console.warn('searchGroupByID error:', imError); // 搜素群组失败的相关信息
                });
            },
            logoutIm: function () {
                if (this.timInst) {
                    let promise = this.timInst.logout();
                    promise.then((imResponse) => {
                        console.log('登出成功');
                        console.log(imResponse.data); // 登出成功
                    }).catch(function (imError) {
                        console.warn('logout error:', imError);
                    });
                }
            },
            //加入房间
            // joinRoom(client, roomId) {
            //     //anchor：主播， audience：观众
            //     client.join({ roomId, role: 'anchor' }).catch(error => {
            //         console.log('进房失败 ' + error);
            //     }).then(() => {
            //         console.log('进房成功');
            //         this.createStream(this.userId)//创建本地流
            //         this.playStream(this.client) //播放远端流
            //     });
            // },

            //创建本地音视频流
            createStream(userId) {
                const localStream = TRTC.createStream({ userId, audio: true, video: true });
                this.localStream = localStream;
                var id=localStream.id_;
                localStream.initialize().catch(error => {
                }).then(() => {
                    if(this.clientRole=='anchor'){
                        localStream.play('local_stream');// 创建好后才能播放 本地流播放 local_stream 是div的id
                    }else{
                        this.remoteStream = `<div id="${'remote_stream-' + id}" style="width:100%;height:100%"></div>`
                        if (!this.isHave) {
                            this.list.push({
                                id,
                                userId: userId,
                                remoteStream: this.remoteStream,
                                origin: localStream
                            })
                        } else {
                            this.list.splice(this.list.findIndex((v) => v.userId === userId), 1, {
                                id,
                                userId: userId,
                                remoteStream: this.remoteStream,
                                origin: localStream
                            })
                        }
                        this.$nextTick(() => {
                        //播放
                            // $("#local_stream").empty();
                            localStream.play('remote_stream-' + id);
                        
                            let audio = $('#audio').clone()
                            audio.attr('id', 'audio_' + id)
                            audio.css({
                                'width': '14px',
                                'height': '21px',
                                'background': 'none',
                                'margin-left': 'auto'
                            })
                            let unaudio = $('#unaudio').clone()
                            unaudio.attr('id', 'unaudio_' + id)
                            unaudio.css({
                                'width': '14px',
                                'height': '21px',
                                'background': 'none',
                                'margin-left': 'auto'
                            })

                            let mask = $('#mask').clone()
                            mask.text('')
                            mask.attr('id', 'mask_' + id)
                            mask.css({
                                'width': '100%',
                                'height': '100%',
                                'text-align': 'center',
                                'z-index': '99'
                            })
                            mask.appendTo($('#player_' + id))
                            mask.hide()

                            let status = $('<div>', {
                                id: 'status_' + id
                            })
                            status.css({
                                'width': '100%',
                                'height': '100%',
                                'text-align': 'center',
                                'color': '#fff',
                                'display': 'flex',
                                'align-items': 'center',
                                'justify-content': 'center'
                            })
                            status.html('<span>已离开会议<span>')
                            status.appendTo($('#player_' + id))
                            status.hide()
                            $('#player_' + id).css({ 'position': 'relative' })
                            let bottom = $('<div/>', { id: 'user_' + id })
                            bottom.html(`<span style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">${localStream.userId_}</span>`)
                            bottom.css({
                                'width': '100%',
                                'height': '20%',
                                'line-height': '35px',
                                'color': '#fff',
                                'background': '#3c3d40',
                                'display': 'flex',
                                'align-items': 'center',
                                'z-index': '99',
                                'margin-top': 'auto'
                            })
                            audio.appendTo(bottom)
                            unaudio.appendTo(bottom)
                            bottom.appendTo($('#' + id).parent())
                        })
                    }
                
                    this.publishStream(localStream, this.client) //创建好后才能发布
                    this.updateStream()
                });
            },

            //发布本地音视频流
            publishStream(localStream, client) {
                client.publish(localStream).catch(error => {
                    console.log('本地流发布失败 ' + error);
                }).then(() => {
                    $('#local_stream').addClass('mainVedio').parent().addClass('active')
                    console.log('本地流发布成功');
                });
            },
            // 远端监听
            updateStream() {
                this.client.on('stream-removed', (event) => {
                    const remoteStream = event.stream;
                    $(`${'#mask_' + remoteStream.getId()}`).hide()
                    $(`${'#status_' + remoteStream.getId()}`).show();
                    for (var i = 0; i < this.list.length; i++) {
                        if (this.list[i].userId == remoteStream.getUserId()) {
                            this.list.splice(i, 1)
                        }
                    }
                })
                // 关闭摄像头
                this.client.on('mute-video', (evt) => {
                    let streamId = this.getUidByStreamId(evt.userId)
                    if (streamId) {
                        $('#mask_' + streamId).show()
                    }
                })
                // 打开摄像头
                this.client.on('unmute-video', (evt) => {
                    let streamId = this.getUidByStreamId(evt.userId)
                    if (streamId) {
                        $('#mask_' + streamId).hide()
                    }
                })
                // 关闭语音
                this.client.on('mute-audio', (evt) => {
                    let streamId = this.getUidByStreamId(evt.userId)
                    $(`${'#user_' + streamId}`).find('#audio_' + streamId).hide().next().show()
                })
                // 打开语音
                this.client.on('unmute-audio', (evt) => {
                    let streamId = this.getUidByStreamId(evt.userId)
                    $(`${'#user_' + streamId}`).find('#unaudio_' + streamId).hide().prev().show()
                })
            },
            // 根据id获取uid
            getUidByStreamId(streamId) {
                for (let [uid, stream] of this.members_) {
                    if (stream.getUserId() == streamId) {
                        return uid
                    }
                }
            },
            //订阅远端流--加入房间之前
            //远端流通过监听事件client.on('stream-added')获取，收到该事件后，通过 Client.subscribe() 订阅远端音视频流。
            subscribeStream(client) {
                client.on('stream-added', event => {
                    const remoteStream = event.stream;
                    this.isHave = false
                    for (let [uid, stream] of this.members_) {
                        if (stream.getUserId() === remoteStream.getUserId()) {
                            $('#user_' + stream.getId()).remove()
                            this.members_.delete(stream.getId())
                            this.isHave = true
                        }
                    }
                    this.members_.set(remoteStream.getId(), remoteStream)
                    //订阅远端流
                    client.subscribe(remoteStream);
                });
                client.on('stream-removed', (event) => {
                    const remoteStream = event.stream;
                    $("#local_stream").children().remove();
                   
                })
            },

            //播放远端流
            playStream(client) {
                client.on('stream-subscribed', event => {
                    const remoteStream = event.stream;
                    const id = remoteStream.getId()
                    if(remoteStream.getUserId()==localStorage.getItem('anchor_id')||localStorage.getItem('anchor_id')=='000'){   //主播
                        remoteStream.play('local_stream')
                        this.roomtype=true;
                    }else{   
                        this.remoteStream = `<div id="${'remote_stream-' + id}" style="width:100%;height:100%"></div>`
                        if (!this.isHave) {
                            this.list.push({
                                id,
                                userId: remoteStream.getUserId(),
                                remoteStream: this.remoteStream,
                                origin: remoteStream
                            })
                        } else {
                            this.list.splice(this.list.findIndex((v) => v.userId === remoteStream.getUserId()), 1, {
                                id,
                                userId: remoteStream.getUserId(),
                                remoteStream: this.remoteStream,
                                origin: remoteStream
                            })
                        }

                        //做了dom操作 需要使用$nextTick(),否则找不到创建的标签无法进行播放
                        this.$nextTick(() => {
                            //播放
                            remoteStream.play('remote_stream-' + remoteStream.getId());
                            let audio = $('#audio').clone()
                            audio.attr('id', 'audio_' + id)
                            audio.css({
                                'width': '14px',
                                'height': '21px',
                                'background': 'none',
                                'margin-left': 'auto'
                            })
                            let unaudio = $('#unaudio').clone()
                            unaudio.attr('id', 'unaudio_' + id)
                            unaudio.css({
                                'width': '14px',
                                'height': '21px',
                                'background': 'none',
                                'margin-left': 'auto'
                            })

                            let mask = $('#mask').clone()
                            mask.text('')
                            mask.attr('id', 'mask_' + id)
                            mask.css({
                                'width': '100%',
                                'height': '100%',
                                'text-align': 'center',
                                'z-index': '99'
                            })
                            mask.appendTo($('#player_' + id))
                            mask.hide()

                            let status = $('<div>', {
                                id: 'status_' + id
                            })
                            status.css({
                                'width': '100%',
                                'height': '100%',
                                'text-align': 'center',
                                'color': '#fff',
                                'display': 'flex',
                                'align-items': 'center',
                                'justify-content': 'center'
                            })
                            status.html('<span>已离开会议<span>')
                            status.appendTo($('#player_' + id))
                            status.hide()
                            console.log(4198649, $('#audio').clone())
                            $('#player_' + id).css({ 'position': 'relative' })
                            let bottom = $('<div/>', { id: 'user_' + id })
                            bottom.html(`<span style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">${remoteStream.getUserId()}</span>`)
                            bottom.css({
                                'width': '100%',
                                'height': '20%',
                                'line-height': '35px',
                                'color': '#fff',
                                'background': '#3c3d40',
                                'display': 'flex',
                                'align-items': 'center',
                                'z-index': '99',
                                'margin-top': 'auto'
                            })
                            audio.appendTo(bottom)
                            unaudio.appendTo(bottom)
                            bottom.appendTo($('#' + id).parent())
                        })
                    }

                    
                });
            },

            //退出音视频
            leaveRoom() {

                if (this.client) {
                    // $("#local_stream").empty();
                    if(this.roles=='play'){   //主播退房提示
                        var that = this;
                        let message = this.timInst.createCustomMessage({
                            to: that.timChatGroupId,
                            conversationType: TIM.TYPES.CONV_GROUP,
                            payload: {
                                data: '主播退房', // 用于标识该消息是骰子类型消息
                                description: '主播', // 获取骰子点数
                                extension: '主播退房'
                            },
                        });
                        this.timInst.sendMessage(message).then(function (res) {
                            // that.maivisible = false;
                        }).catch(function (err) {
                            console.warn('主播退房失败', err);
                        });
                        this.leavetype=false;
                        this.playbtn=true;
                    }
                    this.client.leave().then(() => {
                        $("#local_stream").children().remove();
                        this.localStream.stop(); // 停止本地流，关闭本地流内部的音视频播放器
                        this.localStream.close();// 关闭本地流，释放摄像头和麦克风访问权限
                        this.localStream = null;
                        this.client = null
                        // 退房成功，可再次调用client.join重新进房开启新的通话。
                    }).catch(error => {
                    });
                    
                    
                }
            },

            //切换角色
            changeroles() {
                client.switchRole('anchor').catch(error => {
                }).then(() => {
                    // 角色切换成功，现在是主播角色
                });
            },

            // async closeRoom() {
            //     await this.client.unpublish(this.localStream)
            //     console.log(this.client)
            //     // leave the room
            //     await this.client.leave()
            //     this.localStream.stop()
            //     this.localStream.close()
            //     this.localStream = null
            //     // this.isJoined_ = false;
            //     $('#local_stream').parent()
            //         .children('.info')
            //         .remove()
            // },

            // 将自己切换到主视频
            // switchMain () {
            //     if ($('#local_stream').hasClass('mainVedio')) { return }
            //     $('.mainVedio').removeClass('mainVedio').parent().removeClass('active')
            //     $('#local_stream').addClass('mainVedio')
            //     $('#warp').addClass('active')
            // },
            getVideo(id) {
                if ($('#' + id).hasClass('mainVedio')) { return }
                $('.mainVedio').removeClass('mainVedio').parent().removeClass('active')
                $('#' + id).addClass('mainVedio').css({ 'height': '93%' }).parent().addClass('active')
            },


            //获取用户签名 trtc
            genTestUserSig(userID) {
                /**
                 * 腾讯云 SDKAppId，需要替换为您自己账号下的 SDKAppId。
                 *
                 * 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav ) 创建应用，即可看到 SDKAppId，
                 * 它是腾讯云用于区分客户的唯一标识。
                 */
                // const SDKAPPID = 1400557280;
                const SDKAPPID = 1400395034;
                /**
                 * 签名过期时间，建议不要设置的过短
                 * <p>
                 * 时间单位：秒
                 * 默认时间：7 x 24 x 60 x 60 = 604800 = 7 天
                 */
                const EXPIRETIME = 604800;
                /**
                 * 计算签名用的加密密钥，获取步骤如下：
                 *
                 * step1. 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav )，如果还没有应用就创建一个，
                 * step2. 单击“应用配置”进入基础配置页面，并进一步找到“帐号体系集成”部分。
                 * step3. 点击“查看密钥”按钮，就可以看到计算 UserSig 使用的加密的密钥了，请将其拷贝并复制到如下的变量中
                 *
                 * 注意：该方案仅适用于调试Demo，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。
                 * 文档：https://cloud.tencent.com/document/product/647/17275#Server
                 */
                const SECRETKEY =
                    // "033d0bd0ad41ed53ba93025edd6e184b4f436c40a6bd65e72ab7ca03fbcdf0e2";
                    "d81962caea352dbf6f53da1acd36d0b9443eba17c1e1dcda99ab7027e64ea37a";

                // a soft reminder to guide developer to configure sdkAppId/secretKey
                if (SDKAPPID === "" || SECRETKEY === "") {
                    alert(
                        "请先配置好您的账号信息： SDKAPPID 及 SECRETKEY " +
                        "\r\n\r\nPlease configure your SDKAPPID/SECRETKEY in js/debug/GenerateTestUserSig.js"
                    );
                }
                const generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
                const userSig = generator.genTestUserSig(userID);
                return {
                    sdkAppId: SDKAPPID,
                    userSig: userSig
                };
            },
            genTestTimSig(userID) {
                var SDKAPPID = 1400395034;
                var EXPIRETIME = 604800;
                var SECRETKEY = 'd81962caea352dbf6f53da1acd36d0b9443eba17c1e1dcda99ab7027e64ea37a';
                var generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
                var userSig = generator.genTestUserSig(userID);
                return {
                    SDKAppID: SDKAPPID,
                    userSig: userSig
                };

            },
           
            GoSign: function () {
                this.enter()
            },
            pulldetail: function () {  //会议材料下拉
                if (this.pull == 1) {
                    this.pull = 0
                } else {
                    this.pull = 1
                }
            },
           
            pusherplay:function(type){
                this.device=type;
                this.leavetype=false;
                this.pusherVisible=false;
                this.playbtn=true;
            },
            copy:function(){
                this.leavetype=false;
                this.pusherVisible=false;
                this.playbtn=true;
                var _this = this;
                // var clipboard = new Clipboard(".btn"); //单页面引用
            　　var clipboard = new this.Clipboard(".btns"); //在main.js中引用
                clipboard.on("success", e => {
                    // 释放内存
                    clipboard.destroy();
                    var that=this;
                    PullStream({
                        room_id:parseInt(this.id)+100000000,//69
                        play_url:this.playLinkAddr//rtmp://play.pochanjie.com/live/meeting69
                    }).then(res=>{
                        // that.userId=res.data.uid;
                        // var userId=res.data.uid;    
                        this.playbtn=false;
                        this.leavetype=true;
                        this.requestId=res.data.requestId;

                        var userId=that.userId;    //主播自己的userid
                        const config = that.genTestUserSig(userId)
                        const sdkAppId = config.sdkAppId
                        const userSig = config.userSig   //获取签名
                        var client = TRTC.createClient({
                            mode: 'live',
                            sdkAppId,
                            userId,
                            userSig
                        });
                        that.client=client;
                        this.timChatGroupId = parseInt(this.id)+100000000 +'';
                        that.initIM();   

                        // client.unpublish(this.localStream).then(() => {
                        //     // 取消发布本地流成功

                        // });
                        client.on('stream-subscribed', event => {
                            const remoteStream = event.stream;
                            // 远端流订阅成功，播放远端音视频流
                            // const id = remoteStream.getId();
                            // remoteStream.play(id);
                            remoteStream.play('local_stream');
                        });
                        client.on('stream-added', event => { 
                            const remoteStream = event.stream;
                            // 订阅远端音频和视频流
                            client.subscribe(remoteStream, { audio: true, video: true }).catch(e => {
                                console.error('failed to subscribe remoteStream');
                            });
                        });
                        client.join({ roomId: that.meeting_id, role: 'anchor' }).then(() => {
                        }).catch(error => {
                            console.error('Join room failed: ' + error);
                        });
                        
                    });
                });
                clipboard.on("error", e => {
                    // 不支持复制
                    Message({
                    message: "该浏览器不支持自动复制",
                    type: "warning"
                    });
                    // 释放内存
                    clipboard.destroy();
                });
            },
            enter: function () {   //进入会议	
                if(this.roles=='play'){   //主播，选择直播方式
                    if(this.play_schema==0){  //内部
                        if(this.camerastype==false||this.microphonestype==false){
                            this.devicescontent="麦克风或摄像头设备没有连接,连接后请重新进入会议"
                        }else{
                            this.playbtn = false;

                            setTimeout(() => {
                                this.videostart = true;
                                this.meeting_id = parseInt(this.id)+100000000;
                                this.timChatGroupId = this.meeting_id + '';
                                
                                const micList = TRTC.getMicrophones();
                                const cameraList = TRTC.getCameras();
                                const speakerList = TRTC.getSpeakers();
                                const hasCameraDevice = cameraList.length > 0;
                                const hasMicrophoneDevice = micList.length > 0;
                                const hasSpeakerDevice = speakerList.length > 0;
                                navigator.mediaDevices.getUserMedia({ video: hasCameraDevice, audio: hasMicrophoneDevice })
                                    .then((stream) => {
                                        stream.getTracks().forEach(track => track.stop());
                                    })
                                    .catch((error) => {

                                    });
                                TRTC.checkSystemRequirements().then(checkResult => {
                                    // console.error(checkResult, 'TRTC.checkSystemRequirements');
                                    if (checkResult.result) {
                                        this.createClient(this.userId);// 创建一个实时音视频通话的客户端对象
                                        this.initIM();   
                                    } else {
                                        this.content = "SDK 不支持当前浏览器，根据用户设备类型建议用户使用 SDK 支持的浏览器"
                                        console.error('checkResult', checkResult.result, 'checkDetail', checkResult.detail);
                                    }
                                })
                            }, 1000);
                        }
                        // StopPush({
                        //     requestId:this.requestId
                        // }).then(res=>{

                        // })
                    }else{   //外部
                        this.deviceVisible=false;
                        this.playbtn = false;
                        this.videostart = true;
                        
                        this.meeting_id=parseInt(this.id)+100000000;
                        StudyUrl({
                            study_id:this.id,
                            room_id:this.meeting_id
                        }).then(res => {
                            this.pusherVisible=true;
                            this.pusheraddress=res.data.push;
                            this.itemid=this.id;
                            this.playLinkAddr=res.data.play
                        })
                    }
                    
                }else{   //非主播播放
                    this.playbtn = false;
                    if(this.camerastype==false||this.microphonestype==false){
                        this.devicescontent="麦克风或摄像头设备没有连接,连接后请重新进入会议";
                    }
                    setTimeout(() => {
                        this.deviceVisible=false;
                        this.videostart = true;
                        this.meeting_id = parseInt(this.id)+100000000;
                        this.timChatGroupId = this.meeting_id + '';

                        
                        const cameraList = TRTC.getCameras();
                        const micList = TRTC.getMicrophones();
                        // const speakerList = TRTC.getSpeakers();
                        const hasCameraDevice = cameraList.length > 0;
                        const hasMicrophoneDevice = micList.length > 0;
                        // const hasSpeakerDevice = speakerList.length > 0;
                        navigator.mediaDevices.getUserMedia({ video: hasCameraDevice, audio: hasMicrophoneDevice })
                            .then((stream) => {
                                stream.getTracks().forEach(track => track.stop());
                            })
                            .catch((error) => {

                            });
                        TRTC.checkSystemRequirements().then(checkResult => {
                            // console.error(checkResult, 'TRTC.checkSystemRequirements');
                            if (checkResult.result) {
                                this.createClient(this.userId);// 创建一个实时音视频通话的客户端对象
                                this.initIM();   
                            } else {
                                this.content = "SDK 不支持当前浏览器，根据用户设备类型建议用户使用 SDK 支持的浏览器"
                                console.error('checkResult', checkResult.result, 'checkDetail', checkResult.detail);
                            }
                        })
                    }, 1000);
                }
            },

        }
    }
</script>
<style scoped>
    .Meet_box {
        position: absolute;
        top: 185px;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #EEF3FA;

    }

    .Meet_box_inside {
        background: #F5FAFE;
        border: 0.1rem solid #E2E2E8;
        border-radius: 0.5rem;
        width: 86.4%;
        margin: 4rem auto;
        display: flex;
        padding: 3rem 0;
    }

    .Meet_box_video {
        width:80%;
        margin: 0 auto;
    }

    .Meet_box_name {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        margin: 0px auto 10px;
        font-family: "siyuanb";
    }

    .Meet_box_detail {
        height:600px;
        position: relative;
    }

    .Meet_box_detail img {
        width: 100%;
        height: 100%;
    }

    .Meet_box_detail_btn {
        position: absolute;
        width: 95.6%;
        height: 60px;
        left: 1.2%;
        padding: 0 1%;
        background: rgb(39 39 39 / 50%);
        border-radius: 8px;
        bottom: 10px;
        z-index: 999;
        display: flex;
        align-items: center;
    }

    .audiencebox {
        position: absolute;
        height: 140px;
        width: 95.6%;
        bottom: 80px;
        left: 2.2%;
        display: flex;
        justify-content: flex-start;
    }

    .audiencebox_list {
        width: 140px;
        height: 140px;
        background: #000;
        margin-right: 20px;
    }

    .btn_min {
        margin-right: 20px;
        width: 14rem;
        height: 4rem;
        border-radius: 0.8rem;
        text-align: center;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn_min span {
        font-size: 20px;
        margin-right: 10px;
    }

    .detail_input {
        margin-left: 30px;
        display: flex;
    }

    .detail_input input {
        width: 345px;
        height: 40px;
        background: #000000;
        opacity: 0.6;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 8px;
        margin-right: 10px;
        padding: 0 3%;
        color: #fff;
    }

    .sendbtn {
        width: 91px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 8px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
    }

    .Meet_box_mess {
        width: 15.84%;
        height: 666px;
        background: #E7EEF5;
        border: 2px solid rgba(255, 255, 255, 0.2);
        box-shadow: 6px 6px 16px rgba(163, 177, 198, 0.6), -6px -6px 16px rgba(255, 255, 255, 0.6);
        border-radius: 10px;
        margin-left: 32px;
        padding: 0 2%;
    }

    .Meet_box_mess_now {
        height: 50px;
        /* opacity: 0.6; */
        border-bottom: 1px dashed rgb(112 110 140 / 60%);
        font-family: 'siyuanb';
        font-size: 16px;
        line-height: 24px;
        color: #FF7474;
        padding: 18px 0;
        font-family: "siyuanb";
    }

    .Meet_box_mess_other div:nth-child(1) {
        font-size: 14px;
        line-height: 21px;
        margin: 20px 0;
    }

    .Meet_box_mess_other div {
        font-size: 14px;
        line-height: 30px;

    }

    /* 弹框 */
    .el-dialog {
        width: 1060px;
        height: 600px;
        background: #F5FAFE;
        border: 1px solid #E2E2E8;
        border-radius: 5px;
    }

    .el-dialog .el-dialog__header {
        border-bottom: 1px solid #E7EEF5;
        text-align: center;
        color: #706e8c;
        font-family: 'siyuanb';
    }

    .el-dialog .el-dialog__body {
        padding-top: 15px;
    }

    .dialog_name {
        text-align: center;
        margin-bottom: 12px;
        font-size: 14px;
    }

    .dialog_time {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .dialog_tit {
        text-align: center;
        color: #F47814;
        font-size: 14px;
        margin-bottom: 22px;
    }

    .dialog_list {
        height: 46px;
        background: #EEF3FA;
        border-radius: 5px;
        padding-left: 14px;
        margin-bottom: 10px;
    }

    .dialog_list_btn {
        display: flex;
    }

    .dialog_list_btn span {
        width: 60px;
        height: 30px;
        border-radius: 5px;
        display: block;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        line-height: 30px;
        cursor: pointer;
    }

    .dialog_list_btn span:nth-child(1) {
        background: #43A0F5;

    }

    .dialog_list_btn span:nth-child(2) {
        background: #FE9B4B;
    }

    .dialog_lists {
        height: 40px;
    }

    .dialog_btn {
        display: flex;
        color: #fff;
        font-size: 1.4rem;
        margin: 44px auto 0;
        width: 65%;
    }

    .dialog_btn div {
        width: 268px;
        height: 40px;
        line-height: 40px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
    }

    .dialog_btn div:nth-child(1) {
        margin-right: 30px;
    }

    /* 签到成功 */
    .qianbtn {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        font-size: 16px;

    }

    .qianbtn div {
        height: 50px;
        width: 40%;
        text-align: center;
        line-height: 50px;
        border: 1px solid #fff;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
    }

    /* 参会人 */
    .joinlist {
        height: 50px;
        /* line-height: 50px; */
        font-size: 20px;
        border-bottom: 1px dashed #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .joinlist image {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        margin-right: 10px;
        float: left;
        margin-top: 5px;
        background-size: 100% 100%;
    }

    .joinlist text:nth-child(1) {
        width: 70%;
        display: block;
        white-space: norwap;
        overflow-x: scroll;
        /* height: 50px; */
        padding: 0 10px;

    }

    .joinlist text:nth-child(2) {
        width: 20%;
        text-align: center;
        display: block;
        color: yellowgreen;

    }

    /* 上麦管理 */
    .gongbottoms {
        display: flex;
        justify-content: space-around;
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
        color: #FFFFFF;
    }

    .gongbottoms view {
        width: 45%;
        text-align: center;
        border-radius: 5px;

    }

    .gongbottoms text:nth-child(1) {
        margin-right: 10px;
    }

    /* 统计 */
    .tongqian {
        margin-bottom: 15px;
    }

    table {
        border-top: 1px solid #eee;
        border-left: 1px solid #eee;
    }

    .tonglist {
        /* height: 30px; */
        line-height: 30px;
        font-size: 14px;
        text-align: center;
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid #eee;
        align-items: center;
    }

    .tonglist text {

        /* border-right: 1px solid #eee; */
        text-align: center;
    }

    .tonglist text:nth-child(1) {
        font-weight: 700;
        width: 40%;
    }

    .tonglist text:nth-child(2) {
        width: 35%;
    }

    .tonglist text:nth-child(3) {
        width: 20%;
    }

    /* 上麦管理 */
    /* 申请上麦 */
    .maitable {
        border: 1px solid #EEEEEE;
        font-size: 14px;
        width: 100%;
    }

    .maitable tr {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #eee;
        width: 100%;
    }

    .maitable tr:nth-child(1) {
        font-weight: 700;
    }

    .maitable tr:last-child {
        border: none;
    }

    .maitable td {
        width: 33%;
        text-align: center;
        float: left;
        /* border-right: 1px solid #EEEEEE; */
    }

    .maitable td:last-child {
        border: none;
        color: #06AE56;
        cursor: pointer;
    }

    /* 表决列表 */
    .biaolist {
        padding: 10px 0;
        font-size: 18px;
        /* height: 40px; */
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #eee;
        justify-content: space-between;
        line-height: 20px;

    }

    .biaolist span:nth-child(1) {
        width: 70%;
        float: left;
        word-break: break-all;
        word-wrap: break-word;

    }

    .biaolist span:nth-child(2) {
        float: right;
        width: 25%;
        line-height: 40px;
        background: green;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }

    /* 上麦 */
    .maicon {
        text-align: center;
        line-height: 5rem;
        font-size: 1.8rem;
    }

    .twobtn {
        height: 40px;
        display: flex;
        justify-content: space-around;
        line-height: 40px;

    }

    .twobtn div:nth-child(1) {
        background: #eee;
        width: 30%;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }

    .twobtn div:nth-child(2) {
        background: #00aa00;
        width: 30%;
        text-align: center;
        border-radius: 5px;
        color: #FFFFFF;
        cursor: pointer;
    }

    /* 表决内容弹起 */
    .biaocontent {
        height: 50px;
        width: 90%;
        border: 1px solid #ccc;
        margin: 0 auto;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        color: green;
        overflow: auto;
    }

    .biaoradio {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;

    }

    .biaoradio label {
        width: 40%;
        border: 1px solid #eee;
        font-size: 16px;
        padding: 3px 5px;
        border-radius: 5px;
        text-align: center;
    }

    .biaolist {
        padding: 10px 0;
        font-size: 14px;
        /* height: 40px; */
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #eee;
        justify-content: space-between;
        line-height: 20px;

    }

    .biaolist text:nth-child(1) {
        width: 70%;
        float: left;
        word-break: break-all;
        word-wrap: break-word;

    }

    .biaolist text:nth-child(2) {
        float: right;
        width: 25%;
        line-height: 40px;
        background: green;
        color: #fff;
        text-align: center;
        border-radius: 5px;

    }
    .btn{
        color: #fff;
        padding: 0 10px;
        border-radius: 5px;
        cursor: pointer;
    }
    .surebtn{
        width: 50%;
        font-size: 14px;
        margin: 0 auto;
        margin-top: 15px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }
    /* 控制按钮 */
    .kongbtn{
        height: 30px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        bottom: 0;
        margin-bottom: 10px;
        font-size: 12px;
        text-align: center;

    }
    .kongbtn div{
       border-radius: 5px;
       line-height: 30px;
       background: #FFF;
       padding: 0 10px;
       cursor: pointer;
    }

    /* 外接设备 */
    .devicebox{

    }
    .devicebox div,.devicebox button{
        padding: 1rem 2rem;
        border: 0.1rem solid #b9b9b9;
        border-radius: 0.5rem;
        cursor: pointer;
    }
</style>