<template>
    <div class="backcolor">
        <Header></Header>
        <div class="Network_box">
            <div class="Network_tit flexar">破<br>产<br>业<br>务</div>
            <div class="Network_login" v-if="logintype==false&&casedetail==false">
                <div class="login_tit">谋道破产管理智慧平台</div>
                <div class="login_desc">联席办公区</div>
                <div class="login_input">
                    <span>账号</span>
                    <input type="text" placeholder="请输入登录账号" v-model="account">
                </div>
                <div class="login_input">
                    <span>密码</span>
                    <input type="text" placeholder="请输入登录密码" v-model="mima">
                </div>
                <div class="login_btn" @click="caselogin">登录</div>
            </div>
            <div class="Network_list" v-if="companyList.length!==0&&casedetail==false">
                <div class="list_tit">案件列表</div>
                <div class="list_con" v-for="(item,index) in companyList" :key="index">
                    <div class="list_mess flexbe">
                        <div class="list_mess_listbox">
                            <div class="list_mess_list">债务人：{{item.company_name}}</div>
                            <div class="list_mess_list">债权截止日期：{{item.claims_expire}}</div>
                            <div class="list_mess_list">受理法院：{{item.court}}</div>
                            <div class="list_mess_list">管理人机构：{{item.custodian_name}}</div>
                        </div>
                        <div class="list_mess_network" @click="Gonet(item.id)">
                            <img src="../../image/p_wang.png" alt="">
                            <div>网上办公区域</div>
                        </div>
                    </div>
                    <img src="../../image/case.png" alt="" class="list_case_img" @click="caseprogress(item,index)">
                    <div class="case_type" v-show="progresstype==1&&progressindex==index">案件进展状态</div>
                    <div class="case_type_box" v-show="progresstype==1">
                        <div style="color: #FD2001;font-size: 16px;text-align: center;line-height: 30px;">{{item.info_status_1==null||item.info_status_1==''?'':item.info_status_1}}</div>
                        <div style="color: #FD2001;font-size: 14px;text-align: center;line-height: 20px;">{{item.info_status_2==null||item.info_status_2==''?'':item.info_status_2}}</div>
                        <div style="font-size: 14px;">{{item.info_status_text==null||item.info_status_text==''?'':item.info_status_text}}</div>
                        <!-- <div>债权申报进行中</div>
                        <div>《债权表》实时更新</div> -->
                    </div>
                </div>
            </div>
            <div v-if="companyList.length==0&&casedetail==false" style="font-size: 16px;">暂无内容</div>
            <div v-if="othertype==true&&casedetail==false" @click="loginother">登录或添加其他账号</div>
            <div class="Network_detail" v-if="casedetail==true">
                <div class="detail_left">
                    <div :class="type.key==current?'detail_left_list_active':'detail_left_list'"  v-for="(type,indextype) in area" :key="indextype" @click="changenet(type)">
                        <img src="../../image/logo.png" alt="">
                        <span>{{type.value}}</span>
                    </div>
                </div>
                <div class="detail_right" v-for="(type,indextype) in area" :key="indextype" v-if="type.key==current">
                    <div class="detail_right_tit">{{casetype}}</div>
                    <div class="detail_chat" v-if="permission[type.key].can_read==1">
                        <!-- <div class="detail_chat_time">10:40</div> -->
                        
                        <div v-for="(item,index) in tableList[type.key]" :key="item.id" class="detail_chat_con flexbe" v-if="tableList.length!==0&&item.roles!==roles">
                            <div class="detail_chat_con_left">
                                <span style="margin-right: 19px"">{{item.title}}</span>
                                <img src="../../image/logo.png" alt="">
                            </div>
                            <div class="detail_chat_con_right" >
                                <div @click="getbook(item)" :colspan="item.file.length==0?'2':''" v-if="item.file.length!==0&&(item.file[0].type==('png'||'jpg'||'jpeg'))">阅读</div>
                                <div @click="xiazai(item)" v-if="item.file.length!==0&&(item.file[0].type=='doc'||item.file[0].type=='docx'||item.file[0].type=='excel'||item.file[0].type=='pdf')">下载</div>
                            </div>
                        </div>
                        <div v-for="(item,index) in tableList[type.key]" :key="item.id" class="detail_chat_con flexbe" v-if="tableList.length!==0&&item.roles==roles">
                            <div class="detail_chat_con_left">
                                <div @click="getbook(item)" :colspan="item.file.length==0?'2':''" v-if="item.file.length!==0&&(item.file[0].type==('png'||'jpg'||'jpeg'))">阅读</div>
                                <div @click="xiazai(item)" v-if="item.file.length!==0&&(item.file[0].type=='doc'||item.file[0].type=='docx'||item.file[0].type=='excel'||item.file[0].type=='pdf')">下载</div>
                               
                            </div>
                            <div class="detail_chat_con_right" >
                                <span style="margin-right: 19px"">{{item.title}}</span>
                                <img src="../../image/logo.png" alt="">
                            </div>
                        </div>
                       
                        <div v-if="tableList.length==0" style="font-size: 16px;">暂无内容</div>
                    </div>
                    <div class="detail_con">
                        <div class="detail_con_qian"><span>适时工作处理签：</span><input type="text" v-model="worktitle"></div>
                        <div class="detail_con_qian"><span>适时工作处理签：</span><textarea name="" id="" cols="30" rows="10" v-model="workcontent"></textarea></div>
                        <div class="detail_con_qian">
                            <span>上传附件：</span>
                            <!-- https://pc.pochanjie.com/api/claims/upload -->
                            <el-upload
                                class="upload-demo"
                                action=""
                                :headers="myHeaders"
                                :http-request="uploadSectionFile"
                                :auto-upload="true"
                                :on-preview="handlePreview"
                                :on-remove="handleRemove"
                                :on-error="uploadFileError"
                                :on-success="uploadFileSuccess"
                                :before-remove="beforeRemove"
                                multiple
                                :limit="3"
                                :on-exceed="handleExceed"
                                :file-list="fileList">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <span style="color: #999;font-size: 12px;margin-left: 10px;">1.支持扩展名：.jpg .png .jpeg .doc .docx .excel .pdf</span>
                                <span style="color: #999;font-size: 12px;margin-left: 10px;">2.请同一次上传同一种文件</span>
                               
                            </el-upload>
                            <!-- <input type="file" multiple  @change='upload' style="opacity: 0;z-index: 999;"> -->
                            <!-- <img src="../../image/upfile.png" alt="" style="cursor: pointer;position: absolute;left: 12%;"> -->
                        </div>
                        
                        <!-- 上传内容展示 -->
                        <!-- <div style="padding: 10px 0;" v-if="file_type!=='image'">
                            <div v-for="(item,index) in pathname" :key="index" v-if="pathname.length!==0" style="display: flex;justify-content: space-between;line-height: 30px;color: #999999;">
                                <span :class="item.type=='doc'?'icon-doc':item.type=='xlsx'?'icon-xls':'icon-PDF' " class="iconfont"></span>
                                <span style="width: 80%;overflow: hidden;display: block;">{{item.name}}</span>
                                <span @click="cancelpath(index)" >取消</span>
                            </div>
                        </div> -->
                       
                        <!-- <div class="detail_con_qian detail_notice"><span></span> 支持扩展名：.rar .zip .doc .docx .pdf .jpg...</div> -->
                        <div class="sendbtn" @click="sendcontent(type.key)">发送</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Network_notice">网上办公是法官、书记员、会议主席、债委会成员专用区域。请向管理人索取对应案件的账号及密码后登录</div>
         <!-- 提示框 -->
         <Toast :toastText="content" v-show="toastShow"></Toast>
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'
    import Toast from '../../components/Toast.vue'

    import {caseSelfList,CaseUserLogin,CaseOfficeContent,CaseSetContent,PersonCaseStatus,CaseGetUserStatus,ClaimsUpload} from '@/request/api';

    export default {
        name: 'Network',
        components: {
            Header,
            Toast
        },
        data(){
            return{
                // caseactive:0,
                logintype:false,
                othertype:false,
                casedetail:false,
                companyList:[],
                account:'',
                mima:'',
                 
                //案件进展状态
                progresstype:0,
                progressindex:-1,
                //网上办公区域
                case_id:'',
                area:[],
                permission:[],
                tableList:[],
                current:0,
                casetype:'',
                worktitle:'',
                workcontent:'',
                pathname:[],
                roles:'',

                //上传
                fileList: [],
                myHeaders:{'content-type': 'application/json','Authori-zation':'Bearer ' + localStorage.getItem('token')},
                srcarr1:[],
                imagestring1:'',
                content:'',
                toastShow:false
            }
        },
        created() {
            this.getlist('')
        },
        methods:{
            caselogin:function(){
                var that=this;
                CaseUserLogin({
                    account:that.account,
                    password:that.mima,
                    // token:localStorage.getItem("token")
                }).then(res=>{
                    if(res.data.allow==0){
                        that.content=res.data.message;
                        that.toastShow=true;
                        setTimeout(function(){
                            that.toastShow = false
                        }, 1500)
                        that.account='';
                        that.mima=''
                    }else{
                        // that.caseactive=1;
                    }
                })
            },
            loginother:function(){

            },
            getlist:function(name){
                caseSelfList({
                    name:name,
                    // token:localStorage.getItem("token")
                }).then(res=>{
                    if(res.data.check==1){
                        this.logintype=true;
                        this.companyList=res.data.list;
                        if(res.data.show_button==1){
                            this.othertype=true;
                        }else{
                            this.othertype=false;
                        }
                    }else{
                        this.logintype=false
                    }
                })
            },
            caseprogress:function(item,index){//案件进展状态
                this.case_id=item.id;
                this.progressindex=index
                if(this.progresstype==0){
                    PersonCaseStatus({   
                        case_id:item.id

                    }).then(res=>{
                        if(res.data.allow==1){
                            if((item.info_status_1==''||item.info_status_1==null)&&(item.info_status_2==''||item.info_status_2==null)&&(item.info_status_text==''||item.info_status_text==null)){
                                this.content='暂无案件进展状态'
                                this.toastShow=true
                               
                            }else{
                                this.progresstype=1
                                // this.currentid=item.id;
                            }
                        }else{
                            this.content='【案件管理】是法官、管理人、会议主席、债委会处理破产事务的办公区域，请联系管理人获取相关权限后，再使用本系统的相关功能。'
                            this.toastShow=true
                        }
                        var that=this;
                        setTimeout(function(){
                            that.toastShow = false
                        }, 1500)
                       
                    })
                }else{
                    this.progresstype=0

                }
                
            },
            Gonet:function(id){  //网上办公区域权限显示 
                this.case_id=id;
                CaseGetUserStatus({
                    case_id:id
                }).then(res=>{
                    if(res.data.allow==0){   
                        this.content=res.data.message
					}else{    //直接进入网上办公区域
                        // this.caseactive=2;
                        this.getcasecontent();
                        this.casedetail=true;
					}
                })
            },
            changenet:function(type){
                this.current=type.key;
                this.casetype=type.value;
            },
            getcasecontent:function(){
                CaseOfficeContent({ 
                    case_id:this.case_id
                }).then(res=>{
                    if(res.data.check==1){
                        this.permission=res.data.permission;
                        this.tableList=res.data.content;
                        this.area=res.data.option;   
                        this.casetype=this.area[0].value;  //默认第一个
                        this.roles=res.data.roles;
                        // if(aaa==-1){
                        //     this.current=this.area[0].key;
                        // }else{
                        //     this.current=aaa;
                        // }
                    }else{
                        this.content="权限不正确"
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false;
                        }, 1500);
                    }
                })
            },
            getbook:function(item){   //阅读
                window.open(item.file[0].path)
            },
            xiazai:function(item){
                window.open(item.file[0].path)
            },
            //上传
            uploadSectionFile(param){
                var fileObj = param.file;
                var form = new FormData();
                // 文件对象
                form.append("file", fileObj);
                this.$axios.post('/claims/upload',form).then(res => {
                    param.onSuccess(res)
                   
                }).catch(({err}) => {
                    param.onError(err)
                })  
            },
            //上传失败
            uploadFileError(err, file, fileList){
            // this.$message.error("上传失败！")
            },
            //上传成功
            uploadFileSuccess(response, file, fileList){
                // if(response.data.error==0){
                    file.response=response.data.data;
                    this.fileList.push(file)
                    this.srcarr1.push(response.data.data.src)
                // }else{
                //     // this.$message.error(response.data.message);//文件上传错误提示
                // }

            },
            handleRemove(file, fileList) {
                for(var i=0;i<fileList.length;i++){
                    this.srcarr1.push(fileList[i].response.src)
                }
            },
            handlePreview(file) {
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            //上传完
            sendcontent:function(key){
                if(this.worktitle==''){
					this.content='请输入标题',
                    this.toastShow=true;
                //    return
				}
                if(this.workcontent==''){
					this.content='请输入内容'
                    this.toastShow=true;
                    // return
				}
                setTimeout(() => {
                    this.toastShow=false;
                }, 1500);
                var that=this;
                that.imagestring1=that.srcarr1.join(',');
                CaseSetContent({
                    case_id:that.case_id,
					type:key,
					title:that.worktitle,
					content:that.workcontent,
					file:that.imagestring1,
                }).then(res=>{
                    if(res.data.check==1){
                        this.content="提交成功";
                        this.getcasecontent();
                        this.worktitle='';
                        this.workcontent='';
                        this.srcarr1=[];
                        this.fileList=[];
                        this.imagestring1='';

                    }else{
                        this.content="提交失败"
                    }
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false;
                    }, 1500);
                })
            },
            
        }
    }
</script>
<style scoped>
.Network_box{
    position: relative;
    background: #eef3fa;
    padding-bottom: 2rem;
    min-height: 100%;
    display: flex;
    width: 86.4%;
    margin: 0 auto;
    /* margin-top: 45px; */
    justify-content: space-between;
}
.Network_tit{
    width: 52px;
    height: 138px;
    margin-top: 127px;
    background: #43A0F5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    /* font-size: 16px; */
    font-size: 1.6rem;
    color: #fff;
    text-align: center;
    /* vertical-align: middle; */
}
.Network_login{
    width: 30.35%;
    height: 431px;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    /* margin: 148px auto 0; */
    /* position: absolute;
    top: 148px;
    left: 36.72%; */
    margin: 0 auto;
    margin-top: 100px;
}
.Network_notice{
    /* font-size: 14px; */
    font-size: 1.4rem;
    line-height: 21px;
    /* position: fixed; */
    margin: 25px 0;
    width: 100%;
    text-align: center;
}
/* 登录框 */
.login_tit{
    /* font-size: 20px; */
    font-size: 2rem;
    line-height: 30px;
    /* font-family:'siyuanb'; */
    font-family: "siyuanb";
    margin-top: 71px;
    text-align: center;
    
}
.login_desc{
    text-align: center;
    
    /* font-size: 13px; */
    font-size: 1.3rem;
    
}
.login_input{
    height: 45px;
    width: 58.26%;
    border-bottom: 1px solid rgb(112 110 140 / 60%);
    margin: 0 auto;
    line-height: 45px;
    /* font-size: 14px; */
    font-size: 1.4rem;
    outline: none;
    margin-top: 20px;
}
.login_input input{
    border: none;
    background: transparent;
    margin-left: 48px;
    width: 15rem;
    outline: none;
}
.login_btn{
    width: 58.26%;
    height: 40px;
    background: #43A0F5;
    border-radius: 5px;
    /* font-size: 14px; */
    font-size: 1.4rem;
    text-align: center;
    line-height: 40px;
    color: #fff;
    margin: 61px auto 0;
    cursor: pointer;
}

/* 案件列表 */
.Network_list{
    width: 90%;
}
.list_tit{
    text-align: center;
    
    
    padding-top: 63px;
    /* font-family:'siyuanb'; */
    font-family: "siyuanb";
/* font-size: 20px; */
    font-size: 2rem;
    line-height: 30px;
    }
.list_con{
    /* width: 64.42%; */
    /* height: 410px; */
    margin: 34px auto 0;
    padding: 1.82% 2.61% 2.71%;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.6);
    border-radius: 10px;

}
.list_mess_list{
    /* font-size: 14px; */
    font-size: 1.4rem;
    line-height: 21px;
    
    
    margin-bottom: 10px;
}
.list_mess_network{
    width: 166px;
    height: 138px;
    /* left: 1410px; */
    /* top: 347px; */
    background: #43A0F5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    color: #fff;
    /* font-family:'siyuanb'; */
    font-family: "siyuanb";
    /* font-size: 14px; */
    font-size: 1.4rem;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}
.list_mess_network img{
    margin: 33px auto 15px;
}
.list_case_img{
    margin: 33px auto 15px;
    display: block;
    cursor: pointer;
}
.case_type{
    /* font-size: 14px; */
    font-size: 1.4rem;
    line-height: 21px;
    /* identical to box height */
    
    color: #706E8C
}
.case_type_box{
    /* width: 100%; */
    height: 110px;
    background: #F5FAFE;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
    margin-top: 24px;
    /* font-size: 14px; */
    font-size: 1.4rem;
    text-align: center;
    color: #FF5C5C;
}
.case_type_box div:nth-child(1){
    margin: 31px auto 10px;
}

/* 区域 */
.Network_detail{
    width: 90%;
    /* height: 56.1rem; */
    /* margin: 5rem auto 0; */
    margin-top: 5rem;
    background: #E7EEF5;
    border: 0.2rem solid rgba(255, 255, 255, 0.2);
    box-shadow: 0.9rem 0.9rem 1.6rem rgb(163 177 198 / 60%), -0.9rem -0.9rem 1.6rem rgb(255 255 255 / 60%);
    border-radius: 1rem;
    padding: 1.04% 1.2%;
    display: flex;
    justify-content: space-between;

}
.detail_left{
    width: 17.38%;
}
.detail_left_list{
    height: 53px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 3px;
}
.detail_left .detail_left_list_active{
    height: 53px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 3px;
    background: #43A0F5;
    color: #fff;
}
.detail_left_list img,.detail_left_list_active img{
    width: 40px;
    height: 40px;
    background: #BBD9F4;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 18px;
}
.detail_right{
    width: 77%;
    /* height: 56.2rem;  */
    background: #F5FAFE;
    border: 0.1rem solid #E2E2E8;
    border-radius: 0.5rem;
    padding: 0 2.5rem;
}
.detail_right_tit{
    height: 46px;
    
    
    line-height: 46px;
    border-bottom: 1px solid #E7EEF5;
    /* border-radius: 5px; */
    font-size: 14px;
    font-family: 'siyuanb';
}
.detail_chat{
    border-bottom: 1px solid #E7EEF5;
    padding-bottom: 20px;
    margin: 24px 0;
}
.detail_chat_time{
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    
    
}
.detail_chat_con{
    height: 50px;
    margin-bottom: 30px;
}
.detail_chat_con:last-child{
    margin-bottom: 0;
}
.detail_chat_con_left{
    font-size: 14px;
    display: flex;
    align-items: center;
    
    
}
.detail_chat_con_left img,.detail_chat_con_right img{
    width: 49px;
    height: 49px;
    background: #BBD9F4;
    border-radius: 5px;
    /* margin-right: 19px; */
}
.detail_chat_con_right{
    display: flex;
    align-items: center;
}
.detail_chat_con_right div,.detail_chat_con_left div{
    width: 60px;
    height: 30px;
    border-radius: 5px;
    color: #fff;
    font-family: 'siyuanb';
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}
.detail_chat_con_right div:nth-child(1),.detail_chat_con_left div:nth-child(1){
    background: #43A0F5;
    margin-right: 10px;

}
.detail_chat_con_right div:nth-child(2),.detail_chat_con_left div:nth-child(2){
    background: #FE9B4B;
}
.detail_con{
    font-family: 'siyuanb';
    font-size: 14px;
    line-height: 21px;
    /* display: flex; */
    /* align-items: center; */
    
    
}
.detail_con_qian{
    /* height: 40px; */
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /* position: relative; */
}
.detail_con_qian span{
    width: 11.5%;
    text-align: right;
}
.detail_con_qian input{
    width: 444px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
}
.detail_con_qian textarea{
    width: 444px;
    height: 120px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;

}
.upload-demo{
    /* position: absolute;
    top: 0;
    left: 12%; */
    margin-top: 20px;
}
.detail_notice{
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    
    
    font-family: 'siyuanr';
}
.sendbtn{
    width: 100px;
    height: 30px;
    background: #43A0F5;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    line-height: 30px;
    float: right;
    cursor: pointer;
    margin-bottom: 10px;
}
</style>