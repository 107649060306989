<template>
    <div class="Bottom_box">
        <div class="grids_title flexbe">
           <div class="grids_title_name">破产前沿</div>
           <div class="more" @click="Gomore">更多></div>
           <!-- <dv-decoration-10 style="width:90%;height:5px;position: absolute;bottom: 0;" :color="['#43A0F5','#ccc']" dur="6"/> -->
        </div>
        <div class="grids_con" @click="Godetail(item)" v-for="(item,index) in pochanlist" :key="index" v-if="pochanlist.length!==0">
           <img :src="baseURL+item.first_image[0].path" alt="">
           <div class="grids_con_mess">
                <div>{{item.title}}</div>
                <div>{{item.author}}</div>
                <div>{{item.create_time}}</div>
            </div>
        </div>
        <Nocontent v-if="pochanlist.length==0"></Nocontent>
        
    </div>
</template>
 
<script>
import {InformationList} from '@/request/api';
import Common from '../request/common.vue'
import Nocontent from '../components/Nocontent.vue'

    export default {
        name: 'Pochan',
        components: {
            Nocontent
        },
        data(){
            return{
                baseURL:'https://pc.pochanjie.com',

                pochanlist:[],
               
            }
        },
        created() {
            this.pochan();
           
        },
        methods:{
            // 7块
            pochan:function(){
                InformationList({
                    plate:'2',   //公告id
                    page:1,
                    limit:3,
                    pro:'',
                    city:'',
                    plate_class:0,
                    from:'pc'
                }).then(res => { 
                    this.pochanlist=res.data.rows;
                })
            },
            Gomore:function(){
               this.$router.push({path:'/Rulers',query:{plate:'2',current:6}})
               Common.current=7;
            },
            Godetail:function(item){
                // this.$router.push('/Rulerdetail')
                window.open(this.baseURL+item.pdf_input[0].path)

            }
        }
    }
</script>
 
<style scoped>


</style>