<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header ></Header>
        <div class="Election_cons">
            <div class="Network_tit flexar">表<br>决<br>通<br>道</div>
            <div class="Election_first" v-if="electionactive==0">
                <!-- <img src="../../image/first.png" alt=""> -->
                
                <div class="Election_con" v-for="(item,index) in videolists" :key="index" v-if="videolists.length!==0">
                    <div class="Election_name">{{item.theme}}</div>
                    <div class="Election_mess">
                        <div class="Election_time">{{item.start_time}}-{{item.end_time}}</div>
                        <!-- <div class="flexbe" style="height: 46px;padding-right: 10px;"><span>表决材料</span><span @click="pulldetail" style="cursor: pointer;" class="iconfont icon-a-Frame2"></span></div> -->
                        <div class="dialog_list flexbe" style="height: auto;" v-for="(items,index) in item.detail.material_files" :key='index' >
                            <span>{{items.real_name}}</span>
                            <div class="dialog_list_btn" @click="read(items.path,item.detail.user_sign_file.file)">阅读</div>
                        </div>
                        <div class="dialog_list_btn_all" @click="getsign(item,'file')" v-if="roles=='member'">{{item.detail.user_sign_file.file==0?'一键签收':'已签收'}}</div>
                    </div>
                    <div class="Election_btns themebgcolor" @click="GoVote(item)" >我要参加表决</div>
                    
                </div>
                <div @click="GoVoteother" class="Election_btns themebgcolor" v-if="show_button==1">我要参加其他表决</div>

                <div v-if="videolists.length==0" style="text-align: center;font-size: 20px;margin-top: 70px;">
                    <div>目前没有新的需要表决的事项</div>
                    <div>请关注管理人发布的有关动态消息</div>
                </div>
                <!-- <Nocontent ></Nocontent> -->
            </div>
            <div class="Election_first" v-if="electionactive==1">
                 <!-- 选择身份 -->
                <div class="Election_second_con_other" v-if="status=='other'">
                    <div class=" Election_btns" @click="electionnext('zhai')" :style="{background:type=='zhai'?'#FF9F46':'#43A0F5'}" v-if="roles=='member'">我是债权人</div>
                    <div class=" Election_btns" @click="electionnext('dai')" :style="{background:type=='dai'?'#FF9F46':'#43A0F5'}" v-if="roles=='member'">我是代理人</div>
                    <div class=" Election_btns" @click="electionnext('manage')" :style="{background:type=='manage'?'#FF9F46':'#43A0F5'}" v-if="roles=='manage'">我是管理员</div>
                    <div class=" Election_btns" @click="electionnext('aid')" :style="{background:type=='aid'?'#FF9F46':'#43A0F5'}"  v-if="roles=='aid'">我是助手</div>
                    <div class=" Election_btns" @click="electionnext('work')" :style="{background:type=='work'?'#FF9F46':'#43A0F5'}"  v-if="roles=='work'">我是法院工作人员</div>
                </div>

                <!-- 选项卡 -->

                <div class="Election_second" v-if="status=='ordinary'">
                    <div class="Manage_tab">
                        <div :class="currentzhai==index?'Manage_tab_active':''" v-for="(item,index) in managetab" :key="index" @click="tabclick(index)">{{item}}</div>
                    </div>
                    <div class="Manage_mine" v-if="currentzhai==0">
                        <div class="Election_second_tit">表决事项</div>
                        <div class="Election_second_list" v-for="(item,index) in biaolist" :key="index">
                            <div class="vote_tit">{{item.label}}</div>
                            <div class="vote_con flexbe" v-for="(people,index2) in item.biaopeople" :key='index2' >
                                <span>{{people.case_user_name}}</span>
                                <div style="color: #999999;" v-if="people.allow==1&&people.is_vote==1">
                                    表决结果：{{people.result==0?'未表决':people.result==1?'同意':'反对'}}
                                </div>
                                <div class="flexbe" v-if="people.allow==1&&people.is_vote==0">
                                    <div class="vote_con_btn" @click="agreememberlist(people.case_user_id,index,index2,'0')" >同意</div>
                                    <div class="vote_con_btn" @click="agreememberlist(people.case_user_id,index,index2,'1')" >反对</div>
                                    <div class="vote_con_btn" @click="tijiao(item.vote_id,people.result,index)" v-if="people.allow==1&&people.is_vote==0" :style="{background:people.result==0?'#CBCBCB':'orange'}">提交</div>
                                </div>
                                <div class="flexbe" v-if="people.allow==0">
                                    暂时不允许表决
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="Manage_mine" v-if="currentzhai==1">
                        <div class="Election_tab" v-for="(item,index) in data" :key="index">
                            <div class="Election_tab_tit">{{item.name}}</div>
                            <div class="Election_tab_list flexbe" :style="{background: (indexs == 'yes' ||  indexs == 'agree') ? '#B0C7E6' :''}" v-for="(items,indexs) in item.left.data" :key="indexs">
                                <span>{{items.name}}</span>
                                <span>{{items.number}}</span>
                                <span>{{items.percent}}%</span>
                            </div>
                            <div class="Election_tab_list flexbe" :style="{background: (indexs == 'yes' ||  indexs == 'agree') ? '#B0C7E6' :''}" v-for="(items,indexs) in item.right.data" :key="indexs">
                                <span>{{items.name}}</span>
                                <span>{{items.number}}</span>
                                <span>{{items.percent}}%</span>
                            </div>
                        </div>
                    </div>
                    <div class="Manage_mine" v-if="currentzhai==2&&path!='video'">
                        <div class="Join_person" v-for="(item,index) in joinlist" :key="index" v-if="joinlist.length!==0">{{item.name}}</div>
                        <div class="Join_person" v-if="joinlist.length==0">暂无参会人</div>
                    </div>
                    <div class="Manage_mine" v-if="currentzhai==2&&path=='video'" style="padding: 20px;">
                        <div class="joinlist" v-for="(item,index) in aidlist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>助手</span>
                        </div>
                        <div class="joinlist" v-for="(item,index) in managelist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>管理员</span>
            
                        </div>
                        <div class="joinlist" v-for="(item,index) in memberlist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>债权人</span>
            
                        </div>
                        <div class="joinlist" v-for="(item,index) in otherlist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>其他</span>
            
                        </div>
                        <div class="joinlist" v-for="(item,index) in playlist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>主播</span>
            
                        </div>
                        <div class="joinlist" v-for="(item,index) in worklist" :key="index">
                            <span>{{item.nickname}}</span>
                            <span>工作人员</span>
            
                        </div>
                    </div>
                </div>
            </div>
            <div class="Election_first" v-if="electionactive==2">
                 <!-- 债权人 -->
                 <div class="Election_third_con_other" v-if="type=='zhai'">
                    <div class="Election_third_con_list flexbe">
                        <span>姓名：</span>
                        <input type="text" placeholder="请输入姓名" v-model="zhainame">
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>手机号：</span>
                        <input type="text" placeholder="请输入手机号" v-model="zhaiphone">
                    </div>
                    <div class="Election_third_con_list" style="display: flex;align-items: center;">
                        <span>身份：</span>
                        <div style="margin-left: 20px;">
                            <el-radio-group v-model="zhaiFrom.type" style="margin-left: 10px;" >
                                <el-radio :label="1">个人</el-radio>
                                <el-radio :label="2">公司</el-radio>
                            </el-radio-group>
                        </div>
                        
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>验证码：</span>
                        <input type="text" placeholder="请输入验证码" v-model="zhaicode">
                    </div>
                    <div class="nextbtn" @click="nextstep('zhai')">登录</div>
                </div>
                <!-- 代理人 -->
                <div class="Election_third_con_other" v-if="type=='dai'">
                    <div class="Election_third_con_list flexbe">
                        <span>姓名：</span>
                        <input type="text" placeholder="请输入姓名" v-model="agent_name">
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>手机号：</span>
                        <input type="text" placeholder="请输入手机号" v-model="agent_phone">
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>区域：</span>
                        <el-cascader
                            clearable
                            v-model="areavalue"
                            :options="selectarea"
                            :props="{ expandTrigger: 'hover' }"
                            @change="handleChange">
                        </el-cascader>
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>送达地址：</span>
                        <input type="text" placeholder="请输入送达地址" v-model="agent_addr">
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>债权人姓名：</span>
                        <input type="text" placeholder="请输入债权人姓名" v-model="claim_name">
                    </div>
                    <div class="Election_third_con_list" style="display: flex;align-items: center;">
                        <span>身份：</span>
                        <div style="margin-left: 20px;">
                            <el-radio-group v-model="daiFrom.type" style="margin-left: 10px;" >
                                <el-radio :label="1">个人</el-radio>
                                <el-radio :label="2">公司</el-radio>
                            </el-radio-group>
                        </div>
                        
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>邀请码：</span>
                        <input type="text" placeholder="请输入邀请码" v-model="codes">
                    </div>
                    <div class="nextbtn" @click="nextstep('dai')">登录</div>
                </div>
                <!-- 工作人员，助手，管理员 -->
                <div class="Election_third_con_other" v-if="type=='manage'||type=='aid'||type=='work'">
                    <div class="Election_third_con_list flexbe">
                        <span>账号：</span>
                        <input type="text" placeholder="请输入账号" v-model="account">
                    </div>
                    <div class="Election_third_con_list flexbe">
                        <span>密码：</span>
                        <input type="text" placeholder="请输入密码" v-model="mima">
                    </div>
                    <div class="nextbtn" @click="nextstep()">验证</div>
                </div>


                <!-- <div class="Election_third_con" v-if="status=='ordinary'">
                    <div class="vote_second_tit">表决统计</div>
                    <div class="vote_list">
                        <div class="vote_list_tit">表决材料的签收情况</div>
                        <div class="vote_lists"><span>已签收债权人数</span><span>1</span><span>50%</span></div>
                        <div class="vote_lists"><span>已签收债权人数</span><span>1</span><span>50%</span></div>
                        <div class="vote_lists"><span>已签收债权人数</span><span>1</span><span>50%</span></div>
                        <div class="vote_lists"><span>已签收债权人数</span><span>1</span><span>50%</span></div>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- 提示框 -->
        <Toast :toastText="content" v-show="toastShow"></Toast>
        
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'
    import Toast from '../../components/Toast.vue'
    import Nocontent from '../../components/Nocontent.vue'
    import { getcitydata } from "../../../public/city-picker.data";

    import {VoteLists,BallotSingle,VoteStatistical,VoteVoteList,VoteUserList,VoteCheckVote,VoteDoVote,MeetingCheckIdentity,VoteSignFile,
        MeetingLists,MeetingVoteList,MeetingCheckVote,MeetingStatistical,MeetingGetChrList,MeetingDoVote} from '@/request/api';

    export default {
        name: 'Election',
        components: {
            Header,
            Toast,
            Nocontent
        },
        data(){
            return{
                baseURL:'https://pc.pochanjie.com',

                radio: '1',
                checked: true,
                electionactive:0,
                status:'ordinary',
                step:'first',
                roles:'member',
                active:0,
                path:'',
                pull:0,

                managetab:["表决","统计","参会人"],
                currentzhai:0,
                dialogTableVisible:false,

                videolists:[],
                show_button:0,
                biaojuelist:{},

                //表决
                meeting_id:'',
                case_id:'',
                election_content:[],
                election_status:'',
                biaolist:[],
                data:'',
                joinlist:[],
                major:[],
                case_user_id:'',

                // 其他登录
                type:'',
                account:'',
                mima:'',
                zhainame:'',
                zhaiphone:'',
                zhaicode:'',
                zhaiFrom:{
                    id: 0,
                    name: '',
                    type: 1,
                },
                region:[],
                // 地址
                areavalue: [],
                selectarea: [],
				agent_name:'',
				agent_phone:'',
				agent_addr:'',
				claim_name:'',
				claim_code:'',
				agent_pro:'',
				agent_city:'',
				agent_area:'',
                daiFrom:{
                    id: 0,
                    name: '',
                    type: 1,
                },
                codes:'',
                content:'',
                toastShow:false,

                aidlist:[],
                managelist:[],
                memberlist:[],
                otherlist:[],
                playlist:[],
                worklist:[],

            }
        },
        created() {
            this.selectarea=getcitydata('3')

            this.path=this.$route.query.path;
            if(this.path=='video'){
                this.electionactive=1
                this.status='ordinary'
                this.major=this.$route.query.item;
                this.meeting_id=this.major.id;
                this.case_id=this.major.case_id;
                this.votemessage()
            }else{
                this.videolist()
            }
           
        },
      
        methods:{
            handleChange(value) {   //选择地址
                this.agent_pro=value[0];
                this.agent_city=value[1];
                this.agent_area=value[2];
                this.areavalue=value;
            },
            
            videolist:function(){
                // if(this.path=='video'){
                //     MeetingLists({   //表决会议列表
                //         // meeting_id:this.meeting_id
                //     }).then(res=>{
                //         this.videolists=res.data.list;
                //         this.roles=res.data.roles;
                //         this.show_button=res.data.show_button;
                //         // this.major=this.videolists[0];
                //         // this.otherlist=this.videolists.slice(1)
                //     })
                // }else{
                    VoteLists({   //表决会议列表
                        token:localStorage.getItem('token')
                    }).then(res=>{
                        this.videolists=res.data;
                        // this.roles=res.data.roles;
                        // this.show_button=res.data.show_button;
                    })
                // }
            },
            electionnext:function(type){
                this.electionactive=2;
                this.type=type;
            },
            GoVote:function(item){
                this.meeting_id=item.id;
                if(this.roles=='member'&&item.file_status == 1){
                    if(item.file_status==0){
                        this.content="请先签收文书";
                        this.toastShow=true;
                        setTimeout(() => {
                            this.toastShow=false
                        }, 1500);
                        return
                    }
                }
               

                var that=this;
				var timestemp1 = Date.parse(new Date())
				var Time2 = new Date(item.start_time);
				var timestemp2 = Time2.getTime();
				var Time3 = new Date(item.end_time);
				var timestemp3 = Time3.getTime();
				if(timestemp2>timestemp1){
					this.content="当前会议还没有开始";
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
				}else if(timestemp1>timestemp3){
                    this.content="当前会议已结束";
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
				}else{
                    this.biaojuelist=item;
                    this.meeting_id=item.id;
                    this.case_id=item.case_id;
                    this.electionactive=1;
                    this.status="ordinary";
                    this.votemessage();
                }
               
            },
            GoVoteother:function(){
                this.electionactive=1;
                this.status='other'
            },
            votemessage:function(){
                if(this.path=='video'){   //从会议进来的表决
                    MeetingVoteList({
                        token:localStorage.getItem('token'),
                        meeting_id: this.meeting_id,
                        // case_id: this.case_id
                    }).then(res => {
                        this.election_content=res.data.election_content;
						this.biaolist=res.data.list;
						for(var i=0;i<this.biaolist.length;i++){
                            MeetingCheckVote({
                                // meeting_id:this.meeting_id,
								// case_id:this.case_id,
                                token:localStorage.getItem('token'),
								vote_id:this.biaolist[i].vote_id,
								i:i   
                            }).then(res=>{
                                if(res.code==1){
									this.biaolist[res.data.i]['biaopeople']=res.data.list;
									var tmp = this.biaolist;
									this.biaolist = [];
									this.biaolist = tmp;
								}else{
									this.content=res.msg;
                                    this.toastShow=true;
                                    setTimeout(() => {
                                        this.toastShow=false
                                    }, 1500);
								}
                            })
						}
                    })

                    MeetingStatistical({
                        token:localStorage.getItem('token'),
                        meeting_id:this.meeting_id
                    }).then(res=>{
                        this.election_status=res.data.election_status;
                        this.election_data=res.data.election_data;
                        this.data=res.data.data;
                    })

                    MeetingGetChrList({
                        token:localStorage.getItem('token'),
                        meeting_id:this.meeting_id
                    }).then(res=>{
                        this.aidlist = res.data.aid;
                        // this.managelist = res.data.manage;
                        this.memberlist = res.data.member;
                        this.otherlist = res.data.other;
                        this.playlist = res.data.play;
                        this.worklist = res.data.work;
                    })
                    
                }else{    //表决通道自身的表决
                    VoteVoteList({   //表决列表
                        token:localStorage.getItem('token'),
                        ballot_id:this.meeting_id,
                    }).then(res=>{
                        // this.election_content=res.data.election_content;
                        // this.election_status=res.data.election_status;
                        this.biaolist=res.data.list;
                        
                        for(var i=0;i<this.biaolist.length;i++){
                            VoteCheckVote({
                                token:localStorage.getItem('token'),
                                vote_id:this.biaolist[i].vote_id,
                                i:i
                            }).then(res=>{
                                if(res.code==1){
                                    this.biaolist[res.data.i]['biaopeople']=res.data.list;
                                    var tmp = this.biaolist;
                                    this.biaolist = [];
                                    this.biaolist = tmp;
                                }else{
                                    this.content=res.msg;
                                    this.toastShow=true;
                                    setTimeout(() => {
                                        this.toastShow=false
                                    }, 1500);
                                }
                            })
                        }
                    })

                    VoteStatistical({   //统计列表
                        token:localStorage.getItem('token'),
                        ballot_id:this.meeting_id
                    }).then(res=>{
                        // this.election_status=res.data.election_status;
                        // this.election_data=res.data.election_data;
                        this.data=res.data.data;
                    })

                    VoteUserList({  //参会人列表
                        token:localStorage.getItem('token'),
                        ballot_id:this.meeting_id
                    }).then(res=>{
                        this.joinlist=res.data
                    })
                }
                
            },
            pulldetail:function(){  //会议材料下拉
                if(this.pull==1){
                    this.pull=0
                }else{
                    this.pull=1
                }
            },
            read:function(path,status){   //阅读
                if(status==0){   
                    this.content="请先签收文书再进行阅读";
                    this.toastShow=true;
                    setTimeout(() => {
                        this.toastShow=false
                    }, 1500);
                }else{
                    window.open(this.baseURL+path)
                }
            },
            getsign:function(item,type){  //签收
				var that=this;
				if(item.detail.user_sign_file.file==1&&type=='file'){
					that.content="会议材料已签收";
                    that.toastShow=true;
                    setTimeout(() => {
                        that.toastShow=false;
                    }, 1500);
				}
				
               
                if(item.detail.user_sign_file.file==0){
                    VoteSignFile({
                        token:localStorage.getItem('token'),
                        ballot_id:item.id,
                        input_type:type
                    }).then(res=>{
                        if(res.code==1){
                            that.content="签收成功"
                        }else{
                            that.content=res.msg;
                        }
                        that.toastShow=true;
                        setTimeout(() => {
                            that.toastShow=false;
                            that.videolist()
                        }, 1500);
                    })
                }
                
                

			},
            agreememberlist:function(id,index,index2,index1){
				this.case_user_id=id;
				this.biaolist[index].biaopeople[index2].result=parseInt(index1)+1;
					
				var aa=this.biaolist;
				this.biaolist=[];
				this.biaolist=aa;
			},
            tijiao:function(id,result,index){
				var that=this;
				if(result==0){
					that.content="请选择你的意见"
				}else{
                    if(that.path=='video'){
                        MeetingDoVote({
                            token:localStorage.getItem('token'),
                            case_user_id:that.case_user_id,
                            vote_id:id,
                            result:result   //1同意2不同意0啥也没选
                        }).then(res=>{
                            if(res.code==1){
                                that.content=res.msg;
                                MeetingCheckVote({
                                    token:localStorage.getItem('token'),
                                    // meeting_id:that.meeting_id,
                                    // case_id:that.case_id,
                                    vote_id:id,
                                    i:index
                                }).then(res=>{
                                    if(res.code==1){
                                        that.biaolist[res.data.i]['biaopeople']=res.data.list;
                                        var aa=that.biaolist;
                                        that.biaolist=[];
                                        that.biaolist=aa;
                                    }
                                })
                                // VoteVoteList({   //表决列表
                                //     vote_id:this.meeting_id,
                                //     case_id:this.case_id
                                // }).then(res=>{
                                //     // this.election_content=res.data.election_content;
                                //     // this.election_status=res.data.election_status;
                                //     this.biaolist=res.data.list;
                                // })
                            }else{
                                that.content=res.msg
                            }
                        })
                    }else{
                        VoteDoVote({
                            token:localStorage.getItem('token'),
                            case_user_id:that.case_user_id,
                            vote_id:id,
                            result:result   //1同意2不同意0啥也没选
                        }).then(res=>{  
                            if(res.code==1){
                                that.content=res.msg;
                                VoteCheckVote({
                                    token:localStorage.getItem('token'),
                                    vote_id:id,
                                    i:index
                                }).then(res=>{
                                    if(res.code==1){
                                        that.biaolist[res.data.i]['biaopeople']=res.data.list;
                                        var aa=that.biaolist;
                                        that.biaolist=[];
                                        that.biaolist=aa;
                                        that.content=res.msg
                                    }
                                })
                            }else{
                                that.content=res.msg
                            }
                        })
                    }
                    
				}
                that.toastShow=true;
                setTimeout(() => {
                    that.toastShow=false
                }, 1500);
			},
            nextstep:function(type){
                var that=this;
				if(that.roles=='manage'||that.roles=='work'||that.roles=='aid'){
					if(that.account==''||that.mima==''){
						that.content="账号或密码为空！"
					}else{
                        MeetingCheckIdentity({
                            account:that.account,
							password:that.mima,
							type:that.roles
                        }).then(res=>{
                            that.content=res.msg;
                            that.electionactive=0
							
						})
                    }
				}else if(that.roles=='member'){
					if(type=='zhai'){  // 0 债权人 1 代理人
						if(that.zhainame==''||that.zhaiphone==''||that.zhaicode==''){
							that.content="基本信息为空"
						}else{
							MeetingCheckIdentity({
                                name:that.zhainame,
								phone:that.zhaiphone,
								attributes:that.zhaiFrom.type,
								identification_code:that.zhaicode,
								type:that.roles,
								member_type:0
                            }).then(res=>{
                                that.content=res.msg;
                                that.electionactive=0
                            })
						}
						
					}
                    if(type=='dai'){					// 0 债权人 1 代理人
						if(that.agent_name==''||that.agent_phone==''||that.agent_pro==''||that.agent_area==''||that.agent_addr==''||that.claim_name==''||that.codes==''){
							that.content="请填写必填项"
                          
						}else{
							MeetingCheckIdentity({
                                agent_name:that.agent_name,
								agent_phone:that.agent_phone,
								agent_pro:that.agent_pro,
								agent_city:that.agent_city,
								agent_area:that.agent_area,
								agent_address:that.agent_addr,
								creditor_name:that.claim_name,
								invitation_code:that.codes,
								type:that.roles,
								attributes:that.daiFrom.type,
								member_type:1
                            }).then(res=>{
                                that.content=res.msg;
                                that.electionactive=0
                            })
						}
						
					}
				}
                that.toastShow=true;
                setTimeout(() => {
                    that.toastShow=false
                }, 1500);

            },
            tabclick:function(index){
                this.currentzhai=index
            },
            electionbtn:function(){
                this.dialogTableVisible=true
            },
            sendbtn:function(){
                this.dialogTableVisible=true
            }
        }
    }
</script>
<style scoped>
    .Election_cons{
        width: 86.4%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    .Election_first{
        position: relative;
    }
    .Network_tit{
        width: 52px;
        height: 138px;
        margin-top: 127px;
        background: #43A0F5;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        /* font-size: 16px; */
        font-size: 16px;
        color: #fff;
        text-align: center;
       
    }
    .Election_first{
        width: 90%;
        margin-top: 32px;
    }
    .Election_first img{
        display: block;
        margin: 0 auto;
        width: 55.8%;
    }
    .Election_con{
        /* height: 385px; */
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        border: 1px solid #e7eef5;
        text-align: center;
        margin-bottom: 10px;
    }
    .Election_time{
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
    }
    .Election_name{
        font-size: 16px;
        font-family: 'siyuanb';
        height: 46px;
        line-height: 46px;
        /* border-bottom: 1px solid #E7EEF5; */
    }
    .Election_mess{
        border-bottom: 1px solid #E7EEF5;
        padding: 20px 0;
    }
    .Election_name{
        /* font-size: 14px; */
        /* line-height: 21px; */
        /* text-align: center; */
        
        
        /* margin-bottom: 10px; */
    }
    .Election_tit{
        color: #F47814;
        margin-bottom: 24px;
    }
    .Election_title{
        margin-bottom: 13px;
        
    }
    .Election_btn{
        display: flex;
        margin: 0 auto;
        width: 15%;
        justify-content: space-between;
    }
    .Election_btn div:nth-child(1){
        width: 78px;
        height: 30px;
        line-height: 30px;
        background: #43A0F5;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
    }
    .Election_btn div:nth-child(2){
        width: 78px;
        height: 30px;
        line-height: 30px;
        background: #FE9B4B;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;

    }
    .Election_btns{
        width: 268px;
        height: 40px;
        border: 1px solid #E2E2E8;
        border-radius: 5px;
        margin: 23px auto 20px;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
        text-align: center;
        font-size: 16px;

    }
    .Election_btns div:hover{
        background: #FF9F46;
    }
    .dialog_list{
        /* height: 46px; */
        width: 40%;
        background: #EEF3FA;
        border-radius: 5px;
        padding:10px;
        margin: 0 auto 10px;
        font-size: 14px;
    }
    .dialog_list_btn{
        width: 60px;
        height: 30px;
        border-radius: 5px;
        display: block;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        line-height: 30px;
        cursor: pointer;
        background: #43A0F5;
    }
    .dialog_list_btn_all{
        width: 120px;
        height: 30px;
        border-radius: 5px;
        display: block;
        text-align: center;
        color: #fff;
        margin: 0 auto;
        line-height: 30px;
        cursor: pointer;
        font-size: 14px;
        background: #FE9B4B;
    }
    .dialog_lists{
        height: 40px;
    }
    /* 第二部分 */
    .Election_second_con_other{
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        border: 1px solid #e7eef5;
        text-align: center;
    }
    .Election_second{
        
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        border: 1px solid #e7eef5;
        padding-bottom: 20px;
    }
    .Election_second_tit{
        font-size: 16px;
        height: 49px;
        line-height: 49px;
        
        border-bottom: 1px solid #E7EEF5;
        
        font-family: 'siyuanb';
        text-align: left;
        padding-left: 37px;
    }
    .Election_second_list{
        margin-top: 2rem;
        padding: 0 3.7rem;
        background: #EEF3FA;
        border-radius: 5px;
        /* padding: 17px 20px 0px; */
        padding: 17px 20px;
        width: 95.8%;
        margin: 10px auto;
    }
   
    /* 第三部分 */
    .Election_third_con_other{
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        border: 1px solid #e7eef5;
        text-align: center;
        padding: 32px 0;
    }
    .Election_third_con_list{
        height: 40px;
        margin: 0px auto 10px;
        width: 35%;
        
    }
    .Election_third_con_list span{
        text-align: right;
        width: 20%;
        font-family: 'siyuanb';
        font-size: 14px;
    }
    .Election_third_con_list input{
        width: 346px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #E2E2E8;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0 13px;
        outline: none;
    }
    .nextbtn{
        width: 100px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        background: #43A0F5;
        border-radius: 5px;
        margin: 30px auto 0;
        cursor: pointer;
    }
    /* 第三部分 */
    .Election_third_con{
        
        height: 591px;
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
        border-radius: 10px;
        border: 1px solid #e7eef5;
    }
    .Election_third_con_xuan{
        padding: 24px 44px 31px 44px;
        border-bottom: 1px solid #E7EEF5;
    }
    .Election_third_con_xuan_tit{
        font-size: 14px;
        line-height: 21px;
        
        
        font-family: 'siyuanb';
    }
    .Election_third_con_xuan_hou{
        margin: 29px 0 20px;
        font-size: 14px;
        /* line-height: 21px; */
        
        
    }
    .selectxuan{
        display: flex;
    }
    .selectxuan div{
        width: 100px;
        height: 30px;
        background: rgb(130 130 148 / 20%);
        /* opacity: 0.2; */
        border-radius: 5px;
        text-align: center;
        line-height: 30px;
        margin-right: 20px;
        cursor: pointer;
    }
    .Election_third_con_xuan input{
        width: 372px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #E2E2E8;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 10px;
        display: block;
        padding-left: 13px;
        outline: none;
    }
    .Election_third_con_xuan input:nth-child(1){
        margin-top: 20px;
    }
    .Election_third_con_xuan:last-child{
        border: none;
    }
    .sendbtns{
        width: 78px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        background: #43A0F5;
        border-radius: 5px;
        cursor: pointer;
        margin: 20px auto;
        text-align: center;
    }

    /* vote */
    .vote_tit{
        font-size: 14px;
        line-height: 21px;
    }
    .vote_con{
        height: 50px;
        font-size: 14px;
        border-bottom: 1px solid rgb(138 138 138 / 10%);
    }
    .vote_con:last-child{
        border: none;
    }
    .vote_con_btn{
        width: 60px;
        height: 30px;
        border-radius: 5px;
        color: #fff;
        text-align: center;
        line-height: 30px;
        margin-right: 10px;
        cursor: pointer;
    }
    .vote_con_btn:nth-child(1){
        background: #43A0F5;
    }
    .vote_con_btn:nth-child(2){
        background: #FF7070;
    }
    .vote_con_btn_active{
        width: 60px;
        height: 30px;
        border-radius: 5px;
        color: #fff;
        text-align: center;
        line-height: 30px;
        margin-right: 10px;
        cursor: pointer;
        background: #FE9B4B;
    }
  
    /* 表决统计 */
    .vote_second_tit{
        font-size: 16px;
        height: 49px;
        line-height: 49px;
        
        border-bottom: 1px solid #E7EEF5;
        
        font-family: 'siyuanb';
        text-align: center;
        padding-left: 37px;
    }
    .vote_list{

    }
    .vote_list_tit{
        width: 98.8%;
        height: 4.6rem;
        background: #EEF3FA;
        border-radius: 0.5rem;
        margin: 0 auto;
        text-align: center;
        line-height: 46px;
        font-size: 14px;
        
        
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .vote_lists{
        font-size: 14px;
        line-height: 21px;
        
        
        display: flex;
        justify-content: space-between;
        width: 95.5%;
        margin: 0 auto;
        margin-bottom: 10px;
    }

     /* 选项卡 */
     .Manage_tab{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        text-align: center;
        font-size: 16px;
        font-family: 'siyuanb';
        line-height: 40px;
        cursor: pointer;
    }
    .Manage_tab .Manage_tab_active{
        background: #43A0F5;
        color: #fff;
    }
    .Manage_tab div{
        width: 33.3%;
        height: 40px;
        
        background: #fff;
        border: 1px solid #E2E2E8;
        box-sizing: border-box;
        border-radius: 5px 0px 0px 0px;
    }
    /* 统计 */
    .Election_tab{
        width: 90%;
        margin: 0 auto;
        margin-top: 18px;
    }
    .Election_tab_tit{
        height: 48px;
        background: #DAE5EF;
        border-radius: 5px;
        font-size: 14px;
        line-height: 48px;
        text-align: center;
    }
    .Election_tab_list{
        padding: 0 26px;
        font-size: 14px;
    }
    .Election_tab_list:nth-child(even){
        background: #EEF3FA;
        height: 30px;
    }
    .Election_tab_list:nth-child(odd){
        background: #E1EAF2;
        height: 37px;
    }
    /* 参会人 */
    .Join_person{
        line-height: 50px;
        font-size: 14px;
        padding: 0 20px;
    }
    .joinlist{
        height: 50px;
        /* line-height: 50px; */
        font-size: 16px;
        border-bottom: 1px dashed #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>