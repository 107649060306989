<template>
  <div class="backcolor" style="min-width: 1200px;">
    <Header :current='current'></Header>
    <div class="Home_main">
      <div class="Home_swiper">
        <div class="pic">
          <ul class="tu">
              <transition-group name="img">
              <li v-for="(item,index) in bannerList" :key="index" v-show="index===mark" @mouseenter="stop" @mouseleave="go">
                  <img :src="baseURL+item.path" :key="index" mode="center">
              </li>
              </transition-group>
          </ul>
          <ul class="bannerBtn">
              <li v-for="(num,index) in bannerList.length" :key="index">
                  <a href="javascript:;" :style="{background:num-1===mark?'#fff':''}" @click='change(num-1)' class='aBtn'></a>
              </li>
          </ul>
        </div>
      </div>
      <div class="Home_tab">
        <div class="Home_tab_list"><div v-for="(item,index) in tablists" :key="index" :class="currenthome==index?'spec':'ruglar'"  @click="tabclick(index)">{{item}} </div></div>
        <div v-if="currenthome==0" class="Home_tab_con1" style="display: flex;justify-content: space-between;">  
          <el-carousel :interval="10000" arrow="always" style="width: 100%;">
            <el-carousel-item v-for="(items,indexs) in Debtorinformationlist" :key="indexs" style="display: flex;justify-content: space-between;">
              <div class="Home_com_mess">
                <div class="Home_com_mess_name">{{items.company_name}}</div>
                <div class="Home_com_mess_list">受理破产案件法院：{{items.court}}</div>
                <div class="Home_com_mess_list">管理人：{{items.custodian_name}}</div>
                <div class="Home_com_mess_list">债权申报截止日期：{{items.claims_expire}}</div>
                <div class="Home_com_mess_list">债权申报地点：{{items.address}}</div>
                <div class="Home_com_mess_list">管理人联系电话：{{items.phone}}</div>
                <div class="Home_com_mess_list">管理人电子邮箱：{{items.email}}</div>
                <div class="Home_com_mess_list"> 管理人微信号：{{items.weixin}}</div>
              </div>
              <div class="Home_con_btn" v-if="logintype==1">
                <!-- <div @click="debtordetail(items.id,items.is_show,'zhai')">我是债权人，我要申报债权</div>
                <div  @click="debtordetail(items.id,items.is_show,'dai')">我是代理人，我要代为申报债权</div> -->
                <div :class="items.is_show==1?'graybgcolor':'themebgcolor'"  @click="debtordetail(items.id,items.is_show,'zhai',indexs)">我是债权人，我要申报债权</div>
                <div :class="items.is_show==1?'graybgcolor':'orangebgcolor'"  @click="debtordetail(items.id,items.is_show,'dai',indexs)">我是代理人，我要代为申报债权</div>
                <div v-if="items.is_show==1&&supplytype==true&&detailcurrent==indexs" class="orangebgcolor" @click="supply(items.id,type)">补充注册</div>
              </div>
            </el-carousel-item>
          </el-carousel>

          
        </div>
        <div v-if="currenthome==1" class="Home_tab_con1">
          <div class="morebtn" @click="Gomorenotice">更多>></div>
          <div style="height:70%">
            <div class="msg__content">
              <vue-seamless-scroll
                  :data="systemMsg"
                  :class-option="optionSetting" 
                  class="seamless-warp"
              >
                <div class="Download_content" v-for="(item,index) in documents" :key="index" v-if="documents.length!==0">
                  <div class="book_tit">{{item.title}}</div>
                  <!-- <div class="book_time">2021年06月04日   -    2021年07月10日 </div> -->
                  <div class="book_list flexbe" v-for="items in item.data" v-if="item.data.length!==0">
                      <span>{{items.title}}</span>
                      <div style="display: flex;">
                          <div class="book_list_btn" @click="read(items.input[0].path)"  v-if="item.download==1">阅读</div>
                          <div class="book_list_btn"  @click="read(items.input[0].path)" v-if="item.download==1">下载</div>
                      </div>
                  </div>
                  <div class="book_list" style="text-align: center;" v-if="item.data.length==0">暂无内容</div>
                </div>
              </vue-seamless-scroll>
            </div>
            <!-- <div class="nocontent" >暂无内容</div> -->
            <Nocontent v-if="documents.length==0" ></Nocontent>
          </div>
        </div>
        <!-- <div v-if="currenthome==1" class="Home_tab_con1">  
          <div class="morebtn" @click="Gomorenotice">更多>></div>
          <div style="overflow-y: scroll;height:70%">
            <div class="msg__content">
              <vue-seamless-scroll
                  :data="systemMsg"
                  :class-option="optionSetting" 
                  class="seamless-warp"
              >
              <div v-for="item in systemMsg" :key="item.id" class="Home_po_list" @click="Gonoticedetail(item)" >
                <img :src="item.first_image[0].path" alt="">
                <div class="Home_po_mess flexbe">
                  <div><span>{{item.title}}</span></div>
                  <div><span>{{item.author}}</span><span>{{item.create_time}}</span></div>
                </div>
              </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div> -->

        <div v-if="currenthome==2" class="Home_tab_con1" style="display: flex;justify-content: space-between;padding:0;">  
          <Calendar></Calendar>
        </div>
        <div v-if="currenthome==3" class="Home_tab_con1" >
          <div class="morebtn" @click="Gomore" v-if="daynoticelist.length!==0">更多>></div>
          <div style="height:70%" v-if="daynoticelist.length!==0">
            <vue-seamless-scroll :data="daynoticelist" :class-option="optionSetting" class="seamless-warp" >
              <div class="Day_list" v-for="item in daynoticelist" @click="Godaynotice">
                <div class="Day_tit">{{item.title}}</div>
                <div class="Day_time flexbe">
                  <span>{{item.company_name}}</span>
                  <span>{{item.time}}</span>
                </div>
                <div class="Day_con">{{item.content}}</div>
              </div>
            </vue-seamless-scroll>
          </div>
          <!-- <div >暂无内容</div> -->
          <Nocontent v-if="daynoticelist.length==0"></Nocontent>
        </div>
      </div>

    </div>
    <!-- 网上服务 -->
    <div class="Net_box">
      <div class="Net_name flexar">债<br>权<br>人<br>平<br>台</div>
      <div class="Net_list flexar">
        <div class="Net_lists" v-for="(item,index) in netlists" :key="index" :class="currentnet==index?'Net_lists_active':''" @click="Gonet(item,index)">
          <!-- <img v-bind:src="item.image" alt=""> -->
          <span :class="item.icon"></span>
          <div>{{item.name}}</div>
        </div>
      </div>
      <!-- <div class="Net_name flexar">云<br>办<br>公<br>平<br>台</div>
      <div class="Net_names orangebgcolor" @click="Gonetwork ">
        <span class="iconfont icon-Frame-15"></span>
        <div>协同办公</div>
      </div> -->

    </div>
    <!-- 几大模块 orangebgcolor-->
    <div class="Home_grids " style="display: flex;justify-content: space-between;">
      <div class="Net_names " @click="Gonetwork " style="width: 29.3%;padding: 0 1.5%;
      height:345px;
      font-size: 20px;
      text-align: center;color: orange;background: #E7EEF5;">
        <!-- <div style="height: 7rem;
        line-height: 7rem;">协同办公</div> -->
        <!-- <div class="grids_title flexbe">
          <div class="grids_title_name">协同办公</div>
       </div> -->
        <div style="position: relative;">
          <div style="color: #43A0F5; position: absolute;top: 15px;text-align: center;width: 100%;font-size: 16px;" >协同办公</div>
          <dv-decoration-5 style="width: 90%;height: 25px;margin: 0 auto;position: absolute;top: 30px;left:5%" :color="['#43A0F5','#43A0F5']"  />
        </div>
        <img src="../../image/xie.png" alt="" style="width: 200px;margin-top: 55px;">
        <dv-decoration-6 style="width:80%;height:30px" />
        <!-- <span class="iconfont icon-Frame-15"></span> -->
      </div> 
      <Guanliren v-on:childByValue="childByValue"></Guanliren>
      <Guanli ></Guanli>

      <!-- 
       -->
       <Falv ></Falv>
      <Pochan ></Pochan>

      <Guanlirenx ></Guanlirenx>
      <!-- <Touzi ></Touzi> -->
    </div>
    <!-- 小banner -->
    <img src="../../image/nomember.png" alt="" class="minbanner">
    <Ying style="margin-bottom: 30px;" v-on:childByValue="childByValue" v-if="studylists.length!==0"></Ying>
    <You v-if="youshow==1"></You>
    <div class="Bottom_list" style="text-align: center;font-size: 20px;">友情链接：<a href="http://www.court.gov.cn/" target="_blank">最高人民法院</a> <a href="http://pccz.court.gov.cn/pcajxxw/index/xxwsy" target="_blank">全国破产重整网</a><a href="https://www1.rmfysszc.gov.cn/" target="_blank">诉讼资产网</a><a href="https://sf.taobao.com/?spm=a21bo.jianhua.201859.4.5af911d9rRsAS5" target="_blank">阿里淘宝司法拍卖平台</a><a href="https://auction.jd.com/home.html" target="_blank">京东司法拍卖平台</a></div>

    <Bottom></Bottom>
    <!-- 使用帮助 -->
    <div class="Helpbox" >
      <div>使用</div>
      <div>帮助</div>
      
    </div>

    <!-- 右下角码 -->
    <div class="Fixedcode">
      <div class="codebox">
        <div class="qqbtn" @click="golive">
          <div class="codeicon">
            <img src="../../image/QQ.png" alt="">
          </div>
          <div><a href="tencent://message/?uin=2910401883&Site=Sambow&Menu=yes">在线咨询</a></div>
          <!-- <div class="qqcode" :class="qqshow==1?'qqactive':''">111222333</div> -->
        </div>
        <div class="chatbtn">
          <div class="codeicon">
            <img src="../../image/wx.png" alt="">

          </div>
          <div>小程序</div>
          <div class="code" :class="chatshow==1?'chatactive':''">
            <img src="../../image/ma.jpg" alt="">
          </div>
        </div>
        <!-- <div>
          <div class="iconfont icon-Frame-24 codeicon"></div>
          <div>公众号</div>
        </div> -->
      </div>
     
    </div>
    <!-- 提示框 -->
    <Toast :toastText="content" v-show="toastShow"></Toast>
    <el-dialog title="提示" :visible.sync="dialogTableVisible" >
      <div style="text-align: center;font-size: 15px;" v-if="type=='zhai'">您已有申报的债权,是否申报新债权？</div>
      <div class="flexar" style="height: 50px;" v-if="type=='zhai'">
          <div style="text-align: center;background: #43A0F5;color: #fff;padding: 5px 20px;border-radius: 5px;cursor: pointer;" @click="lookmine">查看我的申报</div>
          <div style="text-align: center;background: orange;color: #fff;padding: 5px 20px;border-radius: 5px;cursor: pointer;" @click="looknew('zhai')">申报新的债权</div>
      </div>
      <div style="text-align: center;font-size: 15px;" v-if="type=='dai'">您已有代理的债权,是否代理新债权？</div>
      <div class="flexar" style="height: 50px;" v-if="type=='dai'">
          <div style="text-align: center;background: #43A0F5;color: #fff;padding: 5px 20px;border-radius: 5px;cursor: pointer;" @click="lookmine">查看我的代理</div>
          <div style="text-align: center;background: orange;color: #fff;padding: 5px 20px;border-radius: 5px;cursor: pointer;" @click="looknew('dai')">代理新的债权</div>
      </div>
  </el-dialog>
  <!-- <el-dialog title="提示" :visible.sync="maiguanvisible">
    <div >如果想使用完整的功能，请使用Chrom72+版本或Firefox66+版本</div>
  </el-dialog> -->
  </div>
</template>
<script>
//header头部
import Header from '../../components/Header.vue'
import Pochan from '../../components/Pochan.vue'
import Falv from '../../components/Falv.vue'
import Guanliren from '../../components/Guanliren.vue'
import Guanlirenx from '../../components/Guanlirenx.vue'
import Touzi from '../../components/Touzi.vue'
import Guanli from '../../components/Guanli.vue'

import Ying from '../../components/Ying.vue'
import You from '../../components/You.vue'
import Bottom from '../../components/Bottom.vue'
import Calendar from '../../components/Calendar.vue'
import Toast from '../../components/Toast.vue'
import Nocontent from '../../components/Nocontent.vue'


import {ZhaiquanList,PcCaseSelfList,ClaimsGetCheck,SystemDocuments,InformationList,personSms,InformationBanner,MeetingLists,OfficeCheck,UploadH5,StudyList  } from '@/request/api';

export default {
  // beforeRouteEnter(to, form, next) {
  // },
  name: 'Home',
  components: {
    Header,
    Pochan,
    Falv,
    Guanliren,
    Guanlirenx,
    Touzi,
    Guanli,
    Ying,
    You,
    Bottom,
    Calendar,
    Toast,
    Nocontent
    // vueSeamless
  },
  data(){
    return{
      baseURL:'https://pc.pochanjie.com',

      bannerList:[ ],
      mark:0,
      timer:'',
      // 选项卡
      tablists:['债务人信息','我的文书','债权人会议预告','日常通知'],
      currenthome:0,
      // 网上服务
      netlists:[{
        name:'债权申报',
        icon:'iconfont icon-Frame-12',
        image:require('../../image/p_zhai.png'),
        path:'/Debtorinformation',
        backcolor:'#E4D194'
      },{
        name:'债权管理',
        icon:'iconfont icon-Frame-11',
        image:require('../../image/p_quan.png'),
        path:'/Manage',
        backcolor:'#58EBD6'
      },{
        name:'会议文件',
        icon:'iconfont icon-Frame-10',
        image:require('../../image/p_hui.png'),
        path:'/File',
        backcolor:'#A2E67D'
      },{
        name:'我的文书',
        icon:'iconfont icon-Frame-9',
        image:require('../../image/p_xia.png'),
        path:'/Download',
        backcolor:'#DC8FA6'
      },{
        name:'选举通道',
        icon:'iconfont icon-Frame-8',
        image:require('../../image/p_xuan.png'),
        path:'/Election',
        backcolor:'#C35DE0'
      },{
        name:'表决通道',
        icon:'iconfont icon-Frame-14',
        image:require('../../image/p_biao.png'),
        path:'/Vote',
        backcolor:'#71D378'
      },{
        name:'债权人会议',
        icon:'iconfont icon-Frame-13',
        image:require('../../image/p_zhai.png'),
        // path:'/Meet',
        path:'/Liveroom',
        backcolor:'#D39928'
      }],
      currentnet:-1,
      current:0,
      // value: '',
      dateCell:'',
      data:{},
      // {
      //   name:'公开文书',
      //   icon:'icon-wj-ws',
      //   path:'',
      //   backcolor:'#A2E67D'
      // },
      chatshow:0,
      qqshow:0,
      // 日历
      demoEvents: [{
        date: '2021/7/22', // Required
        title: '第一次债权人会议', // Required
        id:0
      },{
        date: '2021/7/22', // Required
        title: '第二次债权人会议', // Required
        id:1
      },{
        date: '2021/7/22', // Required
        title: '第三次债权人会议', // Required
        id:2
      }, {
        date: '2021/7/1',
        title: '第四次债权人会议',
        id:3
        // desc: 'description',
        // customClass: 'disabled highlight' // Custom classes to an calendar cell
      }],
      // 公告
      animateUp: false,
      timer:null,
      systemMsg: [
          // {id:1,title:'1.浅析企业破产程序中的涉税问题处理',office:'贵州XXXXX律师事务所',time:'2021-05-10'},
          // {id:2,title:'2.全民带货？小红书外链淘宝权限将大范围开放',office:'贵州XXXXX律师事务所',time:'2021-05-10'},
          // {id:3,title:'3.贾跃亭FF将在纳斯达克上市 股票代码为FFIE',office:'贵州XXXXX律师事务所',time:'2021-05-10'},
          // {id:4,title:'4.浅析企业破产程序中的涉税问题处理',office:'贵州XXXXX律师事务所',time:'2021-05-10'},
          // {id:5,title:'5.全民带货？小红书外链淘宝权限将大范围开放',office:'贵州XXXXX律师事务所',time:'2021-05-10'},
          // {id:6,title:'6.贾跃亭FF将在纳斯达克上市 股票代码为FFIE',office:'贵州XXXXX律师事务所',time:'2021-05-10'},
      ],
      //文书
      documents:[],

      // 通知
      daynoticelist:[],
      // 友情链接是否显示
      youshow:0,
      // 债务人信息列表
      Debtorinformationlist:[],
      detailcurrent:-1,
      supplytype:false,
      logintype:1,
       // 提示框
      content:'',
      toastShow: false,
      dialogTableVisible:false,
      type:'',
      // 九块
      netroles:'',

      studylists:[],
      maiguanvisible:true
    }
  },
  created:function(){

    this.play();
    this.Zhaiquan('');
    this.Gonggao();
    this.Daynotice();
    this.studylist();
    this.Filelist()
    
    // 无缝衔接
    this.$nextTick(() => {
    })
    InformationBanner({  //banner
      field:'banner'
    }).then(res=>{
      this.bannerList=res.data.banner;
    })
    //测试
    // UploadH5({

    // }).then(res=>{

    // })
    
  },
  mounted () {
    // 一格格
    this.timer = setInterval(this.scrollAnimate, 10000);
    // 无缝衔接
    this.$nextTick(() => {
      setTimeout(() => {
        
      }, 500)
    })
　},
  destroyed() {
    this.maiguanvisible=false
    clearInterval(this.timer)
  },
  computed: {
    optionSetting () {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 3000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods:{
    golive:function(){
      // this.$router.push({path:'/Liveroom'})
    },
    studylist:function(){
      StudyList({
        token:localStorage.getItem('token'),
        type:0
      }).then(res=>{
          if(res.code==1){
            this.studylists=res.data;
            
          }
      })
    },
    // 4块
    Zhaiquan:function(name){
      if(localStorage.getItem('token')){
        PcCaseSelfList({
          // company:name,
          token:localStorage.getItem('token')
      }).then(res => {   
          this.Debtorinformationlist=res.data.rows;
          // if(res.code==1){
          // }
      })
      }else{
        ZhaiquanList({
          // company:name
        }).then(res => {   
            this.Debtorinformationlist=res.data.rows;
            // if(res.code==1){
            // }
        })
      }
      
    },
    Filelist:function(){
      SystemDocuments({
        token:localStorage.getItem('token')

      }).then(res=>{
          this.documents=res.data.documents;
      // this.meeting_file=res.data.meeting_file;
      })
    },
    read:function(path){
        window.open(this.baseURL+path);
    },
    debtordetail:function(id,show,type,index){
      this.id=id;
      this.type=type;
      this.detailcurrent=index;
      if(show==1){
          this.content="您若已经通过其他途径申报债权，请点击“补充注册”加入线上债权人团队，以便正常使用本系统收取法院或管理人发送的文书、参加债权人会议。"
          this.toastShow=true;
          setTimeout(() => {
              this.toastShow=false;
              this.supplytype=true;
          }, 3000);
      }else{
          if(type=='zhai'){
              ClaimsGetCheck({
                token:localStorage.getItem('token'),
                case_id:id,
                type:0
              }).then(res => {   
                  if(res.data.check==1){
                      this.dialogTableVisible=true;
                  }else if(res.data.check==0){   //正常流程
                      this.$router.push({path:'/Debtordetail',query:{case_id:id,type:'zhai'}})
                  }else{
                      this.content="申报次数已达上限，请联系管理人"
                      this.toastShow=true;
                      setTimeout(() => {
                          this.toastShow=false
                      }, 1500);
                  }
                  
              })
          }else{
              ClaimsGetCheck({
                token:localStorage.getItem('token'),
                case_id:id,
                type:1
              }).then(res => {   
                  if(res.data.check==1){
                      this.dialogTableVisible=true;
                  }else if(res.data.check==0){   //正常流程
                      this.$router.push({path:'/Debtordetail',query:{case_id:id,type:'dai'}})
                  }else{
                      this.content="申报次数已达上限，请联系管理人"
                      this.toastShow=true;
                      setTimeout(() => {
                          this.toastShow=false
                      }, 1500);
                  }
                  
              })
          }
          
      }
  },
  supply:function(id,type){
      this.$router.push({path:'/Debtordetail',query:{case_id:id,type:type}})
  },
  lookmine:function(){
      this.$router.push({path:'/Manage',query:{case_id:this.id}})
  },
  looknew:function(type){
      this.$router.push({path:'/Debtordetail',query:{case_id:this.id,type:type}})
  },
   
    // 一格格
    scrollAnimate() {
        this.animateUp = true
        setTimeout(() => {
            this.systemMsg.push(this.systemMsg[0])
            this.systemMsg.shift()
            this.animateUp = false
        }, 500)
    },
    // 轮播
    play(){
        this.timer=setInterval(this.autoplay,10000)
    },
    autoplay(){
      if(this.mark<this.bannerList.length-1){
          this.mark++;
      }else{
          this.mark = 0;
      }

    },
    
    change(num){
    this.mark=num;
    },
    stop(){
        clearInterval(this.timer);
        this.timer=null;
    },
    go(){
        this.timer=setInterval(this.autoplay,3000)
    },
    // 选项卡
    tabclick:function(index){
      this.currenthome=index;
    },
    // 协同办公
    Gonetwork:function(){
      // this.content="破产案件的相关人员需要使用平台处理相关事务，请与该案管理人联系，获取账号。"
      // this.toastShow=true;
      // setTimeout(() => {
        
        OfficeCheck({
          token:localStorage.getItem('token')
        }).then(res=>{
            if(res.code==1){
              this.$router.push('/Networks')
            }else{
              this.content="没有绑定管理员账号,请联系工作人员"
              this.toastShow=true;
              setTimeout(() => {
                  this.toastShow=false;
              }, 1500);
            }
        })
      // }, 2000);
      
    },
    Gonet:function(item,index){
      this.currentnet=index;
      if(item.path=='/Liveroom'){
        MeetingLists({
          token:localStorage.getItem('token')
        }).then(res=>{
          if(res.data.length==0){
            this.content="暂时没有会议"
            this.toastShow=true;
            setTimeout(() => {
              this.toastShow=false
            }, 1500);
          }else{
            this.$router.push(item.path)
          }
        })
      }else{
        this.$router.push(item.path)
      }
    },
    // 日常通知
    Daynotice:function(){
      personSms({
        token:localStorage.getItem('token')
      }).then(res => { 
        if(res.code==1){
          this.daynoticelist=res.data
        }
      })
    },
    Gomore:function(){
      this.$router.push({path:'/Daynotice',query:{current:'8'}})
    },
    //我的文书

    //破产公告
    Gonggao:function(){
      InformationList({
        plate:'8',   //公告id
        page:1,
        limit:10,
        pro:'',
        city:'',
        plate_class:0,
        from:'pc'
      }).then(res => { 
        this.systemMsg=res.data.rows;
      })
    },
    Gomorenotice:function(){
      // this.$router.push({path:'/Rulers',query:{plate:'8',current:'3'}})
      this.$router.push({path:'/Download'})
    },
    Gonoticedetail:function(item){
      // this.$router.push({path:'/Rulerdetail',query:{id:id}})
      window.open(item.pdf_input[0].path)
    },
    // 日历
    handleDayChanged(data){
    },
    handleMonthChanged(data){
    },
    //通知详情
    Godaynotice:function(){
      this.$router.push('/Daynotice')
    },
    // 管理人名册权限,直播大课堂
    childByValue: function (childValue) {
        // childValue就是子组件传过来的值
      this.content = childValue
      this.toastShow=true;
      setTimeout(() => {
        this.toastShow=false
      }, 1500);
    },
  }
}
</script>
<style scoped>
  /* 主要部分 */
 .main{
    width: 60%;
    margin: 15px auto;
    margin-top: 0.5rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 1500px;
 }
 .Home_main{
    height: 370px;
    /* background: red; */
    width: 86.4%;
    margin: 50px auto 29px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
 }
 .Home_swiper{
  width: 40.75%;
  position:relative;
 }
 .pic{
    position: absolute;
    /* margin-left: 100px; */
    /* margin-top: 40px; */
    width: 100%;
    height: 370px;
    top: 0;
    z-index: 1;
    

}
.pic img{
  width:100%;
  /* min-width: 1500px; */
  background-size: 100% 100%;
  height:370px;
}
.tu li{
  position:absolute;
  top:0;
  left:0;
  list-style: none;
}
.img-enter-active,
.img-leave-active {
    transition: all 3s;
}
 
.img-enter,
.img-leave-to {
    opacity: 0;
}
 
.img-enter-to,
.img-leave {
    opacity: 1;
}
.aBtn{
  transition:all 3s ease;
  background: rgba(255, 255, 255, 0.6);
  }
.bannerBtn{
    width: 40%;
    position:absolute;
    bottom:9px;
    left: 40%;
    /* height:20px; */
    text-align: center;
    margin: 0;
    padding: 0;
}
.bannerBtn li{
    float:left;
    width: 22px;
    height: 8px;
    border-radius: 5px;
    list-style:none;
    margin-right: 12px;
}
.bannerBtn li a{
    margin-left:2.5px;
    margin-top:1.5px;
    display: block;
    width: 22px;
    height: 8px;
    border-radius: 5px;
}

/*  */
/* 选项卡 */
.Home_tab{
  width: 57.75%;
  background: #fff;
  background: #F5FAFE;
  border: 1px solid #E2E2E8;
  border-radius: 5px;
}
.Home_tab_list{
  height: 50px;
  line-height: 50px;
  display: flex;
  text-align: center;
  font-size: 16px;
  /* font-size: 1.6rem; */
}
.ruglar{
  color: #999;
  /* font-weight: 400; */
  background: #FFFFFF;
  border: 1px solid #E2E2E8;
  border-radius: 5px;
  width: 25%;
  cursor: pointer;
  position: relative;
}

.spec{
  background: #43A0F5;
  border: 1px solid #E2E2E8;
  border-radius: 5px;
  width: 25%;
  color: #fff;
  /* font-family:'siyuanb'; */
  font-family: "siyuanb";
  cursor: pointer;
  position: relative;
}
.Home_tab_list span{
  width: 7px;
  height: 7px;
  background: #FF5C5C;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 16px;
  right: 30%;
}
/* 选项卡内容 */
.Home_tab_con1{
  height: 100%;
  /* font-size: 1.4rem; */
  font-size: 14px;
  padding: 4% 3.5%;
  position: relative;
}
.morebtn{
  position: absolute;
  right: 20px;
  top: 10px;
  color:#43A0F5;
  cursor: pointer;
}
.el-carousel__indicators--horizontal{
  display: none;
}
.el-carousel__arrow{
  display: none;
}
.Home_com_mess{
  width: 60%;
}
.Home_com_mess_name{
  font-size: 16px;
  /* font-size: 1.6rem; */
  margin-bottom: 15px;
  /* padding-left: 5%; */
  line-height: 30px;
  
  font-family:'siyuanb';
}
.Home_com_mess_list{
  line-height: 20px;
  color: #828294;
  font-size: 14px;
  margin: 8px 0;
  /* font-size: 1.4rem; */
  /* font-weight: 400; */
}
.Home_con_btn{
  width: 38.73%;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgb(126 123 160 / 20%);
  border-radius: 5px;
  height: 220px;
  margin-top: 20px;
  margin-right: 10px;
  /* font-weight: 400; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.Home_con_btn div{
  width: 77%;
  height: 40px;
  /* left: 1454px; */
  /* top: 394px; */
  border: 1px solid #E2E2E8;
  border-radius: 5px;
  line-height: 40px;
  color: #fff;
  margin: 0 auto;
  cursor: pointer;
}
/* .Home_con_btn div:nth-child(1){
  margin: 55px auto 30px;
  background: #43A0F5;
  cursor: pointer;
}
.Home_con_btn div:nth-child(2){
  background: #FF9F46;
  margin:0 auto;
  cursor: pointer;
} */
/* 破产公告 */
.Home_po_list{
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.Home_po_list img{
  width: 134px;
  height: 80px;
  /* background: url(image.png); */
  border-radius: 5px;
}
.Home_po_mess{
  position: relative;
    width: 80%;
    /* display: flex; */
    height: 80px;
}
.Home_po_mess div:nth-child(1){
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Home_po_mess div:nth-child(1):hover{
  text-decoration: underline;
  color: #43A0F5;
}
.Home_po_mess div:nth-child(1) span:nth-child(1){
  font-family: 'siyuanb';
}
.Home_po_mess div:nth-child(1) span:nth-child(2){
  color: #43A0F5;
  cursor: pointer;
}
.Home_po_mess div:nth-child(2){
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 16px;
  width: 100%;
  color:#828294;
  display: flex;
  justify-content: space-between;
}
/* 债权人会议预告 */
.calendar{
  width: 458px;
  height: 284px;
  background: #F5FAFE;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 6px 6px 16px rgb(163 177 198 / 40%), -6px -6px 16px rgb(255 255 255 / 60%);
  border-radius: 10px;
  overflow: hidden;
}
.calendar_box{

}
.calendar_list{
  width: 385px;
  background: #E7EEF5;
  border-radius: 0.3rem;
  padding: 0 11px;
  margin-bottom: 9px;
  height: 34px;
}
.calendar_list span:nth-child(1){
  font-size: 18px;
  font-family: 'siyuanb';
  width: 80%;
  display: block;
}
.calendar_list span:nth-child(2){
  color: #43A0F5;
  font-size: 14px;
  cursor: pointer;
}
/* 日常通知 */
.Day_tit{
  font-family: 'siyuanb';
  text-align: center;
  font-size: 16px;
  position: relative;
}
.Day_tit div{
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  color: #43A0F5;
  font-family: 'siyuanr';
  /* height: 100%; */
  line-height: 25px;
  cursor: pointer;
}
.Day_time{
  margin: 16px auto 22px;
  font-size: 14px;

}
.Day_time span:nth-child(1){
  font-family: 'siyuanb';
  font-size: 16px;
}
.Day_con{
  font-size: 14px;
  line-height: 21px;
}
.Day_list{
  border-bottom: 1px dashed rgb(138 138 138 / 30%);
  padding: 15px 0;
  cursor: pointer;
}
/* 网上服务 */
.Net_box{
  width: 86.4%;
  height: 142px;
  margin: 0 auto;
  font-size: 15px;
  /* font-size: 1.5rem; */
  display: flex;
  justify-content: space-between;
  /* font-family:'siyuanb'; */
  font-family: "siyuanb";
  z-index: 999;
}
.Net_name{
  background: #43A0F5;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
  border-radius: 10px;
  width: 3.13%;
  font-size: 16px;
  /* font-size: 1.6rem; */
  color: #fff;
  word-wrap: break-word;
  line-height: 25px;
}
.Net_names{
  width: 10%;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}
.Net_names span{
  /* width: 48px; */
  margin: 0 auto;
  margin-top: 32px;
  font-size: 200px;
  /* font-size: 50px; */
  display: block;
  /* height: 48px; */
}
.Net_list{
  background: #E7EEF5;
  border: 1px solid rgba(112, 110, 140, 0.2);
  box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
  border-radius: 10px;
  /* width: 79.21%; */
  text-align: center;
  width: 95%;
}
.Net_lists{
  width: 12.86%;
  height: 110px;
  transition: all 1s linear;
  
  background: #FFFFFF;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 9px 9px 16px rgb(163 177 198 / 30%), -9px -9px 16px rgb(255 255 255 / 60%);
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  /* font-size: 1.6rem; */
  cursor: pointer;
}
.Net_lists span{
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
  font-size: 40px;
}
.Net_lists_active{
  background: #E7EEF5;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.3), -9px -9px 16px rgba(255, 255, 255, 0.6), inset 9px 9px 16px rgba(163, 177, 198, 0.3);
  border-radius: 10px;
}
 .Net_lists:hover{
  background: #DBE8F4;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.3), -9px -9px 16px rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}
/*
.Net_lists div:nth-child(1){
  font-size: 40px;
  line-height: 80px;
  color: #fff;
} */
/* 模块 */
.Home_grids{
  width: 86.4%;
  margin: 0 auto;
  font-size: 16px;
  /* font-size: 1.6rem; */
  margin-top: 43px;
  flex-wrap: wrap;
}
/* minbanner */
.minbanner{
  width: 86.4%;
  height: 168px;
  margin: 0 auto;
  display: block;
  margin-bottom: 30px;
  border-radius: 5px;
}

.Helpbox{
  position: fixed;
  right: 0.1rem;
  bottom: 1rem;
  z-index: 999;
  background: #E7EEF5;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 9px 9px  16px rgb(163 177 198 / 30%), -9px -9px 16px rgb(255 255 255 / 60%);
  border-radius: 10px;
  font-size: 14px;
  width: 60px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
}
  /* 右下角 */
  .Fixedcode{
    position: fixed;
    right: 13px;
    bottom: 250px;
    z-index: 999;
    font-size: 14px;
  }
  .codebox{
    width: 60px;
    /* height: 224px; */
    height: 170px;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 30%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
  }
  .code{
    position: absolute;
    left: -175px;
    top: 0;
    width: 152px;
    height: 152px;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.3), -9px -9px 16px rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    opacity: 0;
  }
  .code img{
    width: 112px;
    height: 112px;
    background-size: 100% 100%;
    display: block;
    margin: 20px auto;
  }
  .codeicon{
    /* font-size: 30px; */
    margin-top: 18px;
  }
  .codeicon img{
    width: 30px;
    height: 30px;
    margin: 0 auto;
    display: block;
  }
  .chatbtn:hover .code{
    -webkit-transition: all 1.5s;  
    -moz-transition: all 1.5s;  
    -ms-transition: all 1.5s;  
    -o-transition: all 1.5s;  
    transition: all 1.5s;  
    opacity: 1;
  }
  .qqcode{
    position: absolute;
    left: -85px;
    background: #E7EEF5;
    padding: 5px 10px;
    top: 30px;
    opacity: 0;
  }
  .qqbtn:hover .qqcode{
    -webkit-transition: all 1.5s;  
    -moz-transition: all 1.5s;  
    -ms-transition: all 1.5s;  
    -o-transition: all 1.5s;  
    transition: all 1.5s;  
    opacity: 1;
  }


  .bs-sysMsg {
    position: relative;
    display: flex;
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 10px;
    border-radius: 2px;
    color: #e6a23c;
    background-color: #fdf6ec;
    overflow: hidden;
    opacity: 1;
    align-items: center;
    transition: opacity .2s;
}
.bs-sysMsg .msg__content {
    display: table-cell;
    padding: 0 8px;
    width: 100%;
}
/* 一格格滚动 */
  .animate-up {
    transition: all 0.5s ease-in-out;
    transform: translateY(-90px);
  }
/* 无缝衔接滚动 */
  .seamless-warp {
    height: 100%;
    overflow: hidden;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
  }

  li {
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  }
  .Bottom_list a{
    border-right: 1px solid #aaa;
    padding-right: 10px;
    margin-right: 5px;
    color: #aaa;
  }
.Bottom_list a:last-child{
  border-right: none;
}
.Bottom_list a:hover{
  color: red;
}
/* 会议文书 */
.Download_content{
    background: #fff;
    /* border: 2px solid rgba(255, 255, 255, 0.2); */
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    border-radius: 10px;
    border: 1px solid #e7eef5;
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
}
.book_tit{
    font-size: 18px;
    text-align: center;
    height: 49px;
    line-height: 49px;
    border-bottom: 1px solid #E7EEF5;
    color: red;
}
.book_time{
    font-size: 14px;
    /* line-height: 21px; */
    text-align: center;
    
    
    margin-top: 15px;
    margin-bottom: 26px;

}
.book_list{
    width: 95%;
    height: 46px;
    background: #EEF3FA;
    border-radius: 5px;
    margin: 0 auto;
    padding: 0 1.5%;
    margin: 10px;
    font-size: 14px;
    line-height: 46px;
    align-items: center;

}
.book_list:nth-child(1){
    margin-top: 20px;
}
.book_list_btn{
    width: 60px;
    height: 30px;
    border-radius: 5px;
    color: #fff;
    line-height: 30px;
    margin-right: 10px;
    cursor: pointer;
}
.book_list_btn:nth-child(1){
    background: #43A0F5;
}
.book_list_btn:nth-child(2){
    background: #FE9B4B;

}
</style>
