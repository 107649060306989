<template>
  <router-view v-if="isRouterAlive" class="aaaaa">
    
  </router-view>
  
  
</template>

<script>

export default {
  name: 'app',
  provide(){
    return{
      reload:this.reload
    }
  },
  data(){
    return{
      isRouterAlive:true,
      maiguanvisible:true,
      screenWidth: document.body.clientWidth
    }
  },
  components: {
  },
  created(){
    // setTimeout(() => {
    //   this.maiguanvisible=false
    // }, 3000);
  },
  watch:{
    screenWidth(val){
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
          if(!this.timer){
              // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
              this.screenWidth = val
              this.timer = true
              let that = this
              setTimeout(function(){
                  // 打印screenWidth变化的值
                  that.timer = false
              },400)
          }
      }
  },
  mounted () {


  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    reload(){
      this.isRouterAlive=false;
      this.$nextTick(function(){
        this.isRouterAlive=true;
      })
    }
  }
}
</script>

<style>
  @import url('https://at.alicdn.com/t/font_2683431_ahx21c4fg9.css');
  /* ::-webkit-scrollbar {
    display: none;
} */
page,html,body{
  padding: 0;
  margin: 0;
  min-height: 100%;
  background: #EEF3FA;
  width: 100%;
}

@font-face {
  font-family: 'iconfont';  /* project id 2177325 */
  src: url('https://at.alicdn.com/t/font_2177325_clztb86rud.eot');
  src: url('https://at.alicdn.com/t/font_2177325_clztb86rud.eot?#iefix') format('embedded-opentype'),
  url('https://at.alicdn.com/t/font_2177325_clztb86rud.woff2') format('woff2'),
  url('https://at.alicdn.com/t/font_2177325_clztb86rud.woff') format('woff'),
  url('https://at.alicdn.com/t/font_2177325_clztb86rud.ttf') format('truetype'),
  url('https://at.alicdn.com/t/font_2177325_clztb86rud.svg#iconfont') format('svg');
}
.el-dialog {
    width: 1060px;
    /* height: 600px; */
    background: #F5FAFE;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
}

.el-dialog .el-dialog__header {
    border-bottom: 1px solid #E7EEF5;
    text-align: center;
    color: #706e8c;
    font-family: 'siyuanb';
}

.el-dialog .el-dialog__body {
    padding-top: 15px;
}
/* @media screen and (max-width: 768px) {
	  .aaaaa{
      width: 100%;
    }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .aaaaa{
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .aaaaa{
    min-width: 992px;
  }
} */

</style>
