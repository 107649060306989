<template>
  <div style="width: 100%;position: relative;padding-top: 13px;z-index: 999;">
    <!-- <el-header class="header"> -->
      <!-- 顶部 -->
      <div class="Header_image flexbe">
        <!-- 图标+名称 -->
        <div class="Header_img">
          <img src="../image/logo.png" alt="" style="margin-right:21px;">
          破产管理
          <span style="margin-left: 20px;">智能综合平台</span>
          <!-- <img src="../image/title.png" alt="" mode="aspectFill" style="background-size: 100% 100%;"> -->
        </div>
        <!-- 登录&注册 -->
        <div class="Header_top flexbe" v-if="!token">
            <div @click="login" class="loginbtn" style="margin-right: 10px;"><span class="iconfont icon-Frame-31"></span>登录</div>
            <!-- <div @click="login('register')" class="loginbtn"><span class="iconfont icon-Frame-27"></span>注册</div> -->
        </div>
        <div class="Header_top flexbe" v-if="token">
          <img :src="avatar" alt="">
          <div>{{nickname}}</div>
          <div @click="outlogin" class="loginbtn"><span class="iconfont icon-Frame4"></span>退出登录</div>
        </div>
      </div>
      <!-- 导航 -->
      <div class="Header_tab flexbe">
        <div class="Header_tablist" :class="current==index?'tablists_active':''" v-for="(item,index) in menulist" :key="index" @click="tabclick(item.path,index,item.plate)">
          <!-- <img v-bind:src="item.image_a" alt="" v-if="currents==index">
          <img v-bind:src="item.image" alt="" v-else> -->
          <span :class="item.icon"></span>
          <div>{{item.name}}</div>
        </div>
      </div>
    <!-- </el-header> -->
     <!-- 提示框 -->
     <Toast :toastText="content" v-show="toastShow"></Toast>
  </div>
    
</template>
<script>
    import Common from '../request/common.vue'
    import Toast from '../components/Toast.vue'
    import {WechatLoginout } from '@/request/api';
    
export default {
        name: 'Header',
        props: ['current','plate'],
        components: {Toast},
        inject:['reload'],
        data(){
            return{
              avatar:localStorage.getItem('avatar'),
              nickname:localStorage.getItem('nickname'),
              menulist:[
                {
                  name:'首页',
                  icon:'iconfont icon-Frame',
                  image:require('../image/home.png'),
                  // image_a:require('../image/home_a.png'),
                  path:'/'
                },{
                  name:'债务人信息',
                  icon:'iconfont icon-Frame-1',
                  image:require('../image/zhai.png'),
                  // image_a:require('../image/zhai_a.png'),
                  path:'/Debtorinformation'
                },
                {
                  name:'投资人招募',
                  icon:'iconfont icon-Frame-4',
                  image:require('../image/tou.png'),
                  // image_a:require('../image/tou_a.png'),
                  path:'/Rulers',
                  plate:'4'
                },
                {
                  name:'破产公告',
                  icon:'iconfont icon-Frame-6',
                  image:require('../image/po.png'),
                  // image_a:require('../image/po_a.png'),
                  path:'/Rulers',
                  plate:'8'
                },
                {
                  name:'资产拍卖',
                  icon:'iconfont icon-Frame-5',
                  image:require('../image/zi.png'),
                  // image_a:require('../image/zi_a.png'),
                  path:'/Rulers',
                  plate:'5'
                },
                {
                  name:'法律规定',
                  icon:'iconfont icon-Frame-2',
                  image:require('../image/fa.png'),
                  // image_a:require('../image/fa_a.png'),
                  path:'/Rulers',
                  plate:'1'
                },
                {
                  name:'破产前沿',
                  icon:'iconfont icon-Frame-7',
                  image:require('../image/qian.png'),
                  // image_a:require('../image/qian_a.png'),
                  path:'/Rulers',
                  plate:'2'
                },{
                  name:'管理人园地',
                  icon:'iconfont icon-Frame-3',
                  image:require('../image/guan.png'),
                  // image_a:require('../image/guan_a.png'),
                  path:'/Rulers',
                  plate:'9'
                }
               
              ],
              currents:Common.current,
            
              // plates:Common.plate
              token:localStorage.getItem('token'),
              toastShow:false,
              content:''
            }
        },
        filters: {
        },
        mounted() {
          
        },
        beforeDestroy() {
          
        },
        created(){
          if(this.$route.query.current){
            this.currents=this.$route.query.current;
          }
         
        },
        watch: {
          $route: {
            handler: function(route) {
              // 需要进行解码
              let redirectPath = decodeURIComponent(route.fullPath).split('redirect=')[1]
              this.redirect = redirectPath
            },
            immediate: true
          }
        },
        methods:{
          login:function(type){
            this.$router.push({path:'/Loginnew'});
          },
          outlogin:function(){
           
            // this.$store.commit('$_removeStorage');    // 清除登录信息
            this.$router.push({path:'/Loginnew',query:{type:'login'}});
            this.content="已退出登录"
            this.toastShow=true;
            var storage = window.localStorage;
            storage.clear()
            // WechatLoginout({

            // }).then(res =>{
              
                
             
            // })
          },
          // 跳转
          tabclick:function(path,index,plate){
            Common.current=index;
            this.currents=index;
            // this.plates=plate;
            this.$router.push({path:path,query:{plate:plate,current:index}});
            if(path=='/Debtorinformation'){
              // this.$router.go(0)
              this.reload()
            }
          }
        }
	}
</script>
<style scoped>
  .header{
    /* background-image: linear-gradient(#86c3ef,#eee); */
    width: 100%;
    /* height: 2rem; */
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 999;
    /* min-width: 1500px; */

  }
/* 图标+名称 */
.Header_image{
  width: 97%;
  height: 0.83rem;
  margin: 0 auto;
  background: #E7EEF5;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
  border-radius: 8px;
  padding: 0 10px;
}
.Header_img{
  display: flex;
  align-items: center;
  font-size: 18px;
  /* font-family:'siyuanb'; */
  font-family: "siyuanb";
  color: #6F6C99;
  min-width: 25%;
}
.Header_img img{
  margin-right: 4%;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}
/* 登录 */
  .Header_top{
    min-width: 6.5%;
    color: #6F6C99;
    font-size: 13px;
    margin-right: 20px;
  }
  .Header_top img:nth-child(1){
    width: 28px;
    height: 28px;
    border-radius: 3px;
    margin-right: 10px;
  }
/* 导航tab */
.Header_tab{
  width: 86.4%;
  margin: 0 auto;
  font-size: 16px;
  
  height: 63px;
  margin-top: 45px;
  
}
.Header_tablist{
  width: 11.21%;
  text-align: center;
  background: rgba(255,255,255,0.5);
  height: 100%;
  background: #E7EEF5;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
  border-radius: 10px;
  position:relative;
  /* font-family:'siyuanb'; */
  font-family: "siyuanb";
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 0.3rem; */
  font-size: 14px;
  cursor: pointer;
}
.Header_tablist span{
  margin-right: 10px;
  display: block;
  font-size: 0.45rem;
}
.Header_tablist div{
  /* position: absolute;
  top: 0;
  right: -10%; */
  line-height: 60px;
  height: 1rem;
  /* width: 100%; */
}
.Header_tablist:hover{
  background:#DBE8F4;
  color: #706E8C;
}
.tablists_active{
  background: #43A0F5;
  color: #fff;
  box-shadow: 4px 4px 20px rgba(83, 185, 234, 0.5);
  border: 0;
  width: 11.45%;
  height: 1rem;
}
/* .Header_tablist div:nth-child(1){
  font-size: 35px;
  margin: 10px 0;
} */

/* 登录 */
.loginbtn{
  width: 130px;
  height: 33px;
  background: #E7EEF5;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  color: #706E8C;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  justify-content: center;
  margin-left: 10px;
}
.loginbtn span{
  margin-right: 10px;
  font-size: 21px;
}
.loginbtn:hover{
  background: #D7E5F2;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 8px;
}
/* 点击
background: #CCDBEA;
border: 2px solid rgba(255, 255, 255, 0.6);
border-radius: 8px; */
</style>