<template>
    <div class="backcolor" style="min-width: 1200px;">
        <Header></Header>
        <div class="Download_box">
            <!-- <div class="Download_con"> -->
                <div class="Download_tab">
                    <div class="flexar" :class="current==0?'Download_tab_active ':''" @click="mybook('0')">直<br>播<br>大<br>讲<br>堂</div>
                    <img src="../../image/dashed.png" alt="">
                    <div class="flexar" :class="current==1?'Download_tab_active ':''" @click="mybook('1')">学<br>术<br>研<br>讨<br>会</div>
                </div>
                <div style="width: 90%">
                    <div style=" padding: 10px 0;margin: 10px 0;display: flex;flex-wrap: wrap;">
                        <div class="Ying_list" @click="Golive(item.id,item.play_schema,item.is_pay,item.allow,item.back_url)" v-for="(item,index) in studylists" :key="index">
                            <img :src="item.first_image[0]?baseURL+item.first_image[0].path:require('../../image/ying.png')" >
                            <div class="Ying_list_tit">{{item.title}}</div>
                            <div class="Ying_list_desc">{{item.start_time}}——{{item.end_time}}</div>
                        </div>
                        
                    </div>
                    <el-dialog width="30%" title="微信扫码支付" :visible.sync="payVisible">
                        <img :src="payurl" alt="" style="display: block;margin: 0 auto;">
                        <div style="height: 30px;background: green;color: #fff;padding: 5px 10px;border-radius: 5px;margin: 20px auto 0;cursor: pointer;" @click="alreadypay">已支付,请重新进入直播课堂</div>
                    </el-dialog>
                    <Nocontent v-if="studylists.length==0"></Nocontent>

                </div>
               
            <!-- </div> -->
        </div>
    </div>
</template>
<script>
    import Header from '../../components/Header.vue'
    import Nocontent from '../../components/Nocontent.vue'

    import {StudyList,StudyUserInfo} from '@/request/api';

    export default {
        name: 'Livelist',
        components: {
            Header,
            Nocontent
        },
        data(){
            return{
                baseURL:'https://pc.pochanjie.com',
                
                current:0,
                studylists:[],
                payVisible:false,
                payurl:''
            }
        },
        created(){
            this.current=this.$route.query.current;
            this.studylist()

        },
        methods:{
            studylist:function(){
                StudyList({
                    token:localStorage.getItem('token'),
                    type:this.current
                }).then(res=>{
                    if(res.code==1){
						this.studylists=res.data;
					}
                    
                })
            },
            mybook:function(num){
                this.current=num;
                this.studylist()
            },
            Golive:function(id,play_schema,pay,allow,backurl){
                StudyUserInfo({
                    token:localStorage.getItem('token'),
                    study_id:id
                }).then(res=>{
                    this.roles=res.data.roles;
                    if(allow==1){   //正常流程
                        if(pay==1){   //付费
                            if(this.roles=='play'){   //主播不收费
                                this.$router.push({path:'/Livenew',query:{id:id,play_schema:play_schema,roles:this.roles,backurl:backurl}})
                            }else{   //其他收费
                                StudySingle({
                                    token:localStorage.getItem('token'),
                                    study_id:id,
                                    from:'wxpc'
                                }).then(res=>{
                                    if(res.data.look==0){ //支付
                                        this.payVisible=true;
                                        this.payurl='https://pc.pochanjie.com'+res.data.qrcode;
                                    }else {   //不需要支付，直接进
                                        this.$router.push({path:'/Livenew',query:{id:id,play_schema:play_schema,roles:this.roles,backurl:backurl}})

                                    }
                                })
                            }
                        }else{
                            this.$router.push({path:'/Livenew',query:{id:id,play_schema:play_schema,roles:this.roles,backurl:backurl}})
                        }
                    }else if(allow==2){
                        this.$emit('childByValue', '直播未开始')

                    }else if(allow ==3){
                        this.$emit('childByValue', '直播已结束')
                    }
                }) 
                
                
            },
            alreadypay:function(){
                this.payVisible=false;
                this.studylist()
            },
        }
    }
</script>
<style scoped>
.Download_tit{
   font-family: 'siyuanb';
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom:30px ;
}
.Download_box{
    background: #eef3fa;
    padding-bottom: 2rem;
    min-height: 100%;
    display: flex;
    width: 86.4%;
    margin: 0 auto;
    margin-top: 45px;
    justify-content: space-between;
}
/* 选项卡 */
.Download_con{
    position: relative;
}
.Download_tab{
    /* position: absolute;
    left: 13.4rem; */
}
.Download_tab div{
    width: 1.04rem;
    height: 2.76rem;
    background: #E7EEF5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
}
.Download_tab .Download_tab_active{
    background: #43A0F5;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 60%);
    color: #fff;
}
.Download_tab img{
    width: 4px;
    display: block;
    margin: 5px auto;
}
/* 内容 */
.Ying_list{
    background: #F5FAFE;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
    height: 7rem;
    margin-right: 2%;
    margin-bottom: 15px;
    width: 31%;
    cursor: pointer;
}
.Ying_list img{
    width: 98%;
    height: 80%;
    margin: 1% auto;
    display: block;
    border-radius: 5px;
}
.Ying_list_tit{
    font-size: 18px;
    /* font-size: 1.8rem; */
    
    /* line-height: 0.8rem; */
    padding: 0 3%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family:'siyuanb';
    margin-bottom: 10px;

}
.Ying_list_desc{
    font-size: 14px;
    /* font-size: 1.4rem; */
    padding: 0 3%;
    
    line-height: 21px;
    /* font-weight: 400; */
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.morehang{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.Ying_lists{
    height: 216px;
    overflow: hidden;
    margin-bottom: 15px;
    width: 31%;
    margin-right: 20px;
    border-radius: 5px;
    background: #F5FAFE;
    border: 1px solid #E2E2E8;
}
.Ying_lists:nth-child(3n+3){
    margin-right: 0;
}
.Ying_lists img{
    width: 96%;
    height: 62%;
    margin: 2% auto;
    display: block;
    border-radius: 5px;
}
.Ying_lists_tit{
    /* font-size: 16px; */
    font-size: 16px;
    
    line-height: 30px;
    padding: 0 3%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family:'siyuanb';
}
.Ying_lists_desc{
    /* font-size: 14px; */
    font-size: 1.4rem;
    padding: 0 3%;
    
    line-height: 20px;
    /* font-weight: 400; */
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}
</style>